<cg-olb-headline class="channel-switch-call-timeout-title" [title]="'channelSwitch.callTimeout.title' | transloco">
</cg-olb-headline>

<span class="channel-switch-call-timeout--row">
  <cg-icon class="channel-switch-call-timeout--row--number h-7 w-7" [content]="icons[0]"></cg-icon>
  <div>
    <cg-olb-headline
      class="channel-switch--estimated-time-to-call-back"
      [type]="'h2'"
      [title]="'channelSwitch.callTimeout.closeWindow.title' | transloco"
    ></cg-olb-headline>
    <cg-paragraph>{{ "channelSwitch.callTimeout.closeWindow.description" | transloco }}</cg-paragraph>
  </div>
</span>

<span class="channel-switch-call-timeout--row">
  <cg-icon class="channel-switch-call-timeout--row--number h-7 w-7" [content]="icons[1]"></cg-icon>

  <div>
    <cg-olb-headline
      class="channel-switch--estimated-time-to-call-back"
      [type]="'h2'"
      [title]="'channelSwitch.callTimeout.callback.title' | transloco"
    ></cg-olb-headline>

    <cg-paragraph>{{ "channelSwitch.callTimeout.callback.description" | transloco }}</cg-paragraph>
  </div>
</span>
