<section>
  <ng-template #master>
    <form class="flex flex-col" [formGroup]="form" [ngClass]="{ 'min-h-[82svh]': searchClicked$ | async }">
      @if ((searchClicked$ | async) === false) {
        <cg-branding class="mb-4 mt-[23dvh] block w-full [&>div]:mx-auto"></cg-branding>
        <cg-headline
          class="mb-6 block text-center [&>h1]:font-roboto-condensed [&>h1]:text-[26px] [&>h1]:font-bold [&>h1]:leading-8 d:[&>h1]:text-[30px]"
          [content]="'newAppointment.title' | transloco"
        ></cg-headline>
      }

      <cg-new-appointment-search
        id="appointment-search-started"
        class="sticky -top-[1px] min-w-[480px]"
        [appointmentData]="appointmentData"
        [ngClass]="{
          'z-[60] -mx-4 w-full shadow-xl [&>div>form>cg-new-appointment-filter>div]:rounded-none [&>div>form>cg-new-appointment-filter>div]:border-none [&_[data-cy=appointment-filter-icon]]:rounded-none':
            searchIsSticky,
          'w-screen w:part-[input-elements-wrapper]:!px-0': searchIsSticky,
          '[&>div>form>cg-new-appointment-filter>div]:rounded-2xl': !searchIsSticky,
          'mx-auto h-[53svh]': (searchClicked$ | async) === false,
          '!ml-0': hideBtns
        }"
        [skipFormWithExitId]="skipFormWithExitId.bind(this)"
        (searchClicked)="search()"
      ></cg-new-appointment-search>

      <div class="mt-10 flex flex-col gap-4">
        @if (combinedAppointmentLocations$ | async; as locations) {
          @if (searchClicked$ | async) {
            @if (earliestLocationWithAppointment) {
              <cg-new-appointment-earliest-appointment-card
                class="mb-4 t:order-3 t:mb-8"
                [ngClass]="{ 't:!order-1': selectedAllServiceCenters || earliestInSelectedAppointments }"
                [locationWithAppointment]="earliestLocationWithAppointment"
              ></cg-new-appointment-earliest-appointment-card>
            }
            @if (hasAnyAppointments) {
              @for (selectedLocation of locations.selected; track getCardId(i, selectedLocation); let i = $index) {
                <cg-new-appointment-select-card
                  class="t:order-1"
                  [appointments]="selectedLocation.appointments"
                  [expanded]="currentlyExpanded.has(i)"
                  [serviceCenter]="selectedLocation.serviceCenter"
                  [limitDate]="limitDate"
                  (accordionToggled)="toggleCurrentlyExpanded(i)"
                ></cg-new-appointment-select-card>
              }
              @if (shouldDisplayMobileService === false && !selectedAllServiceCenters) {
                <div class="mt-12 t:order-2">
                  <div class="t:mx-auto t:mb-12 t:text-center">
                    @if (hasSelectedAppointments) {
                      <cg-olb-headline
                        class="part-[headline]:!text-lg t:part-[headline]:!text-2xl"
                        [title]="'newAppointment.ealierAppointments.title' | transloco"
                      ></cg-olb-headline>
                    } @else {
                      <cg-olb-headline
                        class="part-[headline]:!text-lg t:part-[headline]:!text-2xl"
                        [title]="'newAppointment.emptyAppointments.title' | transloco"
                      ></cg-olb-headline>
                    }
                    <ng-template #emptyAppointments>
                      <cg-olb-headline
                        class="part-[headline]:!text-lg t:part-[headline]:!text-2xl"
                        [title]="'newAppointment.emptyAppointments.title' | transloco"
                      ></cg-olb-headline>
                    </ng-template>
                  </div>
                </div>
                @if (locations.earlier.length === 0) {
                  <div class="mt-2 flex flex-row items-center gap-2 t:order-3">
                    <div
                      class="box-border size-2 rounded-full border border-black bg-cg-yellow t:size-4 t:border-2"
                    ></div>
                    <span class="text-[13px] t:text-base">{{
                      "newAppointment.ealierAppointments.noAppointments" | transloco
                    }}</span>
                  </div>
                }
                @if (locations.selected.length; as scLength) {
                  <div class="flex flex-col gap-4 t:order-4">
                    @for (earlierLocations of locations.earlier; track getCardId(i, earlierLocations); let i = $index) {
                      <cg-new-appointment-select-card
                        class="t:order-4"
                        [appointments]="earlierLocations.appointments"
                        [expanded]="currentlyExpanded.has(i + scLength)"
                        [serviceCenter]="earlierLocations.serviceCenter"
                        [limitDate]="limitDate"
                        (accordionToggled)="toggleCurrentlyExpanded(i + scLength)"
                      ></cg-new-appointment-select-card>
                    }
                  </div>
                }
              }
            } @else {
              <cg-new-appointment-no-appointments></cg-new-appointment-no-appointments>
            }
          }
        }
      </div>

      @if (shouldDisplayMobileService) {
        <cg-new-appointment-mobile-service-view class="mt-12 block"></cg-new-appointment-mobile-service-view>
      }

      <button
        type="button"
        class="bla pointer flex items-center gap-4"
        [ngClass]="{
          'mt-12': searchClicked$ | async,
          'mt-4': (searchClicked$ | async) === false
        }"
        (click)="goBack()"
      >
        <cg-icon [content]="backIcon" class="size-4 rotate-180 transform"></cg-icon>
        <span class="font-roboto-condensed font-bold text-cg-anthracite">{{
          "newAppointment.btnBack" | transloco
        }}</span>
      </button>
    </form>
  </ng-template>

  <cg-split-view
    class="bg-map block w-full [&>div>div]:p-0"
    [ngClass]="{
      'with-pin h-[80dvh]': (searchClicked$ | async) === false,
      'no-pin': (searchClicked$ | async) === true
    }"
    [drawerTrigger]="false"
    [master]="master"
    [overlayBackwardText]="'newAppointment.appointmentDrawer.buttons.back' | transloco"
    [overlayForwardText]="'newAppointment.appointmentDrawer.buttons.forward' | transloco"
    [showBackground]="false"
    [showBranding]="false"
  ></cg-split-view>
</section>
