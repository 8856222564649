import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { AppointmentFacade, ContactDataFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { format } from "date-fns";
import addWeeks from "date-fns/addWeeks";
import differenceInDays from "date-fns/differenceInDays";
import parseStringToDate from "date-fns/parse";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { ServiceCenterLocation } from "@cg/core/interfaces";
import { GoogleMapsService } from "@cg/core/services";
import { AddFormControls } from "@cg/core/types";
import { PLACEHOLDER } from "@cg/core/ui";
import { doneIcon, phoneIcon, searchIcon } from "@cg/icon";
import { GoogleMapsComponent, LocationsFacade } from "@cg/locations";
import {
  ChannelSwitchReason,
  Driver,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelAppointmentRequestExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  BaseButtonComponent,
  BrandingComponent,
  CalendarHeaderComponent,
  CustomDateAdapter,
  DamageWindow,
  ErrorMessageComponent,
  GooglePlacesInputComponent,
  LocationAutocompleteResult,
  OpportunityFunnelAppointmentPeriod,
  OptionSelectionItem,
  OptionsSelectionComponent,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  Resume,
  ResumeType,
  SplitViewComponent,
  TextInput
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { OpportunityFunnelAppointmentRequestForm } from "./interfaces/opportunity-funnel-appointment-request-form.interface";
interface MappedLocation {
  distance: number;
  longitude: number;
  latitude: number;
  costCenter: string;
  name: string;
}

@Component({
  selector: "cg-opportunity-funnel-appointment-request",
  templateUrl: "./opportunity-funnel-appointment-request.component.html",
  styleUrls: ["./opportunity-funnel-appointment-request.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    MatDatepickerModule,
    BrandingComponent,
    OlbHeadlineComponent,
    GooglePlacesInputComponent,
    BaseButtonComponent,
    OptionsSelectionComponent,
    ErrorMessageComponent,
    RadioButtonGroupComponent,
    GoogleMapsComponent,
    SplitViewComponent
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }]
})
export class OpportunityFunnelAppointmentRequestComponent
  extends BaseDirective<AddFormControls<OpportunityFunnelAppointmentRequestForm>>
  implements OnInit
{
  public calendarHeader = CalendarHeaderComponent;

  public readonly radioGroup: RadioButtonGroup = {
    controlName: "appointmentPeriod",
    buttons: [
      {
        title: this.translocoService.translate("opportunityFunnelAppointmentRequest.radioGroup.morning"),
        radio: { id: "morning", value: OpportunityFunnelAppointmentPeriod.MORNING }
      },
      {
        title: this.translocoService.translate("opportunityFunnelAppointmentRequest.radioGroup.afternoon"),
        radio: { id: "afternoon", value: OpportunityFunnelAppointmentPeriod.AFTERNOON }
      }
    ]
  };

  public serviceCenterLocations$: Observable<ServiceCenterLocation[]> = this.locationsFacade.serviceCenterLocations$;

  public readonly doneIcon: Icon = doneIcon;
  public readonly searchIcon: Icon = searchIcon;
  public readonly phoneIcon: Icon = phoneIcon;

  public readonly searchInput: TextInput = {
    id: "appointment-search",
    controlName: "searchServiceCenterInput",
    placeholder: "opportunityFunnelAppointmentRequest.search.placeholder",
    errorMessage: "opportunityFunnelAppointmentRequest.search.error"
  };

  @ViewChild(GoogleMapsComponent)
  public map: GoogleMapsComponent;
  public scOptions: OptionSelectionItem[] = [];
  public minDate$ = this.appointmentFacade.earliestPossibleAppointmentDate$;
  public maxDate = addWeeks(new Date(Date.now()).setHours(0, 0, 0), 3);
  public submitted = false;
  public userPosition: google.maps.LatLngLiteral;
  public nextButtonText = "opportunityFunnelAppointmentRequest.ctaTitle";

  private selectedDamage: DamageWindow;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    private readonly translocoService: TranslocoService,
    private readonly locationsFacade: LocationsFacade,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly userCentricsService: UserCentricsService,
    private readonly damageFacade: DamageFacade,
    private readonly contactDataFacade: ContactDataFacade,
    private readonly resumeFacade: ResumeFacade,
    @Inject(OLB_CONFIG) private _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get hasCalendarError(): boolean {
    return this.submitted && this.form.touched && !this.form.controls.date.value;
  }

  public get csIsNotSelected(): boolean {
    return !this.form.controls.select.value || this.form.controls.select.value === "placeholder";
  }

  public async ngOnInit() {
    super.ngOnInit();

    this.userCentricsService.activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps);
    this.appointmentFacade.fetchEarliestPossibleAppointmentDate();

    this.locationsFacade.userPosition$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pos: google.maps.LatLngLiteral) => {
        this.userPosition = pos;
        this.cdr.markForCheck();
      });

    this.locationsFacade.search$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.locationsFacade.clearSelectedServiceCenter();
      this.form.controls.select.reset();
    });

    this.locationsFacade.serviceCenterLocations$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((scs: ServiceCenterLocation[]) => !!scs)
      )
      .subscribe((scs: ServiceCenterLocation[]) => {
        this.scOptions = this.locationsToOptions(scs);

        this.cdr.markForCheck();
      });

    this.damageFacade.selectedDamage$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((selectedDamage: DamageWindow) => !!selectedDamage)
      )
      .subscribe((selectedDamage: DamageWindow) => {
        this.selectedDamage = selectedDamage;
        this.cdr.markForCheck();
      });

    combineLatest([
      this.contactDataFacade.mobile$,
      this.contactDataFacade.email$,
      this.contactDataFacade.driver$,
      this.resumeFacade.resumeResponse$
    ])
      .pipe(
        filter(() => isDirectResumeFn(this._olbConfig.entryChannel)),
        tap(([mobile, email, driver, resumeResponse]: [string, string, Driver, Resume]) => {
          if (resumeResponse.resumeType === ResumeType.B2B_IOM) {
            this.nextButtonText = "opportunityFunnelCustomerAddress.ctaTitle";
            if (!email || !mobile) {
              this.nextButtonText = "opportunityFunnelAppointmentRequest.ctaTitle";
            }
            if (!driver.city || !driver.street || !driver.zip) {
              this.nextButtonText = "opportunityFunnelAppointmentRequest.ctaTitleAddress";
            }
          } else {
            this.nextButtonText = "opportunityFunnelCustomerAddress.ctaTitle";
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public initFormGroup(): void {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    this.form = new FormGroup({
      searchServiceCenterInput: new FormControl<string>(null, Validators.required),
      select: new FormControl<string>(null, Validators.required),
      date: new FormControl<string>(null, Validators.required),
      appointmentPeriod: new FormControl<OpportunityFunnelAppointmentPeriod>(null, Validators.required)
    });
  }

  public setFormValues(): void {
    combineLatest([
      this.locationsFacade.search$,
      this.appointmentFacade.desiredAppointmentDate$,
      this.appointmentFacade.desiredPeriod$,
      this.appointmentFacade.selectedServiceCenterIds$
    ])
      .pipe(take(1))
      .subscribe(([search, date, period, scIds]: [string, Date, OpportunityFunnelAppointmentPeriod, string[]]) => {
        this.form.controls.searchServiceCenterInput.setValue(search);
        this.form.controls.date.setValue(date as unknown as string);
        this.form.controls.appointmentPeriod.setValue(period);
        this.form.controls.select.setValue(scIds[0]);
      });
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelAppointmentRequestExitIds> {
    return this.contactDataFacade.mobile$.pipe(
      withLatestFrom(this.contactDataFacade.email$, this.contactDataFacade.driver$, this.resumeFacade.resumeResponse$),
      map(([mobile, email, driver, resumeResponse]: [string, string, Driver, Resume]) => {
        if (isDirectResumeFn(this._olbConfig.entryChannel)) {
          if (resumeResponse.resumeType === ResumeType.B2B_IOM) {
            if (!email || !mobile) {
              return "emailQuery";
            }
            if (!driver.city || !driver.street || !driver.zip) {
              return "newCustomer";
            }
          }

          return "directResume";
        } else {
          return "default";
        }
      })
    );
  }

  public saveForm(): void {
    this.form.markAllAsTouched();
    this.cdr.markForCheck();

    const { date, appointmentPeriod, select } = this.form.value;

    this.appointmentFacade.setDesiredAppointmentDate(date as unknown as Date);
    this.appointmentFacade.setDesiredPeriod(appointmentPeriod);
    this.appointmentFacade.setSelectedServiceCenterIds([select]);

    if (this.form.valid) {
      this.trackAppointmentSelectionEvent(date, appointmentPeriod, select);
    }
  }

  public handleAutocompleteResult({ lat, lng, address }: LocationAutocompleteResult) {
    if (this.userPosition?.lat === lat && this.userPosition?.lng === lng) {
      return;
    }

    const userLocation = {
      lat,
      lng
    };

    this.locationsFacade.setUserLocation(userLocation);
    this.locationsFacade.setSearch(address);
    const eventLabel = address ? "result" : "no-branch";
    this.trackEvent("opportunity-funnel-appointment-result", eventLabel, {
      "search-city": address
    });
  }

  public handleMarkerClicked({ costCenter }: { costCenter: string }) {
    if (!costCenter) {
      return;
    }

    this.getScByCostCenter(costCenter)
      .pipe(filter((sc: ServiceCenterLocation) => !!sc))
      .subscribe((sc: ServiceCenterLocation) => {
        this.locationsFacade.setSelectedServiceCenterOnMapClick(sc);
        this.form.controls.select.setValue(costCenter);
        this.map?.zoomToPosition(sc.latitude, sc.longitude, 17);

        this.cdr.markForCheck();
      });
  }

  public handleSelectionChanged(costCenter: string) {
    if (!costCenter) {
      return;
    }

    if (costCenter === PLACEHOLDER) {
      this.map?.zoomToFitMarkers();
    } else {
      this.handleMarkerClicked({ costCenter });
    }

    this.trackEvent("opportunity-funnel-appointment-filter", "location");
  }

  // original source: https://geodatasource.com/developers/javascript
  public distance(p1: { lng: number; lat: number }, p2: { lng: number; lat: number }) {
    if (p1.lat === p2.lat && p1.lng === p2.lng) {
      return 0;
    }

    const radlat1 = (Math.PI * p1.lat) / 180;
    const radlat2 = (Math.PI * p2.lat) / 180;
    const theta = p1.lng - p2.lng;
    const radtheta = (Math.PI * theta) / 180;

    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    return dist;
  }

  public updateFormDate(date: Date) {
    const value = format(date, "yyyy-MM-dd");
    this.form.controls.date.setValue(value);
  }

  public noSundayFilter(date: Date): boolean {
    return date.getDay() !== 0;
  }

  public goForward(): void {
    this.submitted = true;
    super.goForward();
  }

  public goToChannelSwitch(): void {
    let reason: ChannelSwitchReason;

    switch (this.selectedDamage) {
      case DamageWindow.LEFT_SIDE:
        reason = "SIDE_WINDOW";
        break;
      case DamageWindow.REAR:
        reason = "REAR_WINDOW";
        break;
    }

    this.processFacade.setChannelSwitchReason(reason);
    this.skipFormWithExitId("channelSwitch");
  }

  private trackEvent(
    eventAction: string,
    eventLabel: string,
    opportunityFunnelEventProperties?: Record<string, unknown>
  ): void {
    let event = { eventAction, eventLabel } as TrackingEvent;
    if (opportunityFunnelEventProperties) {
      event = {
        ...event,
        "opportunity-funnel": {
          ...opportunityFunnelEventProperties
        }
      };
    }
    this.trackingService.trackEvent(event);
  }

  private trackAppointmentSelectionEvent(
    selectedDate: string | Date,
    appointmentPeriod: OpportunityFunnelAppointmentPeriod,
    select: string
  ): void {
    this.getScByCostCenter(select)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((sc: ServiceCenterLocation) => {
        const date =
          selectedDate instanceof Date
            ? selectedDate
            : parseStringToDate(selectedDate as unknown as string, "yyyy-MM-dd", new Date(Date.now()));

        const opportunityFunnelEventProperties = {
          "appointment-request-days-until": differenceInDays(
            date.setHours(0, 0, 0),
            new Date(Date.now()).setHours(0, 0, 0)
          ),
          "appointment-request-daytime": appointmentPeriod.toLowerCase(),
          city: sc.city,
          region: sc.sublocality,
          branch: {
            address: `${sc.street} ${sc.streetNumber}`,
            id: sc.costCenter
          }
        };
        this.trackEvent("opportunity-funnel-appointment-selection", "replace", opportunityFunnelEventProperties);
      });
  }

  private getScByCostCenter(costCenter: string): Observable<ServiceCenterLocation> {
    return this.locationsFacade.serviceCenterLocations$.pipe(
      filter((sc: ServiceCenterLocation[]) => !!sc),
      take(1),
      map((serviceCenters: ServiceCenterLocation[]) =>
        serviceCenters.find((s: ServiceCenterLocation) => s.costCenter === costCenter)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private locationsToOptions(scs: ServiceCenterLocation[]) {
    return scs
      .map((loc: ServiceCenterLocation) => ({
        ...loc,
        distance: this.distance(this.userPosition, { lat: loc.latitude, lng: loc.longitude })
      }))
      .sort((a: MappedLocation, b: MappedLocation) => a.distance - b.distance)
      .map((loc: MappedLocation) => ({
        id: loc.name,
        disabled: false,
        text: `${loc.distance.toFixed(1)} km | ${loc.name}`,
        value: loc.costCenter
      }));
  }
}
