import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ToastPopupComponent } from "@cg/shared";

@Component({
  selector: "cg-appointment-resume-info",
  templateUrl: "./appointment-resume-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ToastPopupComponent]
})
export class AppointmentResumeInfoComponent {
  @Output() public clickedClose = new EventEmitter();

  public show = true;

  public closePopup() {
    this.clickedClose.emit();
  }
}
