<hr class="appointment-confirmation--divider" />
<div class="py-4" data-cy="wiper-booked">
  <cg-olb-headline
    class="part-[headline]:x-[!mb-4,text-base] d:part-[headline]:!text-xl"
    [type]="'h3'"
    [title]="'wiperOffer.wipersRemoveProduct.title' | transloco"
  ></cg-olb-headline>
  <div class="flex flex-col justify-between bg-cg-gray-light-80 p-4 d:flex-row">
    <div class="flex flex-row gap-2 d:gap-6">
      <div>
        <cg-picture
          class="pointer-events-none box-border flex size-[88px] flex-col part-[img]:x-[size-[88px],object-cover,object-left-top] d:size-[144px] d:part-[img]:size-[144px] [&>picture]:size-[88px] d:[&>picture]:size-[144px]"
          [content]="wipersPicture()"
          [shouldTranslateAlt]="true"
        >
        </cg-picture>
        <p
          class="absolute z-10 h-[18px] w-[88px] -translate-y-full bg-cg-green pt-[1px] text-center font-roboto-condensed text-[13px] font-bold uppercase leading-[16px] text-cg-white d:h-6 d:w-[144px] d:pt-1"
        >
          {{ "wiperOffer.wipersRemoveProduct.imageOverlayAddedProduct" | transloco }}
        </p>
      </div>
      <div class="w-full">
        <cg-olb-headline
          class="part-[headline]:x-[!mt-0,!mb-1] [&>h3]:!text-[13px] [&>h3]:!leading-5 d:[&>h3]:!text-xl"
          [type]="'h3'"
          [title]="'wiperOffer.wipersRemoveProduct.subtitle' | transloco"
        ></cg-olb-headline>
        <p class="text-[13px] leading-5 text-cg-anthracite d:text-xl">
          {{ "wiperOffer.wipersRemoveProduct.additionalText" | transloco }}
        </p>
        @if (!isTouch()) {
          <div class="mt-2 flex flex-col gap-0.5">
            <p class="text-[13px] text-cg-anthracite-light-30">
              {{ "wiperOffer.wipersRemoveProduct.priceTitle" | transloco }}
            </p>
            <cg-price
              class="mt-2 part-[price-full]:leading-6"
              [value]="wiperPriceIsReduced() === true ? price().price_wiper_reduced : price().price_wiper_regular"
              data-cy="wiper-booked-price"
            ></cg-price>
          </div>
        } @else {
          <div class="flex t:mt-2">
            <cg-price
              class="part-[price-cents]:!text-[11px] part-[price-full]:x-[!text-base,leading-6]"
              [value]="wiperPriceIsReduced() === true ? price().price_wiper_reduced : price().price_wiper_regular"
              data-cy="wiper-booked-price"
            ></cg-price>
          </div>
        }
      </div>
    </div>
  </div>
  <cg-paragraph
    class="part-[text]:x-[mt-4,!text-[13px],!leading-5,text-cg-anthracite-light-30] d:part-[text]:text-xl"
    [content]="{ text: 'wiperOffer.wipersRemoveProduct.removeHintText' | transloco }"
  >
  </cg-paragraph>
</div>
