import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { mobileServiceIcon, pinIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, DrawerComponent, OverlayService } from "@cg/shared";
import { OptimizelyExperiment, USER_DECISION } from "@cg/core/enums";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-no-sc-dialog",
  templateUrl: "./new-appointment-no-sc-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    IconComponent,
    ParagraphComponent,
    BaseButtonComponent
  ]
})
export class NewAppointmentNoScDialogComponent {
  public readonly headlineType = HeadlineType;
  public pinIcon = pinIcon;
  public mobileServiceIcon = mobileServiceIcon;

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly processFacade: ProcessFacade
  ) {}

  public clickedOk(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.overlayService.close({ decision: USER_DECISION.ACCEPT });
  }

  public mobileServiceClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.overlayService.close({ descision: USER_DECISION.DECLINE });

    this.processFacade.setChannelSwitchReason("MOBILE_SERVICE");
    this.processFacade.goForward("channel-switch");
  }
}
