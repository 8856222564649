<div class="mt-3 flex max-w-content flex-col gap-3 text-cg-anthracite d:max-w-[400px]">
  <div class="flex flex-col gap-2">
    <cg-headline
      class="whitespace-nowrap t:[&>h2]:text-[26px] t:[&>h2]:leading-8"
      [content]="'channelSwitch.models.general.title' | transloco"
      [type]="HeadlineType.h2"
    ></cg-headline>
    <cg-paragraph class="w:whitespace-nowrap">{{ "channelSwitch.models.general.paragraph" | transloco }}</cg-paragraph>
  </div>
  <div class="flex flex-col gap-6">
    <form class="flex flex-col gap-6" [formGroup]="form">
      @if (isListVisible) {
        <cg-list [content]="list"></cg-list>
      }
      <div class="flex flex-col gap-6">
        @if (isLpnInputVisible) {
          <div data-cy="channel-switch-lpn-input" class="flex flex-col gap-2">
            <cg-headline
              class="t:[&>h4]:text-[18px] d:[&>h4]:text-[20px]"
              [content]="'channelSwitch.models.general.kfzTitle' | transloco"
              [type]="HeadlineType.h4"
            ></cg-headline>
            <cg-lpn-input class="!mt-0 inline-block"></cg-lpn-input>
          </div>
        }
        <div class="flex flex-col gap-2">
          @if (callbackText$ | async; as callbackText) {
            <cg-headline
              class="block w-full t:[&>h4]:text-[18px] d:[&>h4]:text-[20px]"
              [attr.data-cy]="
                (needFallback$ | async) ? 'channel-switch--subheadline' : 'channel-switch--estimated-time-to-call-back'
              "
              [content]="callbackText"
              [type]="HeadlineType.h4"
            ></cg-headline>
          }
          <cg-channel-switch-phone-input
            #phoneInput
            class="!max-w-full !py-0 d:pb-6"
            [ngClass]="{ 'last:[&>div]:!hidden': !isPhoneInputCheckboxVisible }"
          >
          </cg-channel-switch-phone-input>
        </div>
      </div>
    </form>
    <div class="flex flex-col gap-6">
      <button
        id="channel-switch-call-me-back-btn"
        class="flex flex-row justify-center gap-4 rounded bg-cg-red px-4 py-3 font-roboto-condensed font-bold text-cg-white d:w-56"
        (click)="triggerCallBack()"
      >
        <cg-icon class="h-6 w-6 [&>mat-icon>svg>g>path]:stroke-white" [content]="phoneIcon"></cg-icon>
        {{ "channelSwitch.models.general.forward" | transloco }}
      </button>
      <button class="flex flex-row items-center justify-center gap-2 text-[13px] d:w-56" (click)="openSelfCallPopup()">
        <cg-icon class="h-6 w-6" [content]="cccAgentSmallIcon"></cg-icon>
        <div
          class="text-cg-anthracite-light-30"
          [innerHTML]="'channelSwitch.models.general.selfCall' | transloco"
        ></div>
      </button>
    </div>
  </div>
</div>
