<hr class="appointment-confirmation--divider" />
<div class="py-4" data-cy="wiper-offer">
  <cg-olb-headline [type]="'h3'" [title]="'wiperOffer.wipersAddProduct.title' | transloco"></cg-olb-headline>
  <cg-paragraph
    [content]="{
      text: 'wiperOffer.wipersAddProduct.introduction' | transloco
    }"
  >
  </cg-paragraph>
  <div class="grid">
    <cg-info-button
      type="button"
      class="justify-self-end part-[icon|>mat-icon>svg>path:nth-of-type(1)]:fill-cg-white part-[icon|>mat-icon>svg>path]:fill-cg-anthracite-light-30 part-[icon|>mat-icon>svg>path:nth-of-type(2)]:stroke-cg-anthracite-light-30 part-[icon|>mat-icon>svg>path:nth-of-type(3)]:stroke-cg-anthracite-light-30 part-[icon|>mat-icon>svg>path]:stroke-cg-anthracite-light-30 part-[text]:!text-cg-anthracite-light-30 [&>button>div]:pr-0"
      [detailContent]="detailContentWipersOffer"
      [label]="'wiperOffer.wipersAddProduct.hintLabel'"
    ></cg-info-button>
  </div>
  <div class="flex flex-col gap-2 bg-cg-gray-light-80 p-4 d:flex-row d:gap-6">
    <div>
      <p
        class="vaps-offer--option--recommendation absolute left-[26px] z-10 -translate-y-1/2 bg-cg-yellow px-2 pt-0.5 font-roboto-condensed text-xs font-bold uppercase text-cg-black after:absolute after:left-0 after:top-full after:h-1.5 after:w-1.5 after:bg-[#d6bc10] after:content-[''] after:[clip-path:polygon(0%_0%,100%_0%,100%_100%)] t:left-[34px] d:left-[70px]"
      >
        {{ "wiperOffer.wipersAddProduct.recommendationLabel" | transloco }}
      </p>
      <cg-picture
        class="pointer-events-none box-border flex h-[144px] w-full flex-col part-[img]:x-[h-[144px],w-full,object-cover,object-left-top] d:w-[144px] d:part-[img]:w-[144px] [&>picture]:x-[h-[144px],w-full,max-w-full] d:[&>picture]:w-[144px]"
        [content]="wipersPicture()"
        [shouldTranslateAlt]="true"
      ></cg-picture>
      <cg-picture
        class="z-5 absolute h-5 w-20 -translate-y-8 translate-x-4"
        [content]="boschLogoWhitePicture"
        [shouldTranslateAlt]="true"
      ></cg-picture>
    </div>
    <div class="w-full">
      <cg-olb-headline
        class="part-[headline]:x-[!mt-0,!mb-1] [&>h3]:!text-xl"
        [type]="'h3'"
        [title]="'wiperOffer.wipersAddProduct.subtitle' | transloco"
      ></cg-olb-headline>
      <p class="text-cg-anthracite">{{ "wiperOffer.wipersAddProduct.additionalText" | transloco }}</p>
      <div class="mt-1 flex flex-col justify-between gap-4 d:flex-row">
        <div class="flex flex-col">
          <cg-paragraph class="mb-2 hidden part-[text]:text-[13px] d:block">{{
            "wiperOffer.wipersAddProduct.hintText" | transloco
          }}</cg-paragraph>

          <p class="text-[13px] text-cg-anthracite-light-30">
            {{ "wiperOffer.wipersAddProduct.priceTitle" | transloco }}
          </p>
          @if (wiperPriceIsReduced()) {
            <div class="mb-1 mt-0.5 flex flex-row items-center gap-1" data-cy="wiper-price-reduction">
              <span class="text-base leading-5 text-cg-anthracite">{{
                "wiperOffer.wipersAddProduct.uvp" | transloco
              }}</span>
              <span class="text-base leading-5 text-cg-anthracite line-through"
                >{{ price().price_wiper_regular }}€</span
              >
              <span class="rounded bg-cg-yellow px-1 py-0.5 text-base font-extrabold leading-5"
                >-{{ price().price_wiper_reduction_amount }}€</span
              >
            </div>
            <cg-price
              class="mt-1 part-[price-full]:text-[32px] part-[price-full]:leading-7 part-[price-full]:!text-cg-red d:mt-0"
              [value]="price().price_wiper_reduced"
              data-cy="wiper-price-reduced"
            ></cg-price>
          } @else {
            <cg-price
              class="mt-2 part-[price-full]:leading-6"
              [value]="price().price_wiper_regular"
              data-cy="wiper-price"
            ></cg-price>
          }
        </div>
        <cg-paragraph class="mb-2 block part-[text]:text-[13px] d:hidden">{{
          "wiperOffer.wipersAddProduct.hintText" | transloco
        }}</cg-paragraph>
        @if (bookedProtect() === false) {
          <button
            cg-base-button
            type="button"
            [btnText]="'wiperOffer.wipersAddProduct.addButtonText' | transloco"
            [variation]="'primary'"
            class="max-h-12 w-full d:mr-3 d:max-w-[163px] d:[&>div>span]:mx-4"
            (click)="addWipers()"
            data-cy="add-wipers"
          ></button>
        }
      </div>
    </div>
    @if (bookedProtect() === true) {
      <button
        cg-base-button
        type="button"
        [btnText]="'wiperOffer.wipersAddProduct.addButtonText' | transloco"
        [variation]="'primary'"
        class="max-h-12 w-full self-end d:max-w-[163px] d:[&>div>span]:mx-4"
        (click)="addWipers()"
        data-cy="add-wipers"
      ></button>
    }
  </div>
</div>

<ng-template #detailContentWipersOffer>
  <cg-olb-headline class="[&>*]:!mt-0" [title]="'wiperOffer.info.title' | transloco"></cg-olb-headline>
  <cg-paragraph
    class="text-base font-bold leading-6 text-cg-anthracite"
    [content]="{ text: 'wiperOffer.info.subtitle' | transloco, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <ul class="mt-2 list-disc pl-4 text-cg-anthracite">
    <li [innerHTML]="'wiperOffer.info.list.bulletpoint1' | transloco"></li>
    <li [innerHTML]="'wiperOffer.info.list.bulletpoint2' | transloco"></li>
    <li [innerHTML]="'wiperOffer.info.list.bulletpoint3' | transloco"></li>
    <li [innerHTML]="'wiperOffer.info.list.bulletpoint4' | transloco"></li>
    <li [innerHTML]="'wiperOffer.info.list.bulletpoint5' | transloco"></li>
  </ul>
  <div class="mt-2 flex justify-end">
    <cg-picture
      class="pointer-events-none max-w-[90px]"
      [content]="boschLogoPicture"
      [shouldTranslateAlt]="true"
    ></cg-picture>
  </div>
</ng-template>
