import { CtalinkVariation } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import type { Ctalink } from "@cg/shared";

export const appointmentLinks: { saveToCalendarCtaLink: Ctalink; navigateWithGoogleMaps: Ctalink } = {
  saveToCalendarCtaLink: {
    icon: arrowsIcon,
    link: {
      text: "appointmentConfirmation.ctas.calendar",
      title: "appointmentConfirmation.ctas.calendar",
      href: "",
      routerLink: false,
      target: "_self"
    },
    id: "appointment-confirmation-save-to-calendar",
    ngTemplate: "cgCtalink",
    variation: CtalinkVariation.OLB
  },

  navigateWithGoogleMaps: {
    icon: arrowsIcon,
    link: {
      text: "appointmentConfirmation.ctas.route",
      title: "appointmentConfirmation.ctas.route",
      href: "",
      routerLink: false,
      target: "_blank"
    },
    id: "appointment-confirmation-navigate-to-service-center",
    ngTemplate: "cgCtalink",
    variation: CtalinkVariation.OLB
  }
};
