import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { calendarIcon } from "@cg/icon";
import { AppointmentData, BreakpointService, OverlayService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ShortAppointment } from "../../interfaces/short-appointment.interface";
import { createAppointmentData } from "../../utils/create-appointment-data.util";
import { NewAppointmentDetailComponent } from "../new-appointment-detail/new-appointment-detail.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-one-appointment-view",
  templateUrl: "./new-appointment-one-appointment-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, DatePipe, IconComponent]
})
export class NewAppointmentOneAppointmentViewComponent implements OnInit {
  public calendarIcon = calendarIcon;
  public destroyRef = inject(DestroyRef);

  private isMobile: boolean;

  @Input()
  public appointment: ShortAppointment;

  @Output()
  public readonly appointmentSelected = new EventEmitter<string>();

  private appointmentData: AppointmentData;

  public constructor(
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly overlayService: OverlayService,
    private readonly breakpointService: BreakpointService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.appointmentData = createAppointmentData(this.appointmentFacade, this.damageFacade);

    this.breakpointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
      this.cdr.markForCheck();
    });
  }

  public async selectAppointment(appointmentId: string): Promise<void> {
    if (this.isMobile) {
      this.appointmentData.setAppointmentId(appointmentId);
      this.overlayService.open(NewAppointmentDetailComponent, { appointmentData: this.appointmentData });
    } else {
      this.appointmentSelected?.emit(appointmentId);
    }
  }
}
