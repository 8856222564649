import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon, List } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { ParagraphComponent, Picture, PictureComponent } from "@cg/core/ui";
import { OlbHeadlineComponent, VinForm, vinPicture } from "@cg/olb/shared";
import {
  ComponentOverarchingChangeDetectionService,
  ErrorMessageComponent,
  InputType,
  ListComponent,
  TextInput,
  TextInputComponent
} from "@cg/shared";
import { hintList } from "../../../../models/list.model";

@Component({
  selector: "cg-vin-form",
  templateUrl: "./vin-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    ParagraphComponent,
    PictureComponent,
    TextInputComponent,
    ListComponent,
    ErrorMessageComponent
  ]
})
export class VinFormComponent implements OnInit, ControlValueAccessor {
  @Input()
  public hideInfoButton = false;

  public destroyRef = inject(DestroyRef);
  public form: FormGroup<AddFormControls<VinForm>>;

  protected readonly InputType = InputType;

  public noIcon: Icon = { name: "", src: "", ngTemplate: "cgIcon" };
  public hintList: List = hintList;
  public vinPicture: Picture = vinPicture;
  public input: { vin: TextInput; checksum: TextInput } = {
    vin: {
      id: "vin",
      controlName: "vin",
      placeholder: "vehicleIdentificationNumber.vin.vinInput.placeholder",
      errorMessage: "vehicleIdentificationNumber.vin.vinInput.errorMessage"
    },
    checksum: {
      id: "vin-checksum",
      controlName: "checksum",
      placeholder: "vehicleIdentificationNumber.vin.checksumInput.placeholder",
      errorMessage: "vehicleIdentificationNumber.vin.checksumInput.errorMessage"
    }
  };

  public get hasVinGroupError(): boolean {
    return this.form && this.form.errors ? this.form.errors["invalidVinGroup"] : false;
  }

  public get hasChecksumError(): boolean {
    return this.form ? this.form.controls.checksum.errors !== null : false;
  }

  public onChange = (_value: Partial<VinForm>) => {};
  public onTouched = () => {};

  public constructor(
    private readonly formGroup: FormGroupDirective,
    private readonly fb: FormBuilder,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const vinInputs = this.fb.group<AddFormControls<VinForm>>(
      {
        vin: this.fb.control<string>("", [Validators.required, ...CgValidators.vinValidators()]),
        checksum: this.fb.control<string>("", CgValidators.vinChecksumValidators())
      },
      {
        validators: CgValidators.validateVinGroup("vin", "checksum")
      }
    );

    this.formGroup.form.setControl("vin", vinInputs);
    this.form = this.formGroup.form.get("vin") as FormGroup<AddFormControls<VinForm>>;

    this.changeDetectionService.changeDetectionRequest$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.writeValue(this.form.value as Required<VinForm>);
      this.form.updateValueAndValidity();
      this.cdr.markForCheck();
    });
  }

  public writeValue(value: VinForm): void {
    if (value && Object.keys(value).length > 0) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  public registerOnChange(onChange: (value: VinForm) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
