import { DamageChipCount } from "@cg/shared";
import type { TrackingDamageChipCount } from "@cg/analytics";

export const eventDamageChipCountMapping: Map<DamageChipCount, TrackingDamageChipCount> = new Map<
  DamageChipCount,
  TrackingDamageChipCount
>([
  [DamageChipCount.TWO, "2-chips"],
  [DamageChipCount.THREE, "3-chips"],
  [DamageChipCount.MORE_THAN_THREE, "more-than-3-chips"]
]);
