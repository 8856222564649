import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  DamagedRearWindowCount,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelDamageRearExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { OpportunityFunnelDamageRearForm } from "../opportunity-funnel-damage-rear/interfaces/opportunity-funnel-damage-rear-form.interface";
import { opportunityFunnelDamageRearTileContent } from "./models/opportunity-funnel-damage-rear-tile-content.model";

@Component({
  selector: "cg-opportunity-funnel-damage-rear",
  templateUrl: "./opportunity-funnel-damage-rear.component.html",
  styleUrls: ["./opportunity-funnel-damage-rear.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DamageAssessmentTileComponent, SplitViewComponent]
})
export class OpportunityFunnelDamageRearComponent extends BaseDirective<
  AddFormControls<OpportunityFunnelDamageRearForm>
> {
  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    private readonly damageFacade: DamageFacade,
    @Inject(OLB_CONFIG) private _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public opportunityFunnelDamageRearTileContent = opportunityFunnelDamageRearTileContent;

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<OpportunityFunnelDamageRearForm>>({
      damagedRearWindowCount: new FormControl<DamagedRearWindowCount>(null, Validators.required)
    });

    this.form?.valueChanges
      .pipe(
        distinctUntilChanged(
          (formA: OpportunityFunnelDamageRearForm, formB: OpportunityFunnelDamageRearForm) =>
            formA.damagedRearWindowCount === formB.damagedRearWindowCount
        ),
        filter((value: OpportunityFunnelDamageRearForm) => !!value.damagedRearWindowCount),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.goForward());
  }

  public get damageRear(): DamagedRearWindowCount {
    return this.form.controls.damagedRearWindowCount.value;
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelDamageRearExitIds> {
    this.trackDamageRear();
    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      return of("directResume");
    }
    return of("default");
  }

  public trackDamageRear(): void {
    const eventLabel = this.damageRear === DamagedRearWindowCount.SINGLE ? "one-window" : "more-than-one-window";
    this.trackingService.trackEvent({
      eventAction: "opportunity-funnel-damage-selection",
      eventLabel,
      "opportunity-funnel": {
        "damage-selection": eventLabel
      }
    } as Partial<TrackingEvent>);
  }

  public saveForm() {
    this.damageFacade.setDamagedRearWindowCount(this.damageRear);
  }

  public setFormValues(): void {
    this.damageFacade.damagedRearWindowCount$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((damagedRearWindowCount: DamagedRearWindowCount) => {
        this.form.controls.damagedRearWindowCount.setValue(damagedRearWindowCount);
      });
  }
}
