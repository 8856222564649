import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { OlbFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { isDirectResumeFn, OlbHeadlineComponent } from "@cg/olb/shared";
import { CtaComponent, SafeContentPipe } from "@cg/shared";
import { navigateToHome } from "../../models/navigate-to-home.model";

@Component({
  selector: "cg-channel-switch-fallback-success",
  templateUrl: "./channel-switch-fallback-success.component.html",
  styleUrls: ["./channel-switch-fallback-success.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, SafeContentPipe, OlbHeadlineComponent, CtaComponent]
})
export class ChannelSwitchFallbackSuccessComponent implements OnInit {
  public navigateToHome = navigateToHome;

  public showCtaButton = true;

  public constructor(
    private readonly processFacade: ProcessFacade,
    private readonly olbFacade: OlbFacade,
    private readonly router: Router,
    @Inject(OLB_CONFIG) private _olbConfig: OlbConfiguration
  ) {}

  public ngOnInit(): void {
    this.showCtaButton = isDirectResumeFn(this._olbConfig.entryChannel) !== true;
  }

  public async terminateOlb() {
    this.processFacade.exitOlb();
    this.olbFacade.resetOLB();
    this.olbFacade.initOLB();

    await this.router.navigate(["/"]);
  }
}
