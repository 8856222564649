import type { TextInput } from "@cg/shared";

export interface CustomerAddressInputs {
  insuranceCompanyInput: TextInput;
  insuranceFirstnameInput: TextInput;
  insuranceLastnameInput: TextInput;
  insuranceStreetInput: TextInput;
  insuranceZipInput: TextInput;
  insuranceCityInput: TextInput;
  insurancePhoneInput: TextInput;
}

export const inputFields: CustomerAddressInputs = {
  insuranceCompanyInput: {
    id: "customer-address-insurance-company",
    controlName: "insuranceCompany",
    placeholder: "customerAddress.inputs.company.placeholder",
    errorMessage: "customerAddress.inputs.company.error"
  },
  insuranceFirstnameInput: {
    id: "customer-address-insurance-firstname",
    controlName: "insuranceFirstname",
    placeholder: "customerAddress.inputs.firstname.placeholder",
    errorMessage: "customerAddress.inputs.firstname.error"
  },
  insuranceLastnameInput: {
    id: "customer-address-insurance-lastname",
    controlName: "insuranceLastname",
    placeholder: "customerAddress.inputs.lastname.placeholder",
    errorMessage: "customerAddress.inputs.lastname.error"
  },
  insuranceStreetInput: {
    id: "customer-address-insurance-street",
    controlName: "insuranceStreet",
    placeholder: "customerAddress.inputs.street.placeholder",
    errorMessage: "customerAddress.inputs.street.error"
  },
  insuranceZipInput: {
    id: "customer-address-insurance-zip",
    controlName: "insuranceZip",
    placeholder: "customerAddress.inputs.zip.placeholder",
    errorMessage: "customerAddress.inputs.zip.error"
  },
  insuranceCityInput: {
    id: "customer-address-insurance-city",
    controlName: "insuranceCity",
    placeholder: "customerAddress.inputs.city.placeholder",
    errorMessage: "customerAddress.inputs.city.error"
  },
  insurancePhoneInput: {
    id: "customer-address-insurance-phone",
    controlName: "insurancePhone",
    placeholder: "customerAddress.inputs.phone.placeholder",
    errorMessage: "customerAddress.inputs.phone.error"
  }
};
