import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { map, take, withLatestFrom } from "rxjs/operators";
import { DamageFacade } from "@cg/olb/state";
import { HashMap, TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { RepairConfig } from "@cg/core/interfaces";
import { cccSmallIcon, costsIcon, repairIcon, successIcon } from "@cg/icon";
import { InsuranceType, OlbFooterComponent } from "@cg/olb/shared";
import { Button, ConfigFacade, OverlayFooterComponent, RequiredService } from "@cg/shared";
import { OVERLAYFLOW_COMPONENTS } from "@cg/core/enums";
import { InsuranceMessageGridItem } from "../../interfaces/insurance-message-grid-form.interface";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { InsuranceBaseMessageComponent } from "../insurance-base-message/insurance-base-message.component";
import { InsuranceMessageNavigation } from "../interfaces/insurance-message-navigation.interface";

@Component({
  selector: "cg-insurance-declined-message",
  templateUrl: "./insurance-declined-message.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InsuranceBaseMessageComponent, OverlayFooterComponent, OlbFooterComponent, TranslocoPipe]
})
export class InsuranceDeclinedMessageComponent
  extends InsuranceBaseMessageComponent<InsuranceTypeForm>
  implements OnInit, InsuranceMessageNavigation
{
  public destroyRef = inject(DestroyRef);

  public readonly successIcon: Icon = successIcon;

  public gridItems: InsuranceMessageGridItem[] = [];

  public footerButtons: Button[];

  public headlineTranslationKey: string;

  public subHeadlineTranslationKey: string;

  public descriptionTranslationKey: string;

  public showCallbackFooter = false;

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly damageFacade: DamageFacade,
    private readonly configFacade: ConfigFacade,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit() {
    this.footerButtons = [
      {
        id: "switchChannelButton",
        text: this.translocoService.translate("insuranceDeclinedMessage.footerButton")
      }
    ];
    this.damageFacade.requiredService$
      .pipe(
        take(1),
        map((requiredService: RequiredService): boolean => requiredService === RequiredService.REPAIR),
        withLatestFrom(this.configFacade.repairConfig$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([isRepair, repairConfig]: [boolean, RepairConfig]) => {
        this.createInputValues(isRepair, repairConfig);
        if (isRepair) {
          this.showCallbackFooter = [InsuranceType.PAID_BY_PERPETRATOR].includes(
            this.form.controls.insuranceType.value
          );
        } else {
          this.showCallbackFooter = [
            InsuranceType.LIABILITY,
            InsuranceType.NONE,
            InsuranceType.PAID_BY_PERPETRATOR
          ].includes(this.form.controls.insuranceType.value);
        }
        this.cdr.detectChanges();
      });
  }

  public onClosedClicked(): void {
    this.form.controls.overlayClose.setValue(true);
    this.form.controls.insuranceType.setValue(null);
  }

  public onGoForwardClicked(): void {
    this.form.controls.lastOverlayShown.setValue(true);
  }

  public onGoBackClicked(): void {
    this.form.controls.nextOverlay.setValue(OVERLAYFLOW_COMPONENTS.INSURANCE_TYPE_SELECTION);
    this.form.controls.insuranceType.setValue(null);
  }

  private createInputValues(isRepair: boolean, repairConfig: RepairConfig): void {
    const insuranceTypeValue: InsuranceType = this.form.controls.insuranceType.value;
    const translationKeyStart = `insuranceDeclinedMessage.${isRepair ? "repair" : "replace"}`;
    const insuranceTypeValueString = insuranceTypeValue.toString().replace(new RegExp("_", "g"), "").toLowerCase();

    this.headlineTranslationKey = `${translationKeyStart}.${insuranceTypeValueString}.headline`;
    if ([InsuranceType.LIABILITY, InsuranceType.NONE].includes(insuranceTypeValue)) {
      if (isRepair) {
        this.gridItems = [
          this.createGridItem(
            repairIcon,
            `${translationKeyStart}.${insuranceTypeValueString}.items.text1`,
            "items-start"
          ),
          this.createGridItem(
            costsIcon,
            `${translationKeyStart}.${insuranceTypeValueString}.items.text2`,
            "items-start",
            {
              priceGm1: repairConfig?.price_gm_1.replace(".", ",") ?? "",
              priceGm2_3: repairConfig?.price_gm_3.replace(".", ",") ?? ""
            }
          )
        ];
      } else {
        this.gridItems = [
          this.createGridItem(
            cccSmallIcon,
            `${translationKeyStart}.${insuranceTypeValueString}.items.text1`,
            "items-start m:items-center"
          )
        ];
      }
    } else if (insuranceTypeValue === InsuranceType.PAID_BY_PERPETRATOR) {
      this.gridItems = [
        this.createGridItem(cccSmallIcon, `${translationKeyStart}.paidbyperpetrator.items.text1`, "items-center")
      ];
    }

    if (isRepair && [InsuranceType.NONE, InsuranceType.LIABILITY].includes(insuranceTypeValue)) {
      this.subHeadlineTranslationKey = `${translationKeyStart}.${insuranceTypeValueString}.subHeadline`;
    } else {
      this.descriptionTranslationKey = `${translationKeyStart}.${insuranceTypeValueString}.description`;
    }
  }

  private createGridItem(
    icon: Icon,
    textKey: string,
    flexItemCssClass: string,
    textParams?: HashMap
  ): InsuranceMessageGridItem {
    return {
      icon,
      paragraph: {
        text: this.translocoService.translate(textKey, textParams),
        ngTemplate: "cgParagraph"
      },
      flexItemCssClass
    };
  }
}
