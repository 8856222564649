<cg-icon [content]="pictureContent"></cg-icon>

<cg-olb-headline [title]="'damageLocationDetails.title' | transloco"></cg-olb-headline>

<cg-olb-headline [title]="'damageLocationDetails.edgeTitle' | transloco" [type]="'h2'"></cg-olb-headline>
<cg-paragraph> {{ "damageLocationDetails.edgeText" | transloco }}</cg-paragraph>

<cg-olb-headline [title]="'damageLocationDetails.driverViewTitle' | transloco" [type]="'h2'"></cg-olb-headline>
<cg-paragraph
  [content]="{
    text: 'damageLocationDetails.driverViewText' | transloco
  }"
></cg-paragraph>

<cg-olb-headline [title]="'damageLocationDetails.otherTitle' | transloco" [type]="'h2'"></cg-olb-headline>
<cg-paragraph
  [content]="{
    text: 'damageLocationDetails.otherText' | transloco
  }"
>
</cg-paragraph>
