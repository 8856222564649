import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { CallbackTimePipe } from "../../pipes/callback-time.pipe";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-countdown",
  templateUrl: "./channel-switch-countdown.component.html",
  styleUrls: ["./channel-switch-countdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, CallbackTimePipe, OlbHeadlineComponent]
})
export class ChannelSwitchCountdownComponent extends ChannelSwitchBaseComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public countDown: number;
  @HostBinding("@fadeIn") protected fadeIn = true;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private channelSwitchFacade: ChannelSwitchFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.handleCountdownUI();
  }

  private handleCountdownUI() {
    this.channelSwitchFacade.countDown$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe((countDown: number) => {
        this.countDown = countDown;
        this.cdr.markForCheck();
      });
  }
}
