@if (toastVisible) {
  <cg-appointment-filter-static-toast (closeClicked)="hideToast()"></cg-appointment-filter-static-toast>
}

<div class="flex flex-row items-center gap-2 pl-2" (click)="showToast()">
  <cg-icon class="h-6 w-6 [&>mat-icon>svg>g#ui]:fill-black" [content]="pinIcon"></cg-icon>
  <div class="flex flex-col font-roboto">
    <cg-paragraph class="font-bold">{{ location.city }}</cg-paragraph>
    <cg-paragraph class="[&>p]:!text-[13px] [&>p]:!leading-5 [&>p]:!text-cg-anthracite-light-30"
      >{{ location.street }}, {{ location.postalCode }} {{ location.city }}</cg-paragraph
    >
  </div>
</div>
