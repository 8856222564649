import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { RichtextComponent } from "@cg/core/ui";
import { ExitIds, OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { Ctalink, CtalinkComponent, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { additionalOptions } from "./models/additional-options.model";

@Component({
  selector: "cg-appointment-already-exists",
  templateUrl: "./appointment-already-exists.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, OlbHeadlineComponent, RichtextComponent, CtalinkComponent, SplitViewComponent]
})
export class AppointmentAlreadyExistsComponent extends BaseDirective<null> implements OnInit {
  public additionalOptions: { firstCtaLink: Ctalink; secondCtaLink: Ctalink } = additionalOptions;

  public get lpn$(): Observable<string> {
    return this.damageFacade.rawLpn$.pipe(
      filter((lpn: string) => !!lpn),
      map((lpn: string) => lpn.toUpperCase())
    );
  }
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private damageFacade: DamageFacade,
    protected trackingService: TrackingService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public getExitIdForSavedForm(): Observable<ExitIds> | undefined {
    return undefined;
  }

  public initFormGroup() {}

  public saveForm(): void {
    return;
  }

  public setFormValues(): void {
    return;
  }

  public onTrack() {
    this.trackingService.trackEvent({
      eventAction: "tap-to-call",
      eventLabel: this.additionalOptions.secondCtaLink.link.text
    });
  }

  public trackClickToMyCarglass() {
    this.trackingService.trackEvent({
      eventAction: "my-carglass",
      eventLabel: "appointment-already-exists"
    });
  }
}
