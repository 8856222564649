<div class="relative cursor-pointer" (click)="bookAppointment($event)">
  <cg-badge
    class="absolute -left-2 -top-4"
    [text]="'newAppointment.earliestAppointmentCard.badge' | transloco"
  ></cg-badge>
  <div class="flex w-full flex-row items-center gap-3 rounded-lg bg-white px-3 py-2 shadow-md t:x-[gap-6,px-6,py-4]">
    <cg-new-appointment-circle>
      <cg-new-appointment-distance-label
        [distance]="locationWithAppointment.serviceCenter.distance"
      ></cg-new-appointment-distance-label>
    </cg-new-appointment-circle>
    <div class="min-w-0">
      <div
        class="overflow-hidden text-ellipsis whitespace-nowrap font-roboto-condensed text-cg-anthracite t:text-2xl"
        [title]="locationWithAppointment.serviceCenter.city + ' - ' + locationWithAppointment.serviceCenter.sublocality"
      >
        <span class="font-bold">
          {{ locationWithAppointment.serviceCenter.city }}
          @if (locationWithAppointment.serviceCenter.sublocality) {
            -
          }
        </span>
        {{ locationWithAppointment.serviceCenter.sublocality }}
      </div>
      <div class="flex flex-row font-roboto-condensed text-[13px] t:text-base">
        <div class="flex items-center gap-1 px-1 py-0.5 text-cg-anthracite">
          <span class="font-bold"
            >{{ locationWithAppointment.appointment.customerAppointmentStart | date: "EE" | slice: 0 : 2 }}
          </span>
          <span>{{ locationWithAppointment.appointment.customerAppointmentStart | date: "dd.MM" }}</span>
        </div>
        <div class="rounded border border-solid border-cg-yellow bg-cg-yellow-light-95 px-1 py-0.5 text-cg-anthracite">
          {{ locationWithAppointment.appointment.customerAppointmentStart | date: "HH:mm" }} -
          {{ locationWithAppointment.appointment.customerAppointmentEnd | date: "HH:mm" }}
        </div>
      </div>
    </div>
    <button
      cg-base-button
      class="ml-auto w-16 part-[text]:text-[13px] part-[icon-text-wrapper]:x-[h-8,px-4] t:w-fit t:part-[text]:text-base t:part-[icon-text-wrapper]:x-[h-12,px-4,py-3]"
      [btnText]="'newAppointment.earliestAppointmentCard.button' | transloco"
      (click)="bookAppointment($event)"
    ></button>
  </div>
</div>
