<section class="damage-side-window--container">
  <ng-template #master>
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline
          class="damage-side-window--headline"
          [title]="'opportunityFunnelDamageSide.title' | transloco"
        ></cg-olb-headline>
        <cg-damage-assessment-tile
          class="damage-side-window--selection"
          [content]="opportunityFunnelDamageSideTileContent"
        ></cg-damage-assessment-tile>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
