<section class="contact-time-container">
  <cg-split-view [master]="master"></cg-split-view>
</section>

<ng-template #master>
  @if (loaded) {
    <div class="contact-time">
      <div class="contact-time--headline-form-wrapper">
        <cg-olb-headline class="contact-time--headline" [title]="title$ | async"></cg-olb-headline>
        @if ((isFallbackNeeded$ | async) === false) {
          <form [formGroup]="form">
            @if (callOptions$ | async) {
              <cg-radio-button-group
                class="contact-time--radio-group"
                [content]="callOptions$ | async"
                [formControlName]="(callOptions$ | async).controlName"
              ></cg-radio-button-group>
            }
          </form>
        }
      </div>
      <cg-paragraph class="contact-time--paragraph" [content]="callText$ | async"></cg-paragraph>
      <cg-paragraph class="contact-time--paragraph-subline" [content]="callTextSubline$ | async"></cg-paragraph>
      <button
        cg-base-button
        class="mt-4 w-full part-[icon-text-wrapper]:px-4 t:mt-6 t:w-fit"
        [btnText]="'opportunityFunnelContactTime.ctaTitle' | transloco"
        (click)="goForward()"
        data-cy="btn-next"
      ></button>
    </div>
  }
</ng-template>
