<section>
  <ng-template #additionalInformation>
    @if ({ hasSelectedAppointment: hasSelectedAppointment$ | async }; as data) {
      @if (data.hasSelectedAppointment) {
        <div class="mt-4 text-left m:px-4 d:px-10 w:px-15">
          <cg-appointment-detail [appointmentData]="appointmentData"></cg-appointment-detail>
        </div>
      }
      @if (!data.hasSelectedAppointment) {
        <cg-tesla-appointment-map
          [appointmentData]="appointmentData"
          [useNewMap]="this.numberOfScs > 16"
        ></cg-tesla-appointment-map>
      }
    }
  </ng-template>
  <ng-template #teslaSubHeadline>
    @if ((hasSelectedAppointment$ | async) === false) {
      <cg-olb-headline
        class="header text-lg font-medium text-cg-green"
        type="h2"
        [title]="'teslaAppointmentRequest.subHeadline' | transloco: { count: numberOfScs }"
      ></cg-olb-headline>
    }
  </ng-template>
  <ng-template #master>
    <div class="w:flex w:flex-row">
      <form
        class="w-full px-4 py-4 pb-0 pt-4 t:px-6 t:pb-0 t:pt-6 w:w-1/2 w:px-15 w:py-10 fhd:w-2/5"
        [formGroup]="form"
      >
        <cg-branding class="my-4 t:my-6"></cg-branding>
        <cg-tesla-appointment-search
          #teslaAppointmentSearch
          [ngClass]="{ selected: !isScSelected }"
          [savedEvent]="savedEvent"
          [appointmentData]="appointmentData"
          [skipFormWithExitId]="skipFormWithExitId.bind(this)"
        ></cg-tesla-appointment-search>
      </form>
      @if (isWidescreen$ | async) {
        <div
          class="bg-cg-gray-light-80 text-center w:w-1/2 w:py-24 fhd:w-3/5 fhd:py-10"
          [ngClass]="{ 'w:py-10 fhd:py-10': hasSelectedAppointment$ | async }"
        >
          <ng-container *ngTemplateOutlet="additionalInformation"> </ng-container>
          <ng-container *ngTemplateOutlet="teslaSubHeadline"> </ng-container>
        </div>
      }
      @if (isScSelected && (isWidescreen$ | async) === false) {
        <div class="w-full text-center t:mb-6 w:w-1/2">
          <ng-container *ngTemplateOutlet="teslaSubHeadline"> </ng-container>
          <ng-container *ngTemplateOutlet="additionalInformation"> </ng-container>
        </div>
      }
    </div>
  </ng-template>
  <cg-split-view class="cg-split-view" [master]="master" [showBranding]="false"></cg-split-view>
  <cg-olb-footer
    class="truncate rounded-lg rounded-t-none"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="forwardBtnClicked()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
