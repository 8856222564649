export const teslaScToMapModel = {
  "112": "sc_id_1", // Lübeck
  "129": "sc_id_2", // Bremen
  "114": "sc_id_3", // Norderstedt
  "153": "sc_id_4", // Herne
  "494": "sc_id_5", // Mönchengladbach
  "447": "sc_id_6", // Köln
  "164": "sc_id_7", // Kassel
  "232": "sc_id_8", // Frankfurt
  "362": "sc_id_9", // Heilbronn
  "174": "sc_id_10", // Berlin
  "105": "sc_id_11", // Leibzig
  "292": "sc_id_12", // Nürnberg
  "136": "sc_id_13", // Hannover
  "361": "sc_id_14", // Ulm
  "284": "sc_id_15", // Kaiserslautern
  "360": "sc_id_16", // München
  "496": "sc_id_17", // Bonn
  "324": "sc_id_18", // Stuttgart
  "237": "sc_id_19", // Wiesbaden
  "354": "sc_id_20" // Dachau
};
