<section>
  <ng-template #master>
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline class="mb-4 block d:mb-6" [title]="'damageLocation.title' | transloco"></cg-olb-headline>
        <div
          class="grid grid-flow-row grid-cols-1 gap-2 t:grid-flow-col t:grid-cols-2 t:gap-6 d:grid-flow-col d:grid-cols-2 d:gap-6"
        >
          <cg-icon
            class="block w-full bg-cg-gray-light-70 t:mb-4 d:mb-3 [&>mat-icon]:h-full [&>mat-icon]:min-h-[9.25rem] [&>mat-icon]:w-full"
            [content]="pictureContent"
          ></cg-icon>
          <div class="flex w-full flex-col">
            <cg-radio-button-group
              [content]="damageLocationOptions"
              [formControlName]="damageLocationOptions.controlName"
              (hover)="onHover($event)"
              (hoverLeave)="onHoverLeave()"
            ></cg-radio-button-group>
            <cg-info-button
              class="ml-auto mt-2 block t:mt-0 d:mt-2 [&>button>div]:pr-0"
              [detailContent]="detailContent"
            ></cg-info-button>
          </div>
        </div>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>

<ng-template #detailContent>
  <cg-damage-location-details></cg-damage-location-details>
</ng-template>
