<section>
  <ng-template #master>
    <cg-branding class="mt-4 [&>div]:mx-auto"></cg-branding>
    <cg-headline
      class="mb-6 mt-3 block text-center [&>h1]:font-roboto-condensed [&>h1]:text-[26px] [&>h1]:font-bold [&>h1]:leading-8"
      [content]="'newAppointment.title' | transloco"
    ></cg-headline>

    <form class="flex flex-col" [formGroup]="form">
      <cg-new-appointment-search
        id="appointment-search-started"
        class="sticky -top-[1px] z-[60]"
        [appointmentData]="appointmentData"
        [ngClass]="{
          'z-60 -mx-4 shadow-xl t:-mx-6 [&>div>form>cg-new-appointment-filter>div>div>div[data-cy=appointment-filter-icon]]:rounded-none [&>div>form>cg-new-appointment-filter>div]:rounded-none [&>div>form>cg-new-appointment-filter>div]:border-none':
            searchIsSticky,
          '!z-30': hideBtns
        }"
        [skipFormWithExitId]="skipFormWithExitId.bind(this)"
        (searchClicked)="search()"
      ></cg-new-appointment-search>

      <div class="mt-10 flex flex-col gap-4">
        @if (combinedAppointmentLocations$ | async; as locations) {
          @if (searchClicked$ | async) {
            @if (earliestLocationWithAppointment) {
              <cg-new-appointment-earliest-appointment-card
                class="mb-4"
                [locationWithAppointment]="earliestLocationWithAppointment"
              ></cg-new-appointment-earliest-appointment-card>
            }
            @if (hasAnyAppointments) {
              @for (selectedLocation of locations.selected; track getCardId(i, selectedLocation); let i = $index) {
                <cg-new-appointment-select-card
                  [appointments]="selectedLocation.appointments"
                  [expanded]="currentlyExpanded.has(i)"
                  [serviceCenter]="selectedLocation.serviceCenter"
                  [limitDate]="limitDate"
                  (accordionToggled)="toggleCurrentlyExpanded(i)"
                ></cg-new-appointment-select-card>
              }
              @if (shouldDisplayMobileService === false && !selectedAllServiceCenters) {
                <div class="mt-12">
                  @if (hasSelectedAppointments) {
                    <cg-olb-headline
                      class="[&>h1]:!text-lg"
                      [title]="'newAppointment.ealierAppointments.title' | transloco"
                    ></cg-olb-headline>
                  } @else {
                    <cg-olb-headline
                      class="[&>h1]:!text-lg"
                      [title]="'newAppointment.emptyAppointments.title' | transloco"
                    ></cg-olb-headline>
                  }
                  <ng-template #emptyAppointments>
                    <cg-olb-headline
                      class="[&>h1]:!text-lg"
                      [title]="'newAppointment.emptyAppointments.title' | transloco"
                    ></cg-olb-headline>
                  </ng-template>
                  @if (locations.earlier.length === 0) {
                    <div class="mt-2 flex flex-row items-center gap-2">
                      <div class="box-border h-2 w-2 rounded-full border border-black bg-cg-yellow"></div>
                      <span class="text-[13px]">{{
                        "newAppointment.ealierAppointments.noAppointments" | transloco
                      }}</span>
                    </div>
                  }
                  @if (locations.selected.length; as scLength) {
                    <div class="flex flex-col gap-4">
                      @for (
                        earlierLocations of locations.earlier;
                        track getCardId(i, earlierLocations);
                        let i = $index
                      ) {
                        <cg-new-appointment-select-card
                          [appointments]="earlierLocations.appointments"
                          [expanded]="currentlyExpanded.has(i + scLength)"
                          [serviceCenter]="earlierLocations.serviceCenter"
                          [limitDate]="limitDate"
                          (accordionToggled)="toggleCurrentlyExpanded(i + scLength)"
                        ></cg-new-appointment-select-card>
                      }
                    </div>
                  }
                </div>
              }
            } @else {
              <cg-new-appointment-no-appointments></cg-new-appointment-no-appointments>
            }
          }
        }
      </div>

      @if (shouldDisplayMobileService) {
        <cg-new-appointment-mobile-service-view class="mt-12 block"></cg-new-appointment-mobile-service-view>
      }
    </form>
  </ng-template>

  <cg-split-view
    class="[&>div>div]:p-0"
    [drawerTrigger]="false"
    [master]="master"
    [overlayBackwardText]="'newAppointment.appointmentDrawer.buttons.back' | transloco"
    [overlayForwardText]="'newAppointment.appointmentDrawer.buttons.forward' | transloco"
    [showBackground]="false"
    [showBranding]="false"
  ></cg-split-view>
</section>
