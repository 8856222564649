import type { FaqData } from "@cg/shared";

export const damageAssessmentResultReplaceFaqModel: FaqData = {
  entries: [
    {
      entryId: "faq.replace.driveWithCrack",
      questionKey: "faq.replace.driveWithCrack.question",
      answerKey: "faq.replace.driveWithCrack.answer"
    },
    {
      entryId: "faq.replace.crackInsuranceWillPay",
      questionKey: "faq.replace.crackInsuranceWillPay.question",
      answerKey: "faq.replace.crackInsuranceWillPay.answer"
    },
    {
      entryId: "faq.replace.repairInsteadOfReplace",
      questionKey: "faq.replace.repairInsteadOfReplace.question",
      answerKey: "faq.replace.repairInsteadOfReplace.answer"
    }
  ]
};
