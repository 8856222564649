import type { LinkWithId } from "@cg/core/ui";

export const additionalOptions: { firstLink: LinkWithId } = {
  firstLink: {
    id: "license-plate-datenschutz",
    href: "https://www.carglass.de/datenschutz/datenverarbeitung-website",
    target: "_blank",
    title: "licensePlate.privacy",
    text: "licensePlate.privacy"
  }
};
