import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { successIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import {
  DamageChipCount,
  DamageLocation,
  DamageSize,
  DamageType,
  InfoButtonComponent,
  SplitViewComponent
} from "@cg/shared";

@Component({
  selector: "cg-damage-assessment-result-prefix",
  templateUrl: "./damage-assessment-result-prefix.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    SplitViewComponent,
    IconComponent,
    OlbHeadlineComponent,
    InfoButtonComponent,
    ParagraphComponent
  ]
})
export class DamageAssessmentResultPrefixComponent {
  public requiredServiceIsReplace$ = this.damageFacade.requiredServiceIsReplace$;
  public showOriginalWindowText = true;

  public get infoText$() {
    return combineLatest([
      this.damageFacade.damageLocation$,
      this.damageFacade.damageType$,
      this.damageFacade.damageSize$,
      this.damageFacade.damageChipCount$
    ]).pipe(
      map(
        ([damageLocation, damageType, damageSize, chipCount]: [
          DamageLocation,
          DamageType,
          DamageSize,
          DamageChipCount
        ]) => {
          this.showOriginalWindowText = damageSize !== DamageSize.SMALL;

          const textForDamageLocation = () => {
            switch (damageLocation) {
              case DamageLocation.EDGE:
                return "damageAssessmentResult.prefixDialog.textEdge";
              case DamageLocation.VIEW:
                return "damageAssessmentResult.prefixDialog.textView";
              case DamageLocation.OTHER:
                return damageSize === DamageSize.SMALL
                  ? "damageAssessmentResult.prefixDialog.textSmallDamage"
                  : "damageAssessmentResult.prefixDialog.textBigDamage";
            }
          };

          switch (damageType) {
            case DamageType.CRACK:
              return "damageAssessmentResult.prefixDialog.textCrack";
            case DamageType.MAJOR:
              switch (chipCount) {
                case DamageChipCount.TWO:
                case DamageChipCount.THREE:
                  return textForDamageLocation();
                case DamageChipCount.MORE_THAN_THREE:
                  return "damageAssessmentResult.prefixDialog.textMajor";
              }
              break;
            case DamageType.MINOR:
              return textForDamageLocation();
          }
        }
      )
    );
  }

  public successIcon: Icon = successIcon;

  public constructor(private damageFacade: DamageFacade) {}
}
