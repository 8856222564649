import { environment } from "@cg/environments";
import type { NewAppointmentDetailContentInterface } from "../interfaces/new-appointment-detail-content.interface";

export const replaceWithAdas: NewAppointmentDetailContentInterface = {
  explaination: "appointment.appointmentDetail.texts.replaceWithAdas.explaination",
  picture: {
    alt: "appointment.appointmentDetail.texts.replaceWithAdas.picture.alt",
    sizes: [],
    source: `${environment.assetPath}/olb/appointment/images/img_replace.png`,
    ngTemplate: undefined
  }
};
