import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from "@angular/core";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { cccAgentIcon } from "@cg/icon";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-connected-to-agent",
  templateUrl: "./channel-switch-connected-to-agent.component.html",
  styleUrls: ["./channel-switch-connected-to-agent.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, IconComponent]
})
export class ChannelSwitchConnectedToAgentComponent extends ChannelSwitchBaseComponent implements OnInit {
  public cccAgentIcon: Icon = cccAgentIcon;
  @HostBinding("@fadeIn") protected fadeIn = true;
  private readonly TIMEOUT_IN_MILISECONDS = 20000;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    private channelSwitchFacade: ChannelSwitchFacade,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    setTimeout(() => {
      this.channelSwitchFacade.setIsCallbackLate(true);
    }, this.TIMEOUT_IN_MILISECONDS);
  }
}
