import { ChangeDetectionStrategy, Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";
import { DamageActions } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { damageLocationMultipleChipsIcon } from "@cg/icon";
import {
  ChooseDamagedWindscreenTypeExitIds,
  Insurance,
  isDirectResumeFn,
  OlbHeadlineComponent,
  Product
} from "@cg/olb/shared";
import { InfoButtonComponent, RadioButtonGroup, RadioButtonGroupComponent, SplitViewComponent } from "@cg/shared";
import { DamageLocationComponent } from "../damage-location/damage-location.component";
import { DamageLocationMultipleChipsDetailsComponent } from "./components/damage-location-multiple-chips-details/damage-location-multiple-chips-details.component";
import { damageLocationMultipleChipsOptions } from "./models/damage-location-multiple-chips-options.model";
import { eventDamageLocationMultipleChipsMapping } from "./models/damage-location-multiple-chips-tracking-mapping.model";

@Component({
  selector: "cg-damage-location-multiple-chips",
  templateUrl: "./damage-location-multiple-chips.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    IconComponent,
    RadioButtonGroupComponent,
    InfoButtonComponent,
    SplitViewComponent,
    DamageLocationMultipleChipsDetailsComponent
  ]
})
export class DamageLocationMultipleChipsComponent extends DamageLocationComponent {
  public damageLocationOptions: RadioButtonGroup = damageLocationMultipleChipsOptions;
  public picture: Icon = damageLocationMultipleChipsIcon;

  public onSelectedValueChanged() {
    if (this.form.valid) {
      this.goForward("damage-location-multiple-chips");
    }
  }

  public override postSaveForm() {
    this.updateDamage();
  }

  public override getExitIdForSavedForm(): Observable<ChooseDamagedWindscreenTypeExitIds> {
    const isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    return combineLatest([
      this.actions$.pipe(ofType(DamageActions.updateDamageSuccess), take(1)),
      this.insuranceFacade.selectedInsurance$,
      this.productFacade.products$
    ]).pipe(
      filter(([_action, _, products]: [Action, Insurance, Product[]]) => !isDirectResume || !!products),
      take(1),
      mergeMap(([_action, insurance, products]: [Action, Insurance, Product[]]) =>
        this.exitIdService.getExitIdForDamageLocation$(this.locateDamage, insurance, isDirectResume, products)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  protected trackDamageLocation() {
    const eventDamageLocation = eventDamageLocationMultipleChipsMapping.get(this.locateDamage);
    this.trackingService.trackEvent({
      eventAction: "damage-location-multiple-chips-selection",
      eventLabel: eventDamageLocation,
      damage: {
        location: eventDamageLocation
      }
    } as Partial<TrackingEvent>);
  }
}
