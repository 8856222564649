import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { filter, map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import { CarIdentificationService } from "@cg/olb/services";
import { DamageFacade, InsuranceFacade, OlbFacade, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { LinkComponent, LinkWithId } from "@cg/core/ui";
import {
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ScrollService,
  VinQueryExitIds
} from "@cg/olb/shared";
import {
  ChosenProduct,
  ComponentOverarchingChangeDetectionService,
  Ctalink,
  CtalinkComponent,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../../core/directives/base/base.directive";
import { VinFormComponent } from "../../components/vin-form/vin-form.component";
import { VehicleIdentificationNumberForm } from "../../interfaces/vehicle-identification-number-form.interface";
import { additionalOptions } from "../../models/additional-options.model";
import { inputFields, VinQueryInputs } from "../../models/input-fields.model";

@Component({
  selector: "cg-vehicle-identification-number",
  templateUrl: "./vehicle-identification-number.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    VinFormComponent,
    CtalinkComponent,
    LinkComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class VehicleIdentificationNumberComponent
  extends BaseDirective<AddFormControls<VehicleIdentificationNumberForm>>
  implements OnInit
{
  public additionalOptions: { firstCtaLink: Ctalink; secondLink: LinkWithId; thirdLink: LinkWithId } =
    additionalOptions;
  public inputFields: VinQueryInputs = inputFields;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    trackingService: TrackingService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly olbFacade: OlbFacade,
    private readonly productFacade: ProductFacade,
    private readonly optimizelyService: OptimizelyService,
    private readonly damageFacade: DamageFacade,
    private readonly carIdentificationService: CarIdentificationService,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<VehicleIdentificationNumberForm>>({
      vin: new FormControl<Pick<VehicleIdentificationNumberForm["vin"], "vin" | "checksum">>(null, [
        Validators.required
      ])
    });
  }

  public setFormValues(): void {
    this.insuranceFacade.vin$
      .pipe(
        withLatestFrom(this.insuranceFacade.vinChecksum$),
        filter(([vin, checksum]: [string, string]) => !!vin && !!checksum),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([vin, checksum]: [string, string]) => {
        this.form.patchValue({
          vin: {
            vin,
            checksum
          }
        });
      });
  }

  public getExitIdForSavedForm(): Observable<VinQueryExitIds> {
    this.saveForm();
    this.olbFacade.getAllProducts();

    return combineLatest([this.productFacade.products$, this.damageFacade.requiredService$]).pipe(
      filter(([products, _requiredService]: [Product[], RequiredService]) => !!products),
      mergeMap(([products, requiredService]: [Product[], RequiredService]) =>
        this.handleProducts(products, requiredService)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public saveForm(): void {
    this.form.markAllAsTouched();
    this.changeDetectionService.changeDetectionRequest$.next();

    if (this.form.invalid || !this.form.controls.vin.value) {
      return;
    }

    const { vin, checksum } = this.form.controls.vin.value;

    if (checksum && checksum.length > 0) {
      this.insuranceFacade.setInsuranceVin(`${vin}-${checksum}`);
    } else {
      this.insuranceFacade.setInsuranceVin(vin);
    }
  }

  public handleProducts(products: Product[], requiredService: RequiredService): Observable<VinQueryExitIds> {
    this.trackVehicleInformation(products);

    switch (products.length) {
      case 0:
        return this.optimizelyService
          .isVariationOfExperimentActive(OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND)
          .pipe(
            take(1),
            takeUntilDestroyed(this.destroyRef),
            map((isProductNotFoundAbTestActive: boolean) =>
              isProductNotFoundAbTestActive ? "noProductFoundOpportunityAbTest" : "noProductFound"
            )
          );
      case 1:
        return this.productFacade.selectedProduct$.pipe(
          filter((product: ChosenProduct) => !!product),
          takeUntilDestroyed(this.destroyRef),
          map(() =>
            requiredService === RequiredService.REPLACE
              ? this.carIdentificationService.getCarIdentifiedProcessId(products[0].brand)
              : "appointment"
          )
        );
      default:
        this.processFacade.setChannelSwitchReason("PRODUCT_NOT_FOUND");

        return this.optimizelyService
          .isVariationOfExperimentActive(OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND)
          .pipe(
            take(1),
            takeUntilDestroyed(this.destroyRef),
            map((isProductNotFoundAbTestActive: boolean) =>
              isProductNotFoundAbTestActive ? "noProductFoundOpportunityAbTest" : "channelSwitch"
            )
          );
    }
  }

  public handleCarNotIdentified($event: MouseEvent) {
    $event.preventDefault();
    this.processFacade.setProcessMetaData({ id: this.currentProcessId, valid: true });
    this.processFacade.goForward("vehicle-identification-manually");
  }

  public trackVehicleInformation(products: Product[]) {
    /* eslint-disable sonarjs/no-duplicate-string */
    let vehicle: {
      adas?: string;
      brand?: string;
      model?: string;
      "vehicle-identification-source": string;
      window: "found" | "multiple-options" | "not-found";
      "year-of-built"?: string;
    };

    if (products.length === 1) {
      const product = products[0];
      vehicle = {
        brand: product.brand,
        model: product.model,
        "vehicle-identification-source": "manual-fin-entry",
        window: "found",
        "year-of-built": product.buildDate
      };
    } else if (products.length === 0) {
      vehicle = {
        "vehicle-identification-source": "manual-fin-entry",
        window: "not-found"
      };
    } else if (products.length > 1) {
      vehicle = {
        brand: "multiple-options",
        model: "multiple-options",
        "vehicle-identification-source": "manual-fin-entry",
        window: "multiple-options",
        "year-of-built": "multiple-options"
      };
    }

    if (products) {
      this.trackingService.trackEvent({
        eventAction: "vehicle-information",
        eventLabel: "manual-fin-entry",
        vehicle
      } as Partial<TrackingEvent>);
    }
  }

  public onTrack(eventAction: string) {
    this.trackingService.trackEvent({
      eventAction: eventAction,
      eventLabel: "vehicle-identification-number"
    } as Partial<TrackingEvent>);
  }
}
