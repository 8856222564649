import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { DamageFacade, InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Insurance, OptionSelectionOverlaySearchComponent } from "@cg/olb/shared";
import { DrawerComponent, OptionSelectionItem } from "@cg/shared";
import { OVERLAYFLOW_COMPONENTS } from "@cg/core/enums";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { InsuranceBaseMessageComponent } from "../insurance-base-message/insurance-base-message.component";
import { InsuranceMessageNavigation } from "../interfaces/insurance-message-navigation.interface";

@Component({
  selector: "cg-insurance-options",
  templateUrl: "./insurance-options.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, DrawerComponent, OptionSelectionOverlaySearchComponent]
})
export class InsuranceOptionsComponent
  extends InsuranceBaseMessageComponent<InsuranceTypeForm>
  implements OnInit, InsuranceMessageNavigation
{
  public insurances$: Observable<OptionSelectionItem[]>;

  public constructor(
    private readonly insuranceFacade: InsuranceFacade,
    private readonly damageFacade: DamageFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form.controls.nextOverlay.setValue(OVERLAYFLOW_COMPONENTS.INSURANCE_PARTNER_MESSAGE, { emitEvent: false });
    this.insurances$ = this.insuranceFacade.createInsurancesOptionsWithTopInsurances().pipe(
      map((insurances: OptionSelectionItem[]) => {
        const c = insurances.find(
          (insurance: OptionSelectionItem) => insurance.value === this.form.controls.insurance.value
        );
        if (c) {
          c.selected = true;
        }
        return insurances;
      })
    );
  }

  public close = (item: OptionSelectionItem) => {
    if (item) {
      this.insuranceFacade.selectedInsurance$
        .pipe(
          take(1),
          filter((insurance: Insurance) => insurance && insurance.id !== item.value)
        )
        .subscribe(() => {
          this.damageFacade.setDamageDate(null);
        });

      this.form.controls.insurance.setValue(item.value);
    } else {
      this.form.controls.overlayClose.setValue(true);
    }
  };

  public onClosedClicked(): void {
    this.form.controls.overlayClose.setValue(true);
  }

  public onGoBackClicked(): void {}
  public onGoForwardClicked(): void {}
}
