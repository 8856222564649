import { Inject, Injectable, Type } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import {
  OLB_PROCESS_FLOW_MODEL,
  OLB_PROCESS_FLOW_PREFIX_COMPONENT_MAPPING,
  OLB_PROCESS_FLOW_TILE_MAPPING,
  PrefixComponentIds,
  ProcessFlow,
  ProcessFlowTileMapping
} from "@cg/olb/shared";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import { AbTestComponentResolverService } from "./ab-test-component-resolver.service";

@Injectable()
export class ComponentResolverService {
  public constructor(
    @Inject(OLB_PROCESS_FLOW_TILE_MAPPING) private readonly tileMapping: ProcessFlowTileMapping<string>,
    @Inject(OLB_PROCESS_FLOW_PREFIX_COMPONENT_MAPPING) private readonly prefixMapping: ProcessFlowTileMapping<string>,
    @Inject(OLB_PROCESS_FLOW_MODEL) private readonly processFlow: ProcessFlow,
    private readonly abTestComponentResolverService: AbTestComponentResolverService,
    private readonly processFacade: ProcessFacade
  ) {}

  public async getComponent(processId: string): Promise<Type<unknown>> {
    const abTestComponent = await this.abTestComponentResolverService.getAbTestingComponent(processId);

    const currentProcessFlowItem = this.processFlow[processId as ProcessId];
    const passthroughNextTileId = await firstValueFrom(this.processFacade.passthroughNextTileId$);

    let componentToRender: Type<unknown>;
    if (!currentProcessFlowItem?.passthroughId || passthroughNextTileId) {
      componentToRender = abTestComponent ?? this.tileMapping[processId];
    } else {
      // if passthrough component was rendered before skip it
      // Can happen because passthroughId is defined multiple times because of resumes
      const processMetaData = await firstValueFrom(this.processFacade.processMetaData$);
      const alreadyExists = processMetaData.some(
        (item: ProcessMetadata) => item.id === currentProcessFlowItem.passthroughId
      );

      if (alreadyExists) {
        componentToRender = abTestComponent ?? this.tileMapping[processId];
      } else {
        componentToRender = this.tileMapping[currentProcessFlowItem.passthroughId];
      }
    }

    if (!componentToRender) {
      console.error(`No component found for ProcessId: ${processId}`);
      return;
    }
    return componentToRender;
  }

  public async getPrefixComponent(processId: string): Promise<Type<unknown>> {
    const prefixId: PrefixComponentIds = this.processFlow[processId].prefixComponent;
    const processMetaData = await firstValueFrom(this.processFacade.processMetaData$);
    if (!prefixId) {
      return;
    }

    const prefixComponentIndex = processMetaData.findIndex(
      (item: ProcessMetadata) => item.prefixComponentId === prefixId
    );

    if (prefixComponentIndex > -1 && processMetaData[prefixComponentIndex].id !== processId) {
      return;
    }

    this.processFacade.addPrefixComponent(processId as ProcessId, prefixId);
    const componentToRender = this.prefixMapping[prefixId];
    if (!componentToRender) {
      console.error(`No component found for PrefixId: ${prefixId}`);
      return;
    }
    return componentToRender;
  }
}
