import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  DamagedSideWindowCount,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelDamageSideExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { OpportunityFunnelDamageSideForm } from "./interfaces/opportunity-funnel-damage-side-form.interface";
import { opportunityFunnelDamageSideTileContent } from "./models/opportunity-funnel-damage-side-tile-content.model";

@Component({
  selector: "cg-opportunity-funnel-damage-side",
  templateUrl: "./opportunity-funnel-damage-side.component.html",
  styleUrls: ["./opportunity-funnel-damage-side.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DamageAssessmentTileComponent, SplitViewComponent]
})
export class OpportunityFunnelDamageSideComponent extends BaseDirective<
  AddFormControls<OpportunityFunnelDamageSideForm>
> {
  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    private readonly damageFacade: DamageFacade,
    @Inject(OLB_CONFIG) private _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public opportunityFunnelDamageSideTileContent = opportunityFunnelDamageSideTileContent;

  public get damageSide(): DamagedSideWindowCount {
    return this.form.controls.damagedSideWindowCount.value;
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<OpportunityFunnelDamageSideForm>>({
      damagedSideWindowCount: new FormControl<DamagedSideWindowCount>(null, Validators.required)
    });

    this.form?.valueChanges
      .pipe(
        distinctUntilChanged(
          (
            formA: Partial<{ damagedSideWindowCount: DamagedSideWindowCount }>,
            formB: Partial<{ damagedSideWindowCount: DamagedSideWindowCount }>
          ) => formA.damagedSideWindowCount === formB.damagedSideWindowCount
        ),
        filter((value: Partial<{ damagedSideWindowCount: DamagedSideWindowCount }>) => !!value.damagedSideWindowCount),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.goForward());
  }

  public setFormValues(): void {
    this.damageFacade.damagedSideWindowCount$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((damagedSideWindowCount: DamagedSideWindowCount) => {
        this.form.controls.damagedSideWindowCount.setValue(damagedSideWindowCount);
      });
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelDamageSideExitIds> {
    this.trackDamageSide();
    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      return of("directResume");
    }
    return of("default");
  }

  public trackDamageSide(): void {
    const eventLabel = this.damageSide === DamagedSideWindowCount.SINGLE ? "one-window" : "more-than-one-window";
    this.trackingService.trackEvent({
      eventAction: "opportunity-funnel-damage-selection",
      eventLabel,
      "opportunity-funnel": {
        "damage-selection": eventLabel
      }
    } as Partial<TrackingEvent>);
  }

  public saveForm(): void {
    this.damageFacade.setDamagedSideWindowCount(this.damageSide);
  }
}
