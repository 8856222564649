<div class="relative h-[100px] w-full overflow-hidden" #timeline [ngClass]="{ 'h-[75px]': hasBreak }">
  <div class="appointment absolute left-0 top-0 opacity-0" [ngClass]="{ invisible: hasBreak }">
    <div class="typo-body-1 appointment-text relative h-11 whitespace-nowrap px-1.5 leading-4 text-cg-anthracite">
      <span class="typo-caption block text-xs leading-5 text-cg-anthracite-light-30 t:text-[13px] t:leading-6">{{
        "appointment.appointmentTime.texts.appointmentStart" | transloco
      }}</span>
      {{ timelineTimes?.appointmentStart | date: "HH:mm" }} -
      {{ timelineTimes?.appointmentEnd | date: "HH:mm" }}
    </div>
    <div
      class="appointment-bar h-4 w-full origin-left scale-x-0 transform bg-white px-1.5 before:absolute before:left-1.5 before:top-0 before:h-4 before:w-[calc(100%-12rem/16)] before:rounded-sm before:bg-cg-olb-primary-selected-color"
    ></div>
  </div>

  <svg class="mt-11 h-4 w-full" [ngClass]="{ invisible: hasBreak }" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <rect class="fill-cg-anthracite-light-80" x="0" y="0" width="2" height="16" rx="1"></rect>
  </svg>

  <div class="absolute left-0 top-5" [ngClass]="{ invisible: isStartIconHidden }">
    <cg-icon class="h-[18px] w-[31px]" data-cy="timeline-icon-start" [content]="iconStart"></cg-icon>
  </div>
  <div class="absolute" [ngClass]="{ 'left-0 right-0 top-4': hasBreak, 'invisible top-5': !hasBreak }">
    <cg-icon class="mx-auto h-[18px] w-[31px]" data-cy="timeline-icon-center" [content]="iconLunchBreak"></cg-icon>
  </div>
  <div class="absolute right-0 top-5" [ngClass]="{ invisible: isEndIconHidden }">
    <cg-icon class="h-[18px] w-[31px]" [content]="iconEnd" data-cy="timeline-icon-end"></cg-icon>
  </div>

  <div class="typo-body-1 absolute bottom-0 left-0 leading-4 text-cg-anthracite">
    <span
      class="typo-caption block text-xs leading-5 text-cg-anthracite-light-30 t:text-[13px] t:leading-6"
      data-cy="timeline-text-start"
    >
      {{
        startTimesAreEqal
          ? ("appointment.appointmentTime.texts.bring" | transloco)
          : ("appointment.appointmentTime.texts.bringFrom" | transloco)
      }}
    </span>
    {{ timelineTimes?.start | date: "HH:mm" }}
  </div>
  <div
    class="typo-body-1 absolute bottom-0 leading-4 text-cg-anthracite"
    [ngClass]="{ 'left-0 right-0 text-center': hasBreak, invisible: !hasBreak }"
  >
    <span
      class="typo-caption block text-xs leading-5 text-cg-anthracite-light-30 t:text-[13px] t:leading-6"
      data-cy="timeline-icon-center"
      >{{ "appointment.appointmentTime.texts.closed" | transloco }}</span
    >
    <span>{{ breakStart }} - {{ breakEnd }}</span>
  </div>
  <div class="typo-body-1 absolute bottom-0 right-0 text-right leading-4 text-cg-anthracite">
    <span
      class="typo-caption block text-xs leading-5 text-cg-anthracite-light-30 t:text-[13px] t:leading-6"
      data-cy="timeline-text-end"
      >{{ "appointment.appointmentTime.texts.pickup" | transloco }}</span
    >
    <span>{{ timelineTimes?.end | date: "HH:mm" }}</span>
  </div>
</div>
