import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { pinIcon } from "@cg/icon";
import { Appointment, AppointmentData, AppointmentLocation } from "@cg/shared";
import { AppointmentFilterStaticToastComponent } from "./appointment-filter-static-toast/appointment-filter-static-toast.component";

@Component({
  selector: "cg-appointment-filter-static",
  templateUrl: "./appointment-filter-static.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, ParagraphComponent, AppointmentFilterStaticToastComponent]
})
export class AppointmentFilterStaticComponent implements OnInit {
  @Input() public appointmentData: AppointmentData;

  public pinIcon: Icon = pinIcon;
  public location: AppointmentLocation;
  public toastVisible = false;
  public timeoutHandler: ReturnType<typeof setTimeout>;

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.appointmentData.currentAppointment$.pipe(take(1)).subscribe((location: Appointment) => {
      this.location = location;
      this.cdr.markForCheck();
    });
  }

  public showToast() {
    this.toastVisible = true;

    this.timeoutHandler = setTimeout(() => {
      this.hideToast();
      this.cdr.markForCheck();
    }, 3000);
  }

  public hideToast() {
    this.toastVisible = false;

    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = undefined;
    }
  }
}
