import type { FaqData } from "@cg/shared";

export const damageAssessmentResultRepairFaqModel: FaqData = {
  entries: [
    {
      entryId: "faq.repair.driveWithChip",
      questionKey: "faq.repair.driveWithChip.question",
      answerKey: "faq.repair.driveWithChip.answer"
    },
    {
      entryId: "faq.repair.insuranceWillPay",
      questionKey: "faq.repair.insuranceWillPay.question",
      answerKey: "faq.repair.insuranceWillPay.answer"
    },
    {
      entryId: "faq.repair.damageVisibleAfterRepair",
      questionKey: "faq.repair.damageVisibleAfterRepair.question",
      answerKey: "faq.repair.damageVisibleAfterRepair.answer"
    }
  ]
};
