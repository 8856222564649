import { DatePipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { timelineBreakIcon, timelineCarLeftIcon } from "@cg/icon";
import { Appointment, OpeningHour } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-appointment-time",
  templateUrl: "./appointment-time.component.html",
  styleUrls: ["./appointment-time.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslocoPipe, IconComponent, DatePipe]
})
export class AppointmentTimeComponent implements AfterViewInit, OnChanges {
  @ViewChild("timeline") public timelineRef: ElementRef;
  @Input() public appointment: Appointment;
  @Input() public openingHour: OpeningHour;

  public destroyRef = inject(DestroyRef);

  private timelineElm: HTMLDivElement;
  private appointmentElm: HTMLDivElement;
  private timelineWidth: number;
  private rerenderOnResizeSubscription: Subscription;

  public startTimesAreEqal: boolean;
  public isStartIconHidden = false;
  public isEndIconHidden = false;

  public timelineTimes: {
    start: Date;
    end: Date;
    appointmentStart: Date;
    appointmentEnd: Date;
  } = null;
  public hasBreak = false;
  public breakStart = null;
  public breakEnd = null;

  public readonly iconStart: Icon = timelineCarLeftIcon;
  public readonly iconEnd: Icon = timelineCarLeftIcon;
  public readonly iconLunchBreak: Icon = timelineBreakIcon;

  public constructor(private cdRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.timelineElm = this.timelineRef.nativeElement;
    this.appointmentElm = this.timelineElm.querySelector(".appointment");
    this.init();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.timelineElm && (!!changes.appointment || !!changes.openingHour)) {
      this.init();
    }
  }

  private init(): void {
    this.startTimesAreEqal = this.appointment.customerAppointmentStart === this.appointment.availabilityPeriodStart;
    this.timelineTimes = {
      start: new Date(this.appointment.availabilityPeriodStart),
      end: this.createOpeningEndDate(),
      appointmentStart: new Date(this.appointment.customerAppointmentStart),
      appointmentEnd: new Date(this.appointment.customerAppointmentEnd)
    };

    this.initBreak();
    this.render();
    this.rerenderOnResize();
  }

  private initBreak(): void {
    if (!!this.openingHour.breakStart && !!this.openingHour.breakEnd) {
      this.hasBreak = true;
      this.breakStart = this.openingHour.breakStart;
      this.breakEnd = this.openingHour.breakEnd;
    } else {
      this.hasBreak = false;
      this.breakStart = null;
      this.breakEnd = null;
    }
  }

  private render(): void {
    this.reset();

    if (!this.hasBreak) {
      const numBars = this.calculateNumBars();

      this.updateTimelineWidth(numBars);
      this.renderBars(numBars);
      this.renderAppointmentBar();
    }

    this.cdRef.detectChanges();
  }

  private reset(): void {
    this.isStartIconHidden = false;
    this.isEndIconHidden = false;
    this.timelineElm.removeAttribute("style");
    this.appointmentElm.removeAttribute("style");
  }

  private rerenderOnResize(): void {
    if (this.rerenderOnResizeSubscription) {
      this.rerenderOnResizeSubscription.unsubscribe();
    }

    this.rerenderOnResizeSubscription = fromEvent(window, "resize")
      .pipe(debounceTime(250), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.render();
      });
  }

  private renderAppointmentBar(): void {
    const timelineLengthSeconds = (this.timelineTimes.end.getTime() - this.timelineTimes.start.getTime()) / 1000;
    const appointmentStartSeconds =
      (this.timelineTimes.appointmentStart.getTime() - this.timelineTimes.start.getTime()) / 1000;
    const appointmentLengthSeconds =
      (this.timelineTimes.appointmentEnd.getTime() - this.timelineTimes.appointmentStart.getTime()) / 1000;

    const timelineWidth = this.timelineWidth;
    const pixelSecond = timelineWidth / timelineLengthSeconds;

    this.updateJobBarStyles(appointmentStartSeconds * pixelSecond, appointmentLengthSeconds * pixelSecond);
  }

  private createOpeningEndDate(): Date {
    const date = new Date(this.appointment.customerAppointmentEnd);
    const [hours, min] = this.openingHour.end.split(":");
    date.setHours(+hours, +min, 0);
    return date;
  }

  private roundToNextBar(pixel: number, lastBar: boolean = false): number {
    return Math.floor(pixel / 3) * 3 + (lastBar ? -1 : 0);
  }

  private renderBars(numBars: number): void {
    const svg: SVGElement = this.selectSvgContainer();
    const protypeBar: SVGRectElement = this.selectPrototypeBar();
    svg.innerHTML = "";

    for (let i = 0; i < numBars; i++) {
      const newBar: SVGRectElement = protypeBar.cloneNode() as SVGRectElement;
      newBar.setAttribute("x", String(i * 3));
      svg.appendChild(newBar);
    }
  }

  private selectSvgContainer(): SVGElement {
    return this.timelineElm.querySelector("svg");
  }

  private selectPrototypeBar(): SVGRectElement {
    return this.timelineElm.querySelector("rect");
  }

  private calculateNumBars(): number {
    return Math.ceil(this.timelineElm.getBoundingClientRect().width / 3);
  }

  private updateTimelineWidth(numBars: number): void {
    this.timelineWidth = 3 * (numBars - 1);
    this.timelineElm.style.width = this.timelineWidth + "px";
  }

  private updateJobBarStyles(left: number, size: number): void {
    const width = this.roundToNextBar(size, true);

    this.appointmentElm.style.left = this.roundToNextBar(left) + "px";
    this.appointmentElm.style.width = this.roundToNextBar(width, true) + "px";

    this.updateIconVisibility(left);
  }

  private updateIconVisibility(leftSpace: number): void {
    if (this.hasBreak) {
      this.isStartIconHidden = false;
      this.isEndIconHidden = false;
    } else {
      const rightSpace = this.roundToNextBar(this.timelineWidth - leftSpace - this.calculateJobBarWidth());
      this.isStartIconHidden = leftSpace <= 60;
      this.isEndIconHidden = rightSpace <= 60;
    }
  }

  private calculateJobBarWidth(): number {
    const bar = this.appointmentElm.querySelector(".appointment-bar").getBoundingClientRect().width;
    const text = this.appointmentElm.querySelector(".appointment-text").scrollWidth;

    return bar > text ? bar : text;
  }
}
