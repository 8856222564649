import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { combineLatest, Observable, of } from "rxjs";
import { filter, take, tap } from "rxjs/operators";
import { ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon, Paragraph } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import {
  arrowsIcon,
  experiencedProfessionalsIcon,
  likeIcon,
  windscreenAvailableIcon,
  windscreenQualityIcon
} from "@cg/icon";
import {
  CarIdentifiedAbTestExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbForwardComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ReplacedScreensStatistic,
  ScrollService
} from "@cg/olb/shared";
import { Button, CtalinkComponent, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { CarIdentifiedForm } from "./interfaces/car-identified-form.interface";
import { changeCarLink } from "./models/change-car-link.model";

@Component({
  selector: "cg-car-identified",
  templateUrl: "./car-identified.component.html",
  styleUrls: ["./car-identified.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    IconComponent,
    OlbHeadlineComponent,
    CtalinkComponent,
    ParagraphComponent,
    OlbForwardComponent,
    SplitViewComponent
  ]
})
export class CarIdentifiedComponent extends BaseDirective<AddFormControls<CarIdentifiedForm>> implements OnInit {
  public destroyRef = inject(DestroyRef);
  public infoList: { icon: Icon; paragraph: Paragraph }[];
  public brand: string;
  public replacedScreensStatistic: ReplacedScreensStatistic;
  public showScreenAvailableFlag = true;

  public readonly likeIcon = likeIcon;
  public readonly forwardBtnContent: Button = {
    id: "forward-button",
    text: "carIdentified.forwardButton",
    iconPosition: "right",
    icon: arrowsIcon
  };

  public changeCarLink = {
    ...changeCarLink,
    link: {
      ...changeCarLink.link,
      text: "carIdentified.link",
      title: "carIdentified.link"
    }
  };

  public slideUpAfterSpinnerGone$: Observable<string>;

  public get car(): string {
    return this.form.controls.car.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    protected cdr: ChangeDetectorRef,
    protected processFacade: ProcessFacade,
    protected exitNodeResolver: ExitNodeResolverService,
    protected trackingService: TrackingService,
    protected scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly productFacade: ProductFacade,
    private readonly translocoService: TranslocoService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.productFacade.products$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((products: Product[]) => {
          if (products && products.length) {
            this.brand = products[0].brand;
          }
        })
      )
      .subscribe(() => this.productFacade.loadReplacedScreensStatistic(this.brand));

    this.productFacade.replacedScreensStatistic$
      .pipe(
        filter((screensStatistic: ReplacedScreensStatistic) => !!screensStatistic),
        takeUntilDestroyed(this.destroyRef),
        tap((screensStatistic: ReplacedScreensStatistic) => {
          this.replacedScreensStatistic = screensStatistic;
        })
      )
      .subscribe(() => this.createInfoList());
  }

  public getExitIdForSavedForm(): Observable<CarIdentifiedAbTestExitIds> {
    return of("appointment");
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<CarIdentifiedForm>>({
      car: new FormControl<string>(null)
    });
  }

  public saveForm(): void {}

  public setFormValues(): void {
    this.productFacade.selectCarModelAndManufacturer$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((car: string) => {
        this.form.controls.car.setValue(car);
        this.cdr.markForCheck();
      });
  }

  public changeCar($event: Event) {
    $event.preventDefault();
    this.processFacade.changeCar();
  }

  public hideAvailabilityFlag(): void {
    this.showScreenAvailableFlag = false;
  }

  private createInfoList(): void {
    const textKeyFirstPart = "carIdentified.listElements";
    const ngTemplate = "cgParagraph";

    combineLatest([
      this.replacedScreensStatistic.fallback
        ? this.translocoService.selectTranslate(`${textKeyFirstPart}.fallbackElementOne`)
        : this.translocoService.selectTranslate(`${textKeyFirstPart}.elementOne`, {
            brand: this.brand,
            replacedScreens: this.replacedScreensStatistic.replacements.toLocaleString("de-DE"),
            year: this.replacedScreensStatistic.year
          }),
      this.translocoService.selectTranslate(`${textKeyFirstPart}.elementTwo`, { brand: this.brand }),
      this.translocoService.selectTranslate(`${textKeyFirstPart}.elementThree`, { brand: this.brand })
    ])
      .pipe(take(1))
      .subscribe(([text1, text2, text3]: [string, string, string]) => {
        this.infoList = [
          { icon: windscreenAvailableIcon, paragraph: { text: text1, ngTemplate } },
          { icon: experiencedProfessionalsIcon, paragraph: { text: text2, ngTemplate } },
          { icon: windscreenQualityIcon, paragraph: { text: text3, ngTemplate } }
        ];
        this.cdr.markForCheck();
      });
  }
}
