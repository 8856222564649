<cg-toast-popup
  class="adverse-buy-callback [&_div]:top-auto"
  [hasRoundBorders]="true"
  [animationYOffset]="'16px'"
  (closeClicked)="closeOverlay()"
>
  <div class="flex gap-3">
    <cg-icon class="h-12 w-12 rounded-2xl bg-cg-yellow" [content]="cccIcon"></cg-icon>
    <div class="flex flex-col gap-3">
      <div class="adverse-buy-callback--description">
        <cg-paragraph [innerHtml]="'appointmentAdverseBuyInfoText.callback.info' | transloco"> </cg-paragraph>
      </div>
      <cg-ctalink class="adverse-buy-callback--ctalink" [content]="adverseBuyCallbackCta" (clickedFn)="goCallback()">
      </cg-ctalink>
    </div>
  </div>
</cg-toast-popup>
