import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { filter, tap } from "rxjs/operators";
import { ChannelSwitchFacade } from "@cg/olb/state";
import { CheckboxComponent, InputType, TextInputComponent } from "@cg/shared";
import { checkboxChannelSwitch } from "../../models/checkbox-input.model";
import { phoneNumberContent } from "../../models/phone-number.model";

@Component({
  selector: "cg-channel-switch-phone-input",
  templateUrl: "./channel-switch-phone-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  standalone: true,
  imports: [ReactiveFormsModule, TextInputComponent, CheckboxComponent]
})
export class ChannelSwitchPhoneInputComponent implements AfterViewInit, OnDestroy {
  public destroyRef = inject(DestroyRef);
  public checkboxContent = checkboxChannelSwitch;
  public phoneNumberContent = phoneNumberContent;

  protected readonly InputType = InputType;

  public constructor(
    private parent: FormGroupDirective,
    private channelSwitchFacade: ChannelSwitchFacade,
    protected cdr: ChangeDetectorRef
  ) {}

  public get phoneNumber(): string {
    return this.parent?.form?.controls.phoneNumber.value ?? "";
  }

  public set phoneNumber(phone: string) {
    this.parent.form.controls.phoneNumber.setValue(phone);
  }

  public get channelSwitchCheckboxChecked(): boolean | undefined {
    return this.parent?.form?.controls.channelSwitchCheckbox.value;
  }

  public set channelSwitchCheckboxChecked(checkbox: boolean) {
    this.parent.form.controls.channelSwitchCheckbox.setValue(checkbox);
  }

  public ngOnDestroy(): void {
    // is called in onDestroy to save values when needFallback flag changes and the view gets replaced
    this.saveForm();
  }

  public ngAfterViewInit(): void {
    this.setFormValues();
  }

  public saveForm(): void {
    if (this.phoneNumber) {
      this.channelSwitchFacade.setPhoneNumber(this.phoneNumber);
    }

    if (this.channelSwitchCheckboxChecked !== undefined) {
      this.channelSwitchFacade.setChannelSwitchCheckbox(this.channelSwitchCheckboxChecked);
    }
  }

  public setFormValues(): void {
    this.channelSwitchFacade.phoneNumber$
      .pipe(
        filter((phone: string) => !!phone),
        tap((phoneNumber: string) => (this.phoneNumber = phoneNumber)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.channelSwitchFacade.channelSwitchCheckbox$
      .pipe(
        filter((checkbox: boolean) => !!checkbox),
        tap((channelSwitchCheckbox: boolean) => {
          this.channelSwitchCheckboxChecked = channelSwitchCheckbox;
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public markForCheck(): void {
    this.cdr.markForCheck();
  }
}
