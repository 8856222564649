<section class="flex flex-col">
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        [title]="'newCustomerAddress.title' | transloco"
        [subHeadline]="'newCustomerAddress.subtitle' | transloco"
      ></cg-olb-headline>
      <!-- IOM SONDERLOCKE BEACHTEN!! -->

      <cg-tabs (tabChanged)="tabChanged($event)" [selectedIndex]="activeTabIndex" [tabs]="tabs" class="mt-4 block">
        <ng-template #privateTabTemplate>
          <div class="mt-8">
            <div class="mb-4 grid grid-cols-2 gap-4">
              <div class="col-start-1 col-end-3">
                <cg-radio-button-horizontal-group
                  class="title"
                  [content]="contactDataInputs.titleGroup"
                  [formControlName]="contactDataInputs.titleGroup.controlName"
                ></cg-radio-button-horizontal-group>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.firstnameInput"
                  autocomplete="given-name"
                  [formControlName]="contactDataInputs.firstnameInput.controlName"
                ></cg-text-input>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.lastnameInput"
                  autocomplete="family-name"
                  [formControlName]="contactDataInputs.lastnameInput.controlName"
                ></cg-text-input>
              </div>
              <div class="flex flex-row items-center">
                <div class="mr-2 !w-3/5">
                  <cg-text-input
                    [content]="contactDataInputs.streetInput"
                    autocomplete="street-address"
                    [formControlName]="contactDataInputs.streetInput.controlName"
                  ></cg-text-input>
                </div>
                <div class="!w-2/5">
                  <cg-text-input
                    [content]="contactDataInputs.zipInput"
                    [formControlName]="contactDataInputs.zipInput.controlName"
                    [specificInputType]="InputType.ZIP"
                  ></cg-text-input>
                </div>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.cityInput"
                  autocomplete="address-level2"
                  [formControlName]="contactDataInputs.cityInput.controlName"
                ></cg-text-input>
              </div>
            </div>

            <cg-checkbox
              [content]="contactDataInputs.otherContactPersonGroup"
              [formControlName]="contactDataInputs.otherContactPersonGroup.controlName"
              class="mt-8 block [&>div>div]:pl-2 [&>div>div]:font-roboto-condensed [&>div>div]:text-[16px] [&>div>div]:font-bold [&>div>div]:text-cg-anthracite [&>div]:items-center"
              data-cy="data-protection-optin-checkbox"
            ></cg-checkbox>

            @if (form.get("otherContactPerson").value) {
              <cg-paragraph
                class="my-8 block"
                [content]="{ text: 'newCustomerAddress.otherContactPerson.description' | transloco }"
              ></cg-paragraph>
              <div class="mt-[10px] grid grid-cols-2 gap-4">
                <div class="col-start-1 col-end-3">
                  <cg-radio-button-horizontal-group
                    class="title"
                    [content]="contactDataInputs.contactTitleGroup"
                    [formControlName]="contactDataInputs.contactTitleGroup.controlName"
                  ></cg-radio-button-horizontal-group>
                </div>
                <div>
                  <cg-text-input
                    [content]="contactDataInputs.contactFirstnameInput"
                    autocomplete="given-name"
                    [formControlName]="contactDataInputs.contactFirstnameInput.controlName"
                  ></cg-text-input>
                </div>
                <div>
                  <cg-text-input
                    [content]="contactDataInputs.contactLastnameInput"
                    autocomplete="family-name"
                    [formControlName]="contactDataInputs.contactLastnameInput.controlName"
                  ></cg-text-input>
                </div>
                <div>
                  <cg-text-input
                    [content]="contactDataInputs.alternativePhoneInput"
                    autocomplete="tel"
                    [formControlName]="contactDataInputs.alternativePhoneInput.controlName"
                    [specificInputType]="InputType.NUMBERS_PHONE"
                  ></cg-text-input>
                </div>
              </div>
            }
          </div>
        </ng-template>

        <ng-template #companyTabTemplate>
          <div class="mt-8">
            <cg-text-input
              [content]="contactDataInputs.companyInput"
              autocomplete="cc-additional-name"
              [formControlName]="contactDataInputs.companyInput.controlName"
            ></cg-text-input>

            <div class="mb-6 mt-4 grid grid-cols-5 gap-4">
              <div class="col-span-2">
                <cg-text-input
                  [content]="contactDataInputs.streetInput"
                  autocomplete="street-address"
                  [formControlName]="contactDataInputs.streetInput.controlName"
                ></cg-text-input>
              </div>
              <div class="col-span-1">
                <cg-text-input
                  [content]="contactDataInputs.zipInput"
                  [formControlName]="contactDataInputs.zipInput.controlName"
                  [specificInputType]="InputType.ZIP"
                ></cg-text-input>
              </div>
              <div class="col-span-2">
                <cg-text-input
                  [content]="contactDataInputs.cityInput"
                  autocomplete="address-level2"
                  [formControlName]="contactDataInputs.cityInput.controlName"
                ></cg-text-input>
              </div>
            </div>

            <div class="mb-[10px]">
              <cg-headline [content]="'newCustomerAddress.contactPerson' | transloco" [type]="'h5'"></cg-headline>
            </div>

            <div class="mb-6 grid grid-cols-2 gap-4">
              <div class="col-start-1 col-end-3">
                <cg-radio-button-horizontal-group
                  class="title"
                  [content]="contactDataInputs.contactTitleGroup"
                  [formControlName]="contactDataInputs.contactTitleGroup.controlName"
                ></cg-radio-button-horizontal-group>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.contactFirstnameInput"
                  autocomplete="given-name"
                  [formControlName]="contactDataInputs.contactFirstnameInput.controlName"
                ></cg-text-input>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.contactLastnameInput"
                  autocomplete="family-name"
                  [formControlName]="contactDataInputs.contactLastnameInput.controlName"
                ></cg-text-input>
              </div>
              <div>
                <cg-text-input
                  [content]="contactDataInputs.contactPhoneInput"
                  [formControlName]="contactDataInputs.contactPhoneInput.controlName"
                  [specificInputType]="InputType.NUMBERS_PHONE"
                ></cg-text-input>
              </div>
            </div>
          </div>
        </ng-template>
      </cg-tabs>

      <div class="mt-8">
        <cg-link class="mb-2 block" [content]="footerLinks.firstLink" (click)="onTrack('agb')"></cg-link>
        <cg-link [content]="footerLinks.secondLink" (click)="onTrack('privacy-statement')"></cg-link>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
  ></cg-olb-footer>
</section>
