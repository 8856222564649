import { DamageWindow, type RadioButtonGroup } from "@cg/shared";

export const otherDamageLocationOptions: RadioButtonGroup = {
  controlName: "damagedWindow",
  buttons: [
    {
      title: "damageWindow.ctas.specialVehicle",
      radio: { id: "special-vehicle", value: DamageWindow.SPECIAL_VEHICLE }
    },
    {
      title: "damageWindow.ctas.multipleWindows",
      radio: { id: "multiple-windows", value: DamageWindow.MULTIPLE_WINDOWS }
    }
  ]
};
