import { AsyncPipe, DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Actions, ofType } from "@ngrx/effects";
import { catchError, combineLatest, filter, finalize, Observable, of, take, tap } from "rxjs";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { AppointmentActions, ContactDataFacade, ProcessFacade } from "@cg/olb/state";
import { SpinnerMode, SpinnerService } from "@cg/spinner";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService } from "@cg/analytics";
import { AppointmentTimeComponent, CarBringingScheduleTextTextComponent, getOpeningHour } from "@cg/appointment-ui";
import { HeadlineComponent, HeadlineType, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import {
  Appointment,
  AppointmentData,
  AvailableServiceCenters,
  BaseButtonComponent,
  DrawerComponent,
  OpeningHour,
  OverlayService,
  RequiredService,
  SetAppointmentPayload
} from "@cg/shared";
import { OptimizelyExperiment, USER_DECISION } from "@cg/core/enums";
import { NewAppointmentAllScAppointmentsDialogComponent } from "../new-appointment-all-sc-appointments-dialog/new-appointment-all-sc-appointments-dialog.component";
import { NewAppointmentDetailContentInterface } from "./interfaces/new-appointment-detail-content.interface";
import { repair } from "./models/repair.model";
import { replaceWithAdas } from "./models/replace-with-adas.model";
import { replaceWithoutAdas } from "./models/replace-without-adas.model";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-detail",
  templateUrl: "./new-appointment-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    TranslocoPipe,
    DrawerComponent,
    HeadlineComponent,
    ParagraphComponent,
    PictureComponent,
    AppointmentTimeComponent,
    CarBringingScheduleTextTextComponent,
    BaseButtonComponent
  ]
})
export class NewAppointmentDetailComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public readonly HeadlineType = HeadlineType;

  @Input()
  public appointmentData: AppointmentData;

  @Input()
  public isParentAllScDialog = false;

  public appointment: Appointment;
  public requiredService: RequiredService;
  public serviceCenterText = "";
  public openingHour: OpeningHour;

  public isCalibration = false;
  public isRepair = false;
  public isReplaceWithAdas = false;
  public isReplaceWithoutAdas = false;

  public content: NewAppointmentDetailContentInterface;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly overlayService: OverlayService,
    private readonly processFacade: ProcessFacade,
    private readonly actions$: Actions,
    private readonly spinnerService: SpinnerService,
    private readonly optimizelyService: OptimizelyService, // AB-Test: OLB_EARLY_CONTACT_DATA
    private readonly contactDataFacade: ContactDataFacade // AB-Test: OLB_EARLY_CONTACT_DATA
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.appointmentData.appointmentId$,
      this.appointmentData.availableAppointments$,
      this.appointmentData.selectedServiceCenter$,
      this.appointmentData.isCalibration$,
      this.appointmentData.requiredService$
    ])
      .pipe(
        filter(
          ([appointmentId]: [string, Appointment[], AvailableServiceCenters, boolean, RequiredService]) =>
            !!appointmentId
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        ([appointmentId, appointments, selectedServiceCenter, isCalibration, requiredService]: [
          string,
          Appointment[],
          AvailableServiceCenters,
          boolean,
          RequiredService
        ]) => {
          this.appointment = appointments?.find((app: Appointment) => app.appointmentId === appointmentId) ?? null;

          if (!this.appointment) {
            return;
          }

          this.requiredService = requiredService;
          this.isCalibration = isCalibration;

          this.openingHour = getOpeningHour(this.appointment, selectedServiceCenter);
          this.initServiceType();
          this.initModel();
          this.initServiceCenterText();

          this.cdr.markForCheck(); // required due to OnPush
          this.cdr.detectChanges(); // run changeDetection for previous marked change
        }
      );
  }

  public initServiceType(): void {
    this.isRepair = this.requiredService === RequiredService.REPAIR;
    this.isReplaceWithAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === true;
    this.isReplaceWithoutAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === false;
  }

  public initModel(): void {
    if (this.isRepair) {
      this.content = repair;
    } else if (this.isReplaceWithAdas) {
      this.content = replaceWithAdas;
    } else if (this.isReplaceWithoutAdas) {
      this.content = replaceWithoutAdas;
    } else {
      throw new Error("Detail view could not be initialized. This should never happen.");
    }
  }

  public initServiceCenterText(): void {
    if (!this.appointment) {
      this.serviceCenterText = "";
    } else {
      const { sublocality } = this.appointment;
      const sublocalityText = sublocality ? ` - ${sublocality}` : "";
      const location = `${this.appointment.city}${sublocalityText}`;

      this.serviceCenterText = `<strong>Carglass<sup>®</sup>-Service-Center</strong><br>${this.appointment.street}<br>${this.appointment.postalCode} <strong>${location}</strong>`;
    }
  }

  public clickedOk(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const confirmAppointment: SetAppointmentPayload = {
      ...this.appointment,
      source: "OLB"
    };

    this.appointmentData.setAppointmentId(this.appointment.appointmentId);
    this.appointmentData.confirmAppointment(confirmAppointment);
    this.spinnerService.addPendingRequest(SpinnerMode.ALWAYS);

    this.actions$
      .pipe(
        ofType(AppointmentActions.confirmAppointmentSuccess),
        take(1),
        mergeMap(() => this.shouldShowNormalContactDataComponent()), // AB-Test: OLB_EARLY_CONTACT_DATA
        tap((showNormalContactData: boolean) => {
          this.processFacade.goForward(showNormalContactData ? "customer-contact" : "customer-address");
          this.overlayService.close({ decision: USER_DECISION.ACCEPT });
        }),
        finalize(() => this.spinnerService.decrementPendingRequests()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public clickedCancel(): void {
    if (!this.isParentAllScDialog) {
      return;
    }
    combineLatest([this.appointmentData.selectedServiceCenter$, this.appointmentData.availableAppointments$])
      .pipe(
        take(1),
        tap(([selectedSc, availableAppointments]: [AvailableServiceCenters, Appointment[]]) => {
          const appointmentsForSc = availableAppointments.filter(
            (appointment: Appointment) => appointment.serviceCenter === selectedSc.serviceCenter
          );
          this.overlayService.open(NewAppointmentAllScAppointmentsDialogComponent, {
            selectedSc,
            availableAppointments: appointmentsForSc
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  // AB-Test: OLB_EARLY_CONTACT_DATA
  // Show normal contact data, when none of the early data AB-test variants is active
  // or when email or mobile are still missing (possible in save and restore case)
  public shouldShowNormalContactDataComponent(): Observable<boolean> {
    return this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_FULL).pipe(
      withLatestFrom(
        this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL),
        this.contactDataFacade.email$,
        this.contactDataFacade.mobile$
      ),
      map(
        ([earlyContactDataFullActive, earlyContactDataOnlyMailActive, email, mobile]: [
          boolean,
          boolean,
          string,
          string
        ]): boolean =>
          (!earlyContactDataFullActive && !earlyContactDataOnlyMailActive) ||
          earlyContactDataOnlyMailActive ||
          !email ||
          !mobile
      ),
      catchError(() => of(true))
    );
  }
}
