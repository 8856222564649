<div class="flex flex-col">
  <form [formGroup]="form">
    @if ((isInitialState$ | async) === false) {
      <cg-new-appointment-filter
        class="max-w-[480px]"
        id="appointment-list"
        [appointmentData]="appointmentData"
        [isFormTouchedProgrammatically]="isFormTouchedProgrammatically"
        [showChevron]="(appointmentData.selectedServiceCenterIds$ | async).length !== 0"
        (filterChanged)="filterHasChanged()"
        (searchClicked)="search()"
        [showDropDownChevron]="(appointmentData.availableServiceCenters$ | async)?.length > 1"
      ></cg-new-appointment-filter>
    } @else {
      <div class="rounded-2xl bg-white p-4 shadow-card">
        <cg-olb-search-for-sc-input
          class="[&_.suffix-icon>mat-icon>svg>g]:stroke-cg-anthracite [&_input]:!bg-white"
          [appointmentData]="appointmentData"
          [clearIcon]="clearIcon"
          [ngClass]="{
            '[&_input]:border-b-cg-anthracite': searchInput
          }"
        ></cg-olb-search-for-sc-input>
      </div>
    }
  </form>
</div>
