<cg-split-view [master]="master"></cg-split-view>

<ng-template #master>
  <section class="technical-error--container">
    <form class="technical-error--form w:max-w-[576px]">
      <div class="technical-error--content">
        <cg-olb-headline [title]="'olbTechnicalError.title' | transloco"></cg-olb-headline>
        <cg-paragraph class="mb-6">
          {{ "olbTechnicalError.paragraphs.first" | transloco }}
        </cg-paragraph>
        <cg-cta [content]="ctaResetOlb$ | async" (clickedFn)="onResetBooking()"></cg-cta>
        <cg-ccc-phone-info
          class="[&>*]:mt-6"
          phoneInfoTextKey="cccOpeningHours.byPhone.technicalError"
          [phoneNumber]="phoneNumber"
          [isTrackingRequired]="true"
        ></cg-ccc-phone-info>
      </div>
    </form>
  </section>
</ng-template>
