<section>
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline [title]="'bindingBooking.' + variationId + '.title' | transloco"></cg-olb-headline>

      <p class="mt-4 font-roboto text-base" [innerHtml]="'bindingBooking.' + variationId + '.copy' | transloco"></p>

      <div class="my-12 block fhd:grid fhd:grid-cols-2 fhd:gap-[92px]">
        <div class="mb-12 flex flex-col d:flex-row fhd:mb-0">
          <cg-icon
            class="mx-auto mb-12 h-6 w-6 d:ml-0 d:mr-8 [&>mat-icon]:h-20 [&>mat-icon]:w-20"
            [content]="iconCosts"
          ></cg-icon>
          <div>
            <cg-olb-headline
              [title]="'bindingBooking.' + variationId + '.costs.title' | transloco"
              type="h3"
            ></cg-olb-headline>
            <p class="mt-4" [innerHtml]="'bindingBooking.' + variationId + '.costs.copy' | transloco"></p>
          </div>
        </div>
        <div class="mb-12 flex flex-col d:flex-row fhd:mb-0">
          <cg-icon
            class="mx-auto mb-12 h-6 w-6 d:ml-0 d:mr-8 [&>mat-icon]:h-20 [&>mat-icon]:w-20"
            [content]="iconDelivery"
          ></cg-icon>
          <div>
            <cg-olb-headline
              [title]="'bindingBooking.' + variationId + '.delivery.title' | transloco"
              type="h3"
            ></cg-olb-headline>
            <p class="mt-4" [innerHtml]="delivery.copy"></p>
          </div>
        </div>
      </div>

      @if (variationIndex > 0) {
        @if (form.get("orderCommitment").invalid && form.get("orderCommitment").touched) {
          <cg-error-message
            [class]="'error-message'"
            [errorMessage]="'bindingBooking.' + variationId + '.errorMessageNoOptIn' | transloco"
          >
          </cg-error-message>
        }
        <div class="mb-4">
          <cg-checkbox [formControlName]="'orderCommitment'" [content]="orderCommitmentCheckbox"></cg-checkbox>
        </div>
      }
      @if (variationIndex === 1) {
        <div class="cta-wrapper">
          <cg-ctalink [content]="termsAndConditionCta"></cg-ctalink>
        </div>
      }
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>

  @if (isDesktop) {
    <cg-olb-footer
      class="overflow-hidden rounded-b-card"
      (backwardBtnClicked)="goBack()"
      (forwardBtnClicked)="goForward()"
      [disableBackBtn]="hideBtns"
      [disableForwardBtn]="hideBtns"
      [goForwardText]="'bindingBooking.' + variationId + '.goForward' | transloco"
    ></cg-olb-footer>
  } @else {
    <cg-olb-footer
      class="overflow-hidden rounded-b-card"
      (backwardBtnClicked)="goBack()"
      (forwardBtnClicked)="goForward()"
      [disableBackBtn]="hideBtns"
      [disableForwardBtn]="hideBtns"
      [goForwardText]="'bindingBooking.' + variationId + '.goForwardShort' | transloco"
    ></cg-olb-footer>
  }
</section>
