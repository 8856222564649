import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppointmentFacade } from "@cg/olb/state";
import { TranslocoService } from "@jsverse/transloco";
import { Icon, Paragraph } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { mechanicIcon, qualityIcon, successIcon, toolsIcon } from "@cg/icon";
import { AptModel, AvailableServiceCenter } from "@cg/shared";

@Component({
  selector: "cg-tesla-restriction-items",
  standalone: true,
  imports: [CommonModule, IconComponent, ParagraphComponent],
  templateUrl: "./tesla-restriction-items.component.html"
})
export class TeslaRestrictionItemsComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public numberOfSCs: number;
  protected readonly successIcon = successIcon;

  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
    private readonly appointmentFacade: AppointmentFacade
  ) {}

  public async ngOnInit() {
    this.appointmentFacade.getServiceCenters({
      aptModel: AptModel.TESLA,
      timerangeBegin: new Date().toISOString()
    });

    this.appointmentFacade.availableServiceCenters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((serviceCenters: AvailableServiceCenter[]) => {
        this.numberOfSCs = serviceCenters?.length ?? 16;
        this.cdr.markForCheck();
      });
  }

  public get iconsWithParagraph(): { icon: Icon; paragraph: Paragraph }[] {
    return [
      {
        icon: qualityIcon,
        paragraph: {
          text: this.translocoService.translate("teslaRestrictions.customerReassurance.warranty"),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: mechanicIcon,
        paragraph: {
          text: this.translocoService.translate("teslaRestrictions.customerReassurance.skilledWorkers", {
            numberOfScs: this.numberOfSCs
          }),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: toolsIcon,
        paragraph: {
          text: this.translocoService.translate("teslaRestrictions.customerReassurance.manufacturerQuality"),
          ngTemplate: "cgParagraph"
        }
      }
    ];
  }
}
