<div class="appointment-list-item rounded" [ngClass]="{ 'mb-4': isExpanded }">
  <ng-template #checkboxTemplate let-appointment="customTemplateItem">
    <div class="ml-2 flex flex-col">
      <div>
        {{ appointment.customerAppointmentStart | date: "HH:mm" }}
        {{ "appointment.appointmentListItem.texts.till" | transloco }}
        {{ appointment.customerAppointmentEnd | date: "HH:mm" }}
        {{ "appointment.appointmentListItem.texts.oclock" | transloco }}
      </div>
      @if (showAppointmentDetails) {
        <div class="text-[13px] font-normal leading-5 text-cg-anthracite">
          <strong>{{ appointment.distance }} km</strong> | {{ appointment.address }}
        </div>
      }
    </div>
  </ng-template>

  <ng-template #expanstionPanelContent>
    <cg-radio-button-group
      #radioGroup
      (selectedValueChanged)="onSelectedValueChanged($event)"
      [content]="radioGroupContent"
      [customTemplate]="checkboxTemplate"
    >
    </cg-radio-button-group>
  </ng-template>

  <ng-template #titleTemplate>
    <div [@animationOpenClose]="isExpanded" class="mb-1 w-full cursor-pointer text-[13px] text-cg-anthracite">
      @if (isExpanded) {
        <div class="flex h-12 flex-row items-center justify-between pr-2">
          @if (showNumberOfAppointments) {
            <span>
              <strong>{{
                "appointment.appointmentListItem.texts.appointmentCountText"
                  | transloco: { count: sameDayAppointments.appointments.length }
              }}</strong>
            </span>
          }
          @if (!showNumberOfAppointments) {
            <span>
              <strong> {{ expandedPanelTitleDay }}, </strong>
              {{ expandedPanelTitleDate$ | async }}
            </span>
          }
          <div class="flex items-center">
            <cg-icon class="h-6 w-6" [content]="icon" [ngClass]="{ expanded: isExpanded }"></cg-icon>
          </div>
        </div>
      }
      @if (!isExpanded) {
        <div class="flex h-12 flex-row items-center justify-between pl-4 pr-2">
          <div>
            <strong>{{ sameDayAppointments.day | date: "EEE" }}</strong>
            {{ sameDayAppointments.day | date: " dd.MM.yyyy" }}
          </div>
          <div class="flex items-center gap-2">
            <strong>
              {{ "appointment.appointmentListItem.texts.appointment" | transloco: { count: appointmentCount } }}</strong
            >
            <cg-icon class="h-6 w-6" [content]="icon" [ngClass]="{ expanded: isExpanded }"></cg-icon>
          </div>
        </div>
      }
    </div>
  </ng-template>

  <cg-expansion-panel
    (accordionToggled)="isExpanded = !isExpanded"
    [titleTemplate]="titleTemplate"
    [contentTemplate]="expanstionPanelContent"
    [expanded]="isExpanded"
  ></cg-expansion-panel>
</div>
