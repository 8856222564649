<div class="w-100 flex max-w-content flex-row flex-nowrap">
  <cg-text-input
    autocomplete="tel"
    [specificInputType]="InputType.NUMBERS_PHONE"
    [content]="phoneNumberContent"
    [formControlName]="'phoneNumber'"
  ></cg-text-input>
</div>

<div class="mt-2 max-w-content-xs m:max-w-content-mobile t:max-w-content-tablet d:max-w-content">
  <cg-checkbox [content]="checkboxContent" [formControlName]="'channelSwitchCheckbox'"></cg-checkbox>
</div>
