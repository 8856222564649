<section class="damage-window--container relative flex h-full flex-col">
  <ng-template #master>
    @if (showEpidemicHint) {
      <cg-toast-message [content]="toast"></cg-toast-message>
    }
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline
          class="relative block part-[headline]:t-only:!text-2xl part-[headline]:t-only:tracking-normal"
          [title]="'damageWindow.title' | transloco"
        ></cg-olb-headline>
        <cg-damage-assessment-tile [content]="damageTileContent"></cg-damage-assessment-tile>
        <cg-olb-headline type="h3" [title]="'damageWindow.moreOptions' | transloco"> </cg-olb-headline>
        <cg-radio-button-group
          class="[&_.radio-button-headline]:!border-none [&_.radio-button]:mb-0 [&_.radio-button]:w-full"
          [content]="otherDamageLocationOptions"
          [formControlName]="otherDamageLocationOptions.controlName"
        ></cg-radio-button-group>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
