<cg-drawer [content]="contentTemplate" [headline]="headline" (closedClicked)="onClosed()"></cg-drawer>
<ng-template #headline>
  <div class="pb-2">
    <cg-olb-headline [title]="'insuranceType.insuranceTypeOptions.title' | transloco"></cg-olb-headline>
  </div>
</ng-template>
<ng-template #contentTemplate>
  <div [formGroup]="form">
    <cg-radio-button-group [content]="content" [formControlName]="'insuranceType'"></cg-radio-button-group>
  </div>
</ng-template>
