<div class="damage-assessment-selection--wrapper" part="damage-assessment-selection-wrapper">
  @for (button of content.buttons; track trackByFn($index)) {
    <div
      class="damage-assessment-selection--button order-2"
      [@overlay]="value === button.radio.value ? 'checked' : 'not-checked'"
      (@overlay.start)="onAnimationStart()"
      (@overlay.done)="onAnimationDone($event)"
      [ngClass]="{
        hover: !noHoverEffect,
        checked: value === button.radio.value,
        '!order-1 border border-cg-yellow': !!preselectValue && preselectValue === button.radio.value,
        '!bg-white': !!preselectValue && preselectValue === button.radio.value && !disabled
      }"
    >
      <label [for]="button.radio.id">
        <input
          type="radio"
          [name]="content.controlName"
          [id]="button.radio.id"
          [value]="button.radio.value"
          [checked]="value === button.radio.value"
          (change)="selectionChanged($event)"
          [disabled]="disabled"
        />
        <cg-icon
          [ngClass]="{
            '[&>mat-icon>svg>g>path]:fill-cg-anthracite-light-70 [&>mat-icon>svg>g>use]:stroke-cg-anthracite-light-70 [&>mat-icon>svg>path]:fill-cg-anthracite-light-70':
              disabled && value !== button.radio.value
          }"
          [content]="button.picture"
        ></cg-icon>
        <div
          class="pb-2 text-center font-roboto-condensed text-[13px] font-bold text-cg-anthracite m:text-base t:text-[13px] d:text-base"
          [ngClass]="{ 'text-cg-anthracite-light-70': disabled && value !== button.radio.value }"
        >
          {{ button.title | transloco }}
        </div>
      </label>
    </div>
  }
</div>
