import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { ParagraphComponent } from "@cg/core/ui";
import { calendarIcon, phoneIcon } from "@cg/icon";
import {
  ChannelSwitchReason,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelCostsExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { BaseButtonComponent, DamageWindow, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";

@Component({
  selector: "cg-opportunity-funnel-product-not-found",
  templateUrl: "./opportunity-funnel-product-not-found.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, SplitViewComponent, OlbHeadlineComponent, ParagraphComponent, BaseButtonComponent]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class OpportunityFunnelProductNotFoundComponent extends BaseDirective<AddFormControls<any>> implements OnInit {
  public readonly calendarIcon: Icon = calendarIcon;
  public readonly phoneIcon: Icon = phoneIcon;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    protected readonly trackingService: TrackingService,
    scrollService: ScrollService,
    private readonly damageFacade: DamageFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public override async ngOnInit() {
    super.ngOnInit();
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public getExitIdForSavedForm(): Observable<OpportunityFunnelCostsExitIds> {
    this.trackingService.trackEvent({
      eventAction: "opportunity-funnel-entry",
      eventLabel: "product-not-found",
      "opportunity-funnel": {
        case: "product-not-found"
      }
    });

    return of("default");
  }

  public saveForm(): void {}

  // TODO: Refactor to own service when A/B-Test is successfull
  // is also used in appointment request tile
  public goToChannelSwitch(): void {
    this.damageFacade.selectedDamage$.pipe(take(1)).subscribe((selectedDamage: DamageWindow) => {
      let reason: ChannelSwitchReason;

      switch (selectedDamage) {
        case DamageWindow.LEFT_SIDE:
          reason = "SIDE_WINDOW";
          break;
        case DamageWindow.REAR:
          reason = "REAR_WINDOW";
          break;
        case DamageWindow.FRONT:
          reason = "PRODUCT_NOT_FOUND";
          break;
      }

      this.processFacade.setChannelSwitchReason(reason);
      this.skipFormWithExitId("channelSwitch");
    });
  }
}
