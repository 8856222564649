import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { LpnInputComponent } from "@cg/lpn-input";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { ErrorMessageComponent } from "@cg/shared";

@Component({
  selector: "cg-lpn-part",
  templateUrl: "./lpn-part.component.html",
  styleUrls: ["./lpn-part.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [NgClass, TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, LpnInputComponent, ErrorMessageComponent]
})
export class LpnPartComponent implements OnInit {
  public form: FormGroup;

  @Input()
  public titleKey: string;

  @Input()
  public subtitleKey: string;

  @Input()
  public isEditable = true;

  public constructor(private readonly formGroup: FormGroupDirective) {}

  public ngOnInit(): void {
    this.form = this.formGroup.form;
  }
}
