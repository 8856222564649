import { sideWindowMultiple, sideWindowSingle } from "@cg/icon";
import { DamagedSideWindowCount } from "@cg/olb/shared";
import type { DamageAssessmentContent } from "../../../../interfaces/damage-assessment-content";

export const opportunityFunnelDamageSideTileContent: DamageAssessmentContent = {
  controlName: "damagedSideWindowCount",
  buttons: [
    {
      title: "opportunityFunnelDamageSide.buttons.single",
      picture: sideWindowSingle,
      radio: {
        id: "damage-side-window-one",
        value: DamagedSideWindowCount.SINGLE
      }
    },
    {
      title: "opportunityFunnelDamageSide.buttons.multiple",
      picture: sideWindowMultiple,
      radio: {
        id: "damage-side-window-multiple",
        value: DamagedSideWindowCount.MULTIPLE
      }
    }
  ]
};
