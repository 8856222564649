import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { CtaComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { navigateToHome } from "../../models/navigate-to-home.model";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-call-success",
  templateUrl: "./channel-switch-call-success.component.html",
  styleUrls: ["./channel-switch-call-success.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, CtaComponent]
})
export class ChannelSwitchCallSuccessComponent extends ChannelSwitchBaseComponent {
  public navigateToHome = navigateToHome;
  @HostBinding("@fadeIn") protected fadeIn = true;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private router: Router
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  // success component
  public async terminateOlb() {
    this.processFacade.exitOlb();
    await this.router.navigate(["/"]);
  }
}
