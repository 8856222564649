import { DamageLocation } from "@cg/shared";
import type { RadioButtonGroup } from "@cg/shared";

export const damageLocationOptions: RadioButtonGroup = {
  controlName: "damageLocation",
  buttons: [
    {
      title: "damageLocation.buttons.edge",
      radio: { id: "damage-location-edge", value: DamageLocation.EDGE }
    },
    {
      title: "damageLocation.buttons.view",
      radio: { id: "damage-location-view", value: DamageLocation.VIEW }
    },
    {
      title: "damageLocation.buttons.other",
      radio: { id: "damage-location-other", value: DamageLocation.OTHER }
    }
  ]
};
