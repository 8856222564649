import { AsyncPipe, LowerCasePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { RepairConfig } from "@cg/core/interfaces";
import { IconComponent } from "@cg/core/ui";
import { infoYellowIcon, successIcon } from "@cg/icon";
import { InsuranceType, OlbHeadlineComponent } from "@cg/olb/shared";
import { ConfigFacade, RequiredService } from "@cg/shared";

@Component({
  selector: "cg-insurance-type-detail",
  templateUrl: "./insurance-type-detail.component.html",
  styleUrls: ["./insurance-type-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, OlbHeadlineComponent, IconComponent],
  providers: [LowerCasePipe]
})
export class InsuranceTypeDetailComponent implements OnInit {
  @Input()
  public extendsVariant2: boolean;

  @Input()
  public headlineType: "h1" | "h2" | "h3" = "h1";

  public headlineKey = "";
  public requiredService = undefined;
  public descriptionTextKeys: string[] = [];
  private _insuranceType = "";

  public checkmark = successIcon;
  public info = infoYellowIcon;

  @Input()
  public get insuranceType(): string {
    return this._insuranceType;
  }
  public set insuranceType(insuranceType: string) {
    this._insuranceType = insuranceType || "unselected";
    this.createTextKeys(this._insuranceType);
  }

  public get repairConfig$(): Observable<{
    price: string;
    priceMoreChips: string;
  }> {
    return this.configFacade.repairConfig$.pipe(
      filter((config: RepairConfig) => !!config && !!config.price_gm_1 && !!config.price_gm_2),
      map((config: RepairConfig) => ({
        price: config.price_gm_1.replace(".", ","),
        priceMoreChips: config.price_gm_2.replace(".", ",")
      }))
    );
  }

  public constructor(
    private readonly damageFacade: DamageFacade,
    private readonly configFacade: ConfigFacade,
    private readonly lowerCasePipe: LowerCasePipe,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.damageFacade.requiredService$
      .pipe(
        filter((requiredService: RequiredService) => !!requiredService),
        take(1)
      )
      .subscribe((requiredService: RequiredService) => {
        this.requiredService = requiredService;
        this.createTextKeys(this.insuranceType);
        this.cdr.detectChanges();
      });
  }

  public createTextKeys(insuranceType: string): void {
    this.descriptionTextKeys = [];
    const insuranceTypeInLowerCase = this.lowerCasePipe.transform(insuranceType);
    const requiredServiceInLowerCase = this.lowerCasePipe.transform(this.requiredService);

    if (!this.extendsVariant2) {
      this.headlineKey = `insuranceType.detail.${requiredServiceInLowerCase}.${insuranceTypeInLowerCase}.headline`;
      if (insuranceType !== "unselected") {
        const firstPart = `insuranceType.detail.${requiredServiceInLowerCase}.${insuranceTypeInLowerCase}`;
        this.headlineKey = firstPart + ".headlineAbTest";

        this.createDescription1TextKeys(firstPart);
        this.createDescription2TextKeys(insuranceType, firstPart);
        this.createDescription3TextKeys(insuranceType, firstPart);
      }
    } else {
      this.headlineKey = `insuranceType.detail.${requiredServiceInLowerCase}.unselected.headline`;
    }
  }

  private createDescription1TextKeys(firstPart: string): void {
    this.descriptionTextKeys.push(`${firstPart}.description1AbTest`);
  }

  private createDescription2TextKeys(insuranceType: string, firstPart: string): void {
    if (insuranceType === InsuranceType.FULLY || insuranceType === InsuranceType.UNKNOWN) {
      this.descriptionTextKeys.push(`${firstPart}.description2AbTest`);
    }

    if (
      this.requiredService === RequiredService.REPAIR &&
      (insuranceType === InsuranceType.LIABILITY || insuranceType === InsuranceType.NONE)
    ) {
      this.descriptionTextKeys.push(`${firstPart}.description2AbTest`);
    }
  }

  private createDescription3TextKeys(insuranceType: string, firstPart: string): void {
    if (this.requiredService === RequiredService.REPLACE && insuranceType === InsuranceType.FULLY) {
      this.descriptionTextKeys.push(`${firstPart}.description3AbTest`);
    }
  }
}
