<div class="header" [ngClass]="{ 'no-subtitle': !subtitleKey }">
  @if (titleKey) {
    <cg-olb-headline [title]="titleKey | transloco" [subHeadline]="subtitleKey | transloco"></cg-olb-headline>
  }
</div>

<div class="lpn-part">
  <cg-olb-headline [type]="'h2'" [title]="'licensePlate.licensePlate.title' | transloco"></cg-olb-headline>

  <div class="lpn-part--input" [formGroup]="form" data-cy="lpn-part-input">
    <cg-lpn-input [isEditable]="isEditable"></cg-lpn-input>

    @if (form.touched && form.controls.lpn.hasError("invalid")) {
      <div class="error-message">
        <cg-error-message
          [class]="'error-message'"
          [errorMessage]="'licensePlate.licensePlate.errorMessage' | transloco"
        >
        </cg-error-message>
      </div>
    }
  </div>
</div>
