<cg-olb-headline
  class="channel-switch--headline"
  [title]="'channelSwitch.countdown.title' | transloco"
></cg-olb-headline>
<div class="channel-switch--timer-count-wrapper">
  <div class="channel-switch--timer-count-wrapper-time">
    <p>{{ countDown | callbackTime }}</p>
  </div>
  <div class="channel-switch--timer-count-wrapper-text">
    @if (countDown / 60 >= 1) {
      <p>{{ "channelSwitch.countdown.minutes" | transloco }}.</p>
    } @else {
      <p>{{ "channelSwitch.countdown.seconds" | transloco }}.</p>
    }
    <ng-template #seconds>
      <p>{{ "channelSwitch.countdown.seconds" | transloco }}.</p>
    </ng-template>
  </div>
</div>
