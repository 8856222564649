import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from "rxjs/operators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { AppointmentListComponent } from "@cg/appointment-ui";
import { Paragraph } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { calendarNoIcon } from "@cg/icon";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  AvailableServiceCenters,
  OptionSelectionItem,
  OptionsSelectionComponent,
  SetAppointmentPayload
} from "@cg/shared";

@Component({
  selector: "cg-tesla-appointment-search",
  templateUrl: "./tesla-appointment-search.component.html",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    HeadlineComponent,
    OptionsSelectionComponent,
    AppointmentListComponent,
    IconComponent,
    ParagraphComponent
  ]
})
export class TeslaAppointmentSearchComponent implements OnInit {
  @Input() public appointmentData: AppointmentData;
  @Input() public skipFormWithExitId: (exitId: string) => void;
  public destroyRef = inject(DestroyRef);
  public options: OptionSelectionItem[] = [];
  public availableServiceCenters: AvailableServiceCenters[];
  public readonly Icon = calendarNoIcon;
  private customerCaseId?: string;
  private appointmentId?: string;
  private availableAppointments?: Appointment[];

  @Input()
  public set savedEvent(value: Subject<boolean>) {
    value.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveForm();
    });
  }

  @ViewChild("appointmentList", { read: ElementRef })
  public appointmentList: ElementRef<HTMLElement>;

  public get isAppointmentListOverflowing(): boolean {
    const appointmentListNodeList: NodeListOf<HTMLElement> =
      this.appointmentList?.nativeElement.querySelectorAll(".appointment-list");

    if (!appointmentListNodeList || appointmentListNodeList.length !== 1) {
      return false;
    }

    const appointmentList = appointmentListNodeList[0];

    return appointmentList.scrollHeight !== Math.max(appointmentList.offsetHeight, appointmentList.clientHeight);
  }

  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;

  public get hasAppointments$(): Observable<boolean> {
    return this.appointmentData.availableAppointments$.pipe(
      map((appointments: Appointment[]) => appointments?.length > 0)
    );
  }

  public get hasSelectedAppointment$(): Observable<boolean> {
    return this.appointmentData.appointmentId$.pipe(map(Boolean));
  }

  public get hasSelectedServiceCenter$(): Observable<boolean> {
    return this.appointmentData.selectedServiceCenterIds$.pipe(
      withLatestFrom(this.appointmentData.availableAppointments$),
      map(
        ([serviceCenterIds, appointments]: [string[], Appointment[]]) =>
          !!(serviceCenterIds.length && appointments !== null)
      )
    );
  }

  public get hasAppointmentError(): boolean {
    const { invalid, touched } = this.form.controls.selectedAppointmentId;
    return invalid && touched;
  }

  public get text(): Paragraph {
    return {
      text: this.translocoService.translate("appointment.appointmentSearch.texts.noAppointmentForSc"),
      ngTemplate: "cgParagraph"
    };
  }

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly parent: FormGroupDirective,
    private readonly translocoService: TranslocoService
  ) {}

  public ngOnInit(): void {
    this.form = this.parent.form as FormGroup<AddFormControls<AppointmentSearchForm>>;
    this.appointmentData.setStatus("FIXED");
    const appointments = this.appointmentData.availableAppointments$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      tap(() => this.cdr.markForCheck()),
      takeUntilDestroyed(this.destroyRef)
    );

    combineLatest([this.appointmentData.appointmentId$, appointments, this.appointmentData.customerCaseId$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([appointmentId, availableAppointments, customerCaseId]: [string, Appointment[], string]) => {
        this.appointmentId = appointmentId;
        this.availableAppointments = availableAppointments;
        this.customerCaseId = customerCaseId;
      });

    this.createScSelection();
  }

  public saveForm(): void {
    if (this.form.invalid) {
      return;
    }

    if (!this.appointmentId) {
      console.error("No appointment id");
      return;
    }

    const selectedAppointment = this.availableAppointments.find(
      (value: Appointment) => value.appointmentId === this.appointmentId
    );

    if (!selectedAppointment) {
      console.error("No selected appointment");
      return;
    }

    const appointmentPayload: SetAppointmentPayload = {
      appointmentId: this.appointmentId,
      customerCaseId: this.customerCaseId,
      availabilityPeriodStart: selectedAppointment.availabilityPeriodStart,
      availabilityPeriodEnd: selectedAppointment.availabilityPeriodEnd,
      customerAppointmentStart: selectedAppointment.customerAppointmentStart,
      customerAppointmentEnd: selectedAppointment.customerAppointmentEnd,
      serviceCenter: selectedAppointment.serviceCenter,
      street: selectedAppointment.street,
      city: selectedAppointment.city,
      sublocality: selectedAppointment.sublocality,
      postalCode: selectedAppointment.postalCode,
      state: selectedAppointment.state,
      country: selectedAppointment.country,
      fitterId: selectedAppointment.fitterId,
      overallTaskTime: selectedAppointment.overallTaskTime,
      jobStart: selectedAppointment.jobStart,
      jobEnd: selectedAppointment.jobEnd,
      locationType: "INHOUSE",
      status: "FIXED",
      source: "OLB"
    };
    this.appointmentData.confirmAppointment(appointmentPayload);
  }

  public scFilterChanged(serviceCenterId: string) {
    this.form.markAsUntouched();
    this.form.controls.selectedAppointmentId.setValue(null);
    if (serviceCenterId) {
      this.appointmentData.setSelectedServiceCenterIds([serviceCenterId]);
      this.appointmentData.clearAndRefetchAppointments(serviceCenterId);
      this.cdr.markForCheck();
    }
  }

  private createScSelection() {
    this.appointmentData.availableServiceCenters$
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((availableServiceCenters: AvailableServiceCenters[]) => {
        this.availableServiceCenters = [...availableServiceCenters].sort(
          (a: AvailableServiceCenters, b: AvailableServiceCenters) => a?.city.localeCompare(b?.city)
        );

        this.options = [];
        this.availableServiceCenters?.forEach((availableServiceCenter: AvailableServiceCenters, index: number) => {
          this.options.push({
            id: `available-sc-${index}`,
            text: `${availableServiceCenter.city} ${availableServiceCenter.postalCode}, ${availableServiceCenter.street}`,
            value: availableServiceCenter.serviceCenter,
            disabled: false
          });
        });

        this.cdr.markForCheck();
      });
  }

  public goToMobileService(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.appointmentData.setChannelSwitchReason("MOBILE_SERVICE");
    this.skipFormWithExitId("mobileService");
  }
}
