<cg-icon [content]="pictureContent"></cg-icon>
<cg-olb-headline [title]="'damageLocationMultipleChipsDetails.title' | transloco"></cg-olb-headline>

<cg-olb-headline [title]="'damageLocationMultipleChipsDetails.edgeTitle' | transloco" [type]="'h2'"></cg-olb-headline>
<cg-paragraph
  [content]="{
    text: 'damageLocationMultipleChipsDetails.edgeText' | transloco
  }"
>
</cg-paragraph>

<cg-olb-headline
  [title]="'damageLocationMultipleChipsDetails.driverViewTitle' | transloco"
  [type]="'h2'"
></cg-olb-headline>
<cg-paragraph
  [content]="{
    text: 'damageLocationMultipleChipsDetails.driverViewText' | transloco
  }"
>
</cg-paragraph>
