<section class="flex flex-col">
  @if (isB2C && isToastForAdverseBuyCallbackVisible$ | async) {
    <cg-adverse-buy-callback (closeClicked)="onCloseCallback()" (clickLink)="goCallback()"></cg-adverse-buy-callback>
  }

  <ng-template #master>
    <form class="flex h-full flex-col" [formGroup]="form">
      <cg-appointment-search
        [skipFormWithExitId]="skipFormWithExitId.bind(this)"
        [savedEvent]="savedEvent"
        [appointmentData]="appointmentData"
      ></cg-appointment-search>
    </form>
  </ng-template>

  <ng-template #detail>
    @if ((hasSelectedAppointment | async) === false) {
      <cg-appointment-no-selected class="flex-1" [appointmentData]="appointmentData"></cg-appointment-no-selected>
    }
    @if (hasSelectedAppointment | async) {
      <cg-appointment-detail
        class="[&>div>cg-headline]:pt-3 d:[&>div>cg-headline]:pt-4"
        [appointmentData]="appointmentData"
      ></cg-appointment-detail>
    }
  </ng-template>

  <cg-split-view
    [master]="master"
    [detail]="detail"
    [drawerTrigger]="hasSelectedAppointment"
    [overlayForwardText]="'Termin übernehmen'"
    [overlayBackwardText]="'Abbrechen'"
    (dialogClosed)="onClosed($event)"
  ></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
