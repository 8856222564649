import { DatePipe, NgClass, NgStyle, SlicePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { addDays, getDay, startOfWeek } from "date-fns";
import { ABTest } from "@cg/core/utils";
import { Appointment, AppointmentData, BreakpointService, OverlayService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { createAppointmentData } from "../../utils/create-appointment-data.util";
import { NewAppointmentDetailComponent } from "../new-appointment-detail/new-appointment-detail.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-selection-grid",
  templateUrl: "./new-appointment-selection-grid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, DatePipe, SlicePipe, NgStyle]
})
export class NewAppointmentSelectionGridComponent implements OnInit {
  @Input()
  public appointments: Appointment[];

  @Input()
  public isDisplayedInAllScDialog = false;

  @Input()
  public selectedAppointmentId: string;

  @Output()
  public selectedAppointmentIdChanged = new EventEmitter<string>();

  public destroyRef = inject(DestroyRef);
  public weekDays: Date[] = [];
  public appointmentGridItems: Appointment[][] = [];
  public maxAppointmentsPerDay = 0;
  public isMobile = false;

  private appointmentData: AppointmentData;

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly breakpointService: BreakpointService
  ) {}

  public ngOnInit(): void {
    this.weekDays = this.getWeekDays();
    this.appointmentGridItems = this.getAppointmentGridData();

    this.appointmentData = createAppointmentData(this.appointmentFacade, this.damageFacade);

    this.breakpointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  public bookAppointment(appointmentId: string): void {
    this.overlayService.close();
    this.appointmentData.setAppointmentId(appointmentId);
    this.overlayService.open(NewAppointmentDetailComponent, {
      appointmentData: this.appointmentData,
      isParentAllScDialog: this.isDisplayedInAllScDialog
    });
  }

  public selectAppointment(appointmentId: string): void {
    this.selectedAppointmentId = appointmentId;

    this.selectedAppointmentIdChanged?.emit(appointmentId);
  }

  private getWeekDays(): Date[] {
    const firstDay = startOfWeek(new Date(this.appointments[0].customerAppointmentStart), { weekStartsOn: 1 });
    let days = [firstDay];

    for (let i = 0; i < 5; i++) {
      days = [...days, addDays(days[days.length - 1], 1)];
    }

    return days;
  }

  private getAppointmentGridData(): Appointment[][] {
    const sortedAppointments = this.appointments.sort((a: Appointment, b: Appointment) => {
      const aTime = new Date(a.customerAppointmentStart).getTime();
      const bTime = new Date(b.customerAppointmentStart).getTime();

      return aTime - bTime;
    });

    const appointmentsPerDay = sortedAppointments.reduce(
      (acc: Appointment[][], appointment: Appointment) => {
        // -1 because getDay returns 0 for sunday
        const day = getDay(new Date(appointment.customerAppointmentStart)) - 1;
        acc[day] = [...acc[day], appointment];
        return acc;
      },
      Array.from({ length: 6 }, () => []) as Appointment[][]
    );

    let maxLength = 0;
    appointmentsPerDay.forEach((day: Appointment[]) => {
      if (day.length > maxLength) {
        maxLength = day.length;
      }
    });

    // fill up the days with empty slots to get a grid layout
    const appointmentsPerDayFilled: Appointment[][] = appointmentsPerDay.map((day: Appointment[]) => {
      const emptySlots: Appointment[] = Array.from({ length: maxLength - day.length }, () => null);
      return [...day, ...emptySlots];
    });

    // reassemble the array to get the correct grid layout (days as columns)
    let result: Appointment[][] = [];
    for (let i = 0; i < maxLength; i++) {
      result = [...result, appointmentsPerDayFilled.map((day: Appointment[]) => day[i])];
    }

    return result;
  }
}
