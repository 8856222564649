import { InsuranceCoveredMessageComponent } from "../components/insurance-covered-message/insurance-covered-message.component";
import { InsuranceDeclinedMessageComponent } from "../components/insurance-declined-message/insurance-declined-message.component";
import { InsuranceOptionsComponent } from "../components/insurance-options/insurance-options.component";
import { InsurancePartnerMessageComponent } from "../components/insurance-partner-message/insurance-partner-message.component";
import { InsuranceTypeOptionsComponent } from "../components/insurance-type-options/insurance-type-options.component";

export const overlayContentMapping = {
  "insurance-type-selection": InsuranceTypeOptionsComponent,
  "insurances-selection": InsuranceOptionsComponent,
  "insurance-covered-message": InsuranceCoveredMessageComponent,
  "insurance-partner-message": InsurancePartnerMessageComponent,
  "insurance-declined-message": InsuranceDeclinedMessageComponent
};
