import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { iif, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { calendarIcon } from "@cg/icon";
import { AppointmentData } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-appointment-no-selected",
  templateUrl: "./appointment-no-selected.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, IconComponent, ParagraphComponent, HeadlineComponent]
})
export class AppointmentNoSelectedComponent {
  @Input() public appointmentData: AppointmentData;
  public icon: Icon = calendarIcon;

  public get text$() {
    return this.appointmentData.formattedAddress$.pipe(
      mergeMap((address: string) =>
        iif(
          () => !!address,
          of("appointment.appointmentNoSelected.texts.chooseAppointment"),
          of("appointment.appointmentNoSelected.texts.choosePlace")
        )
      )
    );
  }
}
