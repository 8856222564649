import { AsyncPipe, DOCUMENT, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageFacade, InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { accordionChevronDownIcon } from "@cg/icon";
import { InsuranceType, OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, BreakpointService, CtaComponent, RequiredService } from "@cg/shared";

@Component({
  selector: "cg-qualtrics-teaser",
  templateUrl: "./qualtrics-teaser.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    BaseButtonComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CtaComponent,
    IconComponent
  ]
})
export class QualtricsTeaserComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public set isPinned(value: boolean) {
    this._isPinned = value;

    if (!this._isPinned) {
      this._expanded = false;
    }

    this.cdr.detectChanges();
  }

  public get isPinned$(): Observable<boolean> {
    return this.breakpointService.isDesktop$.pipe(
      map((isDesktop: boolean) => {
        if (isDesktop) {
          return false;
        }

        return this._isPinned;
      })
    );
  }

  public chevronIcon = accordionChevronDownIcon;

  public get isExpanded$(): Observable<boolean> {
    return this.breakpointService.isDesktop$.pipe(
      map((isDesktop: boolean) => {
        if (isDesktop) {
          return false;
        }

        return this._expanded;
      })
    );
  }

  private _expanded = true;
  private _isPinned = false;

  private readonly QUALTRICS_LINK: string = "https://belron.eu.qualtrics.com/jfe/form/SV_4G9iMnsonW7R0kC";
  private _scrollListener: () => void;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly breakpointService: BreakpointService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly damageFacade: DamageFacade,
    @Inject(OLB_CONFIG) private olbConfig: OlbConfiguration
  ) {}

  public ngOnInit(): void {
    this._scrollListener = this.onScroll.bind(this);
  }

  public ngAfterViewInit(): void {
    this.document.addEventListener("scroll", this._scrollListener);
  }

  public ngOnDestroy(): void {
    this.removeScrollListener();
  }

  public toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this._expanded = !this._expanded;
  }

  public linkClicked(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    combineLatest([this.insuranceFacade.type$, this.damageFacade.requiredService$, this.breakpointService.isTouch$])
      .pipe(take(1))
      .subscribe(([insuranceType, requiredService, isTouch]: [InsuranceType, RequiredService, boolean]) => {
        const entryChannelQuery = `entryChannel=${this.olbConfig.entryChannel.toLowerCase()}`;
        const insuranceTypeQuery = `insuranceType=${insuranceType.toLowerCase()}`;
        const requiredServiceQuery = `requiredService=${requiredService.toLowerCase()}`;
        const viewportQuery = `viewport=${isTouch ? "mobile" : "desktop"}`;
        const abTestVariation = sessionStorage.getItem("qualtrics_abTest_variationId") || "null";
        const abTestVariationQuery = `abTestVariation=${abTestVariation}`;

        window.open(
          `${this.QUALTRICS_LINK}?${entryChannelQuery}&${insuranceTypeQuery}&${requiredServiceQuery}&${viewportQuery}&${abTestVariationQuery}`,
          "_blank"
        );
      });
  }

  private onScroll(): void {
    this._expanded = this.document.documentElement.scrollTop < 100;

    if (!this._expanded) {
      this.removeScrollListener();
    }

    this.cdr.detectChanges();
  }

  private removeScrollListener() {
    if (this._scrollListener) {
      this.document.removeEventListener("scroll", this._scrollListener);
      this._scrollListener = null;
    }
  }
}
