/* eslint-disable max-params */
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { ContactDataFacade, CustomerCaseFacade, OlbFacade, ProcessFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { isOpportunityFunnel } from "@cg/core/utils";
import {
  CustomerDataEmailExitIds,
  InsuranceHolder,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  ComponentOverarchingChangeDetectionService,
  CtalinkComponent,
  CustomerCase,
  CustomerContact,
  CustomerContactFormComponent,
  ProcessMetadata,
  Resume,
  ResumeType,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { additionalOptions } from "./models/additional-options.model";

@Component({
  selector: "cg-customer-contact",
  templateUrl: "./customer-contact.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    CustomerContactFormComponent,
    CtalinkComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class CustomerContactComponent extends BaseDirective<AddFormControls<CustomerContact>> implements OnInit {
  public privacyStatementCta = { ...additionalOptions.firstCtaLink };
  public customerCaseId$: Observable<string> = this.customerCaseFacade.customerCaseId$;
  public form: FormGroup;

  private isDirectResume?: boolean;
  private customerCase?: CustomerCase;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private contactDataFacade: ContactDataFacade,
    private customerCaseFacade: CustomerCaseFacade,
    protected trackingService: TrackingService,
    @Inject(OLB_CONFIG) private readonly _olbConfig: OlbConfiguration,
    private fb: FormBuilder,
    private readonly cdrService: ComponentOverarchingChangeDetectionService,
    private readonly resumeFacade: ResumeFacade,
    private readonly olbFacade: OlbFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get isEmailOptional(): boolean {
    return this._olbConfig?.tiles?.["customer-contact"]?.emailOptional;
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.customerCaseFacade.customerCase$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cCase: CustomerCase) => (this.customerCase = cCase));
    this.isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);
  }

  public initFormGroup(): void {
    this.form = this.fb.group({
      contact: this.fb.group({})
    });
  }

  public saveForm(): void {
    const { email, mobile } = this.form.controls.contact.value;

    this.contactDataFacade.setMobile(mobile);
    this.contactDataFacade.setEmail(email);
  }

  public postSaveForm() {
    super.postSaveForm();

    this.contactDataFacade.insuranceHolder$
      .pipe(
        filter(
          (insuranceHolder: InsuranceHolder) =>
            !!insuranceHolder &&
            !!insuranceHolder.firstname &&
            !!insuranceHolder.lastname &&
            !!insuranceHolder.street &&
            !!insuranceHolder.city &&
            this.isDirectResume &&
            this.form.valid
        ),
        take(1),
        tap((_: InsuranceHolder) => this.olbFacade.updateCustomerContact()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public setFormValues(): void {
    combineLatest([this.contactDataFacade.email$, this.contactDataFacade.mobile$])
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe(([email, mobile]: [string, string]) => {
        if (email) {
          this.form.get("contact.email").setValue(email);
        }
        if (mobile) {
          this.form.get("contact.mobile").setValue(mobile);
        }
      });
  }

  public getExitIdForSavedForm(): Observable<CustomerDataEmailExitIds> {
    return this.resumeFacade.resumeResponse$.pipe(
      withLatestFrom(this.processFacade.processMetaData$),
      map(([resumeResponse, processMetaData]: [Resume, ProcessMetadata[]]) => {
        const customer = this.customerCase?.customer;
        const isAddressIncomplete =
          !customer?.customerShipAddressLine2 || !customer?.customerShipCity || !customer?.customerShipZipCode;
        if (this.isDirectResume) {
          if (resumeResponse.resumeType === ResumeType.B2B_IOM && isAddressIncomplete) {
            return "newCustomer" as CustomerDataEmailExitIds;
          }
          if (isOpportunityFunnel(processMetaData)) {
            return "opportunityFunnelContactTime";
          }
          return "directResume" as CustomerDataEmailExitIds;
        }
        return "newCustomer" as CustomerDataEmailExitIds;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public onTrack(eventAction: string) {
    this.trackingService.trackEvent({
      eventAction: eventAction,
      eventLabel: "customer-contact"
    } as Partial<TrackingEvent>);
  }

  public goForwardFailure(): void {
    super.goForwardFailure();
    this.cdrService.changeDetectionRequest$.next();
  }
}
