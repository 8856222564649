@if ({ isExpanded: isExpanded$ | async }; as data) {
  <div
    class="flex w-full flex-col bg-cg-gray-light-80 px-4 py-3 transition-all duration-300"
    [ngClass]="{
      'xs:-mx-6 t:max-d:-mx-12 -mx-8 w-screen !bg-white/95 shadow-[0px_4px_8px_8px_rgba(0,0,0,0.25)]': isPinned$ | async
    }"
  >
    <div
      class="t:max-d:mx-2 flex justify-between gap-2 d:justify-center d:gap-6"
      [ngClass]="{ 'items-center': !data.isExpanded }"
    >
      <cg-olb-headline
        [title]="(data.isExpanded ? 'qualtricsTeaser.title' : 'qualtricsTeaser.collapsedTitle') | transloco"
        [type]="'h3'"
        class="[&>h3]:pm-0"
        [ngClass]="{
          '[&>h3]:!m-0 [&>h3]:!text-[13px] [&>h3]:!leading-5 d:[&>h3]:!text-base': !data.isExpanded,
          '[&>h3]:!text-[22px] m:[&>h3]:!text-2xl': data.isExpanded
        }"
        style="text-wrap: balance"
      ></cg-olb-headline>
      <cg-icon
        class="h-6 w-6 origin-center p-1 d:!hidden"
        [ngClass]="{ '!hidden': !data.isExpanded }"
        [content]="chevronIcon"
        (click)="toggle($event)"
      ></cg-icon>
      <button
        class="hidden opacity-0 transition-opacity duration-300 [&>div>span]:whitespace-nowrap [&>div>span]:text-[13px] [&>div]:h-8 [&>div]:px-3"
        cg-base-button
        [ngClass]="{
          '!block opacity-100': !data.isExpanded
        }"
        [btnText]="'qualtricsTeaser.btnText' | transloco"
        (click)="linkClicked($event)"
      ></button>
    </div>
    <div
      class="flex flex-col gap-4 overflow-hidden transition-[max-height] duration-300"
      [ngClass]="{ 'max-h-0 ease-out': !data.isExpanded, 'max-h-[224px] ease-in': data.isExpanded }"
    >
      <cg-paragraph [content]="{ text: 'qualtricsTeaser.text' | transloco }" class="pt-0"></cg-paragraph>
      <button cg-base-button [btnText]="'qualtricsTeaser.btnText' | transloco"></button>
    </div>
  </div>
}
