<cg-insurance-base-message
  [insuranceMessageGridItems]="gridItems"
  [headlineTranslation]="title"
  [subHeadlineKey]="'insurancePartnerMessage.subHeadline'"
  [contentUnderGridTemplateRef]="optionalContent"
  [footerTemplateRef]="footerTemplate"
  (closedClicked)="onClosedClicked()"
>
</cg-insurance-base-message>

<ng-template #optionalContent>
  @if (isHukInsurance) {
    <div class="flex flex-col items-center pt-4 d:pt-12">
      <p class="m-auto text-center text-[13px] uppercase leading-[20px] text-cg-anthracite-light-30">
        {{ "insurancePartnerMessage.partnerSubHeadline" | transloco }}
      </p>
      <cg-icon
        class="inline-block h-[30px] w-[159px]"
        [content]="insuranceName === 'HUK24' ? huk24Icon : hukIcon"
      ></cg-icon>
    </div>
  }
</ng-template>

<ng-template #footerTemplate>
  <cg-olb-footer (forwardBtnClicked)="onGoForwardClicked()" (backwardBtnClicked)="onGoBackClicked()"></cg-olb-footer>
</ng-template>
