@if (form) {
  <div class="damage-assessment" [formGroup]="form">
    <cg-damage-assessment-selection
      [formControlName]="content.controlName"
      [content]="content"
      [preselectValue]="preselectValue"
      (animationDone)="onAnimationDone($event)"
    ></cg-damage-assessment-selection>
  </div>
}
