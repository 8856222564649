import { BehaviorSubject } from "rxjs";
import type { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import type { JobStatus, SetAppointmentPayload } from "@cg/shared";

export const createAppointmentData = (appointmentFacade: AppointmentFacade, damageFacade: DamageFacade) => ({
  appointmentId$: appointmentFacade.appointmentId$,
  availableAppointments$: appointmentFacade.availableAppointments$,
  selectedServiceCenter$: appointmentFacade.selectedServiceCenter$,
  selectedServiceCenterIds$: appointmentFacade.selectedServiceCenterIds$,
  availableServiceCenters$: appointmentFacade.availableServiceCenters$,
  isCalibration$: appointmentFacade.isCalibration$,
  requiredService$: damageFacade.requiredService$,
  formattedAddress$: appointmentFacade.formattedAddress$,
  locality$: appointmentFacade.locality$,
  position$: appointmentFacade.position$,
  isLoading$: appointmentFacade.isLoading$,
  reloadAppointments$: new BehaviorSubject<boolean>(false),
  setStatus: (status: JobStatus) => {
    appointmentFacade.setStatus(status);
  },
  resetStateForForm: () => {
    appointmentFacade.resetStateForForm();
  },
  confirmAppointment: (appointment: SetAppointmentPayload) => {
    appointmentFacade.confirmAppointment(appointment);
  },
  setAppointmentId: (appointmentId: string) => {
    appointmentFacade.setAppointmentId(appointmentId);
  },
  setSelectedServiceCenterIds: (serviceCenterIds: string[]) => {
    appointmentFacade.setSelectedServiceCenterIds(serviceCenterIds);
  },
  clearAndRefetchAppointments: (serviceCenterId: string) => {
    appointmentFacade.clearAndRefetchAppointments(serviceCenterId);
  },
  saveAutocompleteResult: (lat: number, lng: number, address: string, locality: string) => {
    appointmentFacade.saveAutocompleteResult({ lat, lng, address, locality });
  },
  isScSelectDisabled: false
});
