import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OlbFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Cta } from "@cg/content-api/typescript-interfaces";
import { ParagraphComponent } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import { ExitIds, OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { CccPhoneInfoComponent, CtaComponent, CtaVariation, PhoneNumber, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";

@Component({
  selector: "cg-technical-error",
  templateUrl: "./technical-error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    SplitViewComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CtaComponent,
    CccPhoneInfoComponent
  ]
})
export class TechnicalErrorComponent extends BaseDirective<null> implements OnInit {
  @Input() public phoneNumber: PhoneNumber = PhoneNumber.TECHNICAL_ERROR;

  @Input() public resetURL = "/olb";
  @Output() public resetClicked: EventEmitter<void> = new EventEmitter<void>();

  public get ctaResetOlb$(): Observable<Cta> {
    return this.translocoService.selectTranslate("olbTechnicalError.ctas.title").pipe(
      map((title: string) => ({
        title,
        id: "technical-error-reset-olb",
        icon: arrowsIcon,
        link: {
          href: this.resetURL,
          title,
          text: title
        },
        arrowDirection: "right",
        ngTemplate: "cgCta",
        variation: CtaVariation.PRIMARY
      }))
    );
  }

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolverService: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly olbFacade: OlbFacade,
    protected readonly trackingService: TrackingService,
    protected readonly translocoService: TranslocoService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolverService, trackingService, scrollService, processFlow);
  }

  public onResetBooking() {
    this.olbFacade.resetOLB();
    this.trackingService.trackEvent({
      eventAction: "reset-session",
      eventLabel: "olb-technical-error"
    });
    this.olbFacade.initOLB(true);
    this.trackingService.trackEvent({
      eventAction: "reset-session",
      eventLabel: "olb-technical-error"
    });

    this.resetClicked.emit();
  }

  public getExitIdForSavedForm(): Observable<ExitIds> | undefined {
    return undefined;
  }

  public initFormGroup(): void {
    return;
  }

  public saveForm(): void {
    return;
  }

  public setFormValues(): void {
    return;
  }
}
