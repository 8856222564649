<div class="flex h-full flex-col">
  <cg-headline
    class="[&>h1]:m-h1 [&>h1]:font-roboto-condensed [&>h1]:text-[26px] [&>h1]:font-bold [&>h1]:leading-8 [&>h1]:t:m-h1-tablet [&>h1]:t:text-[30px] [&>h1]:t:leading-10"
    [content]="'appointment.appointmentNoSelected.texts.title' | transloco"
  ></cg-headline>
  <div class="flex flex-1 items-center justify-center">
    <div class="flex flex-col justify-center">
      <cg-icon class="m-auto mb-8 block h-[72px] w-[72px]" [content]="icon"></cg-icon>
      <cg-paragraph [content]="{ text: (text$ | async | transloco), ngTemplate: 'cgParagraph' }"></cg-paragraph>
    </div>
  </div>
</div>
