import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  isDevMode,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";
import { distinctUntilChanged } from "rxjs/operators";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { closeIcon } from "@cg/icon";
import { InsuranceType } from "@cg/olb/shared";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { overlayContentMapping } from "../../models/overlay-mapping.model";
import { InsuranceBaseMessageComponent } from "../insurance-base-message/insurance-base-message.component";

type ValueType = Partial<{
  insuranceType: InsuranceType;
  insurance: string;
  nextOverlay: string;
  lastOverlayShown: boolean;
  overlayClose: boolean;
}>;

@Component({
  selector: "cg-insurance-overlay-wrapper",
  templateUrl: "./insurance-overlay-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class InsuranceOverlayWrapperComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public overlayType: string;
  public form: FormGroup<AddFormControls<InsuranceTypeForm>>;
  public close: (value?: Partial<InsuranceTypeForm>) => void;

  @ViewChild("render", { static: true, read: ViewContainerRef })
  public renderComponentSlot: ViewContainerRef;

  public readonly closeIcon: Icon = closeIcon;

  public ngOnInit(): void {
    this.resolveOverlayComponent(this.overlayType);
    this.form.controls.lastOverlayShown.setValue(false);
    this.form.controls.overlayClose.setValue(false);
    this.overlayFlow();
  }
  // TODO: maybe find better Overlayflow control
  private overlayFlow() {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(
          (
            prev: Partial<{
              insuranceType: InsuranceType;
              insurance: string;
              nextOverlay: string;
              lastOverlayShown: boolean;
              overlayClose: boolean;
            }>,
            curr: Partial<{
              insuranceType: InsuranceType;
              insurance: string;
              nextOverlay: string;
              lastOverlayShown: boolean;
              overlayClose: boolean;
            }>
          ) =>
            prev.insurance === curr.insurance &&
            prev.insuranceType === curr.insuranceType &&
            prev.nextOverlay === curr.nextOverlay &&
            prev.lastOverlayShown === curr.lastOverlayShown &&
            prev.overlayClose === curr.overlayClose
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        (
          value: Partial<{
            insuranceType: InsuranceType;
            insurance: string;
            nextOverlay: string;
            lastOverlayShown: boolean;
            overlayClose: boolean;
          }>
        ) => {
          this.checkLastOverlayShown(value);
          this.checkOverlayClose(value);
          if (this.form) this.resolveOverlay();
        }
      );
  }

  private resolveOverlay() {
    this.renderComponentSlot.remove();
    this.resolveOverlayComponent(this.form.controls.nextOverlay.value);
  }

  public callOverlayFlow() {
    this.overlayFlow();
  }

  public onClosed(): void {
    this.close();
  }

  private resolveOverlayComponent(overlayType: string) {
    const component = overlayContentMapping[overlayType];
    if (component) {
      const ref = this.renderComponentSlot.createComponent(component);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const instance = ref.instance as InsuranceBaseMessageComponent<any>;

      if (!instance) {
        if (isDevMode()) {
          console.error(`No component found for overlayType: ${overlayType}`);
        }
        return;
      }

      instance.form = this.form;
    }
  }

  public checkLastOverlayShown(value: ValueType) {
    if (this.form.controls.lastOverlayShown.value) {
      this.close(value);
    }
  }

  public checkOverlayClose(value: ValueType) {
    if (this.form.controls.overlayClose.value) {
      this.close(value);
    }
  }

  public checkInsuranceType(value: ValueType) {
    if (
      this.form.controls.insuranceType.value === InsuranceType.PAID_BY_PERPETRATOR ||
      this.form.controls.insuranceType.value === InsuranceType.UNKNOWN
    ) {
      this.close(value);
    }
  }
}
