import { phoneIcon } from "@cg/icon";
import { CtaVariation } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";

export const customerCallsCallCenter: Cta = {
  id: "channel-switch-ccc",
  icon: phoneIcon,
  arrowDirection: "right",
  title: "channelSwitch.models.customerCallCenter.text",
  link: null,
  variation: CtaVariation.PRIMARY,
  ngTemplate: "cgCta"
};
