import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { DamageFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon, Paragraph } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { vapsOfferCloudIcon, vapsOfferSnowIcon, vapsOfferWiperIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, DrawerComponent, OverlayService, RequiredService } from "@cg/shared";

@Component({
  selector: "cg-vaps-protect-second-change",
  templateUrl: "./vaps-protect-second-chance.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    IconComponent,
    BaseButtonComponent
  ]
})
export class VapsProtectSecondChanceComponent implements OnInit {
  private readonly overlayService = inject(OverlayService);
  private readonly translateService = inject(TranslocoService);
  private readonly trackingService = inject(TrackingService);
  private readonly damageFacade = inject(DamageFacade);

  private requiredService = toSignal(this.damageFacade.requiredService$);

  public paragraphTextKey = computed(() =>
    this.requiredService() === RequiredService.REPAIR
      ? "vapsProtectSecondChance.repairSubTitle"
      : "vapsProtectSecondChance.replaceSubTitle"
  );

  public listItems: { icon: Icon; text: Paragraph }[];

  public ngOnInit(): void {
    this.listItems = [
      {
        icon: vapsOfferCloudIcon,
        text: {
          text: this.translateService.translate("vapsProtectSecondChance.list.cloud"),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: vapsOfferSnowIcon,
        text: {
          text: this.translateService.translate("vapsProtectSecondChance.list.snow"),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: vapsOfferWiperIcon,
        text: {
          text: this.translateService.translate("vapsProtectSecondChance.list.wiper"),
          ngTemplate: "cgParagraph"
        }
      }
    ];

    this.trackingService.trackEvent({
      event: "ga-event",
      eventCategory: "olb",
      eventAction: "vaps-selection",
      eventLabel: "show-2nd-layer"
    });
  }

  public close(add: boolean) {
    this.overlayService.close(add);
  }
}
