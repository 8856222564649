<div class="flex flex-col t:mx-auto t:max-w-[400px]">
  <cg-olb-headline
    [type]="'h3'"
    [title]="'newAppointment.noAppointments.title' | transloco"
    class="mx-auto t:part-[headline]:x-[!text-2xl,text-center]"
  ></cg-olb-headline>
  <p class="text-[13px] text-cg-anthracite t:x-[mx-auto,mt-4,text-center,text-base]">
    {{ "newAppointment.noAppointments.paragraph" | transloco }}
  </p>
</div>
<div class="mt-8 flex flex-col items-center text-center">
  <cg-icon class="h-12 w-12" [content]="phoneContactIcon"></cg-icon>
  <cg-olb-headline
    class="mb-1 mt-4 text-cg-anthracite part-[headline]:!m-0 t:part-[headline]:x-[!text-2xl,text-center]"
    [type]="'h3'"
    [title]="'newAppointment.noAppointments.serviceTitle' | transloco"
  ></cg-olb-headline>
  <p
    class="text-[13px] text-cg-anthracite-light-30 t:x-[mx-auto,text-center,text-base]"
    [innerHTML]="
      'newAppointment.noAppointments.serviceParagraph'
        | transloco: { phone: PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE, plainPhone }
    "
    (click)="callLinkClicked($event)"
  ></p>
</div>
