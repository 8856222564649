import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { AppointmentTimeComponent, CarBringingScheduleTextTextComponent } from "@cg/appointment-ui";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { appointmentInfoIcon, calendarIcon, clockIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { Appointment, BaseButtonComponent, OpeningHour } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { NewAppointmentTileService } from "../../services/new-appointment-tile.service";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-desktop-detail",
  templateUrl: "./new-appointment-desktop-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    TranslocoPipe,
    IconComponent,
    ParagraphComponent,
    OlbHeadlineComponent,
    BaseButtonComponent,
    AppointmentTimeComponent,
    CarBringingScheduleTextTextComponent
  ]
})
export class NewAppointmentDesktopDetailComponent {
  @Input()
  public appointment: Appointment;

  @Input()
  public openHour: OpeningHour;

  public readonly calendarIcon = calendarIcon;
  public readonly clockIcon = clockIcon;
  public readonly infoIcon = appointmentInfoIcon;

  public constructor(private readonly newAppointmentTitleService: NewAppointmentTileService) {}

  public bookAppointment(): void {
    this.newAppointmentTitleService.bookAppointment(this.appointment);
  }
}
