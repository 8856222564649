import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { damageLocationMultipleChipsIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-damage-location-multiple-chips-details",
  templateUrl: "./damage-location-multiple-chips-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, IconComponent, OlbHeadlineComponent, ParagraphComponent]
})
export class DamageLocationMultipleChipsDetailsComponent {
  @Input()
  public pictureContent: Icon = damageLocationMultipleChipsIcon;
}
