import { arrowsIcon } from "@cg/icon";
import { CtaVariation } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";

export const navigateToHome: Cta = {
  id: "channel-switch-navigate-to-home",
  icon: arrowsIcon,
  arrowDirection: "right",
  title: "channelSwitch.models.navigateToHome.text",
  link: null,
  variation: CtaVariation.PRIMARY,
  ngTemplate: "cgCta"
};
