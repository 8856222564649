<cg-drawer [showCloseIcon]="true" [content]="content" [showBranding]="false" data-cy="wipers-gdv"></cg-drawer>

<ng-template #content>
  <div class="mt-4 flex flex-col">
    <div class="flex flex-col">
      <form [formGroup]="form" class="flex flex-col">
        <cg-olb-headline class="mb-2" type="h1" [title]="'wipersGdv.headline' | transloco"></cg-olb-headline>
        <cg-olb-headline
          class="mb-2 block part-[headline]:!text-lg"
          type="h3"
          [title]="'wipersGdv.subHeadline' | transloco"
        ></cg-olb-headline>

        <cg-lpn-input class="mb-4 block"></cg-lpn-input>

        <cg-paragraph
          class="mb-4 block [&_a]:!text-cg-anthracite"
          [content]="{ text: 'wipersGdv.paragraph' | transloco, ngTemplate: 'cgParagraph' }"
        ></cg-paragraph>
      </form>
    </div>
    <button
      cg-base-button
      class="w-full"
      [btnText]="'wipersGdv.buttons.yes' | transloco"
      (click)="submit()"
      data-cy="btn-submit"
    ></button>
    <button
      cg-base-button
      class="w-full part-[icon-text-wrapper]:!bg-transparent part-[text]:!text-cg-anthracite"
      [btnText]="'wipersGdv.buttons.no' | transloco"
      (click)="close()"
    ></button>
  </div>
</ng-template>
