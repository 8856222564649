@if (!!carBringingScheduleText) {
  <cg-paragraph
    [content]="{
      text:
        (carBringingScheduleText.content
        | transloco
          : {
              customerAppointmentFrom: carBringingScheduleText?.customerAppointmentFrom,
              serviceCenterOpenFrom: carBringingScheduleText?.serviceCenterOpenFrom
            }),
      ngTemplate: 'cgParagraph'
    }"
  ></cg-paragraph>
}
