import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { filter } from "rxjs/operators";
import { DamageFacade, InsuranceFacade, OlbFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { IconComponent, LinkComponent, LinkWithId, ParagraphComponent } from "@cg/core/ui";
import { infoWhiteIcon, privacyIcon } from "@cg/icon";
import { LpnInputComponent, LpnInputForm } from "@cg/lpn-input";
import {
  InsuranceType,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelLicensePlateExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  BaseButtonComponent,
  ComponentOverarchingChangeDetectionService,
  Lpn,
  OverlayService,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { GdvInfoComponent } from "./components/gdv-info/gdv-info.component";
import { OpportunityFunnelLicensePlateForm } from "./interfaces/opportunity-funnel-license-plate-form.interface";

@Component({
  selector: "cg-opportunity-funnel-license-plate",
  templateUrl: "./opportunity-funnel-license-plate.component.html",
  styleUrls: ["./opportunity-funnel-license-plate.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    SplitViewComponent,
    OlbHeadlineComponent,
    LpnInputComponent,
    IconComponent,
    ParagraphComponent,
    LinkComponent,
    BaseButtonComponent
  ]
})
export class OpportunityFunnelLicensePlateComponent extends BaseDirective<
  AddFormControls<OpportunityFunnelLicensePlateForm>
> {
  public readonly infoIcon: Icon = infoWhiteIcon;

  public readonly sslIcon: Icon = privacyIcon;

  public readonly agbLink: LinkWithId = {
    id: "license-plate-datenschutz",
    href: "https://www.carglass.de/datenschutz/datenverarbeitung-website",
    target: "_blank",
    title: "licensePlate.privacy",
    text: "licensePlate.privacy"
  };

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    private readonly damageFacade: DamageFacade,
    private readonly overlayService: OverlayService,
    private readonly olbFacade: OlbFacade,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<OpportunityFunnelLicensePlateForm>>({
      lpn: new FormControl<LpnInputForm>(null, Validators.required)
    });
  }

  public setFormValues(): void {
    this.damageFacade.lpn$
      .pipe(
        filter((lpn: Lpn) => !!lpn),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((lpn: Lpn) => {
        this.form.controls.lpn.setValue(lpn);

        this.cdr.markForCheck();
      });
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelLicensePlateExitIds> {
    return of("default");
  }

  public override goForward(): void {
    this.form.markAllAsTouched();
    this.changeDetectionService.changeDetectionRequest$.next();

    super.goForward();
  }

  public saveForm(): void {
    this.damageFacade.setLpn(this.form.controls.lpn.value);

    if (!this.form.valid) {
      return;
    }

    this.damageFacade.setNoDate();
    // user can't select insurance in this flow so set it to unknown
    this.insuranceFacade.setInsuranceType(InsuranceType.UNKNOWN);

    this.olbFacade.checkDuplicate();
  }

  protected goForwardSuccess(eventAction: string): void {
    super.goForwardSuccess(eventAction);

    this.trackingService.trackEvent({
      eventAction: "opportunity-license-plate",
      eventLabel: "success"
    });
  }

  public openDrawer(): void {
    this.overlayService.open(GdvInfoComponent);
  }
}
