<div class="flex flex-col">
  <form [formGroup]="form">
    <cg-headline
      class="mt-3 block [&>h1]:font-roboto-condensed [&>h1]:text-[26px] [&>h1]:font-bold [&>h1]:leading-8 [&>h1]:t:text-[30px] [&>h1]:t:leading-10"
      [content]="'appointment.appointmentSearch.texts.title' | transloco"
    ></cg-headline>

    <div class="sticky top-0 z-[2] block rounded-[32px] bg-white pb-4 pt-2 t:pt-4">
      <cg-options-selection
        [selectionId]="'appointment-sc-selection'"
        [placeholder]="'teslaAppointmentRequest.serviceCenterPlaceholder' | transloco"
        [items]="options"
        [isPlaceholderDisabled]="false"
        [formControlName]="'scId'"
        (selectionChangedEmitter)="scFilterChanged($event)"
      ></cg-options-selection>
    </div>

    @if (hasSelectedServiceCenter$ | async) {
      @if (hasAppointments$ | async) {
        <cg-appointment-list
          #appointmentList
          class="-mt-10.5 relative z-[1] block part-[appointment-list]:overflow-auto t:-mt-[58px] [&>div]:max-h-[26.5rem]"
          [ngClass]="{
            error: hasAppointmentError,
            '[&>div]:max-h-[34rem]': hasSelectedAppointment$ | async,
            '[&>div>div>div>cg-appointment-list-item>div>cg-expansion-panel>div>div>span>div>div]:pr-1':
              isAppointmentListOverflowing
          }"
          [appointmentData]="appointmentData"
          [formControlName]="'selectedAppointmentId'"
          [showAppointmentDetails]="false"
          (mobileService)="goToMobileService($event)"
        ></cg-appointment-list>
      } @else {
        <div class="py-8">
          <cg-icon class="h-[72px] text-center" [content]="Icon"></cg-icon>
          <cg-paragraph
            class="max-w-[556px] text-center [&>p:is(a)]:text-cg-anthracite [&>p:is(a)]:underline"
            [content]="text"
          ></cg-paragraph>
        </div>
      }
    }
  </form>
</div>
