import { CommonModule } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { CustomerCaseFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Picture } from "@cg/content-api/typescript-interfaces";
import { ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { OlbHeadlineComponent, VAPsEventFlag } from "@cg/olb/shared";
import { AdditionalProduct, BreakpointService, PriceComponent } from "@cg/shared";
import { WiperPrice } from "../../interfaces/wiper-price.interface";

@Component({
  selector: "cg-wiper-booked",
  standalone: true,
  imports: [TranslocoPipe, CommonModule, OlbHeadlineComponent, PictureComponent, ParagraphComponent, PriceComponent],
  templateUrl: "./wiper-booked.component.html"
})
export class WiperBookedComponent {
  private readonly breakpointService = inject(BreakpointService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);

  public wiperPriceIsReduced = input.required<boolean>();
  public wipersPicture = input.required<Picture>();
  public price = input.required<WiperPrice>();

  public isTouch = toSignal(this.breakpointService.isTouch$);

  public removeWipers(): void {
    this.customerCaseFacade.removeVAP(AdditionalProduct.WIPER, [VAPsEventFlag.AFTER_CONFIRMATION]);
  }
}
