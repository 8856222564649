import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { TrackingService } from "@cg/analytics";
import { ChannelSwitchTrackingReason } from "@cg/olb/shared";
import { ProcessMetadata } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class ChannelSwitchTrackingService {
  public constructor(
    private readonly trackingService: TrackingService,
    private readonly processFacade: ProcessFacade
  ) {}

  public trackChannelSwitch(eventCase: ChannelSwitchTrackingReason, label?: string) {
    switch (eventCase) {
      case ChannelSwitchTrackingReason.ERROR: {
        this.trackingService.trackEvent({ eventAction: "error", eventLabel: label });
        break;
      }
      case ChannelSwitchTrackingReason.TAP_TO_CALLBACK: {
        this.processFacade.processMetaData$.pipe(take(1)).subscribe((processMetaData: ProcessMetadata[]) => {
          if (!processMetaData.some((meta: ProcessMetadata) => "opportunity-funnel-summary" === meta.id)) {
            this.trackingService.trackEvent({ eventAction: "tap-to-callback", eventLabel: "callback" });
          }
        });
        break;
      }
    }
  }
}
