import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslocoService } from "@jsverse/transloco";
import getISOWeek from "date-fns/getISOWeek";
import isToday from "date-fns/isToday";
import lastDayOfISOWeek from "date-fns/lastDayOfISOWeek";
import sub from "date-fns/sub";
import { Appointment, RadioButton, RequiredService, SameDayAppointments, SameWeekAppointments } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class AppointmentListService {
  private readonly datePipe = new DatePipe("de-DE");

  public constructor(private translocoService: TranslocoService) {}

  public prepareDataForView(val: Appointment[]) {
    if (!val || val.length === 0) {
      return [];
    }

    const appointmentsByDays = this.groupAppointmentsByDays(val);
    return this.groupDaysByWeekNumbers(appointmentsByDays);
  }

  public createRadioButtonsFromAppointments(sameDayAppointments: SameDayAppointments): RadioButton[] {
    let buttons: RadioButton[] = [];
    sameDayAppointments.appointments.forEach((appointment: Appointment) => {
      buttons = [
        ...buttons,
        {
          title: "",
          radio: { id: `appointment-available-${appointment.appointmentId}`, value: appointment.appointmentId },
          customTemplateItem: {
            ...appointment,
            address: this.getAddress(appointment)
          }
        }
      ];
    });
    return buttons;
  }

  public getExpandedPanelTitleDay(day: string): string {
    if (isToday(new Date(day))) {
      return this.translocoService.translate("appointment.appointmentListItem.texts.today");
    }
    return `${this.datePipe.transform(day, "EEEE")}`;
  }

  public getExpandedPanelTitleDate(day: string): Observable<string> {
    return this.translocoService.selectTranslate("appointment.appointmentListItem.texts.weekdayTitle", {
      dateString: this.datePipe.transform(day, "dd.MM.yyyy")
    });
  }

  public shouldShowNumberOfAppointments(
    sameDayAppointments: SameDayAppointments,
    requiredService: RequiredService
  ): boolean {
    return (
      isToday(new Date(sameDayAppointments.day)) &&
      sameDayAppointments.appointments.length < 6 &&
      requiredService === RequiredService.REPAIR
    );
  }

  public getDayPartOfAString(time: string): string {
    return time.substr(0, time.indexOf("T"));
  }

  public groupAppointmentsByDays(appointments: Appointment[]): SameDayAppointments[] {
    const appointmentDays = this.getAppointmentDays(appointments);
    appointmentDays.forEach((item: SameDayAppointments, i: number) => {
      let sameDayAppointments = [];
      appointments.forEach((appointment: Appointment) => {
        if (appointment.availabilityPeriodStart.includes(item.day)) {
          sameDayAppointments = [...sameDayAppointments, appointment];
        }
      });
      appointmentDays[i].appointments = sameDayAppointments;
    });

    return appointmentDays;
  }

  public groupDaysByWeekNumbers(sameDaysAppointments: SameDayAppointments[]): SameWeekAppointments[] {
    const firstDay = sameDaysAppointments[0].day;
    const firstWeekNumber = getISOWeek(new Date(firstDay));
    let appointmentByWeeks: SameWeekAppointments[] = [
      {
        week: firstWeekNumber,
        sameWeekAppointments: [],
        isShown: false,
        period: this.getPeriodForDay(firstDay)
      }
    ];
    let currentWeekNumber = firstWeekNumber;
    sameDaysAppointments.forEach((sameDayAppointments: SameDayAppointments) => {
      const tmpWeekNumber = getISOWeek(new Date(sameDayAppointments.day));
      if (currentWeekNumber === tmpWeekNumber) {
        const abw = appointmentByWeeks.find((item: SameWeekAppointments) => item.week === tmpWeekNumber);
        abw.sameWeekAppointments = [...abw.sameWeekAppointments, sameDayAppointments];
      } else {
        currentWeekNumber = tmpWeekNumber;
        appointmentByWeeks = [
          ...appointmentByWeeks,
          {
            week: tmpWeekNumber,
            sameWeekAppointments: [sameDayAppointments],
            isShown: true,
            period: this.getPeriodForDay(sameDayAppointments.day)
          }
        ];
      }
    });
    return appointmentByWeeks;
  }

  public getPeriodForDay(day: string): string {
    const endDate = lastDayOfISOWeek(new Date(day));
    const startDate = sub(endDate, { days: 6 });
    return `${this.datePipe.transform(startDate, "dd.MM.")} bis ${this.datePipe.transform(endDate, "dd.MM.yyyy")}`;
  }

  public getAppointmentDays(appointments: Appointment[]): SameDayAppointments[] {
    let appointmentDays: string[] = [];
    let last = "";
    let current = "";
    appointments.forEach((appointment: Appointment) => {
      current = this.getDayPartOfAString(appointment.availabilityPeriodStart);
      if (current !== last && !appointmentDays.includes(current)) {
        appointmentDays = [...appointmentDays, current];
        last = current;
      }
    });

    return appointmentDays.map((item: string) => ({
      day: item,
      appointments: []
    }));
  }

  private getAddress(appointment: Appointment): string {
    if (appointment.city && appointment.sublocality) {
      return `${appointment.city}-${appointment.sublocality}`;
    } else {
      return `${appointment.street}, ${appointment.postalCode} ${appointment.city}`;
    }
  }
}
