import type { List } from "@cg/content-api/typescript-interfaces";

export interface AppointmentElementsInterface {
  mandatoryDocuments: List;
  mandatoryDocumentsNoDamageDate: List;
  calibrationRequirements: List;
  teslaRequirements: List;
}

export const appointmentElements: AppointmentElementsInterface = {
  mandatoryDocuments: {
    type: "bulletpoint",
    items: ["appointmentConfirmation.texts.insuranceDocuments", "appointmentConfirmation.texts.vehicleRegistration"],
    ngTemplate: "cgList"
  },
  mandatoryDocumentsNoDamageDate: {
    type: "bulletpoint",
    items: [
      "appointmentConfirmation.texts.insuranceDocuments",
      "appointmentConfirmation.texts.vehicleRegistration",
      "appointmentConfirmation.texts.damageDateList"
    ],
    ngTemplate: "cgList"
  },
  calibrationRequirements: {
    type: "bulletpoint",
    items: ["appointmentConfirmation.texts.adas.fuel", "appointmentConfirmation.texts.adas.tyrePressure"],
    ngTemplate: "cgList"
  },
  teslaRequirements: {
    type: "check",
    items: [
      "appointmentConfirmation.texts.tesla.soc",
      "appointmentConfirmation.texts.tesla.tirePressure",
      "appointmentConfirmation.texts.tesla.software"
    ],
    ngTemplate: "cgList"
  }
};
