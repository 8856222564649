import { environment } from "@cg/environments";
import type { NewAppointmentDetailContentInterface } from "../interfaces/new-appointment-detail-content.interface";

export const repair: NewAppointmentDetailContentInterface = {
  explaination: "",
  picture: {
    alt: "appointment.appointmentDetail.texts.repair.picture.alt",
    sizes: [],
    source: `${environment.assetPath}/olb/appointment/images/img_repair.png`,
    ngTemplate: undefined
  }
};
