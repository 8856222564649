import { Inject, Injectable, Type } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { map, take } from "rxjs/operators";
import { OptimizelyService } from "@cg/analytics";
import {
  AbTestComponentMappingEntry,
  AbTestComponentResolverMapping,
  OLB_PROCESS_FLOW_AB_TEST_TILE_MAPPING
} from "@cg/olb/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

@Injectable()
export class AbTestComponentResolverService {
  public constructor(
    @Inject(OLB_PROCESS_FLOW_AB_TEST_TILE_MAPPING) private abTestComponentMapping: AbTestComponentResolverMapping,
    private readonly optimizelyService: OptimizelyService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async getAbTestingComponent(processId: string): Promise<Type<unknown> | undefined> {
    const abTest: AbTestComponentMappingEntry = this.abTestComponentMapping[processId];

    if (!abTest) {
      return undefined;
    }

    // eslint-disable-next-line guard-for-in
    for (const experiment in abTest) {
      const variationOfAbTest = await lastValueFrom(
        this.optimizelyService.getActiveVariationIdWithIndex$(experiment as OptimizelyExperiment).pipe(
          take(1),
          map((result: { variationId: string; index: number }) =>
            abTest[experiment].variations.find(
              (variation: { variationId: string; index: number }) => variation.variationId === result.variationId
            )
          )
        )
      );

      if (variationOfAbTest) {
        return variationOfAbTest?.component;
      }
    }

    return undefined;
  }
}
