<section class="damage-assessment-container">
  <cg-split-view [master]="master" [showBranding]="false"></cg-split-view>
</section>

<ng-template #master>
  <div class="damage-assessment">
    <cg-icon class="damage-assessment--replace-icon" [content]="replaceIcon"></cg-icon>
    <cg-olb-headline
      class="damage-assessment--headline"
      [title]="'opportunityFunnelDamageAssessmentResult.title' | transloco"
      [subHeadline]="
        ((selectedDamage$ | async) === damageWindow.REAR
          ? 'opportunityFunnelDamageAssessmentResult.subTitleRear'
          : 'opportunityFunnelDamageAssessmentResult.subTitleLeftSide'
        ) | transloco
      "
    ></cg-olb-headline>
  </div>
</ng-template>
