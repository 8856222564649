import { CtalinkVariation } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import type { Ctalink } from "@cg/shared";

export const carIsNotListedCtaLinkModel: Ctalink = {
  id: "vehicle-identification-number-car-not-listed",
  icon: arrowsIcon,
  link: {
    href: "",
    text: "vehicleIdentificationManually.ctaLink.title",
    title: "vehicleIdentificationManually.ctaLink.title"
  },
  variation: CtalinkVariation.OLB,
  ngTemplate: "cgCtalink"
};
