import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "callbackTime",
  standalone: true
})
export class CallbackTimePipe implements PipeTransform {
  public transform(value: number): string {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    let time = minutes > 0 ? `${minutes}:` : "";

    if (seconds < 10) {
      time = `${time}0${seconds}`;
    } else {
      time = `${time}${seconds}`;
    }

    return time;
  }
}
