import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { stepper1Icon, stepper2Icon, stepper3Icon } from "@cg/icon";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-call-timeout",
  templateUrl: "./channel-switch-call-timeout.component.html",
  styleUrls: ["./channel-switch-call-timeout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, ParagraphComponent, IconComponent]
})
export class ChannelSwitchCallTimeoutComponent extends ChannelSwitchBaseComponent {
  public icons = [
    {
      ...stepper1Icon,
      color: "#979797"
    },
    {
      ...stepper2Icon,
      color: "#979797"
    },
    {
      ...stepper3Icon,
      color: "#979797"
    }
  ];

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }
}
