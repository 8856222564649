<section class="relative flex h-full flex-col">
  <ng-template #master>
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline
          class="relative block part-[headline]:t-only:!text-2xl part-[headline]:t-only:tracking-normal"
          [title]="'damageType.title' | transloco"
        ></cg-olb-headline>
        <cg-damage-assessment-tile
          class="[&_.damage-assessment-selection--wrapper]:!pb-0"
          [content]="damageTileContent"
          (readyToGoForward)="goForward()"
        ></cg-damage-assessment-tile>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
