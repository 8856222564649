import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { BehaviorSubject, catchError, combineLatest, fromEvent, Observable, of, partition, Subject, take } from "rxjs";
import { filter, first, map, mergeMap, pairwise, startWith, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import {
  AppointmentActions,
  AppointmentFacade,
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  OlbFacade,
  ProcessFacade
} from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { slideUpAnimation } from "@cg/animation";
import {
  AppointmentDetailComponent,
  AppointmentNoSelectedComponent,
  AppointmentSearchComponent
} from "@cg/appointment-ui";
import { AddFormControls } from "@cg/core/types";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { environment } from "@cg/environments";
import {
  ChannelSwitchReason,
  ChooseServiceCenterExitIds,
  Driver,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  BackendSwitchChannelType,
  ComponentOverarchingChangeDetectionService,
  ConfigFacade,
  JobStatus,
  ProcessId,
  Resume,
  ResumeType,
  SetAppointmentPayload,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment, USER_DECISION } from "@cg/core/enums";
import { AdverseBuyCallbackComponent } from "../../components/adverse-buy-callback/adverse-buy-callback.component";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";

@Component({
  selector: "cg-appointment",
  templateUrl: "./appointment.component.html",
  animations: [slideUpAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    AdverseBuyCallbackComponent,
    AppointmentSearchComponent,
    AppointmentNoSelectedComponent,
    AppointmentDetailComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class AppointmentComponent extends BaseDirective<AddFormControls<AppointmentSearchForm>> implements OnInit {
  public destroyRef = inject(DestroyRef);
  public appointmentData: AppointmentData;
  public savedEvent = new Subject<boolean>();
  public isToastForAdverseBuyCallbackVisible$ = new BehaviorSubject<boolean>(false);
  public fromWindowScrollEvent$ = fromEvent(window, "scroll");

  public currentProcess: ProcessId;
  public hasAdverseBuyAppointments = false;
  public env = environment;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    protected trackingService: TrackingService,
    private hostElement: ElementRef,
    private readonly optimizelyService: OptimizelyService, // AB-Test: OLB_EARLY_CONTACT_DATA
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly olbFacade: OlbFacade,
    private readonly resumeFacade: ResumeFacade,
    private readonly configFacade: ConfigFacade,
    private actions$: Actions,
    @Inject(OLB_CONFIG) private _olbConfig: OlbConfiguration,
    @Inject(IS_BROWSER_PLATFORM) public readonly isBrowser: boolean,
    private readonly contactDataFacade: ContactDataFacade,
    private readonly cdrService: ComponentOverarchingChangeDetectionService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
    this.setAppointmentData();
  }

  public get hasSelectedAppointment(): Observable<boolean> {
    return this.appointmentFacade.appointmentId$.pipe(mergeMap((appointmentId: string) => of(!!appointmentId)));
  }

  public get hostElementBoundingClientRect() {
    return this.hostElement.nativeElement.getBoundingClientRect();
  }

  public get documentScrollPositionTop(): number {
    return document.documentElement.scrollTop || document.body.scrollTop;
  }

  public get isB2C(): boolean {
    return !this.env.b2b;
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      // Execute requests which are normally done in the license-plate tile
      this.olbFacade.checkDuplicate();
      this.olbFacade.getAllProducts();
    }

    combineLatest([
      this.processFacade.currentProcessId$,
      this.appointmentFacade.hasAdverseBuyAppointments$,
      this.appointmentFacade.hasAppointmentLoaded$
    ])
      .pipe(
        filter(([_, __, hasLoaded]: [ProcessId, boolean, boolean]) => !!hasLoaded),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([currentProcessId, hasAdverseBuyAppointments]: [ProcessId, boolean, boolean]) => {
        this.currentProcess = currentProcessId;
        this.hasAdverseBuyAppointments = hasAdverseBuyAppointments;
      });

    this.showAdverseBuyCallback$().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.appointmentFacade.hasAppointmentLoaded$
      .pipe(
        filter((loaded: boolean) => !!loaded && this.isBrowser),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        setTimeout(() => {
          const tileEl: HTMLElement = document.querySelector("[data-process-id='appointment']");
          const tileOffset = tileEl.offsetTop;
          const margin = parseInt(window.getComputedStyle(tileEl).marginTop, 10) / 2;

          const pb = document.querySelector("#olb-progress-bar");
          const pbHeight = pb?.getBoundingClientRect().height ?? 0;

          const top = tileOffset - (pbHeight + margin);

          window.scrollTo({ top, behavior: "smooth" });
        }, 200);
      });
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<AppointmentSearchForm>>({
      searchServiceCenterInput: new UntypedFormControl("", Validators.required),
      selectedAppointmentId: new UntypedFormControl("", Validators.required)
    });
  }

  public goForwardFailure(): void {
    super.goForwardFailure();
    this.cdrService.changeDetectionRequest$.next();
  }

  public override postSaveForm(): void {
    super.postSaveForm();

    this.olbFacade.resumeAfterAppointment();
  }

  public saveForm(): void {
    this.isToastForAdverseBuyCallbackVisible$.next(false);
    this.savedEvent.next(true);
  }

  public setFormValues(): void {
    this.tryToResumeSelectedAppointment();
  }

  public getExitIdForSavedForm(): Observable<ChooseServiceCenterExitIds> {
    // we wait for the current appointment to be set, before going to the next tile
    return this.appointmentFacade.currentAppointment$.pipe(
      filter((currentAppointment: Appointment) => !!currentAppointment),
      withLatestFrom(
        this.isToastForAdverseBuyCallbackVisible$,
        this.olbFacade.mustResumeAfterAppointment(),
        this.shouldShowNormalContactDataComponent(), // AB-Test: OLB_EARLY_CONTACT_DATA
        this.contactDataFacade.mobile$,
        this.contactDataFacade.email$,
        this.contactDataFacade.driver$,
        this.resumeFacade.resumeResponse$
      ),
      map(
        ([_, toastIsVisible, mustResume, showNormalContactData, mobile, email, driver, resumeResponse]: [
          Appointment,
          boolean,
          boolean,
          boolean,
          string,
          string,
          Driver,
          Resume
        ]) => {
          if (mustResume) {
            return null;
          }

          if (toastIsVisible) {
            return "channelSwitch";
          }

          if (isDirectResumeFn(this._olbConfig.entryChannel) && mobile && email) {
            if (resumeResponse.resumeType === ResumeType.B2B_IOM && (!driver.city || !driver.street || !driver.zip)) {
              return "newCustomer";
            }
            return "directResume";
          } else if (showNormalContactData) {
            return "emailQuery";
          } else {
            return "emailQueryEarlyContactDataTest"; // AB-Test: OLB_EARLY_CONTACT_DATA
          }
        }
      )
    );
  }

  public onClosed(decision: USER_DECISION): void {
    if (this.currentProcessId !== this.processId) {
      return;
    }

    if (decision === USER_DECISION.ACCEPT) {
      this.isToastForAdverseBuyCallbackVisible$.next(false);
      this.goForward();
    } else {
      this.appointmentFacade.setAppointmentId(null);
      this.processFacade.rewindToExistingProcessId(this.processId);
      this.form.markAsUntouched();
    }
  }

  public onCloseCallback(): void {
    this.isToastForAdverseBuyCallbackVisible$.next(false);
  }

  public goCallback(): void {
    this.isToastForAdverseBuyCallbackVisible$.next(false);
    this.processFacade.setChannelSwitchReason(BackendSwitchChannelType.ABOES_LATE_APPOINTMENTS);
    this.skipFormWithExitId("channelSwitch");
  }

  public showAdverseBuyCallback$(): Observable<number> {
    return this.onScrollUpAndGetClientTop$().pipe(
      filter(() => this.hasAdverseBuyAppointments),
      filter(this.isScrollPositionInHitBox),
      tap(() => this.isToastForAdverseBuyCallbackVisible$.next(true)),
      first()
    );
  }

  public isScrollPositionInHitBox(scrollTopPosition: number): boolean {
    return scrollTopPosition > 275 && scrollTopPosition < 1000;
  }

  public onScrollUpAndGetClientTop$(): Observable<number> {
    const [up$] = partition(
      this.fromWindowScrollEvent$.pipe(
        map(() => this.documentScrollPositionTop),
        startWith(0),
        pairwise(),
        map(([prev, next]: [number, number]) => next - prev)
      ),
      (val: number) => val < 0
    );

    return up$.pipe(
      map(() => this.hostElementBoundingClientRect),
      map(({ top }: { top: number }) => top)
    );
  }

  public setAppointmentData(): void {
    this.appointmentData = {
      appointmentId$: this.appointmentFacade.appointmentId$,
      availableAppointments$: this.appointmentFacade.availableAppointments$,
      selectedServiceCenter$: this.appointmentFacade.selectedServiceCenter$,
      selectedServiceCenterIds$: this.appointmentFacade.selectedServiceCenterIds$,
      availableServiceCenters$: this.appointmentFacade.availableServiceCenters$,
      isCalibration$: this.appointmentFacade.isCalibration$,
      requiredService$: this.damageFacade.requiredService$,
      formattedAddress$: this.appointmentFacade.formattedAddress$,
      locality$: this.appointmentFacade.locality$,
      position$: this.appointmentFacade.position$,
      mobileServiceAvailable$: this.appointmentFacade.mobileServiceAvailable$,
      hasAdverseBuyAppointments$: this.appointmentFacade.hasAdverseBuyAppointments$,
      confirmed$: this.appointmentFacade.confirmed$,
      appointmentNextLoading$: this.appointmentFacade.appointmentNextLoading$,
      nextLoadingLimitReached$: this.appointmentFacade.nextLoadingLimitReached$,
      isLoading$: this.appointmentFacade.isLoading$,
      customerCaseId$: this.customerCaseFacade.customerCaseId$,
      damageChipCount$: this.damageFacade.damageChipCount$,
      reloadAppointments$: new BehaviorSubject<boolean>(false),
      resumeResponse$: this.resumeFacade.resumeResponse$,
      isBookingWithoutAppointmentAllowed: this._olbConfig.isBookingWithoutAppointmentAllowed,
      config: this.configFacade.appointmentsConfig$,
      setStatus: (status: JobStatus) => {
        this.appointmentFacade.setStatus(status);
      },
      resetStateForForm: () => {
        this.appointmentFacade.resetStateForForm();
      },
      confirmAppointment: (appointment: SetAppointmentPayload) => {
        this.appointmentFacade.confirmAppointment(appointment);
      },
      setChannelSwitchReason: (reason: ChannelSwitchReason) => {
        this.processFacade.setChannelSwitchReason(reason);
      },
      setAppointmentId: (appointmentId: string) => {
        this.appointmentFacade.setAppointmentId(appointmentId);
      },
      setSelectedServiceCenterIds: (serviceCenterIds: string[]) => {
        // TODO: use array
        this.appointmentFacade.setSelectedServiceCenterIds(serviceCenterIds);
      },
      clearAndRefetchAppointments: (serviceCenterId: string) => {
        this.appointmentFacade.clearAndRefetchAppointments(serviceCenterId);
      },
      setNextLoadingLimitReached: (limitReached: boolean) => {
        this.appointmentFacade.setNextLoadingLimitReached(limitReached);
      },
      setMobileServiceAvailable: (isAvailable: boolean) => {
        this.appointmentFacade.setMobileServiceAvailable(isAvailable);
      },
      getNextAppointments: (date: string) => {
        this.appointmentFacade.getNextAppointments(date);
      },
      saveAutocompleteResult: (lat: number, lng: number, address: string, locality: string) => {
        this.appointmentFacade.saveAutocompleteResult({ lat, lng, address, locality });
      },
      reloadAppointments: () => {
        this.appointmentFacade.reloadAppointments();
      }
    };

    this.actions$
      .pipe(ofType(AppointmentActions.reloadAppointments), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.appointmentData.reloadAppointments$.next(true);
      });
  }

  private tryToResumeSelectedAppointment(): void {
    if (this.processMetadata?.formData && this.form.value.searchServiceCenterInput) {
      // we set formatted address to trigger fetching appointments
      this.appointmentFacade.setFormattedAddress(this.form.value.searchServiceCenterInput);
    }
  }

  // AB-Test: OLB_EARLY_CONTACT_DATA
  // Show normal contact data, when none of the early data AB-test variants is active
  // or when email or mobile are still missing (possible in save and restore case)
  public shouldShowNormalContactDataComponent(): Observable<boolean> {
    return this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_FULL).pipe(
      withLatestFrom(
        this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL),
        this.contactDataFacade.email$,
        this.contactDataFacade.mobile$
      ),
      take(1),
      map(
        ([earlyContactDataFullActive, earlyContactDataOnlyMailActive, email, mobile]: [
          boolean,
          boolean,
          string,
          string
        ]): boolean =>
          (!earlyContactDataFullActive && !earlyContactDataOnlyMailActive) ||
          earlyContactDataOnlyMailActive ||
          !email ||
          !mobile
      ),
      catchError(() => of(true))
    );
  }
}
