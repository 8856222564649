import { NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { successIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { DrawerComponent } from "@cg/shared";
import { InsuranceMessageGridItem } from "../../interfaces/insurance-message-grid-form.interface";
import { InsuranceMessageNavigation } from "../interfaces/insurance-message-navigation.interface";

@Component({
  selector: "cg-insurance-base-message",
  templateUrl: "./insurance-base-message.component.html",
  styleUrls: ["./insurance-base-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    IconComponent
  ]
})
export class InsuranceBaseMessageComponent<T> implements InsuranceMessageNavigation {
  @Input()
  public insuranceMessageGridItems: InsuranceMessageGridItem[] = [];

  @Input()
  public headlineTranslation: string;

  @Input()
  public subHeadlineKey: string;

  @Input()
  public descriptionKey: string;

  @Input()
  public contentUnderGridTemplateRef: TemplateRef<unknown>;

  @Input()
  public footerTemplateRef: TemplateRef<unknown>;

  @Output()
  public closedClicked: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup<AddFormControls<T>>;

  public readonly successIcon: Icon = successIcon;

  public onClosed(): void {
    this.closedClicked.emit();
  }

  public onClosedClicked(): void {}
  public onGoBackClicked(): void {}
  public onGoForwardClicked(): void {}
}
