<section>
  <ng-template #master>
    @if (isDesktop) {
      <cg-new-appointment-desktop [processMetadata]="processMetadata" />
    } @else {
      <cg-new-appointment-mobile [processMetadata]="processMetadata" />
    }
  </ng-template>

  <cg-split-view
    class="[&>div>div]:p-0"
    [drawerTrigger]="false"
    [master]="master"
    [overlayBackwardText]="'newAppointment.appointmentDrawer.buttons.back' | transloco"
    [overlayForwardText]="'newAppointment.appointmentDrawer.buttons.forward' | transloco"
    [showBackground]="false"
    [showBranding]="false"
  ></cg-split-view>
</section>
