import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { filter } from "rxjs/operators";
import { ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Cta, Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { CtalinkVariation, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { successIcon } from "@cg/icon";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, Product, ScrollService } from "@cg/olb/shared";
import { CtaComponent, Ctalink, CtalinkComponent, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { TeslaRestrictionItemsComponent } from "./components/tesla-restriction-items/tesla-restriction-items.component";
import { TeslaRestrictionsForm } from "./interfaces/tesla-restrictions-form.interface";

@Component({
  selector: "cg-tesla-restrictions",
  templateUrl: "./tesla-restrictions.component.html",
  styleUrls: ["./tesla-restrictions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    SplitViewComponent,
    OlbHeadlineComponent,
    CtalinkComponent,
    IconComponent,
    ParagraphComponent,
    CtaComponent,
    TeslaRestrictionItemsComponent
  ]
})
export class TeslaRestrictionsComponent extends BaseDirective<AddFormControls<TeslaRestrictionsForm>> {
  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    private readonly translocoService: TranslocoService,
    private readonly productFacade: ProductFacade,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public readonly successIcon: Icon = successIcon;

  public changeCarLink: Ctalink = {
    link: {
      text: "teslaRestrictions.changeCar.link",
      title: "teslaRestrictions.changeCar.link",
      href: "",
      routerLink: false
    },
    id: "component-change-identified-car",
    ngTemplate: "cgCtalink",
    variation: CtalinkVariation.PRIMARY
  };

  public get goForwardCtaLinkContent(): Cta {
    return {
      id: "forward-cta",
      title: this.translocoService.translate("teslaRestrictions.ctaTitle"),
      icon: undefined,
      link: undefined,
      variation: "primary",
      arrowDirection: "right",
      ngTemplate: "cgCta"
    };
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<TeslaRestrictionsForm>>({
      model: new FormControl<string>(null)
    });
  }

  public setFormValues(): void {
    this.productFacade.products$
      .pipe(
        filter((products: Product[]) => Boolean(products?.length)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((products: Product[]) => {
        this.form.controls.model.setValue(products[0].model);
        this.cdr.detectChanges();
      });
  }

  public getExitIdForSavedForm(): Observable<string> {
    return of("appointment");
  }

  public saveForm(): void {}

  public get model(): string {
    return this.form.controls.model.value;
  }

  public changeCar($event: Event) {
    $event.preventDefault();
    this.processFacade.changeCar();
  }
}
