import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, take, takeUntil, tap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { closeIcon } from "@cg/icon";
import { InsuranceType, OlbHeadlineComponent } from "@cg/olb/shared";
import { DrawerComponent, OverlayService, RadioButtonGroup, RadioButtonGroupComponent } from "@cg/shared";
import { OVERLAYFLOW_COMPONENTS } from "@cg/core/enums";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { insuranceTypeInformation } from "../../models/insurance-type-informations.model";

@Component({
  selector: "cg-insurance-type-options",
  templateUrl: "./insurance-type-options.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, DrawerComponent, OlbHeadlineComponent, RadioButtonGroupComponent]
})
export class InsuranceTypeOptionsComponent implements OnInit, OnDestroy {
  public content: RadioButtonGroup = insuranceTypeInformation;
  public form: FormGroup<AddFormControls<InsuranceTypeForm>>;

  public readonly closeIcon: Icon = closeIcon;

  private destroyed$ = new Subject();
  public constructor(private readonly overlayService: OverlayService) {}

  public onClosed(): void {
    this.overlayService.close();
  }

  public ngOnInit(): void {
    this.form.controls.insuranceType.valueChanges
      .pipe(
        filter((value: InsuranceType) => !!value),
        take(1),
        tap(() => this.goNextOverlay()),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    if (!this.form.controls.insuranceType.value) {
      this.completeSubjectOnDestroy();
    }
  }

  private goNextOverlay(): void {
    const value =
      this.form.value.insuranceType !== InsuranceType.FULLY
        ? OVERLAYFLOW_COMPONENTS.INSURANCE_DECLINED_MESSAGE
        : OVERLAYFLOW_COMPONENTS.INSURANCE_COVERED_MESSAGE;
    this.form.controls.nextOverlay.setValue(value, { emitEvent: false });
    this.completeSubjectOnDestroy();
  }

  private completeSubjectOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
