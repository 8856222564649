import { provideTransloco } from "@jsverse/transloco";
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";
import { environment } from "@cg/environments";
import type { TranslationConfiguration } from "./interfaces/translation-configuration.interface";
import { TRANSLATION_CONFIGURATION } from "./configuration.token";
import { TranslocoHttpLoader } from "./transloco-http.loader";

export function provideTranslation(configuration: TranslationConfiguration = {}) {
  return [
    provideTransloco({
      config: {
        availableLangs: ["en", "de"],
        defaultLang: "de",
        prodMode: environment.production,
        fallbackLang: "de",
        missingHandler: {
          allowEmpty: true,
          logMissingKey: true
        }
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoMessageformat(),
    { provide: TRANSLATION_CONFIGURATION, useValue: configuration }
  ];
}
