<section class="flex flex-col">
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        [title]="'customerAddress.title' | transloco"
        [subHeadline]="subtitleText | transloco"
      ></cg-olb-headline>

      <cg-address-form
        class="block max-w-content sm:p-content-xs m:p-content-mobile t:p-content-tablet d:p-content-desktop w:p-content-widescreen fhd:p-content-fullhd"
      ></cg-address-form>

      @if (!isIOMResume) {
        <cg-olb-headline
          [type]="'h2'"
          [title]="'customerAddress.insuranceHolder' | transloco"
          [subHeadline]="'customerAddress.driverInformation' | transloco"
        ></cg-olb-headline>

        <div
          class="max-w-content sm:p-content-xs m:p-content-mobile t:p-content-tablet d:p-content-desktop w:p-content-widescreen fhd:p-content-fullhd"
        >
          <cg-radio-button-group
            class="radioButtonGroup"
            [content]="radioButtonGroup"
            [formControlName]="'policyHolder'"
          ></cg-radio-button-group>
        </div>

        @if (form.value.policyHolder === "0") {
          <div
            class="max-w-content sm:p-content-xs m:p-content-mobile t:p-content-tablet d:p-content-desktop w:p-content-widescreen fhd:p-content-fullhd"
          >
            @if (insuranceCompanyInput) {
              <div class="mb-4 flex flex-1 flex-col d:flex-row">
                <cg-text-input
                  class="input-insurance-company"
                  autocomplete="section-insurance organization"
                  [content]="inputFields.insuranceCompanyInput"
                  [formControlName]="'insuranceCompany'"
                ></cg-text-input>
              </div>
            }
            @if (insuranceTitleInput) {
              <div class="mb-4 flex flex-col gap-2">
                @if (isB2B) {
                  <cg-olb-headline
                    [type]="'h2'"
                    [title]="'customerAddress.radioButtons.titleSalutation' | transloco"
                  ></cg-olb-headline>
                }
                <cg-radio-button-horizontal-group
                  data-cs-mask
                  class="mb-4"
                  autocomplete="section-insurance sex"
                  [content]="insuranceTitleGroup"
                  [formControlName]="'insuranceTitle'"
                ></cg-radio-button-horizontal-group>
              </div>
            }
            @if (insuranceFirstnameInput) {
              <div class="mb-4 flex flex-1 flex-col d:flex-row">
                <cg-text-input
                  class="mb-4 w-screen d:mb-0 d:mr-4 d:max-w-[calc((100%-16px)/2)]"
                  autocomplete="section-insurance given-name"
                  [content]="inputFields.insuranceFirstnameInput"
                  [formControlName]="'insuranceFirstname'"
                ></cg-text-input>
                <cg-text-input
                  class="w-screen d:max-w-[calc((100%-16px)/2)]"
                  autocomplete="section-insurance family-name"
                  [content]="inputFields.insuranceLastnameInput"
                  [formControlName]="'insuranceLastname'"
                ></cg-text-input>
              </div>
            }
            @if (insuranceStreetInput) {
              <div class="mb-4 flex flex-1 flex-col d:flex-row">
                <cg-text-input
                  autocomplete="section-insurance street-address"
                  [content]="inputFields.insuranceStreetInput"
                  [formControlName]="'insuranceStreet'"
                ></cg-text-input>
              </div>
            }
            @if (insuranceZipInput) {
              <div class="mb-4 flex flex-1 flex-col d:flex-row">
                <cg-text-input
                  class="mb-4 w-screen d:mb-0 d:mr-4 d:max-w-[150px]"
                  autocomplete="section-insurance postal-code"
                  [content]="inputFields.insuranceZipInput"
                  [formControlName]="'insuranceZip'"
                  [specificInputType]="InputType.ZIP"
                ></cg-text-input>
                <cg-text-input
                  class="w-screen d:max-w-[calc(100%-166px)]"
                  autocomplete="section-insurance address-level2"
                  [content]="inputFields.insuranceCityInput"
                  [formControlName]="'insuranceCity'"
                ></cg-text-input>
              </div>
            }
            @if (insurancePhoneInput) {
              <div class="mb-4 flex flex-1 flex-col d:flex-row">
                <cg-text-input
                  autocomplete="section-insurance tel"
                  [content]="inputFields.insurancePhoneInput"
                  [formControlName]="'insurancePhone'"
                  [specificInputType]="InputType.NUMBERS_PHONE"
                ></cg-text-input>
              </div>
            }
            @if (insuranceCountryInput) {
              <div class="flex flex-1 flex-col">
                <label class="typo-caption text-cg-anthracite-light-30" for="selection-insurance-country">Land</label>
                <cg-options-selection
                  data-cs-mask
                  id="selection-insurance-country"
                  [translate]="true"
                  autocomplete="section-insurance country"
                  [selectionId]="'customer-address-insurance-select-country'"
                  [items]="countries"
                  [formControlName]="'insuranceCountry'"
                ></cg-options-selection>
              </div>
            }
          </div>
        }

        <div class="link-wrapper">
          <cg-link
            class="first:mb-2 first:block"
            [content]="additionalOptions.firstLink"
            (clickedFn)="onTrack('agb')"
          ></cg-link>
          <cg-link [content]="additionalOptions.secondLink" (clickedFn)="onTrack('privacy-statement')"></cg-link>
        </div>
      }
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
