import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { phoneContactIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { FormatTelLinkPipe, PhoneNumber } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-no-appointments",
  standalone: true,
  providers: [FormatTelLinkPipe],
  imports: [TranslocoPipe, IconComponent, OlbHeadlineComponent],
  templateUrl: "./new-appointment-no-appointments.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewAppointmentNoAppointmentsComponent {
  protected readonly phoneContactIcon = phoneContactIcon;
  protected readonly PhoneNumber = PhoneNumber;

  public readonly plainPhone = FormatTelLinkPipe.getPlainPhoneNumber(PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE);

  public constructor(private readonly telPipe: FormatTelLinkPipe) {}

  public callLinkClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const phoneUri = this.telPipe.transform(PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE);
    window.open(phoneUri, "_self");
  }
}
