import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { accordionIconUp, mobileServiceIcon } from "@cg/icon";
import { CtaTwoLinesComponent, ExpansionPanelComponent, OverlayService, RequiredService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { AppointmentsPerWeek } from "../../interfaces/appointments-per-week.interface";
import { NewAppointmentSelectionGridComponent } from "../new-appointment-all-sc-appointments-week-item/new-appointment-selection-grid.component";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-all-sc-appointments-week",
  templateUrl: "./new-appointment-all-sc-appointments-week.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    ExpansionPanelComponent,
    ParagraphComponent,
    NewAppointmentCircleComponent,
    IconComponent,
    NewAppointmentSelectionGridComponent,
    CtaTwoLinesComponent
  ]
})
export class NewAppointmentAllScAppointmentsWeekComponent implements OnInit {
  public accordionIcon = accordionIconUp;
  public expanded: boolean;
  public serviceIcon = mobileServiceIcon;
  public requiredService$ = this.damageFacade.requiredService$;

  public RequiredService = RequiredService;

  @Input()
  public isInitiallyExpanded = false;

  @Input()
  public item: AppointmentsPerWeek;

  @Input()
  public isLastItem: boolean;

  @Input()
  public isDisplayedInAllScDialog = false;

  public constructor(
    private readonly processFacade: ProcessFacade,
    private readonly damageFacade: DamageFacade,
    private readonly overlayService: OverlayService
  ) {}

  public ngOnInit(): void {
    this.expanded = this.isInitiallyExpanded;
  }

  public toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  public mobileServiceClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.overlayService.close();

    this.processFacade.setChannelSwitchReason("MOBILE_SERVICE");
    this.processFacade.goForward("channel-switch");
  }
}
