@if (protectConfig$ | async) {
  <section class="vaps-offer">
    <ng-template #master>
      <cg-olb-headline class="mb-4 block t:mb-6" [title]="translationTexts.title"></cg-olb-headline>

      <div class="vaps-offer--subheadline flex items-center justify-between pt-content-mobile w:pt-content-desktop">
        <cg-headline [type]="'h4'" [content]="translationTexts.subtitle"></cg-headline>
        <cg-info-button class="block [&>button>div]:pr-0" [detailContent]="detailContentVapsOffer"></cg-info-button>
      </div>

      <form [formGroup]="form">
        <div class="grid grid-cols-1 gap-6 leading-6 text-black t:gap-4 d:grid-cols-2"></div>

        <div class="grid grid-cols-1 gap-6 leading-6 text-black d:grid-cols-2 d:gap-4">
          <div
            class="vaps-offer--option relative flex cursor-pointer rounded-card bg-cg-gray-light-70 hover:bg-cg-anthracite-light-90"
            [class.!bg-cg-yellow]="form.touched && !hasSelectedProtect"
            (click)="setProtectOption(false)"
          >
            <input [value]="false" type="radio" formControlName="hasProtect" data-cy="vaps-offer-no-protect-input" />
            <cg-picture
              class="pointer-events-none box-border flex max-h-[232px] max-w-[232px] flex-[0_0_50%] flex-col overflow-hidden"
              [content]="noCarglassProtectPicture"
            ></cg-picture>

            <div
              class="flex w-full cursor-pointer flex-col rounded-r-card px-2 py-3 leading-6 m:p-3 t:p-4 d:px-4 d:py-3 fhd:p-4"
            >
              <p
                class="product-title m-0 w-24 font-roboto-condensed text-xl font-bold text-cg-anthracite m:text-[26px] d:text-2xl"
              >
                {{ translationTexts.noProtectTitle }}
              </p>
              <p class="mx-0 mb-0 mt-auto font-roboto-condensed text-[13px] leading-5 text-cg-anthracite/[0.4]">
                {{ "vapsTwoChoicesABTest.common.noProtectChoice.priceTitle" | transloco }}
              </p>
              <cg-price class="text-muted mt-2 [&>span]:leading-6" [value]="0"></cg-price>
            </div>
          </div>

          <div
            class="vaps-offer--option relative flex cursor-pointer rounded-card hover:bg-cg-yellow/[.8]"
            [ngClass]="{
              'bg-cg-yellow/[.7]': hasSelectedProtect || !form.touched,
              '!bg-cg-yellow': hasSelectedProtect && form.touched,
              'bg-cg-gray-light-70': !hasSelectedProtect && form.touched
            }"
            (click)="setProtectOption(true)"
          >
            <p
              class="vaps-offer--option--recommendation absolute -left-1.5 z-10 -translate-y-1/2 bg-cg-yellow px-2 pt-0.5 font-roboto-condensed text-xs font-bold uppercase text-cg-black after:absolute after:left-0 after:top-full after:h-1.5 after:w-1.5 after:bg-[#d6bc10] after:content-['']"
            >
              {{ "vapsTwoChoicesABTest.recommendationLabel" | transloco }}
            </p>
            <input [value]="true" type="radio" formControlName="hasProtect" data-cy="vaps-offer-protect-input" />
            <cg-picture
              class="pointer-events-none box-border flex max-h-[232px] max-w-[232px] flex-[0_0_50%] flex-col"
              [content]="carglassProtectPicture"
            ></cg-picture>
            <div
              class="flex w-full cursor-pointer flex-col rounded-r-card px-2 py-3 leading-6 m:p-3 t:p-4 d:px-4 d:py-3 fhd:p-4"
            >
              <p
                class="product-title m-0 w-24 font-roboto-condensed text-xl font-bold text-cg-anthracite m:text-[26px] d:text-2xl"
              >
                {{ translationTexts.protectTitle }}
              </p>
              <p
                class="mx-0 mb-0 mt-auto whitespace-nowrap font-roboto-condensed text-[13px] leading-5 text-cg-anthracite"
              >
                {{ "vapsTwoChoicesABTest.common.protectChoice.priceTitle" | transloco }}
              </p>
              <cg-price class="mt-2 [&>span]:leading-6" [value]="protectPrice$ | async"></cg-price>
            </div>
          </div>
        </div>

        @if (form.touched && form.invalid) {
          <cg-error-message
            class="block px-0 !pb-0 pt-4 leading-6 m:px-0 m:py-4 t:px-0 t:py-4 d:px-0 d:py-6 w:px-0 w:py-6 fhd:px-0 fhd:py-6"
            [errorMessage]="'vapsTwoChoicesABTest.noChoiceSelectedError' | transloco"
          ></cg-error-message>
        }
      </form>
    </ng-template>

    <cg-split-view [master]="master" [showBranding]="true"></cg-split-view>

    <cg-olb-footer
      class="split-view--footer"
      [disableBackBtn]="hideBtns"
      [disableForwardBtn]="hideBtns"
      [forwardBtnContent]="forwardBtnContent"
      (backwardBtnClicked)="goBack()"
      (forwardBtnClicked)="goForward()"
    ></cg-olb-footer>
  </section>
}
<ng-template #detailContentVapsOffer>
  <cg-olb-headline class="[&>*]:!mt-0" [title]="'vapsTwoChoicesABTest.info.title' | transloco"></cg-olb-headline>
  <cg-paragraph
    class="text-base font-bold leading-6 text-cg-anthracite"
    [content]="{ text: 'vapsTwoChoicesABTest.info.list.title' | transloco, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <ul class="mt-2 list-disc pl-4 text-cg-anthracite">
    <li>{{ "vapsTwoChoicesABTest.info.list.bulletpoint1" | transloco }}</li>
    <li>{{ "vapsTwoChoicesABTest.info.list.bulletpoint2" | transloco }}</li>
    <li>{{ "vapsTwoChoicesABTest.info.list.bulletpoint3" | transloco }}</li>
  </ul>
</ng-template>
