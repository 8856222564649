import { rearWindowMultiple, rearWindowSingle } from "@cg/icon";
import { DamagedRearWindowCount } from "@cg/olb/shared";
import type { DamageAssessmentContent } from "../../../../interfaces/damage-assessment-content";

export const opportunityFunnelDamageRearTileContent: DamageAssessmentContent = {
  controlName: "damagedRearWindowCount",
  buttons: [
    {
      title: "opportunityFunnelDamageRear.buttons.single",
      picture: rearWindowSingle,
      radio: {
        id: "damage-rear-window-one",
        value: DamagedRearWindowCount.SINGLE
      }
    },
    {
      title: "opportunityFunnelDamageRear.buttons.multiple",
      picture: rearWindowMultiple,
      radio: {
        id: "damage-rear-window-multiple",
        value: DamagedRearWindowCount.MULTIPLE
      }
    }
  ]
};
