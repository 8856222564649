import { DamageWindow } from "@cg/shared";

const trackingMap: Map<DamageWindow, string> = new Map<DamageWindow, string>([
  [DamageWindow.FRONT, "product-not-found"],
  [DamageWindow.LEFT_SIDE, "side-window"],
  [DamageWindow.REAR, "rear-window"]
]);

export function damageWindowToTrackingCase(selectedDamage: DamageWindow): string {
  return trackingMap.get(selectedDamage);
}
