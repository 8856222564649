import { AsyncPipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { combineLatest, map, Observable, take, withLatestFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { FakeDropdownComponent, IconComponent, PLACEHOLDER } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { accordionIconDown, calendarIcon, clearIconWhite, filterIcon, pinFillIcon, searchIcon } from "@cg/icon";
import {
  AppointmentData,
  AvailableServiceCenters,
  BaseButtonComponent,
  BreakpointService,
  OlbSearchForScInputComponent,
  OverlayService,
  ServiceCenterSelectionLabelComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { NewAppointmentScSelectDialogComponent } from "../new-appointment-sc-select-dialog/new-appointment-sc-select-dialog.component";
import { NewAppointmentFilterForm } from "./interfaces/new-appointment-filter-form.interface";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-filter",
  templateUrl: "./new-appointment-filter.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    BaseButtonComponent,
    ReactiveFormsModule,
    IconComponent,
    ServiceCenterSelectionLabelComponent,
    OlbSearchForScInputComponent,
    FakeDropdownComponent
  ]
})
export class NewAppointmentFilterComponent implements OnInit, AfterViewInit {
  @Input() public isFormTouchedProgrammatically: boolean;
  @Input() public appointmentData: AppointmentData;
  @Input() public showChevron: boolean;
  @Input() public showDropDownChevron = true;

  @Output() public filterChanged = new EventEmitter();
  @Output() public searchClicked = new EventEmitter();

  @ViewChild(OlbSearchForScInputComponent)
  public olbSearchForScInputComponent: OlbSearchForScInputComponent;
  public destroyRef = inject(DestroyRef);
  public expanded = false;
  public isTablet = false;
  public availableServiceCenters: AvailableServiceCenters[];
  public form: FormGroup<AddFormControls<NewAppointmentFilterForm>>;
  public pinIcon: Icon = pinFillIcon;
  public filterIcon: Icon = filterIcon;
  public arrowIcon: Icon = accordionIconDown;
  public searchIcon: Icon = searchIcon;
  public calendarIcon: Icon = calendarIcon;
  public clearIcon: Icon = clearIconWhite;
  public selectionText$: Observable<string>;
  public serviceCenterCount$: Observable<number>;
  private previousFormattedAddress: string;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly overlayService: OverlayService,
    private readonly translocoService: TranslocoService,
    private readonly breakPointService: BreakpointService
  ) {}

  public get hasSelection(): boolean {
    return this.countSelectedServiceCenter > 0;
  }

  public get address$(): Observable<string> {
    return this.appointmentData.formattedAddress$;
  }

  public get countSelectedServiceCenter(): number {
    const value = this.form.controls.scIds.value;
    return !!value && value[0] !== PLACEHOLDER ? this.form.controls.scIds.value.length : 0;
  }

  public ngOnInit(): void {
    this.form = new FormGroup<AddFormControls<NewAppointmentFilterForm>>({
      scIds: new UntypedFormControl([]),
      searchServiceCenterInput: new UntypedFormControl("")
    });

    this.expandFilter();
    this.initFormValues();
    this.initServiceCenterCount();
    this.initSelectionText();
    this.checkBreakpoint();
  }

  public ngAfterViewInit(): void {
    if (
      !!this.olbSearchForScInputComponent &&
      !!this.form.controls.searchServiceCenterInput.value &&
      this.isFormTouchedProgrammatically
    ) {
      this.olbSearchForScInputComponent.getPlacePredictions();
    }
  }

  public expandFilter(): void {
    if (this.appointmentData.isScSelectDisabled) {
      return;
    }
    this.expanded = true;
    this.cdr.markForCheck();
  }

  public collapseFilter(): void {
    this.expanded = false;
    this.cdr.markForCheck();
  }

  public search(event?: Event): void {
    event?.stopPropagation();
    event?.preventDefault();

    combineLatest([
      this.appointmentData.availableServiceCenters$,
      this.appointmentData.selectedServiceCenterIds$,
      this.appointmentData.formattedAddress$
    ])
      .pipe(take(1))
      .subscribe(
        ([availableServiceCenters, selectedServiceCenterIds, formattedAddress]: [
          AvailableServiceCenters[],
          string[],
          string
        ]) => {
          if (
            !selectedServiceCenterIds ||
            selectedServiceCenterIds.length === 0 ||
            formattedAddress !== this.previousFormattedAddress
          ) {
            this.appointmentData.setSelectedServiceCenterIds(
              availableServiceCenters?.map((sc: AvailableServiceCenters) => sc.serviceCenter) ?? []
            );
            this.previousFormattedAddress = formattedAddress;
          }

          this.collapseFilter();
          this.searchClicked.emit();
        }
      );
  }

  public showScSelectionDialog(): void {
    this.appointmentData.availableServiceCenters$
      .pipe(take(1))
      .subscribe((availableServiceCenters: AvailableServiceCenters[]) => {
        if (availableServiceCenters?.length <= 1) {
          return;
        }

        this.overlayService.open(NewAppointmentScSelectDialogComponent);

        this.overlayService.afterClosed$
          .pipe(take(1), withLatestFrom(this.appointmentData.selectedServiceCenterIds$))
          .subscribe(([_, selectedServiceCenterIds]: [boolean, string[]]) => {
            if (selectedServiceCenterIds?.length > 0) {
              this.collapseFilter();
            }
          });
      });
  }

  private initFormValues(): void {
    combineLatest([
      this.appointmentData.formattedAddress$,
      this.appointmentData.selectedServiceCenterIds$,
      this.appointmentData.availableServiceCenters$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([searchServiceCenterInput, scIds, availableServiceCenters]: [string, string[], AvailableServiceCenters[]]) => {
          this.appointmentData.setAppointmentId(null);

          this.filterChanged?.emit();

          if (availableServiceCenters?.length > 0) {
            this.form.controls.scIds.setValue(scIds);
          }

          if (searchServiceCenterInput === null) {
            this.olbSearchForScInputComponent.skipAutocompleteOneTime = true;
          }

          this.form.controls.searchServiceCenterInput.setValue(searchServiceCenterInput);

          this.cdr.detectChanges();
        }
      );
  }

  private initServiceCenterCount() {
    this.serviceCenterCount$ = this.appointmentData.availableServiceCenters$.pipe(
      filter((availableServiceCenters: AvailableServiceCenters[]) => !!availableServiceCenters),
      map((availableServiceCenters: AvailableServiceCenters[]) => availableServiceCenters.length),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private initSelectionText(): void {
    this.selectionText$ = combineLatest([
      this.appointmentData.selectedServiceCenterIds$,
      this.appointmentData.availableServiceCenters$
    ]).pipe(
      map(([scIds, availableServiceCenters]: [string[], AvailableServiceCenters[]]) => {
        if (!scIds || !availableServiceCenters || scIds.length === 0) {
          return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.none");
        }

        const count = scIds.length;

        if (count === availableServiceCenters.length && availableServiceCenters.length > 1) {
          return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.all");
        } else if (count > 1) {
          return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.multiple", { count });
        } else if (count === 1) {
          const { city, sublocality = null } = availableServiceCenters.find(
            (sc: AvailableServiceCenters) => sc.serviceCenter === scIds[0]
          );
          return sublocality ? `${city} - ${sublocality}` : city;
        }
      })
    );
  }

  private checkBreakpoint() {
    this.breakPointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile: boolean) => {
      this.isTablet = !isMobile;
      this.cdr.markForCheck();
    });
  }
}
