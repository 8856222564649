import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of, take } from "rxjs";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { GdvExitIdService } from "@cg/olb/services";
import {
  CustomerCaseFacade,
  DamageFacade,
  GdvFacade,
  InsuranceFacade,
  OlbFacade,
  ProcessFacade,
  ProductFacade
} from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { ParagraphComponent } from "@cg/core/ui";
import { LpnInputForm } from "@cg/lpn-input";
import {
  LicensePlateExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, GetGdv, Lpn, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../../../../services/exit-node-resolver.service";
import { LicensePlateForm } from "../../../../interfaces/license-form.interface";
import { LicensePlateBase } from "../../../../license-plate.base";
import { LpnPartComponent } from "../../../parts/lpn-part/lpn-part.component";

@Component({
  selector: "cg-license-plate-no-gdv",
  templateUrl: "./license-plate-no-gdv.component.html",
  styleUrls: ["./license-plate-no-gdv.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    TranslocoPipe,
    LpnPartComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class LicensePlateNoGdvComponent extends LicensePlateBase {
  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly damageFacade: DamageFacade,
    protected readonly olbFacade: OlbFacade,
    protected readonly trackingService: TrackingService,
    protected readonly gdvFacade: GdvFacade,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly productFacade: ProductFacade,
    protected readonly gdvExitIdService: GdvExitIdService,
    protected readonly customerCaseFacade: CustomerCaseFacade,
    protected readonly optimizelyService: OptimizelyService,
    protected readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    @Inject(OLB_CONFIG) protected readonly _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(
      cdr,
      processFacade,
      exitNodeResolver,
      scrollService,
      damageFacade,
      olbFacade,
      trackingService,
      insuranceFacade,
      productFacade,
      gdvFacade,
      gdvExitIdService,
      customerCaseFacade,
      optimizelyService,
      changeDetectionService,
      _olbConfig,
      processFlow
    );
  }

  public override initFormGroup() {
    super.initFormGroup();

    this.form = new FormGroup<AddFormControls<LicensePlateForm>>({
      lpn: new FormControl<LpnInputForm>(null, Validators.required)
    });
    this.gdvFacade.setFetch(GetGdv.NOT_NECESSARY);
  }

  public saveAndContinue(): void {
    super.saveForm();

    this.damageFacade.setNoDate();

    if (this.form.valid) {
      this.olbFacade.getInsurance(() => {
        this.gdvFacade.setUseNoDate(true);
        this.goForward();
      });
    } else if (this.form.invalid) {
      this.goForwardFailure();
    }
  }

  public getExitIdForSavedForm(): Observable<LicensePlateExitIds> {
    return of("vin");
  }

  protected restoreFromEntryState() {
    this.damageFacade.lpn$.pipe(take(1)).subscribe((lpn: Lpn) => {
      if (!lpn) {
        return;
      }

      setTimeout(() => {
        this.form.controls.lpn.setValue(lpn);
        this.cdr.markForCheck();

        this.saveAndContinue();
      }, 100);
    });
  }
}
