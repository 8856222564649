import { CtalinkVariation } from "@cg/core/ui";
import type { Ctalink } from "@cg/shared";

export const additionalOptions: { firstCtaLink: Ctalink } = {
  firstCtaLink: {
    id: "customer-contact-datenschutz",
    link: {
      href: "https://www.carglass.de/datenschutz",
      title: "customerContact.ctas.privacy",
      text: "customerContact.ctas.privacy",
      target: "_blank"
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  }
};
