import { animate, state, style, transition, trigger } from "@angular/animations";
import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { DamageAssessmentContent } from "../../../interfaces/damage-assessment-content";

const ANIMATION_TIMINGS = "400ms cubic-bezier(0.25, 0.8, 0.25, 1)";
const ANIMATION_STATE_CHECKED = "checked";
const ANIMATION_STATE_NOT_CHECKED = "not-checked";
const ANIMATION_OVERLAY_STYLE = { "background-color": "var(--olb-primary-selected-color)" };
const ANIMATION_OVERLAY_STYLE_DEFAULT = { "background-color": "#f1f1f1" };

@Component({
  selector: "cg-damage-assessment-selection",
  templateUrl: "./damage-assessment-selection.component.html",
  styleUrls: ["./damage-assessment-selection.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => DamageAssessmentSelectionComponent),
      multi: true
    }
  ],
  animations: [
    trigger("overlay", [
      state(ANIMATION_STATE_CHECKED, style(ANIMATION_OVERLAY_STYLE)),
      state(ANIMATION_STATE_NOT_CHECKED, style(ANIMATION_OVERLAY_STYLE_DEFAULT)),
      transition("* => *", animate(ANIMATION_TIMINGS))
    ])
  ],
  standalone: true,
  imports: [NgClass, TranslocoPipe, IconComponent]
})
export class DamageAssessmentSelectionComponent implements ControlValueAccessor {
  private _value: string;
  private _disabled = false;

  public noHoverEffect = false;

  @Input() public content: DamageAssessmentContent;
  @Input() public preselectValue: string;
  @Output() public animationDone: EventEmitter<string> = new EventEmitter<string>();

  public onChange = (_value: string) => {};
  public onTouched = () => {};

  public get value(): string {
    return this._value;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public onAnimationStart(): void {
    this.noHoverEffect = true;
    this.cdr.markForCheck();
  }

  public onAnimationDone(event: { toState: string }): void {
    // delay to wait for editoverlay and scrolling to avoid flickering
    setTimeout(() => {
      this.noHoverEffect = false;
      this.cdr.markForCheck();
    }, 400);

    if (event.toState === ANIMATION_STATE_CHECKED) {
      this.animationDone.emit(this.value);
    }
  }

  public selectionChanged(event: { target: { value: string } }): void {
    this.writeValue(event.target.value);
  }

  public writeValue(value: string): void {
    this._value = value;
    this.onChange(value);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public trackByFn = (index: number) => index;
}
