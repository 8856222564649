import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { DamageFacade, InsuranceFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { successIcon } from "@cg/icon";
import {
  ChatInsuranceType,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { FaqComponent, InfoButtonComponent, RequiredService, SplitViewComponent } from "@cg/shared";
import { damageAssessmentResultRepairFaqModel } from "../../models/damage-assessment-result-repair-faq.model";
import { damageAssessmentResultReplaceFaqModel } from "../../models/damage-assessment-result-replace-faq.model";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";

@Component({
  selector: "cg-damage-assessment-result",
  templateUrl: "./damage-assessment-result.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    SplitViewComponent,
    IconComponent,
    OlbHeadlineComponent,
    InfoButtonComponent,
    ParagraphComponent,
    FaqComponent,
    OlbFooterComponent
  ]
})
export class DamageAssessmentResultComponent extends BaseDirective<void> {
  private damageFacade = inject(DamageFacade);
  private insuranceFacade = inject(InsuranceFacade);

  public initFormGroup(): void {}
  public setFormValues(): void {}
  public getExitIdForSavedForm(): Observable<string> {
    return this.forwardForPassthroughNextTileId();
  }

  public saveForm(): void {}

  public readonly requiredServiceIsReplace$ = this.damageFacade.requiredServiceIsReplace$;

  public readonly faqCrack = damageAssessmentResultReplaceFaqModel;
  public readonly faqChip = damageAssessmentResultRepairFaqModel;

  public readonly successIcon: Icon = successIcon;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  protected restoreFromEntryState() {
    combineLatest([this.damageFacade.requiredService$, this.insuranceFacade.chatInsuranceType$])
      .pipe(take(1))
      .subscribe(([requiredService, insuranceType]: [RequiredService, ChatInsuranceType]) => {
        if (requiredService && insuranceType) {
          this.goForward();
        }
      });
  }
}
