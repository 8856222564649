import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import format from "date-fns/format";
import { de } from "date-fns/locale";
import { ParagraphComponent } from "@cg/core/ui";
import { Appointment, DamageChipCount, OpportunityFunnelAppointmentPeriod, RequiredService } from "@cg/shared";

@Component({
  selector: "cg-appointment-date-info",
  templateUrl: "./appointment-date-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ParagraphComponent, AsyncPipe]
})
export class AppointmentDateInfoComponent implements OnInit {
  public appointmentDateInfo: string;
  public destroyRef = inject(DestroyRef);

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.appointmentFacade.currentAppointment$,
      this.appointmentFacade.desiredAppointmentDate$,
      this.appointmentFacade.desiredPeriod$,
      this.appointmentFacade.isCalibration$,
      this.damageFacade.damageChipCount$,
      this.damageFacade.requiredService$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([appointment, desiredAppointmentDate, desiredPeriod, isCalibration, damageChipCount, requiredService]: [
          Appointment,
          Date,
          OpportunityFunnelAppointmentPeriod,
          boolean,
          DamageChipCount,
          RequiredService
        ]) => {
          const dateFormat = "dd.MM.yyyy";
          const dayFormat = "EEEE";

          if (desiredAppointmentDate && desiredPeriod) {
            const desiredPeriodKey =
              desiredPeriod === OpportunityFunnelAppointmentPeriod.MORNING
                ? "appointmentDateInfo.opportunityFunnel.period.morning"
                : "appointmentDateInfo.opportunityFunnel.period.afternoon";
            this.appointmentDateInfo = this.translocoService.translate("appointmentDateInfo.opportunityFunnel.text", {
              desiredAppointmentDay: format(new Date(desiredAppointmentDate), dayFormat, { locale: de }),
              desiredAppointmentDate: format(new Date(desiredAppointmentDate), dateFormat, { locale: de }),
              desiredPeriod: this.translocoService.translate(desiredPeriodKey)
            });
          } else {
            const appointmentDay = format(new Date(appointment.customerAppointmentStart), dayFormat, { locale: de });
            const appointmentDate = format(new Date(appointment.customerAppointmentStart), dateFormat, { locale: de });
            const appointmentTime = format(new Date(appointment.customerAppointmentStart), "HH:mm", { locale: de });
            this.appointmentDateInfo = this.createAppointmentDateInfo(
              damageChipCount,
              requiredService,
              isCalibration,
              appointmentDay,
              appointmentDate,
              appointmentTime
            );
          }
        }
      );
  }

  public createAppointmentDateInfo(
    damageChipCount: DamageChipCount,
    requiredService: string,
    isCalibration: boolean,
    appointmentDay: string,
    appointmentDate: string,
    appointmentTime: string
  ): string {
    let key: string;

    switch (requiredService) {
      case RequiredService.REPAIR: {
        key = this.mapDamageChipCountTime(damageChipCount);
        break;
      }
      case RequiredService.REPLACE:
        if (isCalibration) {
          key = "appointmentDateInfo.replace.appointmentInfoReplace";
        } else {
          key = "appointmentDateInfo.replace.appointmentInfoReplaceHalfDay";
        }
        break;
    }

    return this.translocoService.translate(key, {
      appointmentDay,
      appointmentDate,
      appointmentTime
    });
  }

  private mapDamageChipCountTime(damageChipCount: DamageChipCount): string {
    switch (damageChipCount) {
      case DamageChipCount.ONE:
        return "appointmentDateInfo.repair.appointmentInfoRepairChipCount1";
      case DamageChipCount.TWO:
        return "appointmentDateInfo.repair.appointmentInfoRepairChipCount2";
      case DamageChipCount.THREE:
        return "appointmentDateInfo.repair.appointmentInfoRepairChipCount3";

      default:
        return "appointmentDateInfo.repair.appointmentInfoRepairChipCount1";
    }
  }
}
