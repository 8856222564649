<cg-insurance-base-message
  [insuranceMessageGridItems]="gridItems"
  [headlineTranslation]="headlineTranslationKey | transloco"
  [subHeadlineKey]="subHeadlineTranslationKey"
  [descriptionKey]="descriptionTranslationKey"
  [footerTemplateRef]="footerTemplate"
  (closedClicked)="onClosedClicked()"
></cg-insurance-base-message>

<ng-template #footerTemplate>
  @if (showCallbackFooter) {
    <cg-overlay-footer
      class="[&>div>button>div]:px-4 [&>div>button>div]:py-3"
      [hideCloseBtn]="true"
      [submitButtonTranslationKey]="'insuranceDeclinedMessage.footerButton'"
      (submitButtonClicked)="onGoForwardClicked()"
    ></cg-overlay-footer>
  } @else {
    <cg-olb-footer (forwardBtnClicked)="onGoForwardClicked()" (backwardBtnClicked)="onGoBackClicked()"></cg-olb-footer>
  }
</ng-template>
