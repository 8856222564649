import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { calendarSmallIcon, deliverySmallIcon, likeIcon, serviceSideWindowIcon } from "@cg/icon";
import { ExitIds, OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { SuccessGridItem } from "./interfaces/success-grid-item.interface";

@Component({
  selector: "cg-opportunity-funnel-success",
  templateUrl: "./opportunity-funnel-success.component.html",
  styleUrls: ["./opportunity-funnel-success.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, SplitViewComponent, IconComponent, OlbHeadlineComponent, ParagraphComponent]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class OpportunityFunnelSuccessComponent extends BaseDirective<AddFormControls<any>> {
  public readonly headlineIcon: Icon = likeIcon;

  public get gridItems(): SuccessGridItem[] {
    return [
      {
        icon: calendarSmallIcon,
        paragraph: {
          text: this.translocoService.translate("opportunityFunnelSuccess.items.text1"),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: deliverySmallIcon,
        paragraph: {
          text: this.translocoService.translate("opportunityFunnelSuccess.items.text2"),
          ngTemplate: "cgParagraph"
        }
      },
      {
        icon: serviceSideWindowIcon,
        paragraph: {
          text: this.translocoService.translate("opportunityFunnelSuccess.items.text3"),
          ngTemplate: "cgParagraph"
        }
      }
    ];
  }

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly translocoService: TranslocoService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public getExitIdForSavedForm(): Observable<ExitIds | undefined> {
    return of(undefined);
  }

  public saveForm(): void {}
}
