<section class="flex flex-col">
  <ng-template #master>
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline
          class="mb-4 block t:mb-6 d:mb-0 [&>.olb-tile-headline]:!mb-[18px]"
          [title]="'damageLocationMultipleChips.title' | transloco"
        ></cg-olb-headline>

        <div class="flex flex-col t:flex-row">
          <cg-icon
            class="mb-4 !block w-full bg-cg-gray-light-70 part-[icon]:!h-full part-[icon]:max-h-[208px] part-[icon]:min-h-[148px] part-[icon]:!w-full part-[icon]:max-w-[440px] d:mb-0 d:inline d:w-[440px]"
            [content]="picture"
          ></cg-icon>
          <div class="flex w-full max-w-[464px] flex-col d:w-1/2">
            <cg-radio-button-group
              class="box-border w-full items-center t:items-start t:pl-6 d:w-auto [&_.radio-button]:w-full [&_label]:hover:bg-cg-anthracite-light-90"
              [content]="damageLocationOptions"
              [formControlName]="damageLocationOptions.controlName"
              (selectedValueChanged)="onSelectedValueChanged()"
            ></cg-radio-button-group>
            <cg-info-button
              class="mt-2 block t:mt-0 d:mt-2 [&_.info-button]:ml-auto"
              [detailContent]="detailContent"
            ></cg-info-button>
          </div>
        </div>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>

<ng-template #detailContent>
  <cg-damage-location-multiple-chips-details></cg-damage-location-multiple-chips-details>
</ng-template>
