import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { CtalinkVariation, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { cccIcon } from "@cg/icon";
import { Ctalink, CtalinkComponent, ToastPopupComponent } from "@cg/shared";

const AAB_EARLIER_APPOINTMENT_INFO: Partial<TrackingEvent> = {
  event: "ga-event",
  eventCategory: "olb",
  eventAction: "appointment"
};

@Component({
  selector: "cg-adverse-buy-callback",
  templateUrl: "./adverse-buy-callback.component.html",
  styleUrls: ["./adverse-buy-callback.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ToastPopupComponent, IconComponent, ParagraphComponent, CtalinkComponent]
})
export class AdverseBuyCallbackComponent implements OnInit {
  @Output() public clickLink = new EventEmitter<void>();
  @Output() public closeClicked = new EventEmitter<void>();

  public cccIcon: Icon = cccIcon;
  public adverseBuyCallbackCta: Ctalink = {
    id: "adverse-buy-phone",
    link: {
      text: "appointmentAdverseBuyInfoText.callback.ctas.callback",
      title: "appointmentAdverseBuyInfoText.callback.ctas.callback",
      href: undefined,
      routerLink: false,
      target: "_self"
    },
    ngTemplate: "cgCtalink",
    variation: CtalinkVariation.OLB
  };

  public constructor(private trackingService: TrackingService) {}

  public ngOnInit() {
    this.trackingService.trackEvent({
      ...AAB_EARLIER_APPOINTMENT_INFO,
      eventLabel: "earlier-appointment-info-view"
    });
  }

  public goCallback() {
    this.clickLink.emit();
    this.trackingService.trackEvent({
      ...AAB_EARLIER_APPOINTMENT_INFO,
      eventLabel: "earlier-appointment-info-click"
    });
  }

  public closeOverlay() {
    this.closeClicked.emit();
  }
}
