<div class="flex flex-col gap-4">
  <div
    class="flex w-full flex-row items-center gap-x-3 rounded bg-cg-gray-light-80 px-3 py-2"
    (click)="handleSelectAll()"
  >
    <cg-new-appointment-circle>
      <cg-icon class="h-6 w-6" [content]="homeIcon"></cg-icon>
    </cg-new-appointment-circle>
    <div class="flex-1">
      <div class="text-cg-anthracite">
        <span class="font-roboto-condensed font-bold">{{
          "newAppointment.multiSelect.selectAll.title" | transloco: { count: items.length }
        }}</span>
      </div>
    </div>
    <div class="self-start">
      <input type="checkbox" class="pointer-events-none h-6 w-6 appearance-none" [checked]="isAllSelected()" />
    </div>
  </div>

  <div class="flex flex-col gap-y-0.5">
    @for (item of items; track item) {
      <ng-template
        *ngTemplateOutlet="itemView; context: { item, isSelected: isItemSelected(item.serviceCenter) }"
      ></ng-template>
    }
  </div>
</div>

<ng-template #itemView let-isSelected="isSelected" let-item="item">
  <div
    class="items-top flex w-full cursor-pointer flex-row gap-x-3 rounded bg-cg-gray-light-80 px-3 py-2"
    [class.bg-cg-yellow]="isSelected"
    (click)="handleSelectItem(item)"
  >
    <cg-new-appointment-circle [selected]="isSelected">
      <cg-new-appointment-distance-label [distance]="item.distance"></cg-new-appointment-distance-label>
    </cg-new-appointment-circle>
    <div class="text-cg-back flex-1">
      <div [class.text-cg-anthracite]="!isSelected">
        @if (item.sublocality) {
          <span class="font-bold"> {{ item.city }} - </span>{{ item.sublocality }}
        }
        @if (!item.sublocality) {
          <span class="font-bold"> {{ item.city }} </span>
        }
      </div>
      <div class="text-[13px]" [class.text-cg-anthracite-light-30]="!isSelected">
        {{ item.postalCode }} • {{ item.street }}
      </div>
    </div>
    <div class="self-start">
      <input
        type="checkbox"
        class="pointer-events-none h-6 w-6 appearance-none"
        [checked]="isSelected"
        (change)="handleSelectItem(item)"
      />
    </div>
  </div>
</ng-template>
