import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import packageInfo from "@cg/version";
import { Translation, TranslocoLoader } from "@jsverse/transloco";
import merge from "lodash/merge";
import de from "./assets/i18n/de.json";
import { TRANSLATION_CONFIGURATION } from "./configuration.token";
import { TranslationConfiguration } from "./interfaces/translation-configuration.interface";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  public constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Inject(TRANSLATION_CONFIGURATION) private config: TranslationConfiguration
  ) {}

  public getTranslation(lang: string): Observable<Translation> {
    const appTranslation$ = this.getTranslationFile(`${lang}.json`);
    const brokerTranslation$ = this.config.loadBrokerTranslation
      ? this.getTranslationFile(`broker/broker_${lang}.json`)
      : of({});

    return forkJoin([appTranslation$, brokerTranslation$]).pipe(
      map(([appTranslations, brokerTranslations]: [Translation, Translation]) =>
        merge(de, brokerTranslations, appTranslations)
      )
    );
  }

  private getTranslationFile(fileName: string): Observable<Translation> {
    const { location } = this.document;
    return this.http.get<Translation>(
      `${location.protocol}//${location.host}/assets/i18n/${fileName}?v=${packageInfo.version}`
    );
  }
}
