import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, take } from "rxjs";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { GdvExitIdService } from "@cg/olb/services";
import {
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  GdvFacade,
  InsuranceFacade,
  OlbFacade,
  ProcessFacade,
  ProductFacade
} from "@cg/olb/state";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { LpnInputForm } from "@cg/lpn-input";
import { OLB_PROCESS_FLOW_MODEL, OlbFooterComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, GetGdv, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../../../../../services/exit-node-resolver.service";
import { LicensePlateForm } from "../../../../interfaces/license-form.interface";
import { LicensePlateBase } from "../../../../license-plate.base";
import { GdvPartComponent } from "../../../parts/gdv-part/gdv-part.component";
import { LpnPartComponent } from "../../../parts/lpn-part/lpn-part.component";

@Component({
  selector: "cg-license-plate-with-gdv-and-fin",
  templateUrl: "./license-plate-with-gdv-and-fin.component.html",
  styleUrls: ["./license-plate-with-gdv-and-fin.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, LpnPartComponent, GdvPartComponent, SplitViewComponent, OlbFooterComponent]
})
export class LicensePlateWithGdvAndFinComponent extends LicensePlateBase {
  public get fetchGdv(): GetGdv {
    return this.form.controls.fetchGdv.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly olbFacade: OlbFacade,
    protected readonly damageFacade: DamageFacade,
    protected readonly gdvFacade: GdvFacade,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly contactDataFacade: ContactDataFacade,
    protected readonly trackingService: TrackingService,
    protected readonly productFacade: ProductFacade,
    protected readonly gdvExitIdService: GdvExitIdService,
    protected readonly customerCaseFacade: CustomerCaseFacade,
    protected readonly optimizelyService: OptimizelyService,
    protected readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    @Inject(OLB_CONFIG) protected readonly _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(
      cdr,
      processFacade,
      exitNodeResolver,
      scrollService,
      damageFacade,
      olbFacade,
      trackingService,
      insuranceFacade,
      productFacade,
      gdvFacade,
      gdvExitIdService,
      customerCaseFacade,
      optimizelyService,
      changeDetectionService,
      _olbConfig,
      processFlow
    );
  }

  public override initFormGroup() {
    super.initFormGroup();

    this.form = new FormGroup<AddFormControls<LicensePlateForm>>({
      lpn: new FormControl<LpnInputForm>(null, Validators.required),
      fetchGdv: new FormControl<GetGdv>(null, Validators.required)
    });

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: LicensePlateForm) => {
      if (value.fetchGdv && value.fetchGdv !== this.fetchGdvSub) {
        this.gdvFacade.setFetch(value.fetchGdv);
      }

      if (this.form.valid) {
        this.gdvFacade.setUseNoDate(true);
      }
    });
  }

  public override setFormValues() {
    super.setFormValues();
    this.form.controls.fetchGdv?.setValue(this.fetchGdvSub);
    this.cdr.detectChanges();
  }

  public saveAndContinue(): void {
    super.saveForm();

    this.damageFacade.setNoDate();

    if (this.form.valid) {
      this.olbFacade.getInsurance(() => {
        this.gdvFacade.setUseNoDate(true);
        this.goForward();
      });
    } else if (this.form.invalid) {
      this.goForwardFailure();
    }
  }

  protected override updateFormValues() {
    if (this.form && this.processMetadata?.formData) {
      const formData = { ...this.processMetadata.formData };

      this.form.patchValue(formData);

      this.cdr.markForCheck();
    }
  }

  protected restoreFromEntryState() {
    combineLatest([this.damageFacade.lpn$, this.gdvFacade.fetch$])
      .pipe(take(1))
      .subscribe(([lpn, fetchGdv]: [LpnInputForm, GetGdv]) => {
        setTimeout(() => {
          this.form.controls.lpn.setValue(lpn);
          this.form.controls.fetchGdv.setValue(fetchGdv);
          this.cdr.markForCheck();

          this.saveAndContinue();
        }, 100);
      });
  }
}
