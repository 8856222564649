import { Inject, Injectable } from "@angular/core";
import { ExitId, OLB_PROCESS_FLOW_MODEL, ProcessFlow } from "@cg/olb/shared";
import { ProcessId } from "@cg/shared";

@Injectable()
export class ExitNodeResolverService {
  public constructor(@Inject(OLB_PROCESS_FLOW_MODEL) private processFlow: ProcessFlow) {}

  public getExitNodeByProcessId(processId: ProcessId, exitScenario: ExitId): ProcessId {
    return this.processFlow[processId].exitNodes[exitScenario];
  }
}
