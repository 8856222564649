<cg-drawer [content]="content" [headline]="headline" [showCloseIcon]="true" [buttons]="buttons"> </cg-drawer>

<ng-template #headline>
  <cg-olb-headline
    class="block m:pr-16"
    [type]="headlineType.h1"
    [title]="'newAppointment.noScDialog.title' | transloco"
    [subHeadline]="'newAppointment.noScDialog.subHeadline' | transloco"
  ></cg-olb-headline>
</ng-template>

<ng-template #content>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row gap-2 p-2 pt-0">
      <cg-icon
        class="h-10 w-10 p-2 pt-0 [&>mat-icon>svg>g>g>g>path]:stroke-cg-anthracite"
        [content]="pinIcon"
      ></cg-icon>
      <cg-paragraph
        [content]="{
          text: 'newAppointment.noScDialog.texts.pin' | transloco,
          ngContent: 'cgParagraph'
        }"
      ></cg-paragraph>
    </div>

    <div class="flex flex-row gap-2 p-2">
      <cg-icon
        class="h-10 w-10 [&>mat-icon>svg>g>g>g>g>g>g]:stroke-cg-anthracite"
        [content]="mobileServiceIcon"
      ></cg-icon>
      <cg-paragraph
        [content]="{
          text: 'newAppointment.noScDialog.texts.mobileService' | transloco,
          ngContent: 'cgParagraph'
        }"
      ></cg-paragraph>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="flex w-full flex-col gap-2">
    <button
      class="part-[icon-text-wrapper]:px-4"
      cg-base-button
      [btnText]="'newAppointment.noScDialog.buttons.ok' | transloco"
      (click)="clickedOk($event)"
    ></button>
    <button
      class="w-full px-4 py-3 text-center font-roboto-condensed font-bold text-cg-anthracite-light-30 underline"
      (click)="mobileServiceClicked($event)"
    >
      {{ "newAppointment.noScDialog.buttons.reset" | transloco }}
    </button>
  </div>
</ng-template>
