import { CtalinkVariation } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import { type Ctalink, FormatTelLinkPipe, PhoneNumber } from "@cg/shared";

export const additionalOptions: { firstCtaLink: Ctalink; secondCtaLink: Ctalink } = {
  firstCtaLink: {
    id: "appointment-already-exists-my-carglass",
    icon: arrowsIcon,
    link: {
      href: "https://my.carglass.de/",
      title: "appointmentAlreadyExists.ctas.customerPortal",
      text: "appointmentAlreadyExists.ctas.customerPortal"
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  },
  secondCtaLink: {
    id: "appointment-already-exists-call-ccc",
    icon: arrowsIcon,
    link: {
      href: FormatTelLinkPipe.getPhoneLink(PhoneNumber.APPOINTMENT),
      title: PhoneNumber.APPOINTMENT,
      text: PhoneNumber.APPOINTMENT
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  }
};
