import { Injectable, Type } from "@angular/core";
import { combineLatest, distinct, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { isOpportunityFunnel } from "@cg/core/utils";
import { ProcessMetadata } from "@cg/shared";
import { ChannelSwitchCallFailureComponent } from "../components/channel-switch-call-failure/channel-switch-call-failure.component";
import { ChannelSwitchCallSuccessComponent } from "../components/channel-switch-call-success/channel-switch-call-success.component";
import { ChannelSwitchCallTimeoutComponent } from "../components/channel-switch-call-timeout/channel-switch-call-timeout.component";
import { ChannelSwitchConnectedToAgentComponent } from "../components/channel-switch-connected-to-agent/channel-switch-connected-to-agent.component";
import { ChannelSwitchCountdownComponent } from "../components/channel-switch-countdown/channel-switch-countdown.component";
import { ChannelSwitchFallbackSuccessComponent } from "../components/channel-switch-fallback-success/channel-switch-fallback-success.component";
import { ChannelSwitchFallbackSuccessOpportunityFunnelComponent } from "../components/channel-switch-fallback-success-opportunity-funnel/channel-switch-fallback-success-opportunity-funnel.component";
import { ChannelSwitchGeneralComponent } from "../components/channel-switch-general/channel-switch-general.component";

@Injectable({
  providedIn: "root"
})
export class ChannelSwitchFactoryResolverService {
  public constructor(
    private readonly channelSwitchFacade: ChannelSwitchFacade,
    private readonly processFacade: ProcessFacade
  ) {}

  public getComponentToRender(): Observable<Type<unknown>> {
    return combineLatest([
      this.channelSwitchFacade.initialView$,
      this.channelSwitchFacade.hasTimerStarted$,
      this.channelSwitchFacade.callSuccess$,
      this.channelSwitchFacade.callFailure$,
      this.channelSwitchFacade.isConnectedToAgent$,
      this.channelSwitchFacade.isTimerDone$,
      this.channelSwitchFacade.needFallback$,
      this.channelSwitchFacade.showFallback$,
      this.channelSwitchFacade.fallbackSubmitted$,
      this.channelSwitchFacade.isCallbackLate$,
      this.processFacade.processMetaData$
    ]).pipe(
      map(
        ([
          initialView,
          timerStarted,
          callSuccess,
          callFailure,
          isConnectedToAgent,
          isTimerDone,
          needFallback,
          showFallback,
          fallbackSubmitted,
          isCallbackLate,
          processMetaData
        ]: [
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          ProcessMetadata[]
        ]) => {
          if (needFallback) {
            return this.mapFallbackComponent(showFallback, fallbackSubmitted, processMetaData);
          }

          if (initialView) {
            return ChannelSwitchGeneralComponent;
          }

          if (callFailure) {
            return ChannelSwitchCallFailureComponent;
          }

          if (callSuccess) {
            return ChannelSwitchCallSuccessComponent;
          }

          if (isConnectedToAgent || isTimerDone) {
            if (isCallbackLate && isTimerDone && !isConnectedToAgent) {
              return ChannelSwitchCallTimeoutComponent;
            }

            return ChannelSwitchConnectedToAgentComponent;
          }

          if (timerStarted) {
            return ChannelSwitchCountdownComponent;
          }
        }
      ),
      distinct()
    );
  }

  private mapFallbackComponent(
    showFallback: boolean,
    fallbackSubmitted: boolean,
    processMetaData: ProcessMetadata[]
  ): Type<unknown> {
    if (!showFallback) {
      this.channelSwitchFacade.setShowFallback(true);
    }

    if (fallbackSubmitted) {
      if (isOpportunityFunnel(processMetaData)) {
        return ChannelSwitchFallbackSuccessOpportunityFunnelComponent;
      }

      return ChannelSwitchFallbackSuccessComponent;
    }

    return ChannelSwitchGeneralComponent;
  }
}
