<section class="customer-data-email--container">
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        class="max-w-content"
        [title]="'customerContact.title' | transloco"
        [subHeadline]="subHeadlineKey$ | async | transloco"
      ></cg-olb-headline>

      @if ({ value: onlyMailVariantActive$ | async }; as onlyMailVariantActive) {
        @if (onlyMailVariantActive.value !== null && onlyMailVariantActive.value !== undefined) {
          <cg-customer-contact-form-early-data-test
            class="block max-w-content"
            [customerCaseId]="customerCaseId$ | async"
            [onlyMailForm]="onlyMailVariantActive.value"
            [isEmailOptional]="false"
            [currentProcessId$]="processFacade.currentProcessId$"
          ></cg-customer-contact-form-early-data-test>
        }
      }

      <p
        class="mb-6 max-w-content font-roboto text-base text-cg-anthracite-light-30"
        [innerHtml]="agreementKey$ | async | transloco"
      ></p>

      <div class="flex flex-col">
        <cg-ctalink
          [content]="privacyStatementCta"
          (clickedFn)="onTrack('privacy-statement')"
          class="[&>div>div>a]:hover:text-cg-red"
        ></cg-ctalink>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
