import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable, of } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { AppointmentSearchForm, BrandingComponent, BreakpointService, SplitViewComponent } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { NewAppointmentDesktopComponent } from "../new-appointment-desktop/new-appointment-desktop.component";
import { NewAppointmentMobileComponent } from "../new-appointment-mobile/new-appointment-mobile.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment",
  templateUrl: "./new-appointment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    TranslocoPipe,
    HeadlineComponent,
    BrandingComponent,
    SplitViewComponent,
    OlbHeadlineComponent,
    NewAppointmentMobileComponent,
    NewAppointmentDesktopComponent
  ],
  standalone: true
})
export class NewAppointmentComponent
  extends BaseDirective<AddFormControls<AppointmentSearchForm>>
  implements OnInit, OnDestroy
{
  // eslint-disable-next-line max-params

  public isDesktop = false;

  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly trackingService: TrackingService,
    protected readonly breakpointService: BreakpointService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.breakpointService.isDesktop$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
      this.cdr.markForCheck();
    });
  }

  public initFormGroup(): void {}

  public setFormValues(): void {
    // TODO: maybe implement this later for save and restore or mycarglass
  }

  public getExitIdForSavedForm(): Observable<string> {
    return of("");
  }

  public saveForm(): void {}
}
