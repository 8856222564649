<section>
  <cg-split-view [master]="master"></cg-split-view>
</section>

<ng-template #master>
  <div class="form-container">
    <cg-olb-headline
      class="font-roboto-condensed"
      [title]="'teslaRestrictions.title' | transloco: { model }"
      data-cy="tesla-car-identified-title"
      data-cs-mask
    >
    </cg-olb-headline>
    <div class="flex gap-[8px]">
      <p class="text-cg-anthracite" data-cs-mask>
        {{ "teslaRestrictions.changeCar.text" | transloco }}
      </p>
      <cg-ctalink
        class="[&>div]:h-fit"
        [content]="changeCarLink"
        (clickedFn)="changeCar($event)"
        data-cy="car-identified-change-car-link"
      ></cg-ctalink>
    </div>
    <cg-tesla-restriction-items></cg-tesla-restriction-items>
  </div>

  <cg-cta
    [content]="goForwardCtaLinkContent"
    [disableIcon]="true"
    (clickedFn)="goForward()"
    data-cy="car-identified-forward-button"
  ></cg-cta>
</ng-template>
