import { ChangeDetectionStrategy, Component } from "@angular/core";
import { HeadlineComponent, IconComponent } from "@cg/core/ui";
import { errorIcon, phoneContactIcon } from "@cg/icon";
import {
  BrandingComponent,
  CccOpeningHoursComponent,
  DialogComponent,
  DialogErrorComponent,
  PhoneNumber
} from "@cg/shared";

@Component({
  selector: "cg-add-wipers-error",
  standalone: true,
  imports: [
    DialogComponent,
    IconComponent,
    HeadlineComponent,
    CccOpeningHoursComponent,
    BrandingComponent,
    DialogErrorComponent
  ],
  templateUrl: "./add-wipers-error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddWipersErrorComponent {
  protected readonly errorIcon = errorIcon;
  protected readonly phoneContactIcon = phoneContactIcon;
  protected readonly serviceNumber = PhoneNumber.APPOINTMENT;
}
