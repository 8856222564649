import type { LinkWithId } from "@cg/core/ui";

export const additionalOptions: { secondLink: LinkWithId; firstLink: LinkWithId } = {
  firstLink: {
    id: "customer-address-agb",
    href: "https://www.carglass.de/agb",
    title: "customerAddress.ctas.terms",
    text: "customerAddress.ctas.terms",
    target: "_blank"
  },
  secondLink: {
    id: "customer-address-datenschutz",
    href: "https://www.carglass.de/datenschutz",
    title: "customerAddress.ctas.privacy",
    text: "customerAddress.ctas.privacy",
    target: "_blank"
  }
};
