import { DatePipe, SlicePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { BadgeComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { AppointmentData, BaseButtonComponent, OverlayService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { LocationWithAppointment } from "../../interfaces/location-with-appointment.interface";
import { createAppointmentData } from "../../utils/create-appointment-data.util";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDetailComponent } from "../new-appointment-detail/new-appointment-detail.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-earliest-appointment-card",
  templateUrl: "./new-appointment-earliest-appointment-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    SlicePipe,
    TranslocoPipe,
    BaseButtonComponent,
    BadgeComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent
  ]
})
export class NewAppointmentEarliestAppointmentCardComponent implements OnInit {
  @Input()
  public locationWithAppointment: LocationWithAppointment;

  private appointmentData: AppointmentData;

  public constructor(
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly overlayService: OverlayService
  ) {}

  public ngOnInit(): void {
    this.appointmentData = createAppointmentData(this.appointmentFacade, this.damageFacade);
  }

  public bookAppointment(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.appointmentData.setAppointmentId(this.locationWithAppointment.appointment.appointmentId);
    this.overlayService.open(NewAppointmentDetailComponent, { appointmentData: this.appointmentData });
  }
}
