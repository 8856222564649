import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { ExitIds, OLB_PROCESS_FLOW_MODEL, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../../core/directives/base/base.directive";
import { ChannelSwitchForm } from "../../interfaces/channel-switch-form.interface";

@Component({
  selector: "cg-channel-switch-base",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class ChannelSwitchBaseComponent extends BaseDirective<AddFormControls<ChannelSwitchForm>> {
  protected constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public getExitIdForSavedForm(): Observable<ExitIds> | undefined {
    return undefined;
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public saveForm(): void {}
}
