import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, filter, map, Observable, take } from "rxjs";
import { ContactDataFacade, DamageFacade, OlbFacade, ProcessFacade } from "@cg/olb/state";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, IconComponent, LinkComponent, LinkWithId, ParagraphComponent } from "@cg/core/ui";
import { isOpportunityFunnel } from "@cg/core/utils";
import { environment } from "@cg/environments";
import {
  CustomerContactExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  CheckboxComponent,
  ComponentOverarchingChangeDetectionService,
  InputType,
  ProcessMetadata,
  RadioButtonHorizontalGroupComponent,
  SplitViewComponent,
  Tab,
  TabsComponent,
  TextInputComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { NewCustomerAddressForm } from "./interfaces/new-customer-address-form.interface";
import { newCustomerAddressInputs } from "./new-customer-address.inputs";

@Component({
  selector: "cg-new-customer-address",
  standalone: true,
  imports: [
    CommonModule,
    OlbHeadlineComponent,
    ReactiveFormsModule,
    TranslocoPipe,
    SplitViewComponent,
    TabsComponent,
    OlbFooterComponent,
    IconComponent,
    RadioButtonHorizontalGroupComponent,
    TextInputComponent,
    HeadlineComponent,
    ParagraphComponent,
    CheckboxComponent,
    LinkComponent
  ],
  templateUrl: "./new-customer-address.component.html",
  styles: ""
})
export class NewCustomerAddressComponent
  extends BaseDirective<AddFormControls<NewCustomerAddressForm>>
  implements OnInit, AfterViewInit
{
  @ViewChild("companyTabTemplate") public companyTabTemplate!: TemplateRef<unknown>;
  @ViewChild("privateTabTemplate") public privateTabTemplate!: TemplateRef<unknown>;
  public tabs: Tab[] = [];
  public activeTabIndex: number;
  public destroyRef = inject(DestroyRef);
  public contactDataInputs = newCustomerAddressInputs;
  protected readonly InputType = InputType;
  public isIOMResume = false;

  public footerLinks: { firstLink: LinkWithId; secondLink: LinkWithId } = {
    firstLink: {
      id: "customer-address-agb",
      href: "https://www.carglass.de/agb",
      title: "customerAddress.ctas.terms",
      text: "customerAddress.ctas.terms",
      target: "_blank"
    },
    secondLink: {
      id: "customer-address-datenschutz",
      href: "https://www.carglass.de/datenschutz",
      title: "customerAddress.ctas.privacy",
      text: "customerAddress.ctas.privacy",
      target: "_blank"
    }
  };

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    private contactDataFacade: ContactDataFacade,
    private olbFacade: OlbFacade,
    protected trackingService: TrackingService,
    private damageFacade: DamageFacade,
    private optimizelyService: OptimizelyService,
    private readonly cdrService: ComponentOverarchingChangeDetectionService,
    private readonly fb: FormBuilder,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get policyHolderInput(): FormControl<string> {
    return this.form.controls.policyHolder;
  }

  public get insuranceCompanyInput(): FormControl<string> {
    return this.form.controls.companyName;
  }

  public get insuranceTitleInput(): FormControl<string> {
    return this.form.controls.title;
  }

  public get insuranceFirstnameInput(): FormControl<string> {
    return this.form.controls.firstName;
  }

  public get insuranceLastnameInput(): FormControl<string> {
    return this.form.controls.lastName;
  }

  public get insuranceStreetInput(): FormControl<string> {
    return this.form.controls.street;
  }

  public get insuranceZipInput(): FormControl<string> {
    return this.form.controls.zip;
  }

  public get insuranceCityInput(): FormControl<string> {
    return this.form.controls.city;
  }

  public get insuranceCountryInput(): FormControl<string> {
    return this.form.controls.country;
  }

  public get insurancePhoneInput(): FormControl<string> {
    return this.form.controls.contactPhone;
  }

  public get currentAddressFormValue(): NewCustomerAddressForm {
    return this.form.value as NewCustomerAddressForm;
  }

  public get activeVapsOffer(): boolean {
    return environment.features.vapsOffer;
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.activeTabIndex = 0;
  }

  public ngAfterViewInit(): void {
    this.tabs = [
      {
        header: "newCustomerAddress.tabPrivate",
        template: this.privateTabTemplate
      },
      {
        header: "newCustomerAddress.tabCompany",
        template: this.companyTabTemplate
      }
    ];
    this.cdr.detectChanges();
    super.ngAfterViewInit();
  }

  public tabChanged(tabIndex: number): void {
    this.activeTabIndex = tabIndex;
  }

  public initFormGroup(): void {
    this.form = this.fb.group({
      isCompany: [false],
      title: ["", Validators.required],
      firstName: ["", CgValidators.nameValidators()],
      lastName: ["", CgValidators.nameValidators()],
      street: ["", CgValidators.streetValidators()],
      zip: ["", Validators.required],
      city: ["", CgValidators.cityValidators()],
      country: ["DE", Validators.required],
      contactTitle: [""],
      contactFirstName: ["", CgValidators.nameValidatorsOptional()],
      contactLastName: ["", CgValidators.nameValidatorsOptional()],
      contactPhone: ["", CgValidators.phoneValidators(false)],
      companyName: ["", CgValidators.companyValidators()],
      policyHolder: [""],
      otherContactPerson: [false]
    }) as FormGroup<AddFormControls<NewCustomerAddressForm>>;

    this.form
      .get("otherContactPerson")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isChecked: boolean) => {
        this.updateContactPersonValidators(isChecked);
      });
  }

  private updateContactPersonValidators(isChecked: boolean): void {
    this.form.get("contactTitle").setValidators(isChecked ? [Validators.required] : []);
    this.form
      .get("contactFirstName")
      .setValidators(isChecked ? CgValidators.nameValidators() : CgValidators.nameValidatorsOptional());
    this.form
      .get("contactLastName")
      .setValidators(isChecked ? CgValidators.nameValidators() : CgValidators.nameValidatorsOptional());
    this.form.get("contactPhone").setValidators(CgValidators.phoneValidators(isChecked));

    this.form.get("contactTitle").updateValueAndValidity();
    this.form.get("contactFirstName").updateValueAndValidity();
    this.form.get("contactLastName").updateValueAndValidity();
    this.form.get("contactPhone").updateValueAndValidity();
  }

  public goForwardFailure(): void {
    super.goForwardFailure();
    this.cdrService.changeDetectionRequest$.next();
  }

  public saveForm(): void {
    this.saveInsuranceHolderForm();
    this.copyCustomerToDriver();

    const otherContactPerson = this.form.controls.otherContactPerson.value;
    if (!otherContactPerson) {
      this.contactDataFacade.setIsPolicyHolder(!!otherContactPerson);
    } else {
      this.saveDriverForm();
    }

    if (this.isIOMResume) {
      this.copyDriverToCustomer();
    }

    if (this.form.valid) {
      this.olbFacade.updateCustomerContact();
    }
  }

  public setFormValues(): void {
    this.fillDriverForm();

    this.contactDataFacade.isPolicyHolder$
      .pipe(
        filter((isPolicyHolder: boolean) => isPolicyHolder !== null),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((isPolicyHolder: boolean) => {
        this.form.controls.otherContactPerson.setValue(!!isPolicyHolder);

        if (!isPolicyHolder) {
          this.fillInsuranceHolderForm();
        }
      });
  }

  public getExitIdForSavedForm(): Observable<CustomerContactExitIds> {
    return combineLatest([
      this.damageFacade.requiredServiceIsReplace$,
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.BINDING_BOOKING),
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_SUMMARY),
      this.processFacade.processMetaData$
    ]).pipe(
      map(
        ([isReplace, bindingBookingExperimentActive, isSummaryTestActive, processMetaData]: [
          boolean,
          boolean,
          boolean,
          ProcessMetadata[]
        ]) => {
          if (isOpportunityFunnel(processMetaData)) {
            return "opportunityFunnelContactTime";
          }

          if (this.activeVapsOffer) {
            return "vapsOffer";
          }

          if (isSummaryTestActive) {
            return "summary";
          }

          if (isReplace && bindingBookingExperimentActive) {
            return "bindingBooking";
          }

          return "appointmentSuccess";
        }
      )
    );
  }

  public fillDriverForm() {
    const addressForm = this.form;

    this.contactDataFacade.driverTitle$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverTitle: string) => {
        if (driverTitle) {
          addressForm.get("title").setValue(driverTitle);
        }
      });

    this.contactDataFacade.driverFirstname$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverFirstname: string) => {
        if (driverFirstname) {
          addressForm.get("firstName").setValue(driverFirstname);
        }
      });

    this.contactDataFacade.driverLastname$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverLastname: string) => {
        if (driverLastname) {
          addressForm.get("lastName").setValue(driverLastname);
        }
      });

    this.contactDataFacade.driverStreet$
      .pipe(
        take(1),
        map((street: string) => street ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((street: string) => addressForm.get("street").setValue(street));

    this.contactDataFacade.driverZip$
      .pipe(
        take(1),
        map((zip: string) => zip ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((zip: string) => addressForm.get("zip").setValue(zip));

    this.contactDataFacade.driverCity$
      .pipe(
        take(1),
        map((city: string) => city ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((city: string) => addressForm.get("city").setValue(city));

    this.contactDataFacade.driverCountry$
      .pipe(
        take(1),
        map((country: string) => (country ? country : "DE")),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((country: string) => addressForm.get("country").setValue(country));
  }

  public fillInsuranceHolderForm() {
    this.contactDataFacade.insuranceHolderCompany$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((company: string) => this.insuranceCompanyInput.setValue(company));

    this.contactDataFacade.insuranceHolderTitle$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((title: string) => this.insuranceTitleInput.setValue(title));

    this.contactDataFacade.insuranceHolderFirstname$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((firstname: string) => this.insuranceFirstnameInput.setValue(firstname));

    this.contactDataFacade.insuranceHolderLastname$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((lastname: string) => this.insuranceLastnameInput.setValue(lastname));

    this.contactDataFacade.insuranceHolderStreet$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((street: string) => this.insuranceStreetInput.setValue(street));

    this.contactDataFacade.insuranceHolderZip$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((zip: string) => this.insuranceZipInput.setValue(zip));

    this.contactDataFacade.insuranceHolderCity$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((city: string) => this.insuranceCityInput.setValue(city));

    this.contactDataFacade.insuranceHolderCountry$
      .pipe(
        map((country: string) => (country ? country : "DE")),
        takeUntilDestroyed(this.destroyRef),
        take(1)
      )
      .subscribe((country: string) => this.insuranceCountryInput.setValue(country));

    this.contactDataFacade.insuranceHolderPhone$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((phone: string) => this.insurancePhoneInput.setValue(phone));
  }

  public saveDriverForm() {
    if (!this.currentAddressFormValue) {
      return;
    }

    const { contactTitle, contactFirstName, contactLastName, contactPhone } = this.currentAddressFormValue;

    this.contactDataFacade.setDriverTitle(contactTitle);
    this.contactDataFacade.setDriverFirstname(contactFirstName);
    this.contactDataFacade.setDriverLastname(contactLastName);
    this.contactDataFacade.setDriverPhone(contactPhone);
  }

  public saveInsuranceHolderForm() {
    if (this.form.controls.isCompany.value) {
      this.contactDataFacade.setInsuranceHolderCompany(this.insuranceCompanyInput.value);
    }
    this.contactDataFacade.setInsuranceHolderTitle(this.insuranceTitleInput.value);
    this.contactDataFacade.setInsuranceHolderFirstname(this.insuranceFirstnameInput.value);
    this.contactDataFacade.setInsuranceHolderLastname(this.insuranceLastnameInput.value);
    this.contactDataFacade.setInsuranceHolderStreet(this.insuranceStreetInput.value);
    this.contactDataFacade.setInsuranceHolderZip(this.insuranceZipInput.value);
    this.contactDataFacade.setInsuranceHolderCity(this.insuranceCityInput.value);
    this.contactDataFacade.setInsuranceHolderCountry(this.insuranceCountryInput.value);

    if (this.insurancePhoneInput.value) {
      this.contactDataFacade.setInsuranceHolderPhone(this.insurancePhoneInput.value);
    }
  }

  public copyCustomerToDriver() {
    const { title, firstName, lastName, street, zip, city, country } = this.currentAddressFormValue;

    this.contactDataFacade.setDriverTitle(title);
    this.contactDataFacade.setDriverFirstname(firstName);
    this.contactDataFacade.setDriverLastname(lastName);
    this.contactDataFacade.setDriverStreet(street);
    this.contactDataFacade.setDriverZip(zip);
    this.contactDataFacade.setDriverCity(city);
    this.contactDataFacade.setDriverCountry(country);
  }

  public copyDriverToCustomer() {
    const { title, firstName, lastName, street, zip, city, country } = this.currentAddressFormValue;
    this.contactDataFacade.setInsuranceHolderTitle(title);
    this.contactDataFacade.setInsuranceHolderFirstname(firstName);
    this.contactDataFacade.setInsuranceHolderLastname(lastName);
    this.contactDataFacade.setInsuranceHolderStreet(street);
    this.contactDataFacade.setInsuranceHolderZip(zip);
    this.contactDataFacade.setInsuranceHolderCity(city);
    this.contactDataFacade.setInsuranceHolderCountry(country);
  }

  public onTrack(eventAction: string) {
    this.trackingService.trackEvent({
      eventAction: eventAction,
      eventLabel: "customer-address"
    } as Partial<TrackingEvent>);
  }
}
