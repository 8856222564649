<section class="costs-container">
  <cg-split-view [master]="master" [showBranding]="true"></cg-split-view>
</section>

<ng-template #master>
  <div>
    <cg-olb-headline
      class="costs--headline"
      [title]="'opportunityFunnelProductNotFound.title' | transloco"
    ></cg-olb-headline>
    <cg-paragraph
      class="mb-8 m:mb-6"
      [innerHTML]="'opportunityFunnelProductNotFound.paragraph' | transloco"
    ></cg-paragraph>

    <div class="mt-4 flex max-w-content flex-col gap-2 t:mt-6 t:flex-row t:gap-4">
      <button
        cg-base-button
        [btnText]="'opportunityFunnelCosts.ctaTitle' | transloco"
        class="w-full part-[icon|>mat-icon>svg>path]:fill-white part-[icon|>mat-icon>svg]:fill-transparent part-[icon-text-wrapper]:px-4"
        (click)="goForward()"
        [icon]="calendarIcon"
      ></button>
      <button
        cg-base-button
        [btnText]="'opportunityFunnelCosts.ctaCallbackTitle' | transloco"
        class="w-full part-[icon-text-wrapper]:px-4"
        (click)="goToChannelSwitch()"
        [icon]="phoneIcon"
        variation="secondary"
      ></button>
    </div>
  </div>
</ng-template>
