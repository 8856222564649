@if (repairConfig$ | async; as config) {
  <div class="insurance-type-detail">
    <cg-olb-headline [title]="headlineKey | transloco" [type]="headlineType"></cg-olb-headline>
    @for (textKey of descriptionTextKeys; track textKey) {
      <p class="insurance-type-detail-list-item">
        <cg-icon
          class="insurance-type-detail-list-item--icon h-6 w-6"
          [content]="insuranceType === 'FULLY' || insuranceType === 'UNKNOWN' ? checkmark : info"
        >
        </cg-icon>
        {{ textKey | transloco: { price: config.price, priceMoreChips: config.priceMoreChips } }}
      </p>
    }
  </div>
}
