import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { AppointmentFacade, CustomerCaseFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import format from "date-fns/format";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { CtalinkVariation, IconComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { arrowsIcon, costIcon, deliveryIcon } from "@cg/icon";
import {
  BindingBookingExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  Appointment,
  BreakpointService,
  CheckboxComponent,
  CheckboxInput,
  Ctalink,
  CtalinkComponent,
  CustomerCaseService,
  ErrorMessageComponent,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { BindingBookingFrom } from "./interfaces/binding-booking-from.interface";

@ABTest(OptimizelyExperiment.BINDING_BOOKING)
@Component({
  selector: "cg-binding-booking",
  templateUrl: "./binding-booking.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    IconComponent,
    ErrorMessageComponent,
    CheckboxComponent,
    CtalinkComponent,
    CheckboxComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class BindingBookingComponent extends BaseDirective<AddFormControls<BindingBookingFrom>> implements OnInit {
  public destroyRef = inject(DestroyRef);
  public variationId = "original";
  public variationIndex = 0;
  public iconCosts: Icon = costIcon;
  public iconDelivery: Icon = deliveryIcon;
  public isDesktop: boolean;

  public termsAndConditionCta: Ctalink = {
    id: "binding-booking-terms-and-condition",
    icon: arrowsIcon,
    link: {
      href: "https://www.carglass.de/bestellbedingungen",
      title: `bindingBooking.${this.variationId}.termsAndConditions`,
      text: `bindingBooking.${this.variationId}.termsAndConditions`,
      target: "_blank"
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  };

  public orderCommitmentCheckbox: CheckboxInput = {
    id: "binding-booking-commitment-checkbox",
    controlName: "orderCommitment",
    text: `bindingBooking.${this.variationId}.orderCommitmentCheckbox`,
    style: "big"
  };

  public delivery = {
    copy: `bindingBooking.${this.variationId}.delivery.copy`
  };

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    protected readonly customerCaseFacade: CustomerCaseFacade,
    protected readonly customerCaseService: CustomerCaseService,
    private readonly breakpointService: BreakpointService,
    private readonly optimizelyService: OptimizelyService,
    private readonly translocoService: TranslocoService,
    private readonly appointmentFacade: AppointmentFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.optimizelyService
      .getActiveVariationIdWithIndex$(OptimizelyExperiment.BINDING_BOOKING)
      .pipe(withLatestFrom(this.appointmentFacade.currentAppointment$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([variation, appointment]: [{ variationId: string; index: number }, Appointment]) => {
        this.variationId = variation.variationId;
        this.variationIndex = variation.index;

        this.orderCommitmentCheckbox.text = `bindingBooking.${variation.variationId}.orderCommitmentCheckbox`;
        this.termsAndConditionCta.link.title = `bindingBooking.${variation.variationId}.termsAndConditions`;
        this.termsAndConditionCta.link.text = `bindingBooking.${variation.variationId}.termsAndConditions`;

        this.delivery.copy = this.translocoService.translate(this.delivery.copy, {
          date: format(new Date(appointment.customerAppointmentStart), "dd.MM.yyyy"),
          city: `${appointment.street}, ${appointment.postalCode} ${appointment.city}`
        });

        if (variation.index === 1) {
          this.termsAndConditionCta.icon = null;
        }

        if (variation.index > 0) {
          this.form.controls.orderCommitment.setValidators([Validators.requiredTrue]);
          this.form.controls.orderCommitment.updateValueAndValidity();
        }
      });

    this.breakpointService.isDesktop$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
      this.cdr.markForCheck();
    });
  }

  public getExitIdForSavedForm(): Observable<BindingBookingExitIds> {
    return of("appointmentSuccess");
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<BindingBookingFrom>>({
      orderCommitment: new FormControl<boolean>(false)
    });
  }

  public goForward(): void {
    if (this.form.valid) {
      this.customerCaseFacade.setOrderCommitment(true);
    }

    super.goForward();
  }

  public saveForm(): void {}

  public setFormValues(): void {}
}
