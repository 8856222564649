<section>
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        [title]="'vehicleIdentificationManually.title' | transloco"
        [subHeadline]="'vehicleIdentificationManually.subHeadline' | transloco"
      ></cg-olb-headline>

      <div class="vehicle-identification-manually--dropdown-list max-w-content">
        <div class="vehicle-identification-manually--manufacturer">
          <label>{{ "vehicleIdentificationManually.selections.brand.label" | transloco }}</label>
          <cg-options-selection
            [selectionId]="'vehicle-identification-manually-manufacturers'"
            [placeholder]="'vehicleIdentificationManually.selections.brand.placeholder' | transloco"
            [formControlName]="'brand'"
            [items]="brands"
            (selectionChangedEmitter)="handleManufacturerChange()"
          ></cg-options-selection>

          @if (hasManufacturerError) {
            <div class="vehicle-identification-manually--error-message">
              <cg-error-message
                [class]="'error-message'"
                [errorMessage]="'vehicleIdentificationManually.selections.brand.errorMessage' | transloco"
              ></cg-error-message>
            </div>
          }
        </div>
        <div class="vehicle-identification-manually--model">
          <label>{{ "vehicleIdentificationManually.selections.model.label" | transloco }}</label>
          <cg-options-selection
            [selectionId]="'vehicle-identification-manually-models'"
            [placeholder]="'vehicleIdentificationManually.selections.model.placeholder' | transloco"
            [formControlName]="'model'"
            [items]="models"
            (selectionChangedEmitter)="handleModelChange()"
          >
          </cg-options-selection>
          @if (hasModelError) {
            <div class="vehicle-identification-manually--error-message">
              <cg-error-message
                [class]="'error-message'"
                [errorMessage]="'vehicleIdentificationManually.selections.model.errorMessage' | transloco"
              ></cg-error-message>
            </div>
          }
        </div>
        <div class="vehicle-identification-manually--model-type">
          <label>{{ "vehicleIdentificationManually.selections.type.label" | transloco }}</label>
          <cg-options-selection
            [selectionId]="'vehicle-identification-manually-types'"
            [placeholder]="'vehicleIdentificationManually.selections.type.placeholder' | transloco"
            [formControlName]="'type'"
            [items]="types"
            (selectionChangedEmitter)="handleTypeChange()"
          ></cg-options-selection>
          @if (hasModelTypeError) {
            <div class="vehicle-identification-manually--error-message">
              <cg-error-message
                [class]="'error-message'"
                [errorMessage]="'vehicleIdentificationManually.selections.type.errorMessage' | transloco"
              ></cg-error-message>
            </div>
          }
        </div>
        <div class="vehicle-identification-manually--build-date">
          <label>{{ "vehicleIdentificationManually.selections.buildDate.label" | transloco }}</label>
          <cg-options-selection
            [selectionId]="'vehicle-identification-manually'"
            [placeholder]="'vehicleIdentificationManually.selections.buildDate.placeholder' | transloco"
            [formControlName]="'buildDate'"
            [items]="buildDates"
          ></cg-options-selection>

          @if (hasBuildDateError) {
            <div class="vehicle-identification-manually--error-message">
              <cg-error-message
                [class]="'error-message'"
                [errorMessage]="'vehicleIdentificationManually.selections.buildDate.errorMessage' | transloco"
              >
              </cg-error-message>
            </div>
          }
        </div>
      </div>

      <cg-ctalink
        class="vehicle-identification-manually--channel-switch"
        [content]="carNotListed"
        (clickedFn)="handleCarIsNotListed($event)"
      ></cg-ctalink>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="split-view--footer"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
