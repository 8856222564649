import type { CheckboxInput, RadioButtonGroup, TextInput } from "@cg/shared";

export const titleGroup: RadioButtonGroup = {
  controlName: "title",
  buttons: [
    { title: "newCustomerAddress.female", radio: { id: "customer-address-female", value: "Frau" } },
    { title: "newCustomerAddress.male", radio: { id: "customer-address-male", value: "Herr" } }
  ]
};

export const contactTitleGroup: RadioButtonGroup = {
  controlName: "contactTitle",
  buttons: [
    { title: "newCustomerAddress.female", radio: { id: "customer-address-contact-title-female", value: "Frau" } },
    { title: "newCustomerAddress.male", radio: { id: "customer-address-contact-title-male", value: "Herr" } }
  ]
};

export const otherContactPersonGroup: CheckboxInput = {
  id: "other-contact-person",
  text: "newCustomerAddress.otherContactPerson.title",
  controlName: "otherContactPerson"
};

export const taxDeductibleGroup: RadioButtonGroup = {
  controlName: "taxDeductible",
  buttons: [
    {
      title: "newCustomerAddress.yes",
      radio: { id: "customer-address-company-tax-deductible", value: "yes" }
    },
    {
      title: "newCustomerAddress.no",
      radio: { id: "customer-address-company-not-tax-deductible", value: "no" }
    }
  ]
};

export const companyInput: TextInput = {
  id: "customer-address-company",
  controlName: "companyName",
  placeholder: "newCustomerAddress.companyName.placeholder",
  errorMessage: "newCustomerAddress.companyName.errorMessage"
};

export const firstnameInput: TextInput = {
  id: "customer-address-firstname",
  controlName: "firstName",
  placeholder: "newCustomerAddress.firstName.placeholder",
  errorMessage: "newCustomerAddress.firstName.errorMessage"
};

export const lastnameInput: TextInput = {
  id: "customer-address-lastname",
  controlName: "lastName",
  placeholder: "newCustomerAddress.lastName.placeholder",
  errorMessage: "newCustomerAddress.lastName.errorMessage"
};

export const streetInput: TextInput = {
  id: "customer-address-street",
  controlName: "street",
  placeholder: "newCustomerAddress.street.placeholder",
  errorMessage: ""
};

export const zipInput: TextInput = {
  id: "customer-address-zip",
  controlName: "zip",
  placeholder: "newCustomerAddress.zip.placeholder",
  errorMessage: ""
};

export const cityInput: TextInput = {
  id: "customer-address-city",
  controlName: "city",
  placeholder: "newCustomerAddress.city.placeholder",
  errorMessage: ""
};

export const phoneInput: TextInput = {
  id: "customer-address-phone",
  controlName: "phone",
  placeholder: "newCustomerAddress.phone.placeholder",
  errorMessage: "newCustomerAddress.phone.errorMessage"
};

export const alternativePhoneInput: TextInput = {
  id: "customer-address-contact-phone",
  controlName: "contactPhone",
  placeholder: "newCustomerAddress.alternativePhone.placeholder",
  errorMessage: "newCustomerAddress.alternativePhone.errorMessage"
};

export const emailInput: TextInput = {
  id: "customer-address-email",
  controlName: "contactEmail",
  placeholder: "newCustomerAddress.email.placeholder",
  placeholderRequired: "newCustomerAddress.email.placeholderRequired",
  errorMessage: "newCustomerAddress.email.errorMessage"
};

export const contactFirstnameInput: TextInput = {
  id: "customer-address-contact-firstname",
  controlName: "contactFirstName",
  placeholder: "newCustomerAddress.firstName.placeholder",
  errorMessage: "newCustomerAddress.firstName.errorMessage"
};

export const contactLastnameInput: TextInput = {
  id: "customer-address-contact-lastname",
  controlName: "contactLastName",
  placeholder: "newCustomerAddress.lastName.placeholder",
  errorMessage: "newCustomerAddress.lastName.errorMessage"
};

export const contactPhoneInput: TextInput = {
  id: "customer-address-contact-phone",
  controlName: "contactPhoneNumber",
  placeholder: "newCustomerAddress.phone.placeholder",
  errorMessage: "newCustomerAddress.phone.errorMessage"
};

export const contactAlternativePhoneInput: TextInput = {
  id: "customer-address-contact-alt-phone",
  controlName: "contactAlternativePhoneNumber",
  placeholder: "newCustomerAddress.alternativePhone.placeholder",
  errorMessage: ""
};

export const contactEmailInput: TextInput = {
  id: "customer-address-contact-email",
  controlName: "contactEmail",
  placeholder: "newCustomerAddress.email.placeholder",
  placeholderRequired: "newCustomerAddress.email.placeholderRequired",
  errorMessage: "newCustomerAddress.email.errorMessage"
};

export const newCustomerAddressInputs = {
  titleGroup,
  contactTitleGroup,
  otherContactPersonGroup,
  taxDeductibleGroup,
  companyInput,
  firstnameInput,
  lastnameInput,
  streetInput,
  zipInput,
  cityInput,
  phoneInput,
  alternativePhoneInput,
  emailInput,
  contactFirstnameInput,
  contactLastnameInput,
  contactPhoneInput,
  contactAlternativePhoneInput,
  contactEmailInput
};
