<section>
  <ng-template #master>
    <div>
      @if (showScreenAvailableFlag) {
        <p
          class="absolute -left-1.5 z-10 -translate-y-6 bg-cg-green px-2 pt-0.5 font-roboto-condensed text-xs font-bold uppercase text-cg-white after:absolute after:left-0 after:top-full after:h-1.5 after:w-1.5 after:bg-cg-green after:content-[''] after:[clip-path:polygon(0%_0%,100%_0%,100%_100%)] w:-translate-y-12"
        >
          Scheibe ist verfügbar
        </p>
      }
      <div class="flex flex-col items-center d:items-start">
        <div class="flex max-w-[375px] flex-col items-center gap-6 d:max-w-full d:flex-row">
          <cg-icon class="h-12 w-12" [content]="likeIcon"></cg-icon>
          <div class="flex flex-col gap-2">
            <cg-olb-headline [type]="'h2'" [title]="'carIdentified.headline' | transloco: { brand }"></cg-olb-headline>
            <div class="flex items-center justify-center gap-[8px] d:justify-start">
              <p
                class="after:content['/0000a0'] !m-0 max-w-[207px] overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 text-[13px] leading-5 after:inline-block after:w-0 d:max-w-[255px]"
                data-cy="car-identified-car-name"
                data-cs-mask
              >
                {{ car }}
              </p>
              <cg-ctalink
                class="[&>div]:h-fit"
                [content]="changeCarLink"
                (clickedFn)="changeCar($event)"
                data-cy="car-identified-change-car-link"
              ></cg-ctalink>
            </div>
          </div>
        </div>
        <div class="mt-6 flex max-w-[375px] flex-col gap-6 d:mt-10 d:max-w-full d:flex-row d:gap-8">
          @for (element of infoList; track element) {
            <div class="flex flex-1 items-center gap-4">
              <cg-icon class="h-12 w-12" [content]="element.icon"></cg-icon>
              <cg-paragraph class="part-[text]:x-[text-[13px],leading-5]" [content]="element.paragraph"></cg-paragraph>
            </div>
          }
        </div>
        <cg-olb-forward
          class="mt-6 flex d:mt-10 [&>button]:flex-auto"
          (click)="goForward(); hideAvailabilityFlag()"
          [content]="forwardBtnContent"
          data-cy="car-identified-forward-button"
        ></cg-olb-forward>
      </div>
    </div>
  </ng-template>

  <cg-split-view [master]="master" [showBranding]="false" [overflowVisible]="true"></cg-split-view>
</section>
