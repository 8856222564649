import { CtalinkVariation } from "@cg/core/ui";
import type { Ctalink } from "@cg/shared";

export const changeCarLink: Ctalink = {
  link: {
    text: "carIdentified.link",
    title: "carIdentified.link",
    href: "",
    routerLink: false
  },
  id: "component-change-identified-car",
  ngTemplate: "cgCtalink",
  variation: CtalinkVariation.PRIMARY
};
