<section class="license-plate-container">
  <ng-template #master>
    <form class="license-plate-form" [formGroup]="form">
      <div class="max-width-container">
        <cg-lpn-part
          [titleKey]="'licensePlate.title'"
          [subtitleKey]="'licensePlate.subHeadline'"
          [isEditable]="isEditable"
        ></cg-lpn-part>

        <div class="row-title">
          <cg-radio-button-horizontal-group
            class="title"
            autocomplete="sex"
            formControlName="title"
            [content]="titleGroup"
          ></cg-radio-button-horizontal-group>
        </div>

        <div class="row">
          <cg-text-input
            class="input-firstname"
            autocomplete="given-name"
            formControlName="firstname"
            [content]="nameInputFields.firstnameInput"
          ></cg-text-input>
          <cg-text-input
            class="input-lastname"
            autocomplete="family-name"
            formControlName="lastname"
            [content]="nameInputFields.lastnameInput"
          ></cg-text-input>
        </div>

        <cg-damage-date-part></cg-damage-date-part>

        <cg-gdv-part></cg-gdv-part>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="split-view--footer"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="saveAndContinue()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
