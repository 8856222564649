<div class="flex flex-col gap-5 p-6">
  <div class="rounded-lg bg-cg-gray-light-80 p-6">
    <cg-olb-headline
      class="text-cg-anthracite part-[headline]:x-[!text-2xl,!mt-0]"
      type="h2"
      [title]="'newAppointment.appointmentDesktopDetail.title' | transloco"
    ></cg-olb-headline>

    <div class="flex gap-4">
      <div class="grid items-center gap-x-2">
        <cg-icon class="size-4" [content]="calendarIcon"></cg-icon>
        <cg-olb-headline
          class="text-cg-anthracite part-[headline]:x-[!mb-0,!text-lg]"
          type="h3"
          [title]="'newAppointment.appointmentDesktopDetail.date' | transloco"
        ></cg-olb-headline>
        <cg-paragraph class="col-start-2 pt-0 text-cg-anthracite"
          ><strong>{{ appointment.customerAppointmentStart | date: "EEEEEE" }}</strong>
          {{ appointment.customerAppointmentStart | date: "dd.MM" }}</cg-paragraph
        >
      </div>

      <div class="grid items-center gap-x-2">
        <cg-icon class="size-4" [content]="clockIcon"></cg-icon>
        <cg-olb-headline
          class="text-cg-anthracite part-[headline]:x-[!mb-0,!text-lg]"
          type="h3"
          [title]="'newAppointment.appointmentDesktopDetail.yourAppointment' | transloco"
        ></cg-olb-headline>
        <cg-paragraph class="col-start-2 pt-0 text-cg-anthracite"
          >{{ appointment.customerAppointmentStart | date: "HH:mm" }} -
          {{ appointment.customerAppointmentEnd | date: "HH:mm" }}</cg-paragraph
        >
      </div>

      <button
        type="button"
        cg-base-button
        class="ml-auto part-[icon-text-wrapper]:px-4"
        [btnText]="'newAppointment.appointmentDesktopDetail.buttonBooking' | transloco"
        (click)="bookAppointment()"
      ></button>
    </div>
  </div>

  <cg-appointment-time class="block px-6" [appointment]="appointment" [openingHour]="openHour"></cg-appointment-time>

  <div class="flex items-center gap-2 px-6">
    <cg-icon class="size-4" [content]="infoIcon"></cg-icon>
    <cg-car-bringing-schedule-text
      class="part-[text]:x-[!font-roboto,!text-[13px],!leading-5]"
    ></cg-car-bringing-schedule-text>
  </div>
</div>
