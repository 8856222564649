<section class="flex flex-col">
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        class="block"
        title="{{ 'vehicleIdentificationNumber.title' | transloco }}"
        type="h1"
      ></cg-olb-headline>

      <cg-vin-form
        class="block p-content-xs m:p-content-mobile t:p-content-tablet d:p-content-desktop w:p-content-widescreen fhd:p-content-fullhd"
      ></cg-vin-form>

      <cg-olb-headline [type]="'h3'" [title]="'vehicleIdentificationNumber.noFinTitle' | transloco"></cg-olb-headline>
    </form>

    <div class="mb-8 mt-4">
      <cg-ctalink
        class="mb-2 last-of-type:mb-0 part-[link-icon-wrapper]:h-fit"
        [content]="additionalOptions.firstCtaLink"
        (clickedFn)="handleCarNotIdentified($event)"
      ></cg-ctalink>
    </div>
    <div class="link-wrapper">
      <cg-link class="mb-2 block" [content]="additionalOptions.secondLink" (clickedFn)="onTrack('agb')"></cg-link>
      <cg-link [content]="additionalOptions.thirdLink" (clickedFn)="onTrack('privacy-statement')"></cg-link>
    </div>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
