import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import add from "date-fns/add";
import endOfDay from "date-fns/endOfDay";
import { Configuration } from "@cg/config";
import { ApiService } from "@cg/core/api";
import { EnvironmentConfiguration } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import {
  AppointmentPayload,
  AppointmentResponse,
  AppointmentServiceCenterPayload,
  AppointmentValidDesiredDate,
  EarliestPossibleAppointmentDateResponse,
  JobType,
  SetAppointmentPayload
} from "@cg/shared";

export const FIRST_APPOINTMENT_IN_MIN = 30;
@Injectable({
  providedIn: "root"
})
export class AppointmentService {
  public config: EnvironmentConfiguration = environment;

  public constructor(private apiService: ApiService) {}

  public getAppointments(payload: AppointmentPayload, spinnerMode?: SpinnerMode): Observable<AppointmentResponse> {
    const queryParams = new URLSearchParams("sortBy=aptDate");
    if (payload.aptModel) {
      queryParams.append("aptModel", payload.aptModel);
    }

    return this.apiService.post("appointment", `?${queryParams.toString()}`, payload, {
      spinnerMode: spinnerMode ?? SpinnerMode.ALWAYS
    });
  }

  public getServiceCenters(payload: AppointmentServiceCenterPayload): Observable<AppointmentResponse> {
    const queryParams = new URLSearchParams("aptPerDay=0&aptDays=0");
    if (payload.aptModel) {
      queryParams.append("aptModel", payload.aptModel);
    }

    return this.apiService.post("appointment", `?${queryParams.toString()}`, payload, {
      spinnerMode: SpinnerMode.ALWAYS
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public validateAppointmentDate(payload: AppointmentPayload): Observable<AppointmentValidDesiredDate> {
    return this.apiService.post("appointment", "/validDesiredAppointmentDate", payload);
  }

  public confirmAppointment(payload: SetAppointmentPayload): Observable<void> {
    return this.apiService.post("appointment", `/${payload.appointmentId}/confirmation`, payload);
  }

  public fetchEarliestPossibleAppointmentDate(): Observable<EarliestPossibleAppointmentDateResponse> {
    return this.apiService.get("appointment", "/desiredDate?locationType=INHOUSE");
  }

  public buildCalendarHref(data: { appointmentId: string }): string {
    const host = environment.api.host;
    const serviceConfiguration = Configuration.getServiceConfiguration("appointment");
    return host + serviceConfiguration.basePath + "/" + data.appointmentId + "/ics";
  }

  public buildGoogleMapsRouteHref(street: string, city: string, useOwnLocationAsStartPoint: boolean): string {
    const ownLocationAsStartPoint = useOwnLocationAsStartPoint ? "My+Location" : "";
    return `https://www.google.de/maps/dir/${ownLocationAsStartPoint}/${encodeURIComponent(street + ", " + city)}`;
  }

  /**
   * For a repair or replace job, the earliest timeRangeBegin should start with the current time, otherwise at the end of the day.
   */
  public getTimeRangeBeginFor(date: Date, jobType: JobType, initial: boolean = true): string {
    return (jobType === "REPAIR" || jobType === "REPLACE") && initial
      ? add(date, { minutes: FIRST_APPOINTMENT_IN_MIN }).toISOString()
      : endOfDay(date).toISOString();
  }

  public cancelAppointment(
    orderNumber: string,
    customerCaseId: string,
    cancelId: string,
    reason?: string
  ): Observable<void> {
    return this.apiService.post("frontendProxy", `/order/cancel/${customerCaseId}`, {
      text: reason,
      orderNumber,
      cancelId
    });
  }
}
