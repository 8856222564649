<section>
  <ng-template #master>
    <div class="appointment--container">
      <div class="appointment--container-left">
        <cg-branding></cg-branding>

        <cg-olb-headline
          class="appointment--title"
          type="h1"
          [title]="'opportunityFunnelAppointmentRequest.title' | transloco"
        ></cg-olb-headline>

        @if (form) {
          <form [formGroup]="form">
            <div class="appointment--search-wrapper">
              <cg-google-places-input
                class="appointment--search-input"
                [icon]="null"
                [doneIcon]="doneIcon"
                [content]="searchInput"
                (autocompleteResult)="handleAutocompleteResult($event)"
              ></cg-google-places-input>
              <button cg-base-button class="appointment--search-button" [icon]="searchIcon"></button>
            </div>
            <cg-options-selection
              data-cs-mask
              class="appointment--sc-select"
              [class.sc-selected]="!csIsNotSelected"
              [selectionId]="'appointment-sc-selection'"
              [placeholder]="'opportunityFunnelAppointmentRequest.serviceCenterPlaceholder' | transloco"
              [items]="scOptions"
              [isPlaceholderDisabled]="false"
              [formControlName]="'select'"
              (selectionChangedEmitter)="handleSelectionChanged($event)"
              [errorMessage]="'opportunityFunnelAppointmentRequest.serviceCenterError' | transloco"
              [showErrorIcon]="true"
            ></cg-options-selection>
            @if (!csIsNotSelected) {
              <cg-olb-headline
                class="appointment--calendar-title"
                type="h2"
                [title]="'opportunityFunnelAppointmentRequest.calendarTitle' | transloco"
              ></cg-olb-headline>
              <hr />
              <mat-calendar
                class="appointment--calendar"
                [selected]="form.controls.date.value"
                (selectedChange)="updateFormDate($event)"
                [headerComponent]="calendarHeader"
                [minDate]="minDate$ | async"
                [maxDate]="maxDate"
                [dateFilter]="noSundayFilter"
                [ngClass]="{ 'appointment--calendar-error': hasCalendarError, 'mb-4': !hasCalendarError }"
              ></mat-calendar>
              @if (hasCalendarError) {
                <cg-error-message
                  class="mb-4 mt-2"
                  [errorMessage]="'opportunityFunnelAppointmentRequest.calendarError' | transloco"
                ></cg-error-message>
              }
              <cg-radio-button-group
                class="appointment--radio-group"
                [content]="radioGroup"
                [formControlName]="radioGroup.controlName"
              ></cg-radio-button-group>
            }
            @if (form.valid) {
              <div class="mt-4 flex flex-col gap-2 t:mt-6">
                <hr />
                <button
                  cg-base-button
                  [btnText]="nextButtonText | transloco"
                  class="w-full part-[icon-text-wrapper]:px-4"
                  (click)="goForward()"
                  data-cy="btn-next"
                ></button>
                <button
                  cg-base-button
                  [btnText]="'opportunityFunnelAppointmentRequest.ctaCallbackTitle' | transloco"
                  class="appointment--confirmation-button w-full part-[icon-text-wrapper]:px-4"
                  (click)="goToChannelSwitch()"
                  [icon]="phoneIcon"
                  variation="secondary"
                  data-cy="btn-channel-switch"
                ></button>
              </div>
            }
          </form>
        }
      </div>
      <div class="appointment--container-right" #mapWrapper>
        <cg-google-maps
          data-cs-mask
          [useOwnStyle]="false"
          (markerClicked)="handleMarkerClicked($event)"
          [serviceCenterLocations]="serviceCenterLocations$ | async"
        ></cg-google-maps>
      </div>
    </div>
  </ng-template>

  <cg-split-view class="appointment--split-view" [master]="master" [showBranding]="false"></cg-split-view>
</section>
