import { Injectable } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { combineLatest, mergeMap, Observable, timer, withLatestFrom } from "rxjs";
import { delay, filter, map, take, tap } from "rxjs/operators";
import { SwitchChannelPayloadService } from "@cg/olb/services";
import {
  AppointmentFacade,
  ChannelSwitchFacade,
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  OpportunityFunnelFacade,
  ProcessActions,
  ProcessFacade
} from "@cg/olb/state";
import { SpinnerFacade } from "@cg/spinner";
import { TranslocoService } from "@jsverse/transloco";
import parseStringToDate from "date-fns/parse";
import setHours from "date-fns/setHours";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import {
  ChannelSwitchReason,
  ChannelSwitchTrackingReason,
  damageChipCountToGmCount,
  DamagedRearWindowCount,
  DamagedSideWindowCount,
  OpportunityFunnelSummaryExitIds
} from "@cg/olb/shared";
import {
  ChannelSwitchDamage,
  DamageChipCount,
  DamageWindow,
  Lpn,
  OpportunityFunnelAppointmentPeriod,
  OpportunityFunnelCallTime,
  ProcessId,
  RequiredService,
  SwitchChannelPayload
} from "@cg/shared";
import { ChannelSwitchTrackingService } from "../../channel-switch/services/channel-switch-tracking.service";

@Injectable({
  providedIn: "root"
})
export class OpportunityFunnelSummaryService {
  // eslint-disable-next-line max-params
  public constructor(
    private readonly appointmentFacade: AppointmentFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly damageFacade: DamageFacade,
    private readonly translocoService: TranslocoService,
    private readonly trackingService: TrackingService,
    private readonly actions$: Actions,
    private readonly processFacade: ProcessFacade,
    private readonly channelSwitchTrackingService: ChannelSwitchTrackingService,
    private readonly switchChannelPayloadService: SwitchChannelPayloadService,
    private readonly channelSwitchFacade: ChannelSwitchFacade,
    private readonly spinnerFacade: SpinnerFacade,
    private readonly opportunityFunnelFacade: OpportunityFunnelFacade,
    private readonly contactDataFacade: ContactDataFacade
  ) {}

  public getExitId$(): Observable<OpportunityFunnelSummaryExitIds> {
    return combineLatest([this.contactDataFacade.mobile$, this.channelSwitchFacade.opportunityFunnelCallTime$]).pipe(
      take(1),
      mergeMap(([mobile, callTime]: [string, OpportunityFunnelCallTime]) => {
        const needsCallback = callTime === OpportunityFunnelCallTime.NOW;

        this.opportunityFunnelFacade.setCallNow(needsCallback);

        if (needsCallback) {
          this.channelSwitchFacade.setChannelSwitchCheckbox(true);
          this.channelSwitchFacade.setPhoneNumber(mobile);

          return this.damageFacade.selectedDamage$.pipe(
            tap((selectedDamage: DamageWindow) => {
              this.processFacade.setChannelSwitchReason(this.getChannelSwitchReason(selectedDamage));
              this.triggerCallBack(mobile);
            }),
            map(() => "channelSwitch" as OpportunityFunnelSummaryExitIds)
          );
        } else {
          return combineLatest([
            this.damageFacade.damage$,
            this.damageFacade.lpn$,
            this.customerCaseFacade.customerCaseId$,
            this.damageFacade.selectedDamage$
          ]).pipe(
            take(1),
            tap(
              ([damageValue, lpn, customerCaseId, selectedDamage]: [
                {
                  id: string;
                  compromisedPart: DamageWindow;
                  requiredService: RequiredService;
                  damageChipCount: DamageChipCount;
                  damageDate: string;
                },
                Lpn,
                string,
                DamageWindow
              ]) => {
                const { id, compromisedPart, requiredService, damageChipCount, damageDate } = damageValue;
                const damage = {
                  id,
                  compromisedPart,
                  requiredService,
                  gmCount: damageChipCountToGmCount(damageChipCount),
                  damageDate
                };

                const payload = this.switchChannelPayloadService.buildSwitchChannelPayload({
                  customerCaseId,
                  reason: this.getChannelSwitchReason(selectedDamage),
                  phoneNumber: mobile,
                  damage,
                  lpnInputForm: lpn
                });

                this.customerCaseFacade.switchChannel(payload);
              }
            ),
            map(() => "default" as OpportunityFunnelSummaryExitIds)
          );
        }
      })
    );
  }

  public goForwardOfOpportunityFunnel(): void {
    combineLatest([
      this.appointmentFacade.desiredAppointmentId$,
      this.customerCaseFacade.customerCaseId$,
      this.appointmentFacade.selectedServiceCenterIds$,
      this.appointmentFacade.desiredAppointmentDate$,
      this.appointmentFacade.desiredPeriod$,
      this.damageFacade.damagedSideWindowCount$,
      this.damageFacade.damagedRearWindowCount$,
      this.damageFacade.selectedDamage$
    ])
      .pipe(take(1))
      .subscribe(
        ([
          desiredAppointmentId,
          customerCaseId,
          serviceCenterIds,
          desiredAppointmentDate,
          period,
          sideWindowCount,
          rearWindowCount,
          selectedDamage
        ]: [
          string,
          string,
          string[],
          string | Date,
          OpportunityFunnelAppointmentPeriod,
          DamagedSideWindowCount,
          DamagedRearWindowCount,
          DamageWindow
        ]) => {
          const parsedDate =
            desiredAppointmentDate instanceof Date
              ? desiredAppointmentDate
              : parseStringToDate(desiredAppointmentDate as unknown as string, "yyyy-MM-dd", new Date(Date.now()));

          let startDate: Date;
          let endDate: Date;
          let periodDescription: string;

          switch (period) {
            case OpportunityFunnelAppointmentPeriod.MORNING:
              startDate = setHours(parsedDate, 1);
              endDate = setHours(startDate, 13);
              periodDescription = this.translocoService.translate("summary.opportunityFunnel.period.morning");
              break;
            case OpportunityFunnelAppointmentPeriod.AFTERNOON:
              startDate = setHours(parsedDate, 13);
              endDate = setHours(startDate, 24);
              periodDescription = this.translocoService.translate("summary.opportunityFunnel.period.afternoon");
              break;
          }

          const damageCountTransKey = this.getDamageCountTransKey(selectedDamage, sideWindowCount, rearWindowCount);

          const damageCountDescription = this.translocoService.translate(damageCountTransKey);

          this.trackProvisionalBooking();

          return this.appointmentFacade.confirmAppointment({
            appointmentId: desiredAppointmentId,
            customerCaseId: customerCaseId,
            itemNumbers: ["1268200"],
            status: "DESIRED",
            jobType: "REPLACE",
            locationType: "INHOUSE",
            serviceCenter: serviceCenterIds[0],
            availabilityPeriodStart: startDate.toISOString(),
            availabilityPeriodEnd: endDate.toISOString(),
            customerAppointmentStart: startDate.toISOString(),
            customerAppointmentEnd: endDate.toISOString(),
            comment: `${damageCountDescription} ${periodDescription}`,
            source: "OLB"
          });
        }
      );
  }

  public getDamageDescTransKey(
    selectedDamage: DamageWindow,
    sideWindowCount: DamagedSideWindowCount,
    rearWindowCount: DamagedRearWindowCount
  ) {
    const start = "summary.opportunityFunnel.damageDescription";

    if (selectedDamage === DamageWindow.LEFT_SIDE) {
      switch (sideWindowCount) {
        case DamagedSideWindowCount.SINGLE:
          return `${start}.side`;
        case DamagedSideWindowCount.MULTIPLE:
          return `${start}.multipleSide`;
      }
    } else if (selectedDamage === DamageWindow.REAR) {
      switch (rearWindowCount) {
        case DamagedRearWindowCount.SINGLE:
          return `${start}.rear`;
        case DamagedRearWindowCount.MULTIPLE:
          return `${start}.multipleRear`;
      }
    }
  }

  public getCallTimeText$(): Observable<string> {
    return this.channelSwitchFacade.opportunityFunnelCallTime$.pipe(
      take(1),
      map((callTime: OpportunityFunnelCallTime) => {
        const key =
          callTime === OpportunityFunnelCallTime.LATER
            ? "summary.opportunityFunnel.callTime.later"
            : "summary.opportunityFunnel.callTime.now";
        return this.translocoService.translate(key);
      })
    );
  }

  private triggerCallBack(phoneNumber: string): void {
    this.switchChannelAndSyncWithEbs(phoneNumber);
    this.processFacade.enterChannelSwitch();
  }

  private switchChannelAndSyncWithEbs(phoneNumber: string): void {
    let reason: ChannelSwitchReason;

    this.channelSwitchTrackingService.trackChannelSwitch(ChannelSwitchTrackingReason.TAP_TO_CALLBACK);
    let customerCaseId: string;

    this.customerCaseFacade.customerCaseId$
      .pipe(
        withLatestFrom(this.getChannelSwitchDamage(), this.damageFacade.lpn$, this.getChannelSwitchReason$()),
        map(([caseId, damage, lpn, channelSwitchReason]: [string, ChannelSwitchDamage, Lpn, ChannelSwitchReason]) => {
          customerCaseId = caseId;
          reason = channelSwitchReason;
          return this.switchChannelPayloadService.buildSwitchChannelPayload({
            customerCaseId,
            reason,
            phoneNumber,
            damage: damage,
            lpnInputForm: lpn
          });
        }),
        tap((payload: SwitchChannelPayload) => {
          this.customerCaseFacade.switchChannel(payload);
        }),
        mergeMap(() => this.channelSwitchFacade.needFallback$),
        filter((needsFallback: boolean) => !needsFallback),
        // This could lead to memory-leaks but is required since the component is destroyed here.
        mergeMap(() => this.waitForConversationId(customerCaseId, reason, phoneNumber)),
        take(1)
      )
      .subscribe();
  }

  private waitForConversationId(customerCaseId: string, reason: ChannelSwitchReason, phoneNumber: string) {
    this.spinnerFacade.forceShowSpinner();
    // Order number aus EBS wird asynchron verarbeitet. Wir warten 10sec
    return timer(10000).pipe(
      tap(() => {
        this.spinnerFacade.hideForcedSpinner();

        this.channelSwitchFacade.getConversationId({
          id: customerCaseId,
          reason,
          phoneNumber
        });
      })
    );
  }

  private getChannelSwitchReason$() {
    return this.damageFacade.selectedDamage$.pipe(
      map((selectedDamage: DamageWindow) => this.getChannelSwitchReason(selectedDamage))
    );
  }

  private getChannelSwitchDamage() {
    return this.damageFacade.damage$.pipe(
      map(
        (damage: {
          id: string;
          compromisedPart: DamageWindow;
          requiredService: RequiredService;
          damageChipCount: DamageChipCount;
          damageDate: string;
        }) => {
          const { id, compromisedPart, requiredService, damageChipCount, damageDate } = damage;
          const channelSwitchDamage: ChannelSwitchDamage = {
            id,
            compromisedPart,
            requiredService,
            gmCount: damageChipCountToGmCount(damageChipCount),
            occurrenceDate: damageDate
          };
          return channelSwitchDamage;
        }
      )
    );
  }

  private getDamageCountTransKey(
    selectedDamage: DamageWindow,
    sideWindowCount: DamagedSideWindowCount,
    rearWindowCount: DamagedRearWindowCount
  ) {
    if (selectedDamage === DamageWindow.LEFT_SIDE) {
      switch (sideWindowCount) {
        case DamagedSideWindowCount.SINGLE:
          return "summary.opportunityFunnel.damageDescription.side";
        case DamagedSideWindowCount.MULTIPLE:
          return "summary.opportunityFunnel.damageDescription.multipleSide";
      }
    } else if (selectedDamage === DamageWindow.REAR) {
      switch (rearWindowCount) {
        case DamagedRearWindowCount.SINGLE:
          return "summary.opportunityFunnel.damageDescription.rear";
        case DamagedRearWindowCount.MULTIPLE:
          return "summary.opportunityFunnel.damageDescription.multipleRear";
      }
    } else if (selectedDamage === DamageWindow.FRONT) {
      return "summary.opportunityFunnel.damageDescription.front";
    }
  }

  private getChannelSwitchReason(selectedDamage: DamageWindow): ChannelSwitchReason {
    if (selectedDamage === DamageWindow.LEFT_SIDE) {
      return "SIDE_WINDOW";
    }

    if (selectedDamage === DamageWindow.REAR) {
      return "REAR_WINDOW";
    }

    if (selectedDamage === DamageWindow.FRONT) {
      return "PRODUCT_NOT_FOUND";
    }
  }

  private trackProvisionalBooking() {
    const trackBooking = () =>
      this.trackingService.trackEvent({
        eventAction: "provisional-booking-replace",
        eventLabel: "provisional-booking-confirmation"
      } as Partial<TrackingEvent>);

    // tracks provisional-booking-confirmation in case of later callTime
    this.actions$
      .pipe(
        ofType(ProcessActions.setCurrentProcess),
        filter(({ payload }: { payload: ProcessId }) => payload === "opportunity-funnel-success"),
        delay(200),
        take(1)
      )
      .subscribe(trackBooking);

    // tracks provisional-booking-confirmation in case of now callTime
    this.actions$.pipe(ofType(ProcessActions.channelSwitchEntered), take(1)).subscribe(trackBooking);
  }
}
