<cg-drawer
  class="[&>div]:scrollbar max-h-fill-available"
  [content]="drawerContent"
  [headline]="headline"
  [buttons]="buttons"
  (closedClicked)="clickedCancel()"
></cg-drawer>

<ng-template #headline>
  <cg-headline
    class="typo-title block first-child:font-roboto-condensed first-child:!text-[30px] first-child:!font-bold first-child:!leading-[40px]"
    [content]="'newAppointment.appointmentDetail.title' | transloco"
  ></cg-headline>
</ng-template>

<ng-template #drawerContent>
  <cg-paragraph
    class="mb-6 mt-0 block d:mt-6"
    [content]="{ text: serviceCenterText, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <div class="flex flex-row gap-6">
    <div>
      <cg-headline
        [type]="HeadlineType.h3"
        [content]="'newAppointment.appointmentDetail.texts.date' | transloco"
      ></cg-headline>
      <cg-paragraph [attr.data-cy]="'appointment-detail-start-date'">{{
        appointment?.customerAppointmentStart | date: "dd.MM.yyyy"
      }}</cg-paragraph>
    </div>
    <div>
      <cg-headline
        [type]="HeadlineType.h3"
        [content]="'newAppointment.appointmentDetail.texts.appointment' | transloco"
      ></cg-headline>
      <cg-paragraph
        >{{ appointment?.customerAppointmentStart | date: "HH:mm" }}
        {{ "newAppointment.appointmentDetail.texts.clock" | transloco }}</cg-paragraph
      >
    </div>
  </div>

  <div class="py-8">
    @if (content?.picture) {
      <cg-picture [content]="content.picture" [shouldTranslateAlt]="true"></cg-picture>
    }
    @if (isReplaceWithoutAdas) {
      <cg-paragraph class="mb-2 block">
        {{ "newAppointment.appointmentDetail.texts.flexiblePickUpTimes" | transloco }}
      </cg-paragraph>
      <cg-appointment-time
        class="block w-full"
        [appointment]="appointment"
        [openingHour]="openingHour"
      ></cg-appointment-time>
    }
  </div>

  @if (content?.explaination) {
    <div class="pb-8">
      <cg-paragraph [content]="{ text: content.explaination | transloco, ngTemplate: 'cgParagraph' }"></cg-paragraph>
    </div>
  }

  <div class="flex">
    <cg-car-bringing-schedule-text></cg-car-bringing-schedule-text>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="flex w-full flex-col justify-between gap-2 t:w-fit t:flex-row t:gap-4">
    <button
      cg-base-button
      class="w-full part-[text]:whitespace-nowrap part-[icon-text-wrapper]:px-4"
      [btnText]="'newAppointment.appointmentDetail.buttons.ok' | transloco"
      (click)="clickedOk($event)"
    ></button>
  </div>
</ng-template>
