import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { DamageFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { costsIcon, insuranceIcon, successIcon } from "@cg/icon";
import { OlbFooterComponent } from "@cg/olb/shared";
import { RequiredService } from "@cg/shared";
import { OVERLAYFLOW_COMPONENTS } from "@cg/core/enums";
import { InsuranceMessageGridItem } from "../../interfaces/insurance-message-grid-form.interface";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { InsuranceBaseMessageComponent } from "../insurance-base-message/insurance-base-message.component";
import { InsuranceMessageNavigation } from "../interfaces/insurance-message-navigation.interface";

@Component({
  selector: "cg-insurance-covered-message",
  templateUrl: "./insurance-covered-message.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InsuranceBaseMessageComponent, OlbFooterComponent, TranslocoPipe]
})
export class InsuranceCoveredMessageComponent
  extends InsuranceBaseMessageComponent<InsuranceTypeForm>
  implements OnInit, InsuranceMessageNavigation
{
  public readonly successIcon: Icon = successIcon;

  public isRepair = false;

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly damageFacade: DamageFacade
  ) {
    super();
  }

  public ngOnInit() {
    this.selectRequiredService();
  }

  public selectRequiredService(): void {
    this.damageFacade.requiredService$.pipe(take(1)).subscribe((requiredService: RequiredService) => {
      if (requiredService === RequiredService.REPAIR) {
        this.isRepair = true;
      }
    });
  }

  public onClosedClicked(): void {
    this.form.controls.overlayClose.setValue(true);
    this.form.controls.insuranceType.setValue(null);
  }

  public onGoBackClicked(): void {
    this.form.controls.nextOverlay.setValue(OVERLAYFLOW_COMPONENTS.INSURANCE_TYPE_SELECTION);
    this.form.controls.insuranceType.setValue(null);
  }

  public onGoForwardClicked() {
    this.form.controls.nextOverlay.setValue(OVERLAYFLOW_COMPONENTS.INSURANCE_SELECTION);
  }

  public get gridItems(): InsuranceMessageGridItem[] {
    return [
      {
        icon: costsIcon,
        paragraph: {
          text: this.translocoService.translate(
            this.isRepair ? "insuranceCoveredMessage.items.text3" : "insuranceCoveredMessage.items.text1"
          ),
          ngTemplate: "cgParagraph"
        },
        flexItemCssClass: "t:items-start items-center"
      },
      {
        icon: insuranceIcon,
        paragraph: {
          text: this.translocoService.translate("insuranceCoveredMessage.items.text2"),
          ngTemplate: "cgParagraph"
        },
        flexItemCssClass: "items-center t:items-start"
      }
    ];
  }
}
