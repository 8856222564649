<section class="license-plate-container">
  <ng-template #master>
    <form class="license-plate-form" [formGroup]="form">
      <div class="max-width-container">
        <cg-lpn-part [titleKey]="'licensePlateNoDateNoGdv.title'" [isEditable]="isEditable"></cg-lpn-part>

        <cg-olb-headline [type]="'h3'" [title]="'licensePlateNoDateNoGdv.why.headline' | transloco"></cg-olb-headline>

        <cg-paragraph
          class="why-paragraph"
          [content]="{ text: 'licensePlateNoDateNoGdv.why.text' | transloco }"
        ></cg-paragraph>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="split-view--footer"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="saveAndContinue()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
