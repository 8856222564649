import { AsyncPipe, DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { Appointment, AppointmentData, AvailableServiceCenters, OpeningHour, RequiredService } from "@cg/shared";
import { getOpeningHour } from "../../utils/opening-hour.util";
import { AppointmentTimeComponent } from "../appointment-time/appointment-time.component";
import { CarBringingScheduleTextTextComponent } from "../car-bringing-schedule-text/car-bringing-schedule-text.component";
import { AppointmentDetailContentInterface } from "./interfaces/appointment-detail-content.interface";
import { repair } from "./models/repair.model";
import { replaceWithAdas } from "./models/replace-with-adas.model";
import { replaceWithoutAdas } from "./models/replace-without-adas.model";

@Component({
  selector: "cg-appointment-detail",
  templateUrl: "./appointment-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    AsyncPipe,
    TranslocoPipe,
    HeadlineComponent,
    ParagraphComponent,
    PictureComponent,
    AppointmentTimeComponent,
    CarBringingScheduleTextTextComponent
  ]
})
export class AppointmentDetailComponent implements OnInit {
  public destroyRef = inject(DestroyRef);

  public readonly HeadlineType = HeadlineType;

  @Input()
  public appointmentData: AppointmentData;

  public appointment: Appointment;
  public requiredService: RequiredService;
  public serviceCenterText = "";
  public openingHour: OpeningHour;

  public startTimes: {
    customerAppointmentFrom: string;
    serviceCenterOpenFrom: string;
  } = null;

  public isStartTimeSameAsCustomerAppointment = false;
  public isCalibration = false;
  public isRepair = false;
  public isReplaceWithAdas = false;
  public isReplaceWithoutAdas = false;
  public isReplaceWithoutAdasWithBreak = false;

  public content: AppointmentDetailContentInterface;

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    combineLatest([
      this.appointmentData.appointmentId$,
      this.appointmentData.availableAppointments$,
      this.appointmentData.selectedServiceCenter$,
      this.appointmentData.isCalibration$,
      this.appointmentData.requiredService$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([appointmentId, appointments, selectedServiceCenter, isCalibration, requiredService]: [
          string,
          Appointment[],
          AvailableServiceCenters,
          boolean,
          RequiredService
        ]) => {
          this.appointment = appointments?.find((app: Appointment) => app.appointmentId === appointmentId) ?? null;

          if (!this.appointment) {
            return;
          }

          this.requiredService = requiredService;
          this.isCalibration = isCalibration;

          this.openingHour = getOpeningHour(this.appointment, selectedServiceCenter);
          this.initServiceType();
          this.initModel();
          this.initServiceCenterText();

          this.cdr.markForCheck(); // required due to OnPush
          this.cdr.detectChanges(); // run changeDetection for previous marked change
        }
      );
  }

  public initServiceType(): void {
    this.isRepair = this.requiredService === RequiredService.REPAIR;
    this.isReplaceWithAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === true;
    this.isReplaceWithoutAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === false;
  }

  public initModel(): void {
    if (this.isRepair) {
      this.content = repair;
    } else if (this.isReplaceWithAdas) {
      this.content = replaceWithAdas;
    } else if (this.isReplaceWithoutAdas) {
      this.content = replaceWithoutAdas;
    } else {
      throw new Error("Detail view could not be initialized. This should never happen.");
    }
  }

  public initServiceCenterText(): void {
    if (!this.appointment) {
      this.serviceCenterText = "";
    } else {
      this.serviceCenterText = `<strong>Carglass<sup>®</sup>-Service-Center</strong><br>${this.appointment.street}<br>${this.appointment.postalCode} ${this.appointment.city}`;
    }
  }
}
