<cg-olb-headline class="channel-switch--headline" [title]="'Entschuldigung'"></cg-olb-headline>
<p class="channel-switch--summary">{{ "channelSwitch.callFailure.text" | transloco }}</p>
<div class="channel-switch--failure-call-back">
  <cg-cta
    class="channel-switch--failure-call-back-btn mt-6 block"
    (clickedFn)="callCallCenterInCaseOfError()"
    [translateContent]="true"
    [content]="callCenterAgent"
  ></cg-cta>
</div>
