<cg-drawer
  class="[&>div]:scrollbar"
  [content]="content"
  [headline]="headline"
  [showCloseIcon]="true"
  [fullscreen]="true"
></cg-drawer>

<ng-template #headline>
  <cg-olb-headline
    class="[&>h1]:px-1"
    [title]="'newAppointment.allScAppointmentsDialog.title' | transloco: { location: location }"
    [type]="headlineType.h1"
  ></cg-olb-headline>
</ng-template>

<ng-template #content>
  @for (item of appointmentsPerWeek; track item; let first = $first; let last = $last) {
    <cg-new-appointment-all-sc-appointments-week
      [isInitiallyExpanded]="first"
      [isLastItem]="last"
      [item]="item"
      [isDisplayedInAllScDialog]="true"
    ></cg-new-appointment-all-sc-appointments-week>
  }
</ng-template>
