import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { DrawerComponent } from "@cg/shared";

@Component({
  selector: "cg-gdv-info",
  templateUrl: "./gdv-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, DrawerComponent, OlbHeadlineComponent, ParagraphComponent]
})
export class GdvInfoComponent {}
