import { Component } from "@angular/core";
import { TranslocoModule } from "@jsverse/transloco";
import { HeadlineComponent, IconComponent } from "@cg/core/ui";
import { successIcon } from "@cg/icon";
import {
  BaseButtonComponent,
  DialogDisplayType,
  DialogSuccessComponent,
  DrawerComponent,
  OverlayService
} from "@cg/shared";

@Component({
  selector: "cg-remove-wipers-success",
  standalone: true,
  imports: [
    DrawerComponent,
    BaseButtonComponent,
    TranslocoModule,
    IconComponent,
    HeadlineComponent,
    DialogSuccessComponent
  ],
  templateUrl: "./remove-wipers-success.component.html"
})
export class RemoveWipersSuccessComponent {
  public readonly calendarCancel = successIcon;
  public readonly titleKey = "wiperOffer.wipersRemoveProduct.dialog.success.title";
  public readonly descriptionKey = "wiperOffer.wipersRemoveProduct.dialog.success.description";
  public readonly buttonTextKey = "wiperOffer.wipersRemoveProduct.dialog.success.cancelButtonText";

  protected readonly DialogDisplayType = DialogDisplayType;

  public constructor(protected overlayService: OverlayService) {}

  public close(): void {
    this.overlayService.close();
  }
}
