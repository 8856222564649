<section>
  <ng-template #master>
    <cg-switchable [featureKey]="featureKey">
      <cg-new-customer-address [processMetadata]="processMetadata" *cgFeatureSwitch></cg-new-customer-address>
      <cg-customer-address [processMetadata]="processMetadata" *cgFeatureSwitchAlternative></cg-customer-address>
    </cg-switchable>
  </ng-template>

  <cg-split-view
    class="[&>div>div]:p-0"
    [master]="master"
    [showBackground]="false"
    [showBranding]="false"
  ></cg-split-view>
</section>
