import { AsyncPipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { TranslocoPipe } from "@jsverse/transloco";
import { AddFormControls } from "@cg/core/types";
import { IconComponent, PLACEHOLDER } from "@cg/core/ui";
import { accordionIconDown, filterIcon, pinIcon } from "@cg/icon";
import {
  AppointmentData,
  AvailableServiceCenters,
  OlbSearchForScInputComponent,
  OptionSelectionItem,
  OptionsSelectionComponent,
  ServiceCenterSelectionLabelComponent
} from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { CarBringingScheduleTextTextComponent } from "../car-bringing-schedule-text/car-bringing-schedule-text.component";
import { AppointmentFilterForm } from "./interfaces/appointment-filter-form.interface";

@Component({
  selector: "cg-appointment-filter",
  templateUrl: "./appointment-filter.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    IconComponent,
    OptionsSelectionComponent,
    ServiceCenterSelectionLabelComponent,
    OlbSearchForScInputComponent,
    CarBringingScheduleTextTextComponent
  ]
})
export class AppointmentFilterComponent implements OnInit, AfterViewInit {
  @Input() public isFormTouchedProgrammatically: boolean;
  @Input() public appointmentData: AppointmentData;

  @Output() public filterChanged = new EventEmitter();

  @ViewChild(OlbSearchForScInputComponent)
  public olbSearchForScInputComponent: OlbSearchForScInputComponent;

  public destroyRef = inject(DestroyRef);
  public expanded = false;
  public availableServiceCenters: AvailableServiceCenters[];
  public options: OptionSelectionItem[] = [];
  public form: FormGroup<AddFormControls<AppointmentFilterForm>>;

  public pinIcon: Icon = pinIcon;
  public filterIcon: Icon = filterIcon;
  public arrowIcon: Icon = accordionIconDown;

  public get address$(): Observable<string> {
    return this.appointmentData.formattedAddress$;
  }
  public get serviceCenterCount(): number {
    return this.availableServiceCenters?.length ?? 0;
  }

  public get countSelectedServiceCenter(): number {
    const value = this.form.controls.scId.value;
    return !!value && value !== PLACEHOLDER ? 1 : 0;
  }

  public constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.form = new FormGroup<AddFormControls<AppointmentFilterForm>>({
      scId: new UntypedFormControl(""),
      searchServiceCenterInput: new UntypedFormControl("")
    });

    this.expandFilter();
    this.setFormValues();
    this.createScSelection();
  }

  public ngAfterViewInit() {
    if (
      !!this.olbSearchForScInputComponent &&
      !!this.form.controls.searchServiceCenterInput.value /* &&
      this.isFormTouchedProgrammatically*/
    ) {
      this.olbSearchForScInputComponent.getPlacePredictions();
    }
  }

  public setFormValues(): void {
    combineLatest([
      this.appointmentData.formattedAddress$,
      this.appointmentData.selectedServiceCenterIds$,
      this.appointmentData.availableServiceCenters$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([searchServiceCenterInput, scIds, availableServiceCenters]: [string, string[], AvailableServiceCenters[]]) => {
          this.appointmentData.setAppointmentId(null);

          this.filterChanged?.emit();

          this.form.controls.searchServiceCenterInput.setValue(searchServiceCenterInput);
          if (availableServiceCenters?.length > 0) {
            this.form.controls.scId.setValue(scIds?.[0] ?? null);
          }
          this.cdr.detectChanges();
        }
      );
  }

  public expandFilter() {
    if (this.appointmentData.isScSelectDisabled) {
      return;
    }
    this.expanded = true;
    this.cdr.markForCheck();
  }

  public collapseFilter() {
    this.expanded = false;
    this.cdr.markForCheck();
  }

  public scFilterChanged(serviceCenterId: string) {
    if (serviceCenterId) {
      this.appointmentData.setSelectedServiceCenterIds([serviceCenterId]);
      this.appointmentData.clearAndRefetchAppointments(serviceCenterId);
      this.collapseFilter();
    }
  }

  private createScSelection() {
    this.appointmentData.availableServiceCenters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((availableServiceCenters: AvailableServiceCenters[]) => {
        this.availableServiceCenters = availableServiceCenters;

        this.options = [];
        this.availableServiceCenters?.forEach((availableServiceCenter: AvailableServiceCenters, index: number) => {
          this.options.push({
            id: `available-sc-${index}`,
            text: `${availableServiceCenter.street}, ${availableServiceCenter.postalCode} ${availableServiceCenter.city}`,
            value: availableServiceCenter.serviceCenter,
            disabled: false
          });
        });

        this.cdr.markForCheck();
      });
  }
}
