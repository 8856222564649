import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { GoogleMapsService } from "@cg/core/services";
import { AddFormControls } from "@cg/core/types";
import { ABTest } from "@cg/core/utils";
import { clearIconWhite } from "@cg/icon";
import { AppointmentData, AppointmentSearchForm, OlbSearchForScInputComponent } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { NewAppointmentFilterComponent } from "../new-appointment-filter/new-appointment-filter.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-search",
  templateUrl: "./new-appointment-search.component.html",
  styleUrls: ["./new-appointment-search.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe, ReactiveFormsModule, NewAppointmentFilterComponent, OlbSearchForScInputComponent]
})
export class NewAppointmentSearchComponent implements OnInit {
  @Input()
  public appointmentData: AppointmentData;

  @Input()
  public skipFormWithExitId: (exitId: string) => void;

  @Output()
  public searchClicked = new EventEmitter();

  public destroyRef = inject(DestroyRef);
  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;
  public isInitialState$: Observable<boolean>;
  public clearIcon = clearIconWhite;

  public constructor(
    private userCentricsService: UserCentricsService,
    private parent: FormGroupDirective
  ) {}

  public get searchInput(): string {
    return this.form.controls.searchServiceCenterInput.value;
  }

  public get isFormTouchedProgrammatically(): boolean {
    return this.form.pristine && !this.form.dirty;
  }

  public ngOnInit(): void {
    this.form = this.parent.form as FormGroup<AddFormControls<AppointmentSearchForm>>;
    this.appointmentData.setStatus("FIXED");

    this.userCentricsService.activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps);
    this.initDisplayMode();

    this.setFormValues();
  }

  public setFormValues(): void {
    this.appointmentData.formattedAddress$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((formattedAddress: string) => !!formattedAddress),
        distinctUntilChanged()
      )
      .subscribe((formattedAddress: string) => {
        const oldValue = this.form.controls.searchServiceCenterInput.value;

        if (oldValue && oldValue !== formattedAddress) {
          this.form.markAsUntouched();
          this.appointmentData.resetStateForForm();
        }

        this.form.controls.searchServiceCenterInput.setValue(formattedAddress);
        this.form.markAsPristine({ onlySelf: true });
      });

    this.appointmentData.appointmentId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter((id: string) => this.form.controls.selectedAppointmentId.value !== id)
      )
      .subscribe((appointmentId: string) => {
        this.form.controls.selectedAppointmentId.setValue(appointmentId);
        this.form.markAsPristine({ onlySelf: true });
      });
  }

  public filterHasChanged() {
    this.form.markAsUntouched();
  }

  public search() {
    this.searchClicked.emit();
  }

  private initDisplayMode() {
    this.isInitialState$ = combineLatest([
      this.appointmentData.isLoading$,
      this.appointmentData.formattedAddress$
    ]).pipe(
      map(([isLoading, formattedAddress]: [boolean, string]) => !isLoading && !formattedAddress),
      startWith(true)
    );
  }
}
