<cg-olb-headline [type]="'h2'" [title]="'licensePlate.damageOfDate.title' | transloco"></cg-olb-headline>

<div class="damage-date-input" [formGroup]="form">
  <cg-date-input
    formControlName="date"
    [id]="'license-plate-damage-date'"
    [minDate]="minDate"
    [maxDate]="maxDate"
  ></cg-date-input>
  @if (form.controls.date.touched && form.controls.date.invalid) {
    <div class="error-message">
      <cg-error-message class="error-message" [errorMessage]="'licensePlate.damageOfDate.errorMessage' | transloco">
      </cg-error-message>
    </div>
  }
</div>
