import { AsyncPipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Type,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { filter, tap, withLatestFrom } from "rxjs/operators";
import { PurecloudCallBackService } from "@cg/olb/services";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { TrackingService } from "@cg/analytics";
import { isOpportunityFunnel } from "@cg/core/utils";
import { OLB_PROCESS_FLOW_MODEL, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { ProcessId, ProcessMetadata, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { ChannelSwitchFactoryResolverService } from "./services/channel-switch-factory-resolver.service";

@Component({
  selector: "cg-channel-switch",
  templateUrl: "./channel-switch.component.html",
  styleUrls: ["./channel-switch.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, SplitViewComponent],
  providers: [PurecloudCallBackService]
})
export class ChannelSwitchComponent extends BaseDirective<unknown> implements AfterViewInit {
  @ViewChild("channelSwitchTemplate", { static: false, read: ViewContainerRef })
  public slot: ViewContainerRef;

  public destroyRef = inject(DestroyRef);

  // eslint-disable-next-line max-params
  public constructor(
    public readonly channelSwitchFacade: ChannelSwitchFacade,
    private readonly channelSwitchFactoryResolverService: ChannelSwitchFactoryResolverService,
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public getExitIdForSavedForm(): Observable<string> | undefined {
    return undefined;
  }

  public saveForm(): void {}

  public async ngAfterViewInit(): Promise<void> {
    this.initComponentToRender();
  }

  private initComponentToRender() {
    this.channelSwitchFacade.isCCCOpen();
    this.channelSwitchFacade.getEstimatedWaitingTime();

    this.channelSwitchFactoryResolverService
      .getComponentToRender()
      .pipe(
        filter((component: Type<unknown>) => !!component),
        withLatestFrom(this.processFacade.processMetaData$),
        tap(async ([component, processMetaData]: [Type<unknown>, ProcessMetadata[]]) => {
          if (this.slot.length > 0) {
            this.slot.clear();
          }
          this.slot.createComponent(component);

          const tileId: ProcessId = !isOpportunityFunnel(processMetaData)
            ? "channel-switch"
            : "opportunity-funnel-channel-switch";
          this.scrollService.scrollToTileId(tileId);

          this.cdr.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
