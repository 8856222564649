import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ABTest } from "@cg/core/utils";
import { OptimizelyExperiment } from "@cg/core/enums";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-circle",
  templateUrl: "./new-appointment-circle.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NewAppointmentCircleComponent {
  @Input()
  public selected: boolean;
}
