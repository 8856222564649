import { DatePipe, NgClass, NgTemplateOutlet, SlicePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { firstValueFrom } from "rxjs";
import { AppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { getDay, getHours, getISOWeek, getISOWeekYear, getMinutes, isSameDay } from "date-fns";
import { getOpeningHour } from "@cg/appointment-ui";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { accordionIconUp, busyScIcon } from "@cg/icon";
import { StaticMapComponent } from "@cg/olb/shared";
import {
  Appointment,
  AvailableServiceCenters,
  BreakpointService,
  ExpansionPanelComponent,
  OpeningHour,
  OverlayService,
  Tab,
  TabsComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ShortAppointment } from "../../interfaces/short-appointment.interface";
import { NewAppointmentTileService } from "../../services/new-appointment-tile.service";
import { NewAppointmentAllScAppointmentsDialogComponent } from "../new-appointment-all-sc-appointments-dialog/new-appointment-all-sc-appointments-dialog.component";
import { NewAppointmentSelectionGridComponent } from "../new-appointment-all-sc-appointments-week-item/new-appointment-selection-grid.component";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDesktopDetailComponent } from "../new-appointment-desktop-detail/new-appointment-desktop-detail.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";
import { NewAppointmentOneAppointmentViewComponent } from "../new-appointment-one-appointment-view/new-appointment-one-appointment-view.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-select-card",
  templateUrl: "./new-appointment-select-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    SlicePipe,
    NgTemplateOutlet,
    TranslocoPipe,
    ExpansionPanelComponent,
    IconComponent,
    TabsComponent,
    NewAppointmentOneAppointmentViewComponent,
    NewAppointmentSelectionGridComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent,
    NewAppointmentDesktopDetailComponent,
    ParagraphComponent,
    StaticMapComponent
  ]
})
export class NewAppointmentSelectCardComponent implements OnInit, AfterViewInit {
  @Input()
  public serviceCenter: AvailableServiceCenters;

  @Input()
  public expanded = false;

  @Input()
  public limitDate: Date;

  @Output()
  public accordionToggled: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChildren("weekTab")
  public weekTab: QueryList<TemplateRef<unknown>>;

  public appointmentsPerWeek: Appointment[][] = [];

  public firstWeekTitle: string;

  public busyScIcon: Icon = busyScIcon;
  public accordionIconUp: Icon = accordionIconUp;

  public tabs: Tab[] = [];

  public firstAppointment: ShortAppointment;

  public maxHeight: string;

  public hasOneAppointment: boolean;
  public oneAppointment: ShortAppointment;
  public maxAppointsPerDay: number;
  public selectedAppointmentId: string;
  public selectedAppointment: Appointment;
  public openingHour: OpeningHour;
  public isMobile: boolean;

  private hasAppointments = false;
  private allAppointments: Appointment[];
  private destroyRef = inject(DestroyRef);

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly overlayService: OverlayService,
    private readonly newAppointmentTitleService: NewAppointmentTileService,
    private readonly breakpointService: BreakpointService,
    private readonly appointmentFacade: AppointmentFacade
  ) {}

  @Input()
  public set appointments(appointments: Appointment[]) {
    this.hasAppointments = appointments.length !== 0;

    if (!this.hasAppointments) {
      return;
    }

    this.firstAppointment = this.createShortAppointment(appointments[0]);
    this.appointmentsPerWeek = this.deduplicateAppointmentsByTime(this.sortByIsoWeek(appointments));
    this.maxAppointsPerDay = this.calcMaxItemPerDay();

    const gridItemHeight = 60;
    const otherElementsHeight = 210;
    const detailHeight = 400;
    this.maxHeight = this.maxAppointsPerDay * gridItemHeight + otherElementsHeight + detailHeight + "px";
    this.firstWeekTitle = this.getTabTitle(this.appointmentsPerWeek[0][0]);

    this.allAppointments = appointments;
    this.hasOneAppointment = appointments.length === 1;

    if (this.hasOneAppointment) {
      this.oneAppointment = this.createShortAppointment(this.allAppointments[0]);
    }
  }

  public ngOnInit(): void {
    this.breakpointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit(): void {
    this.initTabs();
  }

  public emitAccordionToggle(): void {
    this.accordionToggled.emit();
  }

  public openAllAppointments(selectedSc: AvailableServiceCenters): void {
    if (!this.isMobile) {
      return;
    }

    this.overlayService.open(NewAppointmentAllScAppointmentsDialogComponent, {
      selectedSc: selectedSc,
      availableAppointments: this.allAppointments
    });
  }

  public getToday() {
    return new Date();
  }

  private initTabs(): void {
    this.tabs = [];

    if (!this.hasAppointments) {
      return;
    }

    this.tabs = this.isMobile
      ? [{ header: this.firstWeekTitle, template: this.weekTab.get(0) }]
      : this.appointmentsPerWeek.map((appointments: Appointment[], index: number) => {
          const firstAppointment = appointments[0];
          const title = this.getTabTitle(firstAppointment);
          return { header: title, template: this.weekTab.get(index) };
        });

    this.cdr.detectChanges();
  }

  public async selectedAppointmentIdChanged(appointmentId: string): Promise<void> {
    if (this.selectedAppointmentId === appointmentId) {
      this.deselectAppointment();
      this.appointmentFacade.setAppointmentId(null);
      return;
    }

    this.selectedAppointmentId = appointmentId;
    this.appointmentFacade.setAppointmentId(appointmentId);
    this.selectedAppointment = this.allAppointments.find(
      (appointment: Appointment) => appointment.appointmentId === this.selectedAppointmentId
    );

    const availableServiceCenters = await firstValueFrom(this.appointmentFacade.availableServiceCenters$);
    const selectedServiceCenter = availableServiceCenters.find(
      (serviceCenter: AvailableServiceCenters) => serviceCenter.serviceCenter === this.selectedAppointment.serviceCenter
    );

    this.openingHour = getOpeningHour(this.selectedAppointment, selectedServiceCenter);
  }

  public deselectAppointment(): void {
    this.selectedAppointmentId = null;
    this.selectedAppointment = null;
    this.openingHour = null;
  }

  private getTabTitle(firstAppointmentOfWeek: Appointment): string {
    return this.newAppointmentTitleService.getWeekTitle(firstAppointmentOfWeek);
  }

  private sortByIsoWeek(appointments: Appointment[]): Appointment[][] {
    const sortedAppointments = appointments
      .map((appointment: Appointment) => {
        const obj: Record<number, Appointment[]> = {};
        const startDate = new Date(appointment.customerAppointmentStart);
        const year = getISOWeekYear(startDate);
        const weekTwoDigits = getISOWeek(startDate).toString().padStart(2, "0");
        const weekPerYear = `${year}${weekTwoDigits}`;
        obj[weekPerYear] = [appointment];

        return obj;
      })
      .reduce((prev: Record<number, Appointment[]>, curr: Record<number, Appointment[]>) => {
        const weekPerYear = Object.keys(curr)[0];
        if (prev[weekPerYear]) {
          prev[weekPerYear] = [...prev[weekPerYear], ...curr[weekPerYear]];
        } else {
          prev[weekPerYear] = curr[weekPerYear];
        }
        return prev;
      });

    return Object.values(sortedAppointments);
  }

  private createShortAppointment(appointment: Appointment): ShortAppointment {
    return {
      id: appointment.appointmentId,
      day: new Date(appointment.customerAppointmentStart),
      start: new Date(appointment.customerAppointmentStart),
      end: new Date(appointment.customerAppointmentEnd)
    };
  }

  private deduplicateAppointmentsByTime(appointmentsPerDay: Appointment[][]): Appointment[][] {
    return appointmentsPerDay.map((appointments: Appointment[]) =>
      appointments.reduce((previousValue: Appointment[], currentValue: Appointment) => {
        const currCustomerAppointmentStart = new Date(currentValue.customerAppointmentStart);
        const currCustomerAppointmentEnd = new Date(currentValue.customerAppointmentEnd);

        const isDuplicate = previousValue.some((appointment: Appointment) => {
          const appointmentStart = new Date(appointment.customerAppointmentStart);
          const appointmentEnd = new Date(appointment.customerAppointmentEnd);

          return (
            isSameDay(appointmentStart, currCustomerAppointmentStart) &&
            getHours(appointmentStart) === getHours(currCustomerAppointmentStart) &&
            getMinutes(appointmentStart) === getMinutes(currCustomerAppointmentStart) &&
            getHours(appointmentEnd) === getHours(currCustomerAppointmentEnd) &&
            getMinutes(appointmentEnd) === getMinutes(currCustomerAppointmentEnd)
          );
        });

        if (!isDuplicate) {
          previousValue.push(currentValue);
        }

        return previousValue;
      }, [] as Appointment[])
    );
  }

  private calcMaxItemPerDay() {
    let maxAppointsPerWeeks = 1;
    for (const week of this.appointmentsPerWeek) {
      const dayMap = new Map<number, number>();

      for (const app of week) {
        const dayOfWeek = getDay(new Date(app.customerAppointmentStart));

        if (dayMap.has(dayOfWeek)) {
          const count = dayMap.get(dayOfWeek);
          dayMap.set(dayOfWeek, count + 1);

          if (maxAppointsPerWeeks < count + 1) {
            maxAppointsPerWeeks = count + 1;
          }
        } else {
          dayMap.set(dayOfWeek, 1);
        }
      }
    }

    return maxAppointsPerWeeks;
  }
}
