import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { homeIcon } from "@cg/icon";
import { AvailableServiceCenters, SafeContentPipe } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-multi-select",
  templateUrl: "./new-appointment-multi-select.component.html",
  styleUrls: ["./new-appointment-multi-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewAppointmentMultiSelectComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SafeContentPipe,
    TranslocoPipe,
    IconComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent
  ]
})
export class NewAppointmentMultiSelectComponent implements ControlValueAccessor {
  @Input()
  public items: AvailableServiceCenters[];

  public homeIcon = homeIcon;

  private selectedItems: string[] = [];

  private onChange: (value: string[]) => void;
  private onTouched: () => void;

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public writeValue(obj: string[]): void {
    this.selectedItems = obj ? obj : [];

    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public handleSelectItem({ serviceCenter }: AvailableServiceCenters) {
    if (!this.isItemSelected(serviceCenter)) {
      this.selectedItems = [...this.selectedItems, serviceCenter];
    } else {
      this.selectedItems = this.selectedItems.filter((item: string) => item !== serviceCenter);
    }

    this.onChange(this.selectedItems);
    this.onTouched();
  }

  public handleSelectAll(): void {
    this.selectedItems = !this.isAllSelected()
      ? this.items.map((item: AvailableServiceCenters) => item.serviceCenter)
      : [];

    this.onChange(this.selectedItems);
    this.onTouched();
  }

  public isItemSelected(serviceCenter: string): boolean {
    return this.selectedItems.includes(serviceCenter);
  }

  public isAllSelected() {
    return this.selectedItems.length === this.items.length;
  }
}
