import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroupDirective, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { DamageAssessmentContent } from "../../interfaces/damage-assessment-content";
import { DamageAssessmentSelectionComponent } from "./damage-assessment-selection/damage-assessment-selection.component";

@Component({
  selector: "cg-damage-assessment-tile",
  templateUrl: "./damage-assessment-tile.component.html",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, DamageAssessmentSelectionComponent]
})
export class DamageAssessmentTileComponent implements OnInit {
  @Input() public content: DamageAssessmentContent;
  @Input() public preselectValue: string;
  @Input() public disable$: EventEmitter<null>;

  @Output()
  public readyToGoForward: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(DamageAssessmentSelectionComponent) public damageAssessment: DamageAssessmentSelectionComponent;

  public destroyRef = inject(DestroyRef);
  public optionForWhichAnimationIsDone$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public form: UntypedFormGroup;

  public constructor(
    private readonly parent: FormGroupDirective,
    private readonly cdr: ChangeDetectorRef
  ) {}

  private get controlValueChanges$(): Observable<string> {
    return this.form.get(this.content.controlName).valueChanges.pipe(
      distinctUntilChanged(),
      tap((_: string) => {
        this.form.updateValueAndValidity({ emitEvent: false });
        this.cdr.markForCheck();
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public ngOnInit() {
    this.form = this.parent?.form;
    this.emitOnValueChanges();

    if (this.disable$) {
      this.disable$.subscribe(() => this.setDisabledState());
    }
  }

  public onAnimationDone(selectedOption: string): void {
    this.optionForWhichAnimationIsDone$.next(selectedOption);
  }

  public setDisabledState(): void {
    this.damageAssessment.setDisabledState(true);
  }

  private emitOnValueChanges(): void {
    combineLatest([this.controlValueChanges$, this.optionForWhichAnimationIsDone$])
      .pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef))
      .subscribe(([changes, optionForWhichAnimationIsDone]: [string, string]) => {
        if (changes === optionForWhichAnimationIsDone) {
          this.optionForWhichAnimationIsDone$.next("");
          this.readyToGoForward.emit();
        }
      });
  }
}
