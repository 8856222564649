<div class="flex flex-col items-center pb-8 pt-6 text-center t:pb-10 t:pt-8 w:pt-0">
  <cg-branding class="w-full [&>div]:mx-auto"></cg-branding>
  <cg-olb-headline
    data-cy="easiness-of-process-title"
    class="max-w-[278px] t:max-w-full"
    [title]="title$ | async"
  ></cg-olb-headline>
  <cg-paragraph
    class="max-w-[296px] m:max-w-[312px] t:max-w-[338px] d:max-w-[512px]"
    [content]="{
      text: subTitle$ | async,
      ngTemplate: 'cgParagraph'
    }"
  >
  </cg-paragraph>
</div>
