<section class="license-plate-container">
  <ng-template #master>
    <form class="license-plate-form" [formGroup]="form">
      <div class="max-width-container">
        <cg-lpn-part
          [titleKey]="'licensePlate.title'"
          [subtitleKey]="'licensePlate.subHeadline'"
          [isEditable]="isEditable"
        ></cg-lpn-part>

        <cg-gdv-part></cg-gdv-part>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="split-view--footer"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="saveAndContinue()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
