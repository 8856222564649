import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { replaceIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { DamageWindow, SplitViewComponent } from "@cg/shared";

@Component({
  selector: "cg-opportunity-funnel-damage-assessment-result",
  templateUrl: "./opportunity-funnel-damage-assessment-result.component.html",
  styleUrls: ["./opportunity-funnel-damage-assessment-result.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, SplitViewComponent, IconComponent, OlbHeadlineComponent]
})
export class OpportunityFunnelDamageAssessmentResultComponent {
  public readonly replaceIcon: Icon = replaceIcon;
  public readonly damageWindow = DamageWindow;

  public selectedDamage$: Observable<DamageWindow>;

  public constructor(private readonly damageFacade: DamageFacade) {
    this.selectedDamage$ = this.damageFacade.selectedDamage$;
  }
}
