import { environment } from "@cg/environments";
import type { AppointmentDetailContentInterface } from "../interfaces/appointment-detail-content.interface";

export const replaceWithAdas: AppointmentDetailContentInterface = {
  explaination: "appointment.appointmentDetail.texts.replaceWithAdas.explaination",
  picture: {
    alt: "appointment.appointmentDetail.texts.replaceWithAdas.picture.alt",
    sizes: [],
    source: `${environment.assetPath}/olb/appointment/images/img_replace.png`
  }
};
