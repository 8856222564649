import { CtalinkVariation } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import type { LinkWithId } from "@cg/core/ui";
import type { Ctalink } from "@cg/shared";

const icon: Icon = arrowsIcon;

export const additionalOptions: { firstCtaLink: Ctalink; secondLink: LinkWithId; thirdLink: LinkWithId } = {
  firstCtaLink: {
    id: "vin-call-us",
    icon,
    link: {
      href: "",
      title: "vehicleIdentificationNumber.ctas.callUs",
      text: "vehicleIdentificationNumber.ctas.callUs"
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  },
  secondLink: {
    id: "vin-agb",
    href: "https://www.carglass.de/agb",
    title: "vehicleIdentificationNumber.ctas.agb",
    text: "vehicleIdentificationNumber.ctas.agb",
    target: "_blank"
  },
  thirdLink: {
    id: "vin-datenschutz",
    href: "https://www.carglass.de/datenschutz",
    title: "vehicleIdentificationNumber.ctas.privacy",
    text: "vehicleIdentificationNumber.ctas.privacy",
    target: "_blank"
  }
};
