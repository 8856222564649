<cg-olb-headline
  [title]="'newAppointment.mobileServiceView.title' | transloco"
  [type]="headlineType.h3"
></cg-olb-headline>

<cg-cta-two-lines
  data-cy="mobile-service"
  subtitle="newAppointment.mobileServiceView.ctas.mobileServiceSubtitle"
  title="newAppointment.mobileServiceView.ctas.mobileServiceTitle"
  class="mt-4 block overflow-hidden rounded border border-cg-gray-light-80 shadow-card [&>div>cg-icon>mat-icon>svg>g>path]:fill-cg-anthracite [&>div>div>cg-icon>mat-icon>svg>g>g>g>g>g>g>path]:stroke-cg-anthracite [&>div>div>div>cg-paragraph>p]:text-cg-anthracite [&>div]:bg-white"
  [icon]="serviceIcon"
  (click)="mobileServiceClicked($event)"
>
</cg-cta-two-lines>

<div class="mt-4 flex flex-col items-center">
  <cg-icon class="my-4 h-12 w-12" [content]="phoneIcon"></cg-icon>

  <div class="text-center text-lg font-bold">
    <cg-paragraph
      class="font-roboto-condensed text-cg-anthracite"
      [innerHTML]="'newAppointment.mobileServiceView.questions.title' | transloco | safeContent"
    ></cg-paragraph>
  </div>
  <span
    class="mt-1 block text-[13px] text-cg-anthracite-light-30"
    [innerHTML]="
      'newAppointment.mobileServiceView.phone' | transloco: { phone: PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE }
    "
    (click)="callLinkClicked($event)"
  ></span>
</div>
