import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { HeadlineComponent, HeadlineType, ParagraphComponent } from "@cg/core/ui";

@Component({
  selector: "cg-appointment-adverse-buy-info",
  templateUrl: "./appointment-adverse-buy-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ParagraphComponent, HeadlineComponent]
})
export class AppointmentAdverseBuyInfoComponent implements OnInit {
  public readonly headlineType = HeadlineType;

  public constructor(private trackingService: TrackingService) {}

  public ngOnInit() {
    this.trackingService.trackEvent({
      event: "ga-event",
      eventCategory: "olb",
      eventAction: "appointment",
      eventLabel: "security-concerns-icon-click"
    });
  }
}
