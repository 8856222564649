import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import subMonths from "date-fns/subMonths";
import { AddFormControls } from "@cg/core/types";
import { environment } from "@cg/environments";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, DateInputComponent, ErrorMessageComponent } from "@cg/shared";

@Component({
  selector: "cg-damage-date-part",
  templateUrl: "./damage-date-part.component.html",
  styleUrls: ["./damage-date-part.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DateInputComponent, ErrorMessageComponent]
})
export class DamageDatePartComponent implements OnInit {
  public form: FormGroup<AddFormControls<{ date: string }>>;
  public minDate: Date;
  public maxDate: Date;

  private destroyRef = inject(DestroyRef);

  public constructor(
    private readonly formGroup: FormGroupDirective,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.form = this.formGroup.form;

    this.maxDate = new Date(Date.now());
    this.minDate = subMonths(this.maxDate, environment.features.minDamageDate);

    this.changeDetectionService.changeDetectionRequest$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
