import { parseISO } from "date-fns";
import type { AdvancedSchedulerWeekDay, OldSchedulerWeekDay } from "@cg/core/types";
import type { Appointment, AvailableServiceCenters, OpeningHour } from "@cg/shared";

function convertNumberToDayOldScheduler(day: number): string {
  // comes from api so not translated here
  const oldSchedulerWeekDays: OldSchedulerWeekDay[] = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"
  ];

  return oldSchedulerWeekDays[day];
}
function convertNumberToDayAdvancedScheduler(day: number): string {
  const advancedSchedulerWeekDays: AdvancedSchedulerWeekDay[] = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY"
  ];

  return advancedSchedulerWeekDays[day];
}

function getOpeningHoursForDate(date: Date, serviceCenter: AvailableServiceCenters): OpeningHour {
  return serviceCenter.openingHours
    ? serviceCenter.openingHours.find(
        (openingHour: OpeningHour) =>
          openingHour.day === convertNumberToDayOldScheduler(date.getDay()) ||
          openingHour.day === convertNumberToDayAdvancedScheduler(date.getDay())
      )
    : null;
}

export function openingHoursStripTrailingMinutes(hours: OpeningHour): OpeningHour {
  const hoursCopy = { ...hours };

  for (const key in hoursCopy) {
    if (key !== "day" && hoursCopy[key]?.length > 5) {
      hoursCopy[key] = hoursCopy[key].slice(0, 5);
    }
  }

  return hoursCopy;
}

export function getOpeningHour(appointment: Appointment, serviceCenter: AvailableServiceCenters): OpeningHour {
  if (!serviceCenter) {
    return;
  }

  const date = parseISO(appointment.customerAppointmentStart);

  const openingHour = { ...getOpeningHoursForDate(date, serviceCenter) };
  return openingHoursStripTrailingMinutes(openingHour);
}
