import type { TextInput } from "@cg/shared";

export interface VinQueryInputs {
  vinInput: TextInput;
  checksumInput: TextInput;
  personalData: TextInput[];
}

export const inputFields: VinQueryInputs = {
  vinInput: {
    id: "vin",
    controlName: "vin",
    placeholder: "vehicleIdentificationNumber.vin.vinInput.placeholder",
    errorMessage: "vehicleIdentificationNumber.vin.vinInput.errorMessage"
  },
  checksumInput: {
    id: "vin-checksum",
    controlName: "checksum",
    placeholder: "vehicleIdentificationNumber.vin.checksumInput.placeholder",
    errorMessage: "vehicleIdentificationNumber.vin.checksumInput.errorMessage"
  },
  personalData: [
    {
      id: "name",
      controlName: "name",
      placeholder: "vehicleIdentificationNumber-AB.vinScan.personalData.input.name.placeholder",
      errorMessage: "vehicleIdentificationNumber-AB.vinScan.personalData.input.name.error"
    },
    {
      id: "vorname",
      controlName: "vorname",
      placeholder: "vehicleIdentificationNumber-AB.vinScan.personalData.input.firstName.placeholder",
      errorMessage: "vehicleIdentificationNumber-AB.vinScan.personalData.input.firstName.error"
    },
    {
      id: "strasse",
      controlName: "strasse",
      placeholder: "vehicleIdentificationNumber-AB.vinScan.personalData.input.street.placeholder",
      errorMessage: "vehicleIdentificationNumber-AB.vinScan.personalData.input.street.error"
    },
    {
      id: "plz",
      controlName: "plz",
      placeholder: "vehicleIdentificationNumber-AB.vinScan.personalData.input.zip.placeholder",
      errorMessage: "vehicleIdentificationNumber-AB.vinScan.personalData.input.zip.error"
    },
    {
      id: "ort",
      controlName: "ort",
      placeholder: "vehicleIdentificationNumber-AB.vinScan.personalData.input.city.placeholder",
      errorMessage: "vehicleIdentificationNumber-AB.vinScan.personalData.input.city.error"
    }
  ]
};
