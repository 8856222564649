import { damageTypeChip2Icon, damageTypeChip3Icon, damageTypeChip3OrMoreIcon } from "@cg/icon";
import { DamageChipCount } from "@cg/shared";
import type { DamageAssessmentContent } from "../../../interfaces/damage-assessment-content";

export const damageChipCountTileContent: DamageAssessmentContent = {
  controlName: "damageChipCount",
  buttons: [
    {
      title: "damageChipCount.buttons.two",
      picture: damageTypeChip2Icon,
      radio: {
        id: "damage-chip-count-two",
        value: DamageChipCount.TWO
      }
    },
    {
      title: "damageChipCount.buttons.three",
      picture: damageTypeChip3Icon,
      radio: {
        id: "damage-chip-count-three",
        value: DamageChipCount.THREE
      }
    },
    {
      title: "damageChipCount.buttons.moreThanThree",
      picture: damageTypeChip3OrMoreIcon,
      radio: {
        id: "damage-chip-count-more-than-three",
        value: DamageChipCount.MORE_THAN_THREE
      }
    }
  ]
};
