<cg-headline
  [content]="'appointmentAdverseBuyInfoText.details.texts.safetyTitle' | transloco"
  [type]="headlineType.h2"
></cg-headline>
<cg-paragraph
  [content]="{
    text: 'appointmentAdverseBuyInfoText.details.texts.safety' | transloco,
    ngTemplate: 'cgParagraph'
  }"
>
</cg-paragraph
><br />

<cg-headline
  [content]="'appointmentAdverseBuyInfoText.details.texts.temperatureDifferencesTitle' | transloco"
  [type]="headlineType.h2"
></cg-headline>
<cg-paragraph
  [content]="{
    text: 'appointmentAdverseBuyInfoText.details.texts.temperatureDifferences' | transloco,
    ngTemplate: 'cgParagraph'
  }"
>
</cg-paragraph
><br />

<cg-headline
  [content]="'appointmentAdverseBuyInfoText.details.texts.policeCheckTitle' | transloco"
  [type]="headlineType.h2"
></cg-headline>
<cg-paragraph
  [content]="{
    text: 'appointmentAdverseBuyInfoText.details.texts.policeCheck' | transloco,
    ngTemplate: 'cgParagraph'
  }"
>
</cg-paragraph>
