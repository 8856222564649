<section class="contact--container">
  <cg-split-view [master]="master" [showBranding]="true"></cg-split-view>
</section>

<ng-template #master>
  <cg-olb-headline
    class="contact--headline"
    [title]="'opportunityFunnelCustomerAddress.title' | transloco"
    [subHeadline]="'opportunityFunnelCustomerAddress.subTitle' | transloco"
  ></cg-olb-headline>

  <form class="contact" [formGroup]="form" data-cy="customer-contact-form">
    <div class="contact--title">
      <cg-radio-button-horizontal-group
        data-cs-mask
        class="title"
        [content]="titleGroup"
        [formControlName]="titleGroup.controlName"
      ></cg-radio-button-horizontal-group>
    </div>

    <div class="contact--customer-data">
      <cg-text-input
        [content]="firstnameInput"
        autocomplete="given-name"
        [formControlName]="firstnameInput.controlName"
      ></cg-text-input>
      <cg-text-input
        [content]="lastnameInput"
        autocomplete="family-name"
        [formControlName]="lastnameInput.controlName"
      ></cg-text-input>
      <cg-text-input
        class="phone-input"
        [content]="phoneInput"
        [formControlName]="phoneInput.controlName"
        [specificInputType]="InputType.NUMBERS_PHONE"
      ></cg-text-input>
      <cg-text-input
        class="email-input"
        [content]="emailInput"
        [formControlName]="emailInput.controlName"
        [specificInputType]="InputType.E_MAIL"
      ></cg-text-input>
    </div>

    <button
      cg-base-button
      [btnText]="'opportunityFunnelCustomerAddress.ctaTitle' | transloco"
      class="mt-4 w-full part-[icon-text-wrapper]:px-4 t:mt-6 t:w-fit"
      (click)="goForward()"
      data-cy="btn-next"
    ></button>
  </form>
</ng-template>
