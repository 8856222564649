<section class="flex flex-col" data-cy="damage-assessment-result">
  <cg-split-view [master]="master" [showBranding]="false"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>

<ng-template #master>
  <div class="flex h-full w-full flex-col flex-wrap items-start gap-4 d:gap-6">
    <div class="flex">
      <cg-icon class="h-12 w-12 self-start" [content]="successIcon"></cg-icon>
      <div class="ml-3">
        <cg-olb-headline
          class="[&>*]:!m-0"
          [title]="
            ((requiredServiceIsReplace$ | async)
              ? 'damageAssessmentResult.titleReplace'
              : 'damageAssessmentResult.titleRepair'
            ) | transloco
          "
          [subHeadline]="'damageAssessmentResult.subTitle' | transloco"
        ></cg-olb-headline>
      </div>
    </div>
    <cg-faq
      [data]="(requiredServiceIsReplace$ | async) ? faqCrack : faqChip"
      [additionalTrackingInformation]="{
        damage: { 'job-type': (requiredServiceIsReplace$ | async) ? 'replace' : 'repair' }
      }"
    ></cg-faq>
  </div>
</ng-template>
