<cg-toast-popup
  class="[&>div>div]:bg-white [&>div]:left-0 [&>div]:top-0 [&>div]:translate-x-0"
  [hasRoundBorders]="true"
  [animationYOffset]="'0%'"
  (closeClicked)="handleCloseClicked()"
>
  <div class="flex items-center gap-4">
    <cg-icon class="h-8 w-8" [content]="icon"></cg-icon>
    <cg-paragraph [content]="paragraph"></cg-paragraph>
  </div>
</cg-toast-popup>
