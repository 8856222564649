import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { infoYellowIcon } from "@cg/icon";
import { ToastPopupComponent } from "@cg/shared";
import type { Paragraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-appointment-filter-static-toast",
  templateUrl: "./appointment-filter-static-toast.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ParagraphComponent, IconComponent, ToastPopupComponent]
})
export class AppointmentFilterStaticToastComponent {
  public readonly icon = infoYellowIcon;

  public get paragraph(): Paragraph {
    return {
      text: this.translocoService.translate("editAppointment.toast.message"),
      ngTemplate: "cgParagraph"
    };
  }

  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  public constructor(private readonly translocoService: TranslocoService) {}

  public handleCloseClicked() {
    this.closeClicked.emit();
  }
}
