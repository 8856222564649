import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { Link } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { LinkComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import {
  ComponentOverarchingChangeDetectionService,
  ErrorMessageComponent,
  RadioButtonGroupComponent
} from "@cg/shared";
import { GdvPartForm } from "./interfaces/gdv-part-form.interface";
import { additionalOptions } from "./models/additional-options.model";
import { licensePlateAutomaticDataTransmission } from "./models/license-plate.model";

@Component({
  selector: "cg-gdv-part",
  templateUrl: "./gdv-part.component.html",
  styleUrls: ["./gdv-part.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    RadioButtonGroupComponent,
    ErrorMessageComponent,
    LinkComponent
  ]
})
export class GdvPartComponent implements OnInit {
  public form: FormGroup<AddFormControls<GdvPartForm>>;
  public automaticDataTransmition = licensePlateAutomaticDataTransmission;
  public additionalOptions: { firstLink: Link } = additionalOptions;

  private destroyRef = inject(DestroyRef);

  public constructor(
    private readonly formGroup: FormGroupDirective,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.form = this.formGroup.form;

    this.changeDetectionService.changeDetectionRequest$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
