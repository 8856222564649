import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { DamageFacade, InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { cccSmallIcon, huk24PartnerLogo, hukPartnerLogo, serviceIcon, successIcon } from "@cg/icon";
import { Insurance, InsuranceNumber, OlbFooterComponent } from "@cg/olb/shared";
import { OptionSelectionItem, RequiredService } from "@cg/shared";
import { OVERLAYFLOW_COMPONENTS } from "@cg/core/enums";
import { InsuranceMessageGridItem } from "../../interfaces/insurance-message-grid-form.interface";
import { InsuranceTypeForm } from "../../interfaces/insurance-type-form.interface";
import { InsuranceBaseMessageComponent } from "../insurance-base-message/insurance-base-message.component";
import { InsuranceMessageNavigation } from "../interfaces/insurance-message-navigation.interface";

@Component({
  selector: "cg-insurance-partner-message",
  templateUrl: "./insurance-partner-message.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, InsuranceBaseMessageComponent, IconComponent, OlbFooterComponent]
})
export class InsurancePartnerMessageComponent
  extends InsuranceBaseMessageComponent<InsuranceTypeForm>
  implements OnInit, OnDestroy, InsuranceMessageNavigation
{
  public readonly successIcon: Icon = successIcon;

  public readonly hukIcon: Icon = hukPartnerLogo;
  public readonly huk24Icon: Icon = huk24PartnerLogo;

  public insurances$: Observable<OptionSelectionItem[]>;
  public insuranceName: string;
  public isPartner: boolean;
  public isRepair = false;
  public isHukInsurance = false;

  private onDestroy$ = new Subject<void>();

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly damageFacade: DamageFacade,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit() {
    if (this.form.controls.insurance.value === "-1") {
      this.form.controls.lastOverlayShown.setValue(true);
    }

    this.selectedInsurance$()
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe((insurance: Insurance) => {
        this.insuranceName = insurance.name;
        this.isHukInsurance = insurance.number === InsuranceNumber.HUK;
        this.isPartner = insurance.partner;
        this.cdr.markForCheck();
      });

    this.selectRequiredService();
  }

  public ngOnDestroy() {
    this.onDestroy$.next();
  }

  public selectedInsurance$(): Observable<Insurance> {
    return this.insuranceFacade.insurances$.pipe(
      map((insurances: Insurance[]) =>
        insurances.find((insurance: Insurance) => insurance.id === this.form.controls.insurance.value)
      )
    );
  }

  public selectRequiredService(): void {
    this.damageFacade.requiredService$
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe((requiredService: RequiredService) => {
        if (requiredService === RequiredService.REPAIR) {
          this.isRepair = true;
        }
      });
  }

  public onClosedClicked(): void {
    this.form.controls.overlayClose.setValue(true);
    this.form.controls.insuranceType.setValue(null);
  }

  public get title() {
    const translationKey = this.isPartner
      ? "insurancePartnerMessage.headline"
      : "insurancePartnerMessage.headlineOptional";
    const params = this.isPartner ? { insuranceName: this.insuranceName } : {};
    return this.translocoService.translate(translationKey, params);
  }

  public get gridItems(): InsuranceMessageGridItem[] {
    return [
      {
        icon: cccSmallIcon,
        paragraph: {
          text: this.translocoService.translate("insurancePartnerMessage.items.text1"),
          ngTemplate: "cgParagraph"
        },
        flexItemCssClass: "t:items-start items-center"
      },
      {
        icon: serviceIcon,
        paragraph: {
          text: this.translocoService.translate(
            this.isRepair ? "insurancePartnerMessage.items.text3" : "insurancePartnerMessage.items.text2"
          ),
          ngTemplate: "cgParagraph"
        },
        flexItemCssClass: "items-center t:items-start"
      }
    ];
  }

  public onGoBackClicked(): void {
    this.form.controls.insurance.setValue(null);
    this.form.controls.nextOverlay.setValue(OVERLAYFLOW_COMPONENTS.INSURANCE_SELECTION);
  }

  public onGoForwardClicked() {
    this.form.controls.lastOverlayShown.setValue(true);
  }
}
