import { AsyncPipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  pairwise,
  startWith,
  take,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { DamageFacade, GdvFacade, InsuranceFacade, ProcessFacade } from "@cg/olb/state";
import { SpinnerFacade } from "@cg/spinner";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingInsuranceType, TrackingService } from "@cg/analytics";
import { slideUpAnimation, slideUpAnimationDone, STATE_HIDE, STATE_SHOW } from "@cg/animation";
import { AddFormControls } from "@cg/core/types";
import { FakeDropdownComponent, IconComponent } from "@cg/core/ui";
import {
  ChatInsuranceType,
  ExitIdService,
  Insurance,
  InsuranceNumber,
  InsuranceSelectionExitIds,
  InsuranceType,
  InsuranceUseCase,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  ErrorMessageComponent,
  eventRequiredServiceMapping,
  OverlayService,
  ProcessId,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import type { InsuranceTypeForm } from "./interfaces/insurance-type-form.interface";
import { OVERLAY_POSITION } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { InsuranceOverlayWrapperComponent } from "./components/insurance-overlay-wrapper/insurance-overlay-wrapper.component";
import { InsuranceTypeDetailComponent } from "./components/insurance-type-detail/insurance-type-detail.component";

@Component({
  selector: "cg-insurance-type",
  templateUrl: "./insurance-type.component.html",
  styleUrls: ["./insurance-type.component.scss"],
  animations: [slideUpAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    OlbHeadlineComponent,
    ErrorMessageComponent,
    IconComponent,
    InsuranceTypeDetailComponent,
    SplitViewComponent,
    OlbFooterComponent,
    FakeDropdownComponent
  ]
})
export class InsuranceTypeComponent
  extends BaseDirective<AddFormControls<InsuranceTypeForm>>
  implements OnInit, AfterViewInit
{
  public slideUpAnimationDone = slideUpAnimationDone;
  public slideUpAfterSpinnerGone$ = this.spinnerFacade.visible$.pipe(
    withLatestFrom(this.processFacade.currentProcessId$),
    map(([visible]: [boolean, ProcessId]) => (visible ? STATE_HIDE : STATE_SHOW)),
    distinctUntilChanged()
  );
  public goForwardButtonClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild("detail", { read: TemplateRef, static: true }) public detail;
  public selectedInsuranceType = "";
  private eventInsuranceTypeMapping = new Map<InsuranceType, TrackingInsuranceType>([
    [InsuranceType.FULLY, "comprehensive-or-partial-coverage-insurance"],
    [InsuranceType.LIABILITY, "liability-insurance"],
    [InsuranceType.NONE, "uninsured"],
    [InsuranceType.UNKNOWN, "unknown"]
  ]);
  private insuranceUseCase?: InsuranceUseCase;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly damageFacade: DamageFacade,
    protected readonly trackingService: TrackingService,
    private readonly spinnerFacade: SpinnerFacade,
    private readonly insuranceTypeExitIdService: ExitIdService,
    private readonly overlayService: OverlayService,
    private readonly gdvFacade: GdvFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get insuranceType(): InsuranceType {
    return this.form.controls.insuranceType.value;
  }

  public get selectedInsurance(): string {
    return this.form.controls.insurance.value;
  }

  public get requiredService$(): Observable<RequiredService> {
    return this.damageFacade.requiredService$;
  }

  public async ngOnInit() {
    this.trackRequiredService();
    await super.ngOnInit();

    this.form.controls.insurance.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((selected: string) => this.insuranceFacade.getInsuranceUseCaseById(selected))
      )
      .subscribe((useCase: InsuranceUseCase) => (this.insuranceUseCase = useCase));
  }

  public onClicked(overlayType: string) {
    this.overlayService.open(
      InsuranceOverlayWrapperComponent,
      {
        form: this.form,
        overlayType,
        close: (value: InsuranceTypeForm) => {
          if (value) {
            this.form.patchValue(value);
          }
          this.cdr.markForCheck();
          this.overlayService.close();
          if (this.form.controls.lastOverlayShown.value) {
            this.goForwardBtnClicked();
          }
        }
      },
      { position: OVERLAY_POSITION.CENTER }
    );
  }

  public selectedInsuranceName$(): Observable<string> {
    return this.insuranceFacade.insurances$.pipe(
      map((insurances: Insurance[]) => {
        const c = insurances.find((insurance: Insurance) => insurance.id === this.form.controls.insurance.value);
        return c ? c.name : "";
      })
    );
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<InsuranceTypeForm>>(
      {
        insuranceType: new UntypedFormControl("", Validators.required),
        insurance: new UntypedFormControl({ value: null, disabled: false }),
        nextOverlay: new UntypedFormControl({ value: null, disabled: false }),
        lastOverlayShown: new UntypedFormControl({ value: false, disabled: false }),
        overlayClose: new UntypedFormControl({ value: false, disabled: false })
      },
      this.validateInsuranceSelection
    );

    this.initInsuranceSelection();
  }

  public goForwardBtnClicked(): void {
    const insuranceType = this.insuranceType;

    if (insuranceType === InsuranceType.PAID_BY_PERPETRATOR) {
      this.handleClickDamageIsPayedByDamagingParty();
      return;
    }

    if (insuranceType === InsuranceType.NONE) {
      this.handleClickCarIsNotInsured();
      return;
    }

    super.goForward();
  }

  public setFormValues(): void {
    this.insuranceFacade.loadInsurances();

    this.form.controls.insuranceType.valueChanges
      .pipe(startWith(null as string), pairwise(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([oldValue, newValue]: [string, string]) => {
        if (
          (oldValue !== InsuranceType.FULLY && newValue === InsuranceType.FULLY) ||
          newValue === InsuranceType.UNKNOWN ||
          newValue === InsuranceType.NONE
        ) {
          this.form.controls.insurance.setValue(null);
        }
      });

    combineLatest([this.insuranceFacade.type$, this.insuranceFacade.selectedInsurance$])
      .pipe(
        filter(
          ([insuranceType, selectedInsurance]: [InsuranceType, Insurance]) =>
            !!insuranceType && !!selectedInsurance && insuranceType !== InsuranceType.LIABILITY
        ),
        map(([insuranceType, _]: [InsuranceType, Insurance]) => insuranceType),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((insuranceType: InsuranceType) => {
        this.form.controls.insuranceType.setValue(insuranceType);
        this.cdr.markForCheck();
      });

    this.insuranceFacade.selectedInsurance$
      .pipe(
        withLatestFrom(this.insuranceFacade.type$),
        filter(
          ([selectedInsurance, insuranceType]: [Insurance, InsuranceType]) =>
            !!selectedInsurance &&
            selectedInsurance.number !== InsuranceNumber.ANDERE &&
            insuranceType !== InsuranceType.UNKNOWN &&
            insuranceType !== InsuranceType.NONE
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([selectedInsurance]: [Insurance, InsuranceType]) => {
        this.form.controls.insurance.setValue(selectedInsurance.id);
        this.cdr.markForCheck();
      });
  }

  public getExitIdForSavedForm(): Observable<InsuranceSelectionExitIds> {
    this.trackInsuranceType();

    return combineLatest([this.requiredService$, this.insuranceFacade.selectedInsurance$]).pipe(
      filter(([requiredService, selectedInsurance]: [RequiredService, Insurance]) => {
        if ([InsuranceType.NONE, InsuranceType.UNKNOWN].includes(this.insuranceType)) {
          return true;
        }

        if (!requiredService || !selectedInsurance) {
          return false;
        }

        if (this.insuranceType === InsuranceType.FULLY && !selectedInsurance) {
          return false;
        }

        return !!selectedInsurance.insuranceUseCase || selectedInsurance?.number === InsuranceNumber.ANDERE;
      }),
      map(([requiredService, selectedInsurance]: [RequiredService, Insurance]) =>
        this.insuranceTypeExitIdService.getExitIdForInsuranceType(
          requiredService,
          this.insuranceType,
          selectedInsurance
        )
      ),
      tap((exitId: InsuranceSelectionExitIds) => {
        if (exitId === "channelSwitch") {
          this.processFacade.setChannelSwitchReason("UNINSURED");
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public async saveForm() {
    this.insuranceFacade.resetSelectedInsurance();

    if (
      !this.insuranceType ||
      this.insuranceType === InsuranceType.PAID_BY_PERPETRATOR ||
      this.insuranceType === InsuranceType.NONE
    ) {
      return;
    }

    const oldValue = await firstValueFrom(this.insuranceFacade.type$);
    if (oldValue !== this.insuranceType) {
      this.gdvFacade.setFetch(null);
    }

    this.insuranceFacade.setInsuranceType(this.insuranceType);

    if (this.insuranceType === InsuranceType.FULLY) {
      const selectedInsurance = this.form.controls.insurance.value;
      this.insuranceFacade.setSelectedInsuranceById(selectedInsurance);
      this.insuranceFacade.setInsuranceUseCaseOfSelectedInsurance(this.insuranceUseCase);
    }
  }

  public handleClickDamageIsPayedByDamagingParty(): void {
    this.trackPayedByDamagingParty();
    this.insuranceFacade.setInsuranceType(InsuranceType.PAID_BY_PERPETRATOR);
    this.processFacade.setChannelSwitchReason("PAID_BY_DAMAGING_PARTY");
    this.skipFormWithExitId("channelSwitch");
  }

  public handleClickCarIsNotInsured(): void {
    this.trackUninsured();

    this.insuranceFacade.handleCarIsNotInsured();

    this.damageFacade.requiredService$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((requiredService: RequiredService) => {
        if (requiredService === RequiredService.REPLACE) {
          this.processFacade.setChannelSwitchReason("UNINSURED");
          this.skipFormWithExitId("channelSwitch");
        } else {
          this.skipFormWithExitId("noDateNoGdv");
        }
      });
  }

  private validateInsuranceSelection(insuranceForm: AbstractControl): Record<string, boolean> {
    if (insuranceForm.get("insuranceType").value !== InsuranceType.FULLY) {
      return null;
    }

    return insuranceForm.get("insurance").value ? null : { required: true };
  }

  private trackRequiredService() {
    this.damageFacade.requiredService$
      .pipe(
        filter((requiredService: RequiredService) => !!requiredService),
        first(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((requiredService: RequiredService) => {
        const jobType = eventRequiredServiceMapping.get(requiredService);
        this.trackingService.trackEvent({
          eventAction: "job-type",
          eventLabel: jobType,
          damage: {
            "job-type": jobType
          }
        } as Partial<TrackingEvent>);
      });
  }

  private trackInsuranceEvent(insuranceType: TrackingInsuranceType, channelSwitch: boolean = false) {
    this.insuranceFacade
      .getInsuranceNameById(this.selectedInsurance)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((insurer: string) => {
        this.trackingService.trackEvent({
          eventAction: "insurance-type-selection",
          eventLabel: insuranceType,
          insurance: {
            type: insuranceType,
            ...(insurer && { insurer })
          }
        } as Partial<TrackingEvent>);

        if (channelSwitch) {
          this.trackChannelSwitch(insuranceType);
        }
      });
  }

  private trackChannelSwitch(insuranceType: TrackingInsuranceType) {
    this.trackingService.trackEvent({
      eventAction: "channel-switch",
      eventLabel: insuranceType
    } as Partial<TrackingEvent>);
  }

  private trackInsuranceType() {
    const insuranceType = this.eventInsuranceTypeMapping.get(this.insuranceType);
    this.trackInsuranceEvent(insuranceType);
  }

  private trackPayedByDamagingParty() {
    this.trackInsuranceEvent("paid-by-damaging-party", false);
  }

  private trackUninsured() {
    this.trackInsuranceEvent("uninsured", false);
  }

  private initInsuranceSelection() {
    this.form.controls.insuranceType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: InsuranceType) => {
        if (value === InsuranceType.FULLY) {
          this.form.controls.insurance.enable();
        } else {
          this.form.controls.insurance.disable();
        }
      });
  }

  protected restoreFromEntryState() {
    combineLatest(this.insuranceFacade.chatInsuranceType$, this.insuranceFacade.selectedInsurance$)
      .pipe(
        take(1),
        filter(
          ([insuranceType, selectedInsurance]: [ChatInsuranceType, Insurance]) =>
            !!insuranceType && !!selectedInsurance && insuranceType === ChatInsuranceType.FULLY
        )
      )
      .subscribe(([, selectedInsurance]: [insuranceType: ChatInsuranceType, selectedInsurance: Insurance]) => {
        setTimeout(() => {
          this.form.controls.insuranceType.setValue(InsuranceType.FULLY);
          this.form.controls.insurance.setValue(selectedInsurance.id);
          this.cdr.markForCheck();
          this.goForwardBtnClicked();
        }, 100);
      });
  }
}
