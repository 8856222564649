<section class="costs-container">
  <cg-split-view [master]="master" [showBranding]="true"></cg-split-view>
</section>

<ng-template #master>
  <div class="costs">
    <cg-olb-headline class="costs--headline" [title]="'opportunityFunnelCosts.title' | transloco"></cg-olb-headline>

    @if (tabs$ | async; as tabs) {
      <cg-tabs class="max-w-content" [tabs]="tabs" (tabChanged)="setSelectedIndex($event)"></cg-tabs>
    }

    <ng-template #kaskoTab>
      <ng-container [ngTemplateOutlet]="tab" [ngTemplateOutletContext]="{ price: kaskoPrice }"></ng-container>
    </ng-template>
    <ng-template #nonKaskoTab>
      <ng-container [ngTemplateOutlet]="tab" [ngTemplateOutletContext]="{ price: nonKaskoPrice }"></ng-container>
    </ng-template>

    <ng-template #kaskoPrice>
      {{ "opportunityFunnelCosts.text.priceKasko" | transloco }}
    </ng-template>
    <ng-template #nonKaskoPrice>
      <span class="text-bold green small">
        {{ "opportunityFunnelCosts.text.priceNoKaskoPrefix" | transloco }}
      </span>
      {{ priceNoKasko }} €
    </ng-template>

    <ng-template #tab let-price="price">
      <div class="costs--tab-content">
        <span class="text-bold green">{{ "opportunityFunnelCosts.text.exchange" | transloco }}</span>
        <div class="costs--tab-content-grid">
          <p class="text-bold">
            {{
              (isSideWindowDamage ? "opportunityFunnelCosts.text.sideWindow" : "opportunityFunnelCosts.text.rearWindow")
                | transloco
            }}
          </p>
          <p class="right text-bold green big h-[1.875rem]">
            <ng-container [ngTemplateOutlet]="price" [ngTemplateOutletContext]="{}"></ng-container>
          </p>
        </div>
        <cg-list
          class="[&>ul>li]:items-start [&>ul]:!mt-2"
          [content]="listContent"
          [shouldTranslateItem]="true"
        ></cg-list>
      </div>
    </ng-template>
    <div class="mt-4 flex max-w-content flex-col gap-2 t:mt-6 t:flex-row t:gap-4">
      <button
        class="costs--button part[icon-text-wrapper]:px-4 w-full part-[icon|>mat-icon>svg>path]:fill-white part-[icon|>mat-icon>svg]:fill-transparent"
        [btnText]="'opportunityFunnelCosts.ctaTitle' | transloco"
        [icon]="calendarIcon"
        (click)="goForward()"
        cg-base-button
        data-cy="btn-book-online"
      ></button>
      <button
        class="costs--button w-full part-[icon-text-wrapper]:px-4"
        [btnText]="'opportunityFunnelCosts.ctaCallbackTitle' | transloco"
        [icon]="phoneIcon"
        (click)="goToChannelSwitch()"
        cg-base-button
        variation="secondary"
        data-cy="btn-channel-switch"
      ></button>
    </div>
  </div>
</ng-template>
