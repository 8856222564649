<section class="damage-rear-window--container">
  <ng-template #master>
    @if (form) {
      <ng-container [formGroup]="form">
        <cg-olb-headline
          class="damage-rear-window--headline"
          [title]="'opportunityFunnelDamageRear.title' | transloco"
        ></cg-olb-headline>
        <cg-damage-assessment-tile
          class="damage-rear-window--selection"
          [content]="opportunityFunnelDamageRearTileContent"
        ></cg-damage-assessment-tile>
      </ng-container>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
