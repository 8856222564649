<cg-insurance-base-message
  [insuranceMessageGridItems]="gridItems"
  [headlineTranslation]="'insuranceCoveredMessage.headline' | transloco"
  [subHeadlineKey]="'insuranceCoveredMessage.subHeadline'"
  [footerTemplateRef]="footerTemplate"
  (closedClicked)="onClosedClicked()"
></cg-insurance-base-message>

<ng-template #footerTemplate>
  <cg-olb-footer (forwardBtnClicked)="onGoForwardClicked()" (backwardBtnClicked)="onGoBackClicked()"></cg-olb-footer>
</ng-template>
