<cg-olb-headline
  class="mb-2 block t:mb-4"
  [title]="'channelSwitch.fallbackSuccessOpportunityFunnel.title' | transloco"
></cg-olb-headline>

<p
  class="channel-switch--paragraph"
  [innerHTML]="'channelSwitch.fallbackSuccessOpportunityFunnel.text' | transloco | safeContent"
  data-cy="channel-switch--headline"
></p>

@if (showCtaButton) {
  <cg-cta
    class="channel-switch--call-success-btn mt-4 block"
    [content]="navigateToHome"
    (clickedFn)="terminateOlb()"
    [translateContent]="true"
  ></cg-cta>
}
