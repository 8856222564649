import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  Feature,
  FeatureSwitchAlternativeDirective,
  FeatureSwitchDirective,
  SwitchableComponent
} from "@cg/feature-toggle";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { OLB_PROCESS_FLOW_MODEL, ProcessFlow, ScrollService } from "@cg/olb/shared";
import { BreakpointService, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { CustomerAddressComponent } from "../customer-address/customer-address.component";
import { CustomerAddressInputs } from "../customer-address/models/input-fields.model";
import { NewCustomerAddressComponent } from "../new-customer-address/new-customer-address.component";

@Component({
  selector: "cg-customer-address-wrapper",
  standalone: true,
  imports: [
    CommonModule,
    SwitchableComponent,
    FeatureSwitchDirective,
    FeatureSwitchAlternativeDirective,
    CustomerAddressComponent,
    NewCustomerAddressComponent,
    SplitViewComponent,
    TranslocoPipe
  ],
  templateUrl: "./customer-address-wrapper.component.html",
  styles: ""
})
export class CustomerAddressWrapperComponent
  extends BaseDirective<AddFormControls<CustomerAddressInputs>>
  implements OnDestroy
{
  public featureKey = Feature.NEW_ADDRESS_TILE;

  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    protected readonly trackingService: TrackingService,
    protected readonly breakpointService: BreakpointService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public getExitIdForSavedForm(): Observable<string> {
    return of("");
  }

  public saveForm(): void {}
}
