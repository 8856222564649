import { DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ABTest } from "@cg/core/utils";
import { OptimizelyExperiment } from "@cg/core/enums";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-distance-label",
  templateUrl: "./new-appointment-distance-label.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe]
})
export class NewAppointmentDistanceLabelComponent {
  @Input()
  public distance: number;
}
